import React from 'react';
import utils from "../../../lib/utils";
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import Popover from 'react-bootstrap/lib/Popover';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import Button from 'react-bootstrap/lib/Button';

export default class Sign extends React.Component {

  componentDidMount () {

    this.props.loadSign();
  }

  render () {

    if (this.props.state && this.props.state.stepLoaded && !this.props.state.error) {

      let checkPopover = <Popover id="sign-check">
        <div className={"sign-check-popover-content"} dangerouslySetInnerHTML={{__html: utils.sanitize(this.props.state.labels["graingerBookingJourney.sign.checkPopoverLabel"])}}></div>
      </Popover>;

      return <div className={"sign-agreement"}>
        <h3 dangerouslySetInnerHTML={{__html: utils.sanitize(this.props.state.labels["graingerBookingJourney.sign.title"])}}></h3>
        <h4 dangerouslySetInnerHTML={{__html: utils.sanitize(this.props.state.labels["graingerBookingJourney.sign.subtitle"])}}></h4>
        <div className={"full-form"}>
          <embed className={"img-responsive"} src={this.props.state.sign.response.signingUrl.esignUrl} style={{width: 100 + "%", height: 100 + "%"}}></embed>
        </div>
        <div className={"message"}>
          <div className={"text-center info-bottom"}>
            {this.props.state.labels["graingerBookingJourney.sign.infoBottom"]}
            <OverlayTrigger trigger="click" placement="right" overlay={checkPopover} rootClose>
              <Button bsStyle="link" className={"sign-check-popover-btn"}><i className='fa fa-question-circle'></i></Button>
            </OverlayTrigger>
            <h4>{this.props.state.labels["graingerBookingJourney.sign.infoBottom"]}</h4>
            <div className={"info"}>{this.props.state.labels["graingerBookingJourney.sign.info"]}</div>
            <div className={"text-center"} dangerouslySetInnerHTML={{__html: utils.sanitize(this.props.state.labels["graingerBookingJourney.sign.termAndConditionsLink"])}}></div>
          </div>
        </div>
      </div>;
    }
    else return <div>Loading step...</div>;
  }
}
