import React from 'react';
import FormControl from 'react-bootstrap/lib/FormControl';
import { PexCommonField } from "./pex-common-field.jsx";
import PropTypes from 'prop-types';

export class PexDropDown extends React.Component {
  render () {
    return <PexCommonField {...this.props} ><SelectField noPlaceHolder = {this.props.noPlaceHolder}>{this.props.children}</SelectField></PexCommonField>;
  }
}

PexDropDown.propTypes = {
  label: PropTypes.string.isRequired,
  fieldKey: PropTypes.string.isRequired,
  errors: PropTypes.object.isRequired,
  updateField: PropTypes.func.isRequired,
};

class SelectField extends React.Component {
  handleChange (event) {
    if (event.target.value === "-") {
      this.props.onChange({target: {value: ""}});
    } else {
      this.props.onChange(event);
    }
  }

  render () {
    return <FormControl value={this.props.value || "-"} componentClass="select" onChange={::this.handleChange} disabled={this.props.disabled}>
      {this.props.noPlaceHolder ? null : <option value="-">{ this.props.placeholder }</option>}
      { this.props.children }
    </FormControl>;
  }
}

SelectField.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};
