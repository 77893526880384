"use strict";

import React from 'react';

const parseWebform = function parseWebform (entityInfo) {
  let webForm = { "formAttributes": [], "formNames": {}, "entityInfo": entityInfo, "formValues": {}};
  webForm.label = entityInfo.label;
  webForm.viewName = entityInfo.viewName;
  for (let formAttribute of entityInfo.attributes) {
    webForm.formNames[formAttribute.path] = { "key": formAttribute.path, "labelError": formAttribute.path };
    if (formAttribute.mandatory === true) webForm.formNames[formAttribute.path].mandatory = true;
    if (formAttribute.dataType === "PhoneNumber") webForm.formNames[formAttribute.path].number = true;
    if (formAttribute.value !== undefined && formAttribute.fieldType === "TEXTFIELD") {
      webForm.formValues[formAttribute.path] = { "key": formAttribute.path, "value": formAttribute.value };
    }
    if (formAttribute.value !== undefined && formAttribute.fieldType === "DATE") {
      webForm.formValues[formAttribute.path] = { "key": formAttribute.path, "value": formAttribute.value };
    }

    if (formAttribute.dataType === "PhoneNumber") {
      webForm.formValues[formAttribute.path] = { "key": formAttribute.path, "value": formAttribute.bobValue.displayValue };
    }

    if (formAttribute.fieldType === "DROPDOWN") {
      formAttribute.dropdownOptions = [];
      if (formAttribute.bobValue) webForm.formValues[formAttribute.path] = { "key": formAttribute.name, "value": formAttribute.bobValue.id };
      formAttribute.selectOptions.sort(function (a, b) {return (a.displayValue > b.displayValue) ? 1 : ((b.displayValue > a.displayValue) ? -1 : 0); });
      for (let dropdownOption of formAttribute.selectOptions) {
        formAttribute.dropdownOptions.push(<option key={dropdownOption.id} value={dropdownOption.id}>{dropdownOption.displayValue}</option>);
      }
    }
    if (formAttribute.additionalAttributes) {
      let additionalClassName, additionalIconName = "";
      if (formAttribute.additionalAttributes.includes(";")) {
        additionalClassName = formAttribute.additionalAttributes.split(";")[0] ? formAttribute.additionalAttributes.split(";")[0].split("=")[1] : null;
        additionalIconName = formAttribute.additionalAttributes.split(";")[1] ? formAttribute.additionalAttributes.split(";")[1].split("=")[1] : null;
      } else if (formAttribute.additionalAttributes.includes("className")) {
        additionalClassName = formAttribute.additionalAttributes.split("=")[1];
      } else if (formAttribute.additionalAttributes.includes("formIcon")) {
        additionalIconName = formAttribute.additionalAttributes.split("=")[1];
      }
      formAttribute.iconClassName = additionalIconName ? additionalIconName : null;
      formAttribute.additionalClassName = additionalClassName ? additionalClassName : null;
    }
  }
  return webForm;
};

const getPayload = function getPayload (webForm) {
  let rtn = {
    "viewName": webForm.viewName,
    "submitParameters": [],
  };
  for (let path of Object.keys(webForm.values)) {
    if (webForm.values[path] !== "") rtn.submitParameters.push({ "name": path, "value": webForm.values[path]});
  }
  return rtn;
};

module.exports = {
  getCurrentComponent: function (steps, currentPath) {
    return getCurrentComponent(steps, currentPath);
  },
  parseWebform: function (attributes) {
    return parseWebform(attributes);
  },
  getPayload: function (webForm) {
    return getPayload(webForm);
  },

};
