import React from 'react';
import { PexCommonField } from "./pex-common-field.jsx";
import PexRadioSelectDropdownWithSearch from "./pex-radio-select-dropdown-with-search.jsx";
import FormControl from 'react-bootstrap/lib/FormControl';
import Col from 'react-bootstrap/lib/Col';
import Row from 'react-bootstrap/lib/Row';
import ListGroup from 'react-bootstrap/lib/ListGroup';
import ListGroupItem from 'react-bootstrap/lib/ListGroupItem';


export class PexCountryPhone extends React.Component {

  render () {
    return <PexCommonField {...this.props}><CountryPhone {...this.props}/></PexCommonField>;
  }

}

function CountryPhone (props) {

  function hanldeOnChange (event, key) {
    props.onChange(event, key);
  }

  function selectCountry (country) {
    props.selectCountry(country);
  }
  let value = props.countrySelected ? props.countrySelected.displayValue : props.textValue;
  for (let obj of props.countriesData) {
    if (obj.diallingCode === props.values.mobileCountry) value = obj.displayValue;
  }
  return <div className={props.className + "-container"}>
    <Col md={6} className="country-select">
      <PexRadioSelectDropdownWithSearch onClickOpen = {props.openCloseDropdown}
        onSelectOption = {props.selectCountry}
        onClickOutside = {props.clickOutside}
        onSearchTextHandler = {props.onSearchTextHandler}
        collectionName = {props.countryCollectionName}
        fieldKey = {props.countryKey}
        key = {props.countryKey}
        data = {props.countriesData}
        className = {props.className + "-prefix"}
        elementSelected = {props.countrySelected}
        textFilterValue = {props.textFilterValue}
        value = {value}
        open = {props.isOpen}
        iconClasses = "flag flag-24 flag"
      />
    </Col>
    <Col md={6} className="phone-number">
      <FormControl className={props.className + "-personal-number"}
        value={props.values[props.phoneKey] || ""}
        placeholder={props.translate("phonePlaceholder")}
        type="text"
        onChange={ (ev) =>{ hanldeOnChange(ev, props.phoneKey);}} />
    </Col>
  </div>;
}
