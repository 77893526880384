import React from 'react';
import Popover from 'react-bootstrap/lib/Popover';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import Button from 'react-bootstrap/lib/Button';
import utils from "../../../lib/utils";


export default class GetStartedA extends React.Component {

  componentDidMount () {
    this.props.loadGetStartedA();
  }

  render () {

    if (this.props.state && this.props.state.stepLoaded && !this.props.state.error) {
      let getStartedATitleLabel = utils.format_path(this.props.state.labels["graingerBookingJourney.getStartedA.title"], {"USERNAME": this.props.state.user.firstName});
      let informationPopover = <Popover id="getStartedA-check-information">
        <div className={"getStartedA-popover-content"} dangerouslySetInnerHTML={{__html: utils.sanitize(this.props.state.labels["graingerBookingJourney.getStartedA.popoverInformationLabel"])}}></div>
      </Popover>;
      return <div className={""}>
        <div className={""}>
          <h3 className={"getStartedA-title"} dangerouslySetInnerHTML={{__html: utils.sanitize(getStartedATitleLabel)}}></h3>
          <h4 className={"getStartedA-info-1"} dangerouslySetInnerHTML={{__html: utils.sanitize(this.props.state.labels["graingerBookingJourney.getStartedA.infoLabel"])}}></h4>
          <div className={"message"}>
            {this.props.state.labels["graingerBookingJourney.getStartedA.info2Label"]}
            <div className={"getStartedA-info-3 alert alert-info"} dangerouslySetInnerHTML={{__html: utils.sanitize(this.props.state.labels["graingerBookingJourney.getStartedA.info3Label"])}}></div>
            <div className={"text-center info-bottom"}>
              <h4 className={"getStartedA-check-information"}>
                {this.props.state.labels["graingerBookingJourney.getStartedA.checkInformationLabel"]}
                <OverlayTrigger trigger="click" placement="right" overlay={informationPopover} rootClose>
                  <Button bsStyle="link" className={"getStartedA-popover-btn"}><i className='fa fa-question-circle'></i></Button>
                </OverlayTrigger>
              </h4>
              <div className={"getStartedA-conditions text-center"} dangerouslySetInnerHTML={{__html: utils.sanitize(this.props.state.labels["graingerBookingJourney.getStartedA.conditionsLink"])}}></div>
            </div>
          </div>
        </div>
      </div>;
    }
    else return <div>Loading step...</div>;
  }
}
