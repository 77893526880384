import React from 'react';
import Col from 'react-bootstrap/lib/Col';
import Row from 'react-bootstrap/lib/Row';
import Button from 'react-bootstrap/lib/Button';
import Form from 'react-bootstrap/lib/Form';
import Panel from 'react-bootstrap/lib/Panel';
import { PexText } from "../form-components/pex-text.jsx";
import translateService from "../../services/translate-service";
import utils from "../../../lib/utils";
import TagManager from 'react-gtm-module';
import PexCookiePolicy from "../../../legal/pex-cookie-policy.jsx";

export default class PexResetPassword extends React.Component {

  render () {

    let self = this;

    function translate (name) {
      return translateService.getLabelsTranslated(self.props.labels, "searchApplet.pexResetPassword.", name, self.props.defaultLabels);
    }

    let formProps = {
      values: this.props.data.values,
      errors: this.props.data.fieldsWithErrors,
      submitted: this.props.data.submitted,
      updateField: this.props.updateResetPasswordForm,
    };

    let title = <h3 className="pex-reset-password-form-title"><strong>{translate("title")}</strong></h3>;
    let content = <Form horizontal onSubmit={this.props.startLostPassword}>
      <Panel header = {title} >
        <h4 className="pex-reset-password-instructions">{translate("instructionsLabel")}</h4>
        <PexText fieldKey="email" className = "pex-reset-password-form-email" iconClassName="fa fa-envelope-o" label={translate("emailLabel")} placeholder={translate("emailPlaceholder")} {...formProps } />
        <Button bsStyle="success" className="pex-reset-password-form-send-button" type="submit">{translate("sendButton")}</Button>
        <ApiError error={this.props.resetPasswordError}/>
      </Panel>
    </Form>;

    let emailSent = <Panel header = {title} bsStyle="success" >
      <div className="pex-reset-password-sent">{translate("passwordSentLabel")}</div>
    </Panel>;

    return <Row>
      <Col sm={6} className="pex-reset-password col-centered">
        {this.props.showResetPasswordMessage ? emailSent : content}
      </Col>
      <PexCookiePolicy colMd={9} />

    </Row>;
  }

}

function ApiError (props) {
  if (props.error) return <p className="pex-reset-password-error" dangerouslySetInnerHTML={{__html: utils.sanitize(props.error)}}></p>;
  return null;
}
