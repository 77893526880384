import React from 'react';
import Col from 'react-bootstrap/lib/Col';
import Row from 'react-bootstrap/lib/Row';
import Button from 'react-bootstrap/lib/Button';
import Form from 'react-bootstrap/lib/Form';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import Panel from 'react-bootstrap/lib/Panel';
import Alert from 'react-bootstrap/lib/Alert';
import { PexText } from "../form-components/pex-text.jsx";
import { PexPassword } from "../form-components/pex-password.jsx";
import { PexDropDown } from "../form-components/pex-drop-down.jsx";
import { PexCheckbox } from "../form-components/pex-checkbox.jsx";
import {PexCountryPhone} from '../form-components/pex-country-phone.jsx';
import utils from '../../../lib/utils.js';
import translateService from '../../services/translate-service.js';
import TagManager from 'react-gtm-module';
import PexCookiePolicy from '../../../legal/pex-cookie-policy.jsx';

export default class PexRegister extends React.Component {

  componentDidMount () {
    if (this.props.gtmId) {

    }
  }

  render () {
    let self = this;
    function translate (name) {
      return translateService.getLabelsTranslated(self.props.state.labels, "searchApplet.pexRegister.", name, self.props.defaultLabels);
    }

    let formProps = {
      values: this.props.data.values,
      errors: this.props.data.fieldsWithErrors,
      submitted: this.props.data.submitted,
      updateField: this.props.updateRegisterForm,
    };

    let booleanProps = {
      values: this.props.data.values,
      errors: this.props.data.fieldsWithErrors,
      submitted: this.props.data.submitted,
      updateField: this.props.updateBoolean,
    };

    var showUniversities = false;
    var universitiesMessage = new String;

    if (this.props.universitiesFilteredByLocation === "true") {
      if (this.props.state.getFormCollectionsResponse !== undefined && this.props.state.getFormCollectionsResponse.universities && this.props.state.getFormCollectionsResponse.universities.length >= 1) {
        var universities = [];
        showUniversities = true;
        for (let university of this.props.state.getFormCollectionsResponse.universities) universities.push(<option key={university.id} value={university.lookupValue}>{university.displayValue}</option>);
      } else {
        universitiesMessage = "showUniversities is set to true but there are no Universities being returned by the system";
      }
    }

    let genders = [];
    if (this.props.state.getFormCollectionsResponse.genders) {
      for (let gender of this.props.state.getFormCollectionsResponse.genders) genders.push(<option key={gender.lookupValue} value={gender.lookupValue}>{gender.displayValue}</option>);
    }

    //TODO: refactor to show the fields that we have in pexRegister. In the future it will be a webForm that's why I don't waste time now
    let updatePersonDetails = false;
    if (this.props.state.currentComponent === "updatePersonDetails") updatePersonDetails = true;
    let title = <h3 className="pex-register-form-title text-center"><strong>{updatePersonDetails ? translate("updatePersonDetailsTitle") : translate("title")}</strong></h3>;
    let disableGenderDropdown = false;
    if (this.props.state.data.settings && this.props.state.data.settings["booking.strictGenders"] === true && this.props.data.values["gender"] !== "") disableGenderDropdown = true;

    return <Row>
      <Col md={12} id="sec0" className="register col-centered">
        <Form horizontal onSubmit={this.submitForm.bind(this)}>
          <Panel header = {title} >
            <UpdateInfoLabel updatePersonDetails={updatePersonDetails} text={translate("updateInfoLabel")} />
            <h4 className="register-self-details-label">{translate("detailsLabel")}</h4>
            <PexText fieldKey="firstName" className = "pex-register-first-name" iconClassName="fa fa-user" label={translate("firstNameLabel")} {...formProps } />
            <PexText fieldKey="lastName" className = "pex-register-last-name" iconClassName="fa fa-user" label={translate("lastNameLabel")} {...formProps } />
            { showUniversities ? <PexDropDown fieldKey="uniCollegeSchoolAttending" className = "register-form-uni-college-school-attending" iconClassName="fa fa-university" label={translate("universityLabel")} placeholder={translate("universityPlaceholder")} {...formProps } >{universities}</PexDropDown> : universitiesMessage }
            <PexDropDown fieldKey="gender" className = "pex-register-gender" iconClassName="fa fa-user" label={translate("genderLabel")} placeholder={translate("genderPlaceholder")} {...formProps } disabled={disableGenderDropdown}>{genders}</PexDropDown>
            <PexCountryPhone fieldKey="countryPhone"
              countryKey="mobileCountry"
              phoneKey="mobileNumber"
              className = "pex-register-phone-number"
              iconClassName="fa fa-phone"
              translate={translate.bind(this)}
              {...formProps}
              label={translate("phoneLabel")}
              countriesData = {this.props.state.countries}
              countrySelected = {this.props.state.pexRegister.countrySelected}
              openCloseDropdown = {this.props.openCloseDropdown}
              clickOutside = {this.props.clickOutside}
              onSearchTextHandler = {this.props.onSearchTextHandler}
              isOpen = {this.props.state.isOpen}
              textValue = {translate("selectCountryLabel")}
              countryCollectionName = "country-prefix"
              textFilterValue = {this.props.state.pexRegister.textFilterValue}
              selectCountry = {this.props.selectCountry}/>
            <h4 className="register-contact-details-label">{translate("contactDetailsLabel")}</h4>
            <PexText disabled={updatePersonDetails} fieldKey="username" className = "pex-register-username" iconClassName="fa fa-envelope-o" label={translate("emailLabel")} {...formProps } />
            {!updatePersonDetails ? <PexText fieldKey="usernameconfirm" className = "pex-register-confirm-username" iconClassName="fa fa-envelope-o" label={translate("confirmEmailLabel")} {...formProps } /> : null}
            {!updatePersonDetails ? <PexPassword fieldKey="password" className = "pex-register-password" iconClassName="fa fa-lock" label={translate("passwordLabel")} {...formProps } /> : null}
            {!updatePersonDetails ? <PexPassword fieldKey="passwordconfirm" className = "pex-register-confirm-password" iconClassName="fa fa-lock" label={translate("confirmPasswordLabel")} {...formProps } /> : null}
            <PexCheckbox fieldKey="noSpam" className="pex-register-no-spam" iconClassName="fa fa-lock" label={translate("noSpamLabel")} {...booleanProps} hideIcon={true} hideLabel={true} />
            <FormGroup>
              <Col componentClass={ControlLabel} sm={3}></Col>
              <Col sm={9}>
                <SubmitButton registerText={translate("registerButton") + " "}
                  updateText={translate("updateButton") + " "}
                  updatePersonDetails={updatePersonDetails}/>
                <LogOutButton label={translate("logOutButton")}
                  updatePersonDetails={updatePersonDetails}
                  logout={this.props.startLogout}/>
                <ApiError error={this.props.data.APIerror}/>
                <BackToLoginLink updatePersonDetails={updatePersonDetails}
                  backToLogin={this.props.backToLogin}
                  linkText={translate("loginLink")}/>
              </Col>
            </FormGroup>
          </Panel>
        </Form>
      </Col>
      <PexCookiePolicy colMd={12}/>
    </Row>;
  }

  submitForm (event) {
    if (this.props.state.currentComponent === "updatePersonDetails") this.props.startUpdatePersonDetails(event);
    else this.props.startRegisterUser(event);
  }
}

function ApiError (props) {
  if (props.error) return <Alert bsStyle="danger"><span className="pex-register-api-error" dangerouslySetInnerHTML={{__html: utils.sanitize(props.error)}}></span></Alert>;
  return null;
}

function BackToLoginLink (props) {
  if (!props.updatePersonDetails) return <Button bsStyle="link" onClick={props.backToLogin} className="pex-back-to-login">{props.linkText}</Button>;
  return null;
}

function SubmitButton (props) {
  let text = props.registerText;
  if (props.updatePersonDetails) text = props.updateText;
  return <Button bsStyle="success" className="pex-register-button btn-block" type="submit">{text}<span className="fa fa-send" /></Button>;
}

function UpdateInfoLabel (props) {
  if (props.updatePersonDetails) return <p className="update-info-label">{props.text}</p>;
  return null;
}

function LogOutButton (props) {
  if (props.updatePersonDetails) return <Button className="update-logout-btn  btn-block" onClick={props.logout} bsStyle="danger">{props.label}</Button>;
  return null;
}
