"use strict";

let utils = require("../../lib/utils");
import React from 'react';

function initForm (formNames, requiredFields) {
  //NOTE: requiredFields
  //- index 0: fieldName
  //- index 1: labelError TODO: make it optional too
  //- index 2: defaultValue(optional)
  let formValues = {};

  function setUpExtraRequiredFields () {
    for (let row of requiredFields.split(";").filter(function (el) {return el.length !== 0;})) {
      let requiredField = row.split(":");
      formNames[requiredField[0]] = {"key": requiredField[0], "labelError": requiredField[1]};
    }
  }

  function setDefaultValue () {
    for (let reqfield of requiredFields.split(";").filter(function (el) {return el.length !== 0;})) {
      if (reqfield.split(":").length === 3) {
        formValues[reqfield.split(":")[0]] = formValues[reqfield.split(":")[0]] = reqfield.split(":")[2];
      }
    }
  }

  if (requiredFields) setUpExtraRequiredFields ();
  for (let formName of Object.keys(formNames)) formValues[formName] = "";
  if (requiredFields) setDefaultValue ();

  //TODO: change loginError by error and chnge as well in login component, same for register
  let form = {
    "values": formValues,
    "formNames": formNames,
    "submitted": false,
    "fieldsWithErrors": checkForm(formValues, formNames).fieldsWithErrors,
    "canSubmit": false,
    "APIerror": undefined,
  };
  return form;
}

function checkForm (values, formNames) {
  let error_list = [];
  let fieldsWithErrors = {};
  let has_errors = false;

  function checkEmailFormat (field) {
    var re = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    if (!fieldsWithErrors[field.key] && values[field.key] && !re.test(values[field.key])) {
      has_errors = true;
      fieldsWithErrors[field.key] = field.labelError + " is not valid";
    }
  }
  function checkSameValue (field) {
    if (!fieldsWithErrors[field.key] && values[field.key] !== values[field.sameValue] ) {
      has_errors = true;
      fieldsWithErrors[field.key] = field.labelError + " is not the same as " + formNames[field.sameValue].labelError.toLowerCase();
    }
  }
  function checkNumberValue (field) {
    if (!fieldsWithErrors[field.key] && values[field.key] && isNaN(values.phoneNumber) ) {
      has_errors = true;
      fieldsWithErrors[field.key] = field.labelError + " is not valid";
    }
  }

  //First validation rules for each field. If a field is required, with format email and has to be equal than other field.
  //First we check it has value and we show this error, we check the format just if it has not a current error, same for all validations
  //that's fieldsWithErrors is empty when start the validation
  for (let field of Object.keys(formNames)) {
    if (!values[field]) { //PRIORITY ONE. REQUIRED
      has_errors = true;
      fieldsWithErrors[field] = formNames[field].labelError + " is required";
    }
    if (formNames[field].email) checkEmailFormat(formNames[field]);
    if (formNames[field].sameValue) checkSameValue(formNames[field]);
    if (formNames[field].number) checkNumberValue(formNames[field]);
  }
  return {canSubmit: !has_errors, fieldsWithErrors: fieldsWithErrors};
}


module.exports = {
  initWidgetDemoForm: function (requiredFields) {
    let widgetDemoForm = {
      "area": { "key": "area", "labelError": "area"},
      "building": { "key": "building", "labelError": "property"},
      "roomType": { "key": "roomType", "labelError": "room type"},
    };
    return initForm(widgetDemoForm, requiredFields);
  },
  checkForm: function (values, formNames) {
    return checkForm(values, formNames);
  },

};
