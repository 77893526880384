import React from 'react';
import {ShowError} from './show-error.jsx';
import Carousel from 'react-bootstrap/lib/Carousel';
import Col from 'react-bootstrap/lib/Col';
import Button from 'react-bootstrap/lib/Button';
import translateService from '../../services/translate-service.js';
import FormControl from 'react-bootstrap/lib/FormControl';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import DatePicker from 'react-datepicker';
import moment from "moment";
import utils from "../../../lib/utils";
import PexCookiePolicy from "../../../legal/pex-cookie-policy.jsx";

export function BookingSummary (props) {
  let offer = props.state.bookNowData && props.state.bookNowData.offer ? props.state.bookNowData.offer : null;
  let myRoom = props.state.bookNowData && props.state.bookNowData.room ? props.state.bookNowData.room : null;
  if (!offer || !myRoom) {
    let footerError = <Button className="go-to-search-btn" bsStyle="success" onClick={props.redirectToSearchApplet}>
      <i className="fa fa-icon"></i> <span className="hidden-xs">{translate("goToSearchButtonLabel")}</span>
    </Button>;
    return <ShowError error={translate("errorLabel")} classname="booking-summary-error" header={translate("somethingWrongLabel")} footer={footerError}/>;
  }

  function translate (name) {
    return translateService.getLabelsTranslated(props.state.labels, "searchApplet.bookingSummary.", name, props.defaultLabels);
  }
  let continueDisabled = true;
  if (props.state.flexibleDateSelected || props.state.criteria.startDate || (!offer.startUntil && offer.start)) {
    continueDisabled = false;
  }
  let startDate = props.state.flexibleDateSelected;
  if (!offer.startUntil) startDate = offer.start;
  else if (props.state.criteria.startDate && props.state.criteria.startDate !== "-") startDate = props.state.criteria.startDate;

  return <div className="panel booking-summary-container">
    <div className="panel-header">
      <h3 className="booking-summary-title text-center">{translate("title")}</h3>
      <div className="booking-summary-room-title">
        <h3>{myRoom.propertyType}</h3>
      </div>
    </div>
    <Carousel>
      {props.searchService.getCarousel(myRoom.images)}
    </Carousel>
    <div className="panel-body">
      <div className="booking-summary-room-information">
        <Col md={6}>
          <TextValue id="buildingAndAreaInput" className="buildingAndArea" label={ translate("addressLabel") + ": " } placeholder={myRoom.building + ", " + myRoom.place}/>
          {myRoom.features ? <TextValue id="featuresInput" className="features" label={ translate("featuresLabel") + ": " } placeholder={myRoom.features}/> : null}
          <TextValue id="floorInput" className="floor" label={ translate("floorLabel") + ": " } placeholder={myRoom.floor}/>
          <TextValue id="portfolioInput" className="portfolio" label={ translate("portfolioLabel") + ": " } placeholder={myRoom.portfolio}/>
        </Col>
        <Col md={6}>
          {myRoom.shortDescription ? <div className="form-group description">
            <label htmlFor="shortDescriptionInput">{translate("descriptionLabel") + ": "} </label>
            <div className="description-content" dangerouslySetInnerHTML={{__html: utils.sanitize(myRoom.shortDescription)}}></div>
          </div> : null}
          <TextValue id="roomPriceInput" className="room-price" label={ translate("priceLabel") + ": " } placeholder={utils.format_rate(offer.price.number, offer.price.unit)}/>
        </Col>
        <Col md={12}>
          <div className="col-md-3 col-no-padding">
            <StartDate translate={translate.bind(this)}
              flexibleDateSelected={props.state.flexibleDateSelected}
              offer={offer}
              handleBookDate={props.handleBookDate}
              startDate={startDate}/>
          </div>
          <div className="col-md-3">
            <TextValue id="contractEnd" className="booking-summary-contract-end" label={ translate("contractEndLabel") + ": " } placeholder={offer.end ? moment(offer.end).format('DD/MM/YY') : null}/>
          </div>
        </Col>
      </div>
    </div>
    <div className="panel-footer">
      <Button className="continue-booking-btn"
        disabled={continueDisabled}
        onClick={function () { props.handleBookNow(offer.lookupValue, startDate, myRoom, myRoom.area, offer); }}
        bsStyle="primary" >{translate("continueLabel")}</Button>
    </div>
    <PexCookiePolicy colMd={12} />
  </div>;

}
//TODO: if appears a new element which needs dangerouslySetInnerHTML like 'shortDescriptionInput', to create a function for both, it is important to use React-Bootstrap instead of directly bootstrap
//because they are passing everything to bootstrap 4, so the classes will render directly bootstrap 4 if some day we want upgrade the react-bootstrap library
function TextValue (props) {
  return <FormGroup className={props.className}>
    <label htmlFor={props.id}>{props.label} </label>
    <FormControl id={props.id} type={"text"} disabled={true} placeholder={props.placeholder} />
  </FormGroup>;
}

function StartDate (props) {
  let dateSelectedInSearch = <div className="booking-summary-date-picker">
    <label htmlFor="flexible-date-picker">{props.translate("contractStartLabel")}</label>
    <DatePicker className="form-control summary-date-picker"
      dateFormat="DD/MM/YY"
      id="flexible-date-picker"
      value={props.flexibleDateSelected ? moment(props.flexibleDateSelected).format('DD/MM/YY') : null}
      placeholderText={props.translate("datePlaceholder")}
      label={props.translate("contractStartLabel")}
      minDate={moment(props.offer.start)}
      maxDate={moment(props.offer.startUntil)}
      onChange={props.handleBookDate}/>
  </div>;
  if (props.startDate && !props.flexibleDateSelected) {
    dateSelectedInSearch = <div className="booking-summary-date-selected">
      <TextValue id="date-selected" className="date-selected" label={props.translate("contractStartLabel")} placeholder={props.startDate ? moment(props.startDate).format('DD/MM/YY') : null}/>
    </div>;
  }
  return dateSelectedInSearch;
}
