import React from 'react';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';

export default class Referencing extends React.Component {

  componentDidMount () {
    this.props.loadReferencing();
  }

  render () {

    if (this.props.state && this.props.state.stepLoaded && !this.props.state.error) {
      //URL EXAMPLE -> https://graingerqa.pexsoftware.com/enterprise/Test/vanMildert.jsp?tenancyId=T00120312
      let vanMildertUrl = window.location.origin + "/enterprise/Test/vanMildert.jsp?tenancyId=" + this.props.state.stay.lookupValue;
      return <div className={""}>
        <Row>
          <Col sm={12}>
            <div className={"referencing-iframe embed-responsive embed-responsive-16by9"}>
              <iframe className={"embed-responsive-item"} src={vanMildertUrl}></iframe>
            </div>
          </Col>
        </Row>
      </div>;
    }
    else return <div>Loading step...</div>;
  }
}


