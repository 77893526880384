import React from 'react';
import {combineReducers, createStore} from "redux";
import ajax from '../lib/ajax.js';
let api = require('../lib/api.js')(ajax);
import formService from './services/form-service.js';
import webFormService from './services/webform-service.js';
import reducer from "./reducers/index.js";
import {Dashboard} from "./components/steps/dashboard.jsx";
import {NewDirectDebit} from "./components/steps/new-direct-debit.jsx";
import {Agreement} from "./components/steps/agreement.jsx";
import {DeclineAcceptDirectDebit} from "./components/steps/decline-accept-direct-debit.jsx";
import {PaymentDetails} from "./components/steps/payment-details.jsx";
import PersonalDetails from "./components/steps/personal-details.jsx";
import {ShowError} from "./components/steps/steps-components/show-error.jsx";
import directDebitService from "./services/direct-debit-service.js";

export default class DirectDebitApplet extends React.Component {

  constructor (props) {
    super(props);
    this.store = createStore(reducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
    this.state = this.store.getState();
    this.timeOutsToCancelDD = {};
  }

  render () {
    if (this.state.directDebit.error) {
      return <ShowError error={this.state.directDebit.error}
        waiting={this.state.directDebit.waiting}
        blockScreen={this.blockScreen.bind(this)}
        goToDashboard={this.goToDashboard.bind(this)}
        validateNewDirectDebit={this.validateNewDirectDebit.bind(this)}
        getSSOKeyAndDecline={this.getSSOKeyAndDecline.bind(this)}
        createNewDirectDebitForUser={this.createNewDirectDebitForUser.bind(this)}
        validatePersonalDetails={this.validatePersonalDetails.bind(this)}
        listBank={this.listBank.bind(this)}
        getSSOKeyAndGetDirectDebitDetails={this.getSSOKeyAndGetDirectDebitDetails.bind(this)}
        validatePaymentDetails={this.validatePaymentDetails.bind(this)}
        confirmDirectDebit={this.confirmDirectDebit.bind(this)}
        load = {this.load.bind(this)}
        getDetailsAfterPersonalDetails = {this.getDetailsAfterPersonalDetails.bind(this)}
        getWebContent = {this.getWebContent.bind(this)}
      />;
    }
    if (this.state.directDebit.detailsResponse && !this.state.directDebit.currentComponent) {
      let tenanciesFormatted = directDebitService.tenanciesToDashboard(JSON.parse(JSON.stringify(this.state.directDebit.detailsResponse.tenancies)), JSON.parse(JSON.stringify(this.state.directDebit.detailsResponse.directDebits)));
      let directDebitsFormatted = directDebitService.directDebitsToDashboard(JSON.parse(JSON.stringify(this.state.directDebit.detailsResponse.directDebits)));
      return <Dashboard tenancies={tenanciesFormatted}
        directDebits={directDebitsFormatted}
        createNewDirectDebit={this.createNewDirectDebit.bind(this)}
        amendDirectDebit={this.amendDirectDebit.bind(this)}
        startCancelDirectDebit={this.startCancelDirectDebit.bind(this)}
        undoCancel={this.undoCancel.bind(this)}
        waiting={this.state.directDebit.waiting}
        cancelling={this.state.directDebit.cancelling}
        cancelInstances={this.state.directDebit.cancelInstances}
        personid={this.state.directDebit.personid}
      />;
    } else if (this.state.directDebit.currentComponent === "new-direct-debit-step") {
      return <NewDirectDebit validateNewDirectDebit={this.validateNewDirectDebit.bind(this)}
        updateEmailDirectDebitForOther={this.updateEmailDirectDebitForOther.bind(this)}
        setDirectDebitOwner={this.setDirectDebitOwner.bind(this)}
        data={directDebitService.dataForNewDirectDebit(JSON.parse(JSON.stringify(this.state.directDebit)))}
        infoBarData={directDebitService.dataForInfoBar(JSON.parse(JSON.stringify(this.state.directDebit)))}
        webContents={this.state.directDebit.webContents} />;
    } else if (this.state.directDebit.currentComponent === "decline-accept") {
      return <DeclineAcceptDirectDebit goToPersonalDetails={this.goToPersonalDetails.bind(this)}
        cancelDirectDebit={this.cancelDirectDebit.bind(this)}
        currentDirectDebit={this.state.directDebit.currentDirectDebit}
        waiting={this.state.directDebit.waiting}
        infoBarData={directDebitService.dataForInfoBar(JSON.parse(JSON.stringify(this.state.directDebit)))}/>;
    } else if (this.state.directDebit.currentComponent === "personal-details") {
      return <PersonalDetails goToDashboard={this.goToDashboard.bind(this)}
        getWebForm={this.getSSOKeyAndWebform.bind(this)}
        updateField={this.updatePersonalDetailsWebForm.bind(this)}
        validatePersonalDetails={this.validatePersonalDetails.bind(this)}
        webFormData={this.state.webFormData}
        waiting={this.state.directDebit.waiting}
        infoBarData={directDebitService.dataForInfoBar(JSON.parse(JSON.stringify(this.state.directDebit)))}/>;
    } else if (this.state.directDebit.currentComponent === "payment-details") {
      return <PaymentDetails changePaymentType={this.changePaymentType.bind(this)}
        validatePaymentDetails={this.validatePaymentDetails.bind(this)}
        goToPersonalDetails={this.goToPersonalDetails.bind(this)}
        goToDashboard={this.goToDashboard.bind(this)}
        infoBarData={directDebitService.dataForInfoBar(JSON.parse(JSON.stringify(this.state.directDebit)))}
        updateBankForm={this.updateBankForm.bind(this)}
        selectBank={this.selectBank.bind(this)}
        updateDebitCardForm={this.updateDebitCardForm.bind(this)}
        onKeyDownBsbHandler={this.onKeyDownBsbHandler.bind(this)}
        waiting={this.state.directDebit.waiting}
        tenancyID={this.state.directDebit.tenancyID}
        paymentTypeSelected={this.state.directDebit.paymentDetails.paymentTypeSelected}
        bankForm={this.state.directDebit.paymentDetails.bankForm}
        bankList={window.pexBankList}
        bankSelected={this.state.directDebit.paymentDetails.bankSelected}
        debitCardForm={this.state.directDebit.paymentDetails.debitCardForm}
        currentDirectDebit={this.state.directDebit.currentDirectDebit}
      />;
    } else if (this.state.directDebit.currentComponent === "agreement") {
      return <Agreement agreementYesNoAction={this.agreementYesNoAction.bind(this)}
        goToPaymentDetails={this.goToPaymentDetails.bind(this)}
        goToDashboard={this.goToDashboard.bind(this)}
        validateAgreement={this.validateAgreement.bind(this)}
        agreementYesNoValue={this.state.directDebit.agreement.agreementYesNoValue}
        infoBarData={directDebitService.dataForInfoBar(JSON.parse(JSON.stringify(this.state.directDebit)))}
        agreementUrl={this.state.directDebit.agreement.agreementUrl}
        waiting={this.state.directDebit.waiting}
        holderName={this.state.directDebit.agreement.holderName}
        receiver={this.state.directDebit.agreement.receiver}
        receiverBacsReference={this.state.directDebit.agreement.receiverBacsReference}
        {...directDebitService.dataForAgreement(this.state.directDebit.currentDirectDebit)}
      />;
    }

    return <div>just a sec</div>;

  }

  componentWillUnmount () {
    //I think I could have just put
    //this.componentWillUnmount = this.store.subscribe ...
    //above.  that would have been pretty cool
    this.unsubscribe();
  }

  componentDidMount () {
    if (this.store) {
      this.unsubscribe = this.store.subscribe(function () {
        ///TODO: find a way to wrap up this code since we are using it in multiple components
        let state = {};
        for (let key of Object.keys(this.state)) state[key] = null; //we have to do this because replaceState no longer exists
        this.setState(Object.assign({}, state, this.store.getState()));

        //instead of setting the state to the redux store state, if we want to pass
        //access the redux store directly, we can replace setState with this.forceUpdate
      }.bind(this));

      let configError = null;
      if (!this.props.api) configError = "configuration error - no api specified";
      else if (!this.props.sso) configError = "configuration error - no sso specified";
      else if (this.props.waitForUndo && !Number.isInteger(Number(this.props.waitForUndo)) || ( Number.isInteger(Number(this.props.waitForUndo)) && (Number(this.props.waitForUndo) < 0 || Number(this.props.waitForUndo) > 6000 )) ) {
        configError = "configuration error - waitForUndo attribute has to be a number between 0 to 6000";
      }
      if (configError) {
        let error = { "code": configError, "response": null };
        this.store.dispatch({ type: "API_ERROR", "error": error});
      }
      else this.load();
    }
  }

  //We cannot call api within reducers, so we manage the lifecycle like a wheel, each new spin(reducer -> render -> componentDidUpdate) we execute the next method with bookingProcess.nextAction
  componentDidUpdate (prevProps, prevState) {
    // console.log(this.state.directDebit.nextAction);
    switch (this.state.directDebit.nextAction) {
      case "getSSOKeyAndGetDirectDebitDetails":
      case "getSSOKeyAndWebform":
      case "createNewDirectDebitForOther":
      case "createNewDirectDebitForUser":
      case "getSSOKeyAndWebFormSubmit":
      case "getSSOKeyAndDecline":
      case "listBank":
      case "getAgreement":
      case "confirmDirectDebit":
      case "startCancelDirectDebit":
      case "getSSOKeyAndValidateObject":
      case "getWebContent":
      case "getDetailsAfterPersonalDetails":
        this[this.state.directDebit.nextAction]();
    }
  }
  load () {
    this.store.dispatch({type: "LOAD"});
  }

  //API CALLS
  getSSOKeyAndGetDirectDebitDetails () {
    let self = this;
    api.getSSOKeyAndGetDirectDebitDetails(this.props.sso, this.props.api, {}, function (response) {
      let directDebits = response.details.directDebits;
      let noTenancyOrHolder = false;
      for (let dd of directDebits) {
        if (!dd.tenancy || !dd.directDebitHolder) {
          noTenancyOrHolder = true;
          break;
        }
      }
      if (!noTenancyOrHolder) self.store.dispatch({ type: "GET_DD_DETAILS_RESPONSE", "response": response});
      else {
        let error = { "code": "DDA10101", "response": "Something has gone wrong" };
        self.store.dispatch({ type: "API_ERROR", "error": error});
      }
    }, function (response) {
      self.store.dispatch({ type: "USER_NOT_LOGGED_IN", "error": "User does not have a session"});
    }, function (response) {
      let error = { "code": "DDA00001", "response": response };
      self.store.dispatch({ type: "API_ERROR", "error": error});
    });
  }
  getDetailsAfterPersonalDetails () {
    let self = this;
    api.getSSOKeyAndGetDirectDebitDetails(this.props.sso, this.props.api, {}, function (response) {
      self.store.dispatch({ type: "GET_DD_DETAILS_RESPONSE_AFTER_PERSONAL_DETAILS", "response": response});
    }, function (response) {
      self.store.dispatch({ type: "USER_NOT_LOGGED_IN", "error": "User does not have a session"});
    }, function (response) {
      let error = { "code": "DDA00021", "response": response };
      self.store.dispatch({ type: "API_ERROR", "error": error});
    });
  }
  getSSOKeyAndWebform (viewName) {
    //TODO: make tests for this
    let self = this;
    api.getSSOKeyAndWebform(this.props.sso, this.props.api, { "viewName": viewName }, function (response) {
      self.unblockScreen();
      self.store.dispatch({type: "GET_WEBFORM_RESPONSE", "response": response});
    }, function (response) {
      self.store.dispatch({ type: "USER_NOT_LOGGED_IN", "error": "User does not have a session"});
    }, function (response) {
      let error = { "code": "DDA00006", "response": response };
      self.store.dispatch({ type: "API_ERROR", "error": error});
    });
  }

  createNewDirectDebitForOther () {
    let self = this;
    api.getSSOKeyAndNewDirectDebit(this.props.sso, this.props.api, { "self": false, "tenancy": this.state.directDebit.tenancyID, "ddEmail": this.state.directDebit.newDirectDebit.form.values.email }, function (response) {
      self.store.dispatch({type: "DIRECT_DEBIT_FOR_OTHER_OK", "response": response});
    }, function (response) {
      self.store.dispatch({ type: "USER_NOT_LOGGED_IN", "error": "User does not have a session"});
    }, function (response) {
      let error = { "code": "DDA00002", "response": response };
      self.store.dispatch({ type: "API_ERROR", "error": error});
    });
  }

  createNewDirectDebitForUser () {
    let self = this;
    api.getSSOKeyAndNewDirectDebit(this.props.sso, this.props.api, { "self": true, "tenancy": this.state.directDebit.tenancyID}, function (response) {
      self.store.dispatch({type: "DIRECT_DEBIT_FOR_USER_OK", "response": response});
    }, function (response) {
      self.store.dispatch({ type: "USER_NOT_LOGGED_IN", "error": "User does not have a session"});
    }, function (response) {
      let error = { "code": "DDA00003", "response": response };
      self.store.dispatch({ type: "API_ERROR", "error": error});
    });
  }

  getSSOKeyAndWebFormSubmit () {
    let self = this;
    api.getSSOKeyAndWebFormSubmit(this.props.sso, this.props.api, webFormService.getPayload(this.state.webFormData.webForm), function (response) {
      self.store.dispatch({type: "SUBMIT_PERSONAL_DETAILS_OK", "response": response});
    }, function (response) {
      self.store.dispatch({ type: "USER_NOT_LOGGED_IN", "error": "User does not have a session"});
    }, function (response) {
      let error = { "code": "DDA00004", "response": response };
      self.store.dispatch({ type: "API_ERROR", "error": error});
    });
  }

  getSSOKeyAndDecline () {
    let self = this;
    api.getSSOKeyAndDecline(this.props.sso, this.props.api, {"registration": this.state.directDebit.declineDirectDebitID}, function (response) {
      self.store.dispatch({type: "CANCEL_DIRECT_DEBIT_OK", "response": response});
    }, function (response) {
      self.store.dispatch({ type: "USER_NOT_LOGGED_IN", "error": "User does not have a session"});
    }, function (response) {
      let error = { "code": "DDA00005", "response": response };
      self.store.dispatch({ type: "API_ERROR", "error": error});
    });
  }

  listBank () {
    let self = this;
    api.listBank(this.props.api, function (response) {
      self.store.dispatch({type: "LIST_BANK_OK", "response": response});
    }, function (response) {
      let error = { "code": "DD30323", "response": response };
      self.store.dispatch({ type: "API_ERROR", "error": error});
    });
  }

  getAgreement () {
    let self = this;
    api.getSSOKeyAndGetDirectDDagreement(this.props.sso, this.props.api, { "registration": this.state.directDebit.currentDirectDebit.id}, function (response) {
      self.store.dispatch({ type: "GET_AGREEMENT_OK", "response": response});
    }, function (response) {
      self.store.dispatch({ type: "USER_NOT_LOGGED_IN", "error": "User does not have a session"});
    }, function (response) {
      let error = { "code": "DD100007", "response": response };
      self.store.dispatch({ type: "API_ERROR", "error": error});
    });
  }

  confirmDirectDebit () {
    let self = this;
    api.getSSOKeyAndConfirmDirectDebit(this.props.sso, this.props.api, this.state.directDebit.agreement.confirmPayload, function (response) {
      self.store.dispatch({ type: "CONFIRM_DIRECT_DEBIT_OK", "response": response});
    }, function (response) {
      self.store.dispatch({ type: "USER_NOT_LOGGED_IN", "error": "User does not have a session"});
    }, function (response) {
      let error = { "code": "DD100008", "response": response };
      self.store.dispatch({ type: "API_ERROR", "error": error});
    });
  }

  getSSOKeyAndValidateObject () {
    let self = this;
    api.getSSOKeyAndValidateObject(this.props.sso, this.props.sso, {"url": "website.jsp"}, function (response) {
      if (response.loggedIn && JSON.stringify(response).indexOf("personid") === -1 ) {
        response = "Validate object has not personid";
        //See SAU-389
        let error = { "code": "DDA00008", "response": response };
        self.store.dispatch({ type: "API_ERROR", "error": error});
        return;
      }
      if (response.loggedIn) self.store.dispatch({type: "CALL_GET_DETAILS", "personid": response.personid});
      else self.store.dispatch({type: "USER_NOT_LOGGED_IN", "error": "User does not have a session"});
    }, function (response) {
      response = "Error validating session";
      let error = { "code": "DDA00007", "response": response };
      self.store.dispatch({ type: "API_ERROR", "error": error});
    });
  }

  getWebContent () {
    let self = this;
    api.getWebContent(this.props.api, { "group": "DIRECT_DEBIT"}, function (response) {
      self.store.dispatch({ type: "GET_WEB_CONTENT_OK", "response": response});
    }, function (response) {
      response = "Error calling getWebContent";
      let error = { "code": "DDA00020", "response": response };
      self.store.dispatch({ type: "API_ERROR", "error": error});
    });
  }

  // DASHBOARD
  createNewDirectDebit (tenancyID) {
    this.store.dispatch({ type: "GO_TO_NEW_DIRECT_DEBIT", "tenancyID": tenancyID});
  }
  cancelDirectDebit (directDebitID) {
    this.store.dispatch({ type: "CANCEL_DIRECT_DEBIT", "directDebitID": directDebitID});
  }
  startCancelDirectDebit (directDebitID) {

    this.store.dispatch({ type: "START_CANCEL_DIRECT_DEBIT", "directDebitID": directDebitID});
    let self = this;
    this.timeOutsToCancelDD[directDebitID] = setTimeout(function () {
      let declineDirectDebitID = null;
      if (self.state.directDebit.cancelInstances) {
        for (let key of Object.keys(self.state.directDebit.cancelInstances)) {
          if (self.state.directDebit.cancelInstances[key].status === "waiting") {
            declineDirectDebitID = self.state.directDebit.cancelInstances[key].declineDirectDebitID;
            break;
          }
        }
      }
      if (declineDirectDebitID) {
        self.cancelDirectDebit (declineDirectDebitID);
      }
    }, this.props.waitForUndo ? this.props.waitForUndo : 3000);
  }

  undoCancel (directDebitID) {
    clearTimeout(this.timeOutsToCancelDD[directDebitID]);
    this.store.dispatch({ type: "UNDO_CANCEL", "directDebitID": directDebitID});
  }

  amendDirectDebit (tenancyID, directDebitID, directDebit) {
    //When a direct debit has not the tenancy in the tenancy list, other user creted the direct debit for so user can decline/accept
    if (!this.state.directDebit.detailsResponse.tenancies.filter((ten) => {return directDebit.tenancy.tenancyId === ten.tenancyId;})[0]) {
      this.store.dispatch({ type: "GO_TO_DECLINE_ACCEPT", "directDebitID": directDebit.directDebitId, "tenancyID": directDebit.tenancyId, "currentDirectDebit": directDebit});
    } else {
      this.goToPersonalDetails (directDebit);
    }
  }

  // NAVIGATION
  goToDashboard () {
    let self = this;
    this.store.dispatch({ type: "CALL_GET_DETAILS", "personid": self.state.directDebit.personid});
  }
  goToPersonalDetails (directDebit) {
    this.store.dispatch({ type: "GO_TO_PERSONAL_DETAILS", "currentDirectDebit": directDebit});
  }

  goToPaymentDetails () {
    //TODO: call the api
    this.store.dispatch({ type: "GO_TO_PAYMENT_DETAILS"});
  }

  blockScreen () {
    this.store.dispatch({ type: "BLOCK_SCREEN"});
  }
  unblockScreen () {
    this.store.dispatch({ type: "UNBLOCK_SCREEN"});
  }

  // STEP 1. NEW DIRECT DEBIT
  setDirectDebitOwner (e) {
    this.store.dispatch({type: "SET_DIRECT_DEBIT_OWNER", "selfOwner": e.target.value});
  }
  updateEmailDirectDebitForOther (field, event) {
    this.store.dispatch({type: "UPDATE_EMAIL_DD_FOR_OTHER", "field": field, "value": event.target.value});
  }
  validateNewDirectDebit (goTo) {
    if (goTo === "dashboard") {
      this.goToDashboard();
      return;
    } else {
      if (this.state.directDebit.newDirectDebit.selfOwner === "no") {
        if (!this.state.directDebit.newDirectDebit.form.canSubmit) {
          this.store.dispatch({type: "NEW_DIRECT_DEBIT_FORM_SUBMITTED_WITH_ERRORS"});
          return;
        }
        this.store.dispatch({type: "DIRECT_DEBIT_FOR_OTHER"});
      } else {
        this.store.dispatch({ type: "DIRECT_DEBIT_FOR_USER"});
      }
    }
  }

  // STEP 2. PERSONAL DETAILS
  updatePersonalDetailsWebForm (field, event) {
    this.store.dispatch({type: "UPDATE_PERSONAL_DETAIL_FORM", "field": field, "value": event.target.value});
  }
  validatePersonalDetails (event) {
    if (event) event.preventDefault();
    if (!this.state.webFormData.webForm.canSubmit) this.store.dispatch({type: "SUBMITTED_WITH_ERRORS"});
    else this.store.dispatch({type: "SUBMIT_PERSONAL_DETAILS"});
  }

  // STEP 3. PAYMENT DETAILS
  updateBankForm (field, event) {
    if (field === "code") {
      if (event.target.value.length === 8) return;
      this.store.dispatch({type: "UPDATE_BANK_FORM", "field": field, "value": event.target.value});
    } else {
      this.store.dispatch({type: "UPDATE_BANK_FORM", "field": field, "value": event.target.value});
    }
  }
  onKeyDownBsbHandler (e) {
    if (e.keyCode === 8) return;//backspace
    if (e.keyCode === 189) return;//dash
    if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) { //numbers
      e.preventDefault();
      return;
    }
    return;
  }
  selectBank (bankSelected) {
    this.store.dispatch({type: "SELECT_BANK", "bankSelected": JSON.parse(JSON.stringify(bankSelected))});
  }
  validatePaymentDetails (event) {
    if (event) event.preventDefault();
    if (this.state.directDebit.paymentDetails.paymentTypeSelected === "debitCard") {
      if (!this.state.directDebit.paymentDetails.debitCardForm.canSubmit) this.store.dispatch({type: "DEBIT_CARD_DETAILS_SUBMITTED_WITH_ERRORS"});
      else this.store.dispatch({type: "GET_AGREEMENT"});
    } else {
      if (!this.state.directDebit.paymentDetails.bankForm.canSubmit) this.store.dispatch({type: "BANK_DETAILS_SUBMITTED_WITH_ERRORS"});
      else this.store.dispatch({type: "GET_AGREEMENT"});
    }
  }
  updateDebitCardForm (field, event) {
    this.store.dispatch({type: "UPDATE_DEBIT_CARD_FORM", "field": field, "value": event.target.value});
  }
  changePaymentType (paymentTypeSelected) {
    this.store.dispatch({ type: "CHANGE_PAY_MODE", "paymentTypeSelected": paymentTypeSelected});
  }

  //STEP 4. AGREEMENT
  agreementYesNoAction (agreementYesNoValue) {
    this.store.dispatch({ type: "CHANGE_AGREEMENT_OPTION", "agreementYesNoValue": agreementYesNoValue.target.value});
  }
  validateAgreement () {
    if (this.state.directDebit.agreement.agreementYesNoValue) {
      this.store.dispatch({ type: "CONFIRM_DIRECT_DEBIT"});
    }
  }
}
