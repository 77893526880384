import React from 'react';
import { PexValidationMessage } from "./pex-validation-message.jsx";
import InputGroup from 'react-bootstrap/lib/InputGroup';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import Col from 'react-bootstrap/lib/Col';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import classNames from 'classnames';
import FormControl from 'react-bootstrap/lib/FormControl';
import PropTypes from 'prop-types';

export class PexCommonField extends React.Component {

  value () {
    return this.props.values[this.props.fieldKey] || '';
  }

  handleChange (event) {
    this.props.updateField(this.props.fieldKey, event);
  }

  childProps () {
    let childProps =
      {
        value: this.value(),
        onChange: ::this.handleChange,
        placeholder: this.props.label,
      };
    if (this.props.disabled) {
      childProps.disabled = true;
    }

    if (this.props.placeholder) {
      childProps.placeholder = this.props.placeholder;
    } else {
      childProps.placeholder = this.props.label;
    }
    return childProps;
  }

  error () {
    if (this.props.submitted && this.props.errors[this.props.fieldKey]) return true;
    return false;
  }


  render () {
    let classesParent = this.props.className;
    let classes = {fieldError: this.error()};
    let sizeLabel = {"sm": 3};
    let sizeComponent = {"sm": 9};
    if (this.props.typeColLabel && this.props.sizeColLabel) {
      sizeLabel = {};
      sizeLabel[this.props.typeColLabel] = this.props.sizeColLabel;
    }
    if (this.props.typeColComponent && this.props.sizeColComponent) {
      sizeComponent = {};
      sizeComponent[this.props.typeColComponent] = this.props.sizeColComponent;
    }
    let validationState = "";
    if (this.props.submitted) {
      if (this.props.errors[this.props.fieldKey]) validationState = { "validationState": "error" };
      else validationState = { "validationState": "success" };
    }
    return <span>
      <FormGroup className={classNames(classesParent, classes)} controlId={"id-" + this.props.className} {...validationState}>
        <Col componentClass={ControlLabel} {...sizeLabel} >{this.props.label}</Col>
        <Col {...sizeComponent}>
          <InputGroup>
            <InputGroup.Addon><i className={this.props.iconClassName}></i></InputGroup.Addon>
            { React.cloneElement(this.props.children, this.childProps()) }<FormControl.Feedback />
          </InputGroup>
          <PexValidationMessage {...this.props }/>
        </Col>
      </FormGroup>
    </span>;
  }

}

PexCommonField.propTypes = {
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  updateField: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  iconClassName: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
};
