var React = require('react'); //my IDE says this is unused but it's needed
import { createStore, combineReducers } from 'redux';
import { Field, reduxForm } from 'redux-form';
import { reducer as formReducer } from 'redux-form';
import { FormGroup, FormControl, ControlLabel, Col, Panel, Row, Button, Checkbox } from 'react-bootstrap';
import ajax from '../../lib/ajax.js';
let api = require('../../lib/api.js')(ajax);
import { Provider } from 'react-redux';

function reducer (state = {}, action) {
  switch (action.type) {
    case "NOT_LOGGED_IN":
      return {state: "NOT_LOGGED_IN"};
    case "ERROR":
      return {state: "ERROR"};
    case "GOT_DOCUMENT_INFO":
      return {state: "GOT_DOCUMENT_INFO", urlBase: action.urlBase, signed: action.signed, document: action.document};
    case "FREEZE":
      return Object.assign({}, state, {frozen: true});
    case "LOGIN_ERROR":
      return Object.assign({}, state, {form_error: action.message, frozen: false});
    case "SIGNED_SUCCESS":
      return {state: "SUCCESS"};
    case "SIGNED_ERROR":
      return Object.assign({}, state, {form_error: action.message, frozen: false});
    case "NOT_LOGGED_WHEN_SIGN":
      return {state: "NOT_LOGGED_WHEN_SIGN"};
    default:
      return state;
  }
}

export default class SharerAgreement extends React.Component {
  constructor (props) {
    super(props);
    this.state = {main: {}};
    this.store = createStore(
      combineReducers({main: reducer, form: formReducer}),
      window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    );
  }

  handleSignSubmit (values) {
    if (values) {
      this.store.dispatch({type: "FREEZE"});
      api.getSSOKeyAndSignTenancyAgreement(this.props.sso, this.props.api, {document: this.state.main.document.id}, function (response) {
        this.store.dispatch({
          type: "SIGNED_SUCCESS",
        });
      }.bind(this), function () {
        /*
        we first tried to dispatch NOT_LOGGED_IN straight from here, but we got the following warning:
         Warning: setState(...): Can only update a mounted or mounting component. This usually means you called setState()
         on an unmounted component. This is a no-op. Please check the code for the Connect(Form(AgreeForm)) component.
        Everything worked as expected, but we decided to change it to give you a link to log in again because:
         -we wanted to find out if the same warning would happen if we clicked on a link (it didn't)
         -we wanted to tell the user anyway what had happened, and this seemed just as easy as writing the message on
         the form

        This might be a bug with redux-react (and the provider).  That's what we get from searching the error.
        However, it might be from using two Providers, and turning one off, and another on right away.

        */

        this.store.dispatch({
          type: "NOT_LOGGED_WHEN_SIGN",
        });
      }.bind(this), function (response) {
        this.store.dispatch({
          type: "SIGNED_ERROR",
          message: response.message ? "api error:" + response.message : "api error 32525223",
        });
      }.bind(this));
    }
  }

  handleLoginSubmit (values) {
    if (values) {
      this.store.dispatch({type: "FREEZE"});
      api.singleSignOnLoginobject(this.props.sso, {
        username: values.email,
        password: values.password,
      }, function (response) {
        if (response.loggedIn) {
          this.getDocumentInfo();
        } else {
          this.store.dispatch({type: "LOGIN_ERROR", message: response.message ? response.message : "error 34567234"});
        }
      }.bind(this), function () {

      }.bind(this));
    }
  }

  handleBackLogin () {
    this.store.dispatch({type: "NOT_LOGGED_IN"});
  }

  render () {
    if (this.state.main.state === "SUCCESS") {
      let phrase = "Agreement Sign Confirmation";
      return <Col xs={6} className="login col-centered">
        <Panel header={phrase}>
          Thank you for signing the tenancy agreement.
        </Panel>
      </Col>;
    }
    if (this.state.main.state === "NOT_LOGGED_IN") {
      return <div className={this.state.main.frozen ? "divFrozen" : null}>
        <Provider store={this.store}><LoginForm phrase={this.state.main.form_error ? this.state.main.form_error : "Please log in"} onSubmit={this.handleLoginSubmit.bind(this)} /></Provider>
      </div>;
    }

    if (this.state.main.state === "GOT_DOCUMENT_INFO") {

      let phrase = this.state.main.signed ? "You have already signed the agreement for this tenancy" : "Please sign the agreement for this tenancy";
      return <div className={this.state.main.frozen ? "divFrozen" : null}>
        <div>{this.state.main.form_error}</div>
        { this.state.main.signed ? <Row><Col xs={6} className="login col-centered"><Panel header={phrase}> <embed src={this.state.main.urlBase + this.state.main.document.id} /> You have already signed the agreement for this tenancy. If you think this is an error, please contact us.</Panel></Col></Row> : <Provider store={this.store}><AgreeForm phrase={phrase} document={this.state.main.urlBase + this.state.main.document.id} onSubmit={this.handleSignSubmit.bind(this)}/></Provider> }
      </div>;
    }

    if (this.state.main.state === "ERROR") {
      return <div>something is wrong - refresh to try again</div>;
    }

    if (this.state.main.state === "NOT_LOGGED_WHEN_SIGN") {
      return <div>You have been logged out <button onClick={this.handleBackLogin.bind(this)}>Go to Login</button></div>;
    }
    return <div>just a sec</div>;
  }

  componentDidMount () {
    this.unsubscribe = this.store.subscribe(function () {
      let state = {};
      for (let key of Object.keys(this.state)) state[key] = null; //we have to do this because replaceState no longer exists
      this.setState(Object.assign({}, state, this.store.getState()));

      //instead of setting the state to the redux store state, if we want to pass
      //access the redux store directly, we can replace setState with this.forceUpdate
    }.bind(this));

    this.getDocumentInfo();
  }

  getDocumentInfo () {
    api.getSSOKeyAndGetTenancyAgreement(this.props.sso, this.props.api, {stay: this.props.stay}, function (response) {
      this.store.dispatch({
        type: "GOT_DOCUMENT_INFO",
        signed: response.document.signed,
        urlBase: response.agreementUrlBase,
        document: response.document,
      });
    }.bind(this), function () {
      this.store.dispatch({type: "NOT_LOGGED_IN"});
    }.bind(this), function () {
      this.store.dispatch({type: "ERROR"});
    }.bind(this));
  }

  componentWillUnmount () {
    //I think I could have just put
    //this.componentWillUnmount = this.store.subscribe ...
    //above.  that would have been pretty cool
    this.unsubscribe();
  }
}

function renderField (props) {
  const { input, label, type, meta: { touched, error } } = props;
  return (
    <FormGroup controlId={"id-"}>
      <Col>{label}</Col>
      <Col>
        <FormControl {...input} placeholder={label} type={type}/>
        {touched && error && <span>{error}</span>}
      </Col>
    </FormGroup>
  );
}

function renderCheckbox (props) {
  const { input, type, meta: { touched, error } } = props;
  return (
    <FormGroup controlId={"id-"}>
      <Checkbox {...input} type={type}/>
      {touched && error && <span className="iAgreeError">{error}</span>}
    </FormGroup>
  );
}

//validate is a reserved name, so this function is executed internally by redux-form
//if the validate rules aren't met the values are not setted in values array
const validate = values => {
  const errors = {};
  if (!values.email) errors.email = 'Required';
  if (!values.password) errors.password = 'Required';
  return errors;
};

class LoginForm extends React.Component {
  //TODO: find out how to use a component in the "component" Field prop as opposed to a function
  render () {
    const { handleSubmit } = this.props;
    let phrase = this.props.phrase;
    return (
      <Row>
        <Col xs={6} className="login col-centered">
          <form onSubmit={handleSubmit}>
            <Panel header={phrase}>
              <Field name="email" type="text" component={renderField} label="Email"/>
              <Field name="password" type="password" component={renderField} label="Password"/>
              <Button className="pex-login-button btn-block" type="submit">Login <i className="fa fa-long-arrow-right"></i></Button>
            </Panel>
          </form>
        </Col>
      </Row>
    );
  }
}
LoginForm = reduxForm({form: 'login', validate})(LoginForm);

const validateAgreeForm = values => {
  const errors = {};
  if (!values.agree) errors.agree = 'Required';
  return errors;
};

class AgreeForm extends React.Component {
  render () {
    const { handleSubmit } = this.props;
    let document = this.props.document;
    let phrase = this.props.phrase;

    return <Row>
      <Col xs={6} className="login col-centered">
        <Panel header={phrase}>
          <embed src={document} />
          <p className="small pull-right">Click <a href={document} download>here</a> to download.</p>
          <form onSubmit={handleSubmit} className="agreeForm">
            <label htmlFor="agree">I Agree</label>
            <Field name="agree" id="agree" component={renderCheckbox} type="checkbox" />
            <Button className="pex-login-button btn-block" type="submit">Sign</Button>
          </form>
        </Panel>
      </Col>
    </Row>;
  }
}
AgreeForm = reduxForm({form: 'agreeForm', validate: validateAgreeForm})(AgreeForm);
