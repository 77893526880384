"use strict";
let md5 = require('md5');
//utilities that are tested separately

module.exports = {
  replaceAll: function (str, find, replace) {
    let new_str = str;
    while (new_str.indexOf(find) !== -1) {
      new_str = new_str.replace(find, replace);
    }
    return new_str;
  },
  format_path: function (format, values) {
    let path = format;
    for (let key of Object.keys(values)) {
      path = path.replace("<" + key + ">", values[key]);
    }
    return path;
  },
  format_rate: function (number, units) {

    if (units.indexOf(" per ") === -1 && units.indexOf("/") === -1) {
      return number + " " + units;
    }

    var bits = units.replace("/", " per ").split(" per ").map(function (x) { return x.trim(); });

    if (["pound", "pounds", "£"].indexOf(bits[0]) !== -1) {
      return "£" + number + "/" + bits[1];
    }

    if (["dollar", "dollars", "$"].indexOf(bits[0]) !== -1) {
      return "$" + number + "/" + bits[1];
    }

    return number + " " + units;
  },
  sanitize: function (a) {
    if (!a) return '';
    return a.replace(/<script[^>]*>.*?<\/script>/gi, '');
  }, //thanks atk on https://gist.github.com/sindresorhus/1993156
  isJson: function (obj) {
    if (typeof obj === 'string') return false;
    if (typeof obj === 'number') return false;
    try {
      JSON.parse(JSON.stringify(obj));
    } catch (e) {
      return false;
    }
    return true;
  },
  getHash: function (room, PRS_GROUP) {
    let rtn = "";
    if (room.details) {
      let detailsSorted = JSON.parse(JSON.stringify(room.details));
      room.details = detailsSorted.sort(function (a, b) { return (b.value < a.value) ; });
    }
    for (let detail of room.details) rtn = rtn + JSON.stringify(detail);
    for (let category of room.categories) rtn = rtn + JSON.stringify(category);
    if (PRS_GROUP) rtn = rtn + room.lookupValue;
    return md5(rtn);
  }, extendUnderscore: function (underscore) {

    //thanks https://gist.github.com/furf/3208381
    underscore.mixin({

      // Get/set the value of a nested property
      deep: function (obj, key, value) {

        var keys = key.replace(/\[(["']?)([^\1]+?)\1?\]/g, '.$2').replace(/^\./, '').split('.'),
          root,
          i = 0,
          n = keys.length;

        // Set deep value
        if (arguments.length > 2) {

          root = obj;
          n--;

          while (i < n) {
            key = keys[i++];
            obj = obj[key] = underscore.isObject(obj[key]) ? obj[key] : {};
          }

          obj[keys[i]] = value;

          value = root;

          // Get deep value
        } else {
          while ((obj = obj[keys[i++]]) !== null && i < n) {};
          value = i < n ? void 0 : obj;
        }

        return value;
      },

    });
    // Usage:
    //
    // var obj = {
    //   a: {
    //     b: {
    //       c: {
    //         d: ['e', 'f', 'g']
    //       }
    //     }
    //   }
    // };
    //
    // Get deep value
    // underscore.deep(obj, 'a.b.c.d[2]'); // 'g'
    //
    // Set deep value
    // underscore.deep(obj, 'a.b.c.d[2]', 'george');
    //
    // underscore.deep(obj, 'a.b.c.d[2]'); // 'george'
    underscore.mixin({
      pluckDeep: function (obj, key) {
        return underscore.map(obj, function (value) { return underscore.deep(value, key); });
      },
    });

    // Usage:
    //
    // var arr = [{
    //   deeply: {
    //     nested: 'foo'
    //   }
    // }, {
    //   deeply: {
    //     nested: 'bar'
    //   }
    // }];
    //
    // underscore.pluckDeep(arr, 'deeply.nested'); // ['foo', 'bar']
    return underscore;

  },
};
