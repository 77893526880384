import React from 'react';
import { PexValidationMessage } from "./pex-validation-message.jsx";
import InputGroup from 'react-bootstrap/lib/InputGroup';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import Col from 'react-bootstrap/lib/Col';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import classNames from 'classnames';
import FormControl from 'react-bootstrap/lib/FormControl';
import PropTypes from 'prop-types';

export class PexCommonField extends React.Component {

  value () {
    return this.props.values[this.props.fieldKey] || '';
  }

  handleChange (event, key) {
    if (key) this.props.updateField(key, event);
    else this.props.updateField(this.props.fieldKey, event);
  }

  childProps () {
    let childProps =
      {
        value: this.value(),
        onChange: ::this.handleChange,
      };
    if (this.props.disabled) {
      childProps.disabled = true;
    }

    if (this.props.placeholder) childProps.placeholder = this.props.placeholder;

    return childProps;
  }

  render () {
    let classesParent = this.props.className;
    let classes = {fieldError: false};
    let sizeLabel = {"sm": 3};
    let sizeComponent = {"sm": 9};
    let disabled = false;
    if (this.props.disabled) disabled = true;
    if (this.props.typeColLabel && this.props.sizeColLabel) {
      sizeLabel = {};
      sizeLabel[this.props.typeColLabel] = this.props.sizeColLabel;
    }
    if (this.props.typeColComponent && this.props.sizeColComponent) {
      sizeComponent = {};
      sizeComponent[this.props.typeColComponent] = this.props.sizeColComponent;
    }
    if (this.props.hideInputAndCol) sizeLabel = {};
    let validationState = "";
    if (this.props.submitted || this.props.values[this.props.fieldKey] || this.props.values[this.props.fieldKey] === "") {
      if (this.props.errors[this.props.fieldKey]) {
        classes.fieldError = true;
        validationState = { "validationState": "error" };
      } else validationState = { "validationState": "success" };
    }
    let label = <Col componentClass={ControlLabel} {...sizeLabel} >{this.props.label}</Col>;
    let showIcon = <InputGroup.Addon><i className={this.props.iconClassName}></i></InputGroup.Addon>;
    if (this.props.hideLabel) label = null;
    if (this.props.hideIcon) showIcon = null;
    if (this.props.hideInputAndCol) {
      return <FormGroup className={classNames(classesParent, classes)} disabled={disabled} controlId={"id-" + this.props.className} {...validationState}>
        {label}
        {showIcon}
        { React.cloneElement(this.props.children, this.childProps()) }<FormControl.Feedback />
        <PexValidationMessage {...this.props }/>
      </FormGroup>;
    }
    return <FormGroup className={classNames(classesParent, classes)} disabled={disabled} controlId={"id-" + this.props.className} {...validationState}>
      {label}
      <Col {...sizeComponent}>
        <InputGroup>
          {showIcon}
          { React.cloneElement(this.props.children, this.childProps()) }<FormControl.Feedback />
        </InputGroup>
        <PexValidationMessage {...this.props }/>
      </Col>
    </FormGroup>;
  }

}

PexCommonField.propTypes = {
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  updateField: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  iconClassName: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
};
