let $ = require('jquery');

module.exports = function (api, path, payload, success, failure, ignoreStatus, additionalArgs) {
  if (path.length && !path.startsWith("/")) {
    failure("if you have a non blank path, it must start with slash");
    return;
  }
  $.ajax(Object.assign({
    url: api + path,
    dataType: 'json',
    type: 'POST',
    contentType: 'application/json',
    data: JSON.stringify(payload),
    success: function (data) {
      if (!ignoreStatus && data.status !== "OK" && data.status !== "WARNING") {
        let error_message;
        let messageType = false;
        if (data.messages && data.messages.length > 0 && data.messages[0].messageType) messageType = data.messages[0].messageType;
        try {
          error_message = [data.messages[0].description, data.messages[0].message].filter(function (str) {
            return str;
          }).join(" - ");
          if (data.messages.length !== 1) {
            throw "unknown format";
          }
        }
        catch (e) {
          error_message = JSON.stringify(data);
        }
        failure(error_message, messageType);
      } else {
        success(data);
      }
    },
    error: function (issue) {
      failure("can't reach server");
    },
  }, additionalArgs));
};
