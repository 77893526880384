import React from 'react';
import { PexThanks } from "../components/form-components/pex-thanks.jsx";
import { PexText } from "../components/form-components/pex-text.jsx";
import { PexTextArea } from "../components/form-components/pex-text-area.jsx";
import { PexDropDown } from "../components/form-components/pex-drop-down.jsx";
import { PexCheckbox } from "../components/form-components/pex-checkbox.jsx";
import { PexDatePicker } from "../components/form-components/pex-date-picker.jsx";
import formService from '../services/form-service.js';
import searchService from '../services/search-service.js';
import ajax from '../../lib/ajax.js';
let api = require('../../lib/api.js')(ajax);
import utils from '../../lib/utils.js';
import Form from 'react-bootstrap/lib/Form';
import Panel from 'react-bootstrap/lib/Panel';
import ButtonGroup from 'react-bootstrap/lib/ButtonGroup';
import Button from 'react-bootstrap/lib/Button';
import moment from 'moment';
import { createStore } from 'redux';
import translateService from '../services/translate-service.js';
import {PexCountryPhone} from '../components/form-components/pex-country-phone.jsx';
import TagManager from 'react-gtm-module';

let format_path = utils.format_path;
let getCurrentMoment = require('../../lib/get-current-moment.js'); //TODO: how do we do an es6 import for this?
const defaultLabels = JSON.parse(JSON.stringify(require("../../../lang/search-applet/en-GB.json"))).labels["en-GB"];

export default class ArrangeViewingForm extends React.Component {
  constructor (props) {
    super(props);
    let myState = {};
    if (!this.props.api) myState = {data: {error: "configuration error - no api specified"}};
    else if (this.props.requiredFields) {
      for (let row of this.props.requiredFields.split(";").filter(function (el) {return el.length !== 0;})) {
        if ((row.match(new RegExp(":")) || []).length > 1 || (row.match(new RegExp(":")) || []).length === 0) {
          myState = {error: "configuration error - required field attribute is incorrect"};
        }
      }
    }
    if (props.gtmId) {
      let tagManagerArgs = { "gtmId": props.gtmId};
      TagManager.initialize(tagManagerArgs);
    }
    myState = Object.assign({}, myState, formService.initArrangeForm(this.props.requiredFields));
    myState.values.date = getCurrentMoment().unix();
    myState.loaded = false;
    myState.isOpen = "";
    myState.textFilterValue = "";
    let self = this;
    this.store = createStore(function (state = myState, action ) {
      switch (action.type) {
        case "MERGE_STATE": return Object.assign({}, state, action.state);
        case "LOAD": return Object.assign(state, {"nextAction": "getLabels", "lang": action.lang});
        case "START_GET_FORM_COLLECTION": return Object.assign(state, {allLocations: action.data.locations, "nextAction": "getFormCollections", "addBookingType": action.data.settings["marketing.locations.addBookingTypes"]});
        case "API_ERROR": return Object.assign({}, state, {data: {error: action.error}, "waiting": false, "loaded": true, "nextAction": "DONE"});
        case "THANKS": return Object.assign({}, state, {waiting: false, state: "sent"});
        case "SUBMIT_WITH_ERROR": {
          let newState = {};
          let error = searchService.getError(action.message, action.messageType, null, self.state.labels, null, "arrangeForm.customAPIMessage.");
          newState.submitError = error;
          newState.waiting = false;
          return Object.assign({}, state, newState);
        }
        case "UPDATE_FIELD": {
          let values = state.values;
          values[action.field] = action.value;
          let new_state = Object.assign({}, state, formService.checkForm(values, state.formNames, state.labels, "arrangeForm"), values);
          return Object.assign({}, state, new_state);
        }
        case "UPDATE_BOOLEAN": {
          let values = state.values;
          values[action.field] = formService.setNoSpamBoolean(values[action.field], action.value);
          let new_state = Object.assign({}, state, formService.checkForm(values, state.formNames, state.labels, "enquiryForm"), values);
          return Object.assign(state, new_state);
        }
        case "SUBMITTED_WITH_ERRORS": return Object.assign(state, {submitted: true});
        case "WAITING": return Object.assign(state, {waiting: true});
        case "GET_FORM_COLLECTION_RESPONSE": {
          let countries = action.getFormCollectionsResponse.countries.map(
            function (country) {
              let obj = {};
              obj.lookupValue = country.lookupValue;
              obj.diallingCode = country.diallingCode;
              obj.displayValue = country.displayValue + " (" + country.diallingCode + ")";
              return obj;
            }
          ).filter(function (el) {return el.diallingCode && Number.isInteger(Number(el.diallingCode.substring(0, 1)));});
          return Object.assign(state, { "getFormCollectionsResponse": action.getFormCollectionsResponse, "loaded": true, "nextAction": "DONE", "countries": countries});
        }
        case "GET_LABELS_OK": {
          let arrangeForm = formService.initArrangeForm(self.props.requiredFields, action.response.labels[state.lang]);
          arrangeForm.values["noSpam"] = formService.setNoSpamBoolean(arrangeForm.values["noSpam"], "true");
          arrangeForm.values.date = getCurrentMoment().unix();
          return Object.assign({}, state, {"labels": action.response.labels[state.lang], "nextAction": "getOptions"}, arrangeForm);
        }
        case "SELECT_COUNTRY": {
          let newState = state;
          newState.countrySelected = action.countrySelected;
          newState.values["mobileCountry"] = action.countrySelected.diallingCode;
          newState = Object.assign(newState, formService.checkForm(newState.values, newState.formNames, newState.labels, "arrangeForm"));
          newState.isOpen = "";
          return Object.assign(state, {"textFilterValue": ""}, newState);
        }
        case "OPEN_CLOSE_DROPDOWN": {
          let isOpen = action.collectionName;
          if (state.isOpen && state.isOpen.length > 0 && state.isOpen === action.collectionName) isOpen = "";
          return Object.assign(state, {"isOpen": isOpen, "textFilterValue": ""});
        }
        case "CLICK_OUTSIDE": {
          return Object.assign(state, {"isOpen": ""});
        }
        case "SEARCH_FILTER_HANDLER": {
          return Object.assign(state, {"textFilterValue": action.value});
        }
        default: return state;
      }
    }, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
    this.state = this.store.getState();
  }

  componentWillUnmount () {
    this.unsubscribe();
  }

  render () {
    if (this.state && this.state.state === "sent") {
      return <PexThanks message={::this.getThanksMessage} />;
    } else if (this.state && this.state.data && this.state.data.error) {
      let error_message = null;
      if (this.state.data.error.messages) {
        error_message = [this.state.data.error.messages[0].description, this.state.data.error.messages[0].message].filter(function (str) { return str; }).join(" - ");
        if (this.state.data.error.messages.length !== 1) error_message = "ERROR: Default error";
      } else {
        error_message = this.state.data.error;
      }
      return <div dangerouslySetInnerHTML={{__html: utils.sanitize(error_message)}}></div>;
    } else if (this.state && this.state.allLocations && this.state.loaded) {
      return <div className={(this.state.waiting) ? "divFrozen" : "container-fluid"}>
        <FormPex submit={::this.submit}
          submittedWithErrors={::this.submittedWithErrors}
          waiting= {::this.waiting}
          generalProps={this.getFormControlProps()}
          booleanProps={this.booleanProps()}
          getCurrentMoment={getCurrentMoment()}
          openCloseDropdown={this.openCloseDropdown.bind(this)}
          clickOutside={this.clickOutside.bind(this)}
          onSearchTextHandler={this.onSearchTextHandler.bind(this)}
          selectCountry={this.selectCountry.bind(this)}
          state={this.state}
          requiredFields={this.props.requiredFields}
          showUniversities={this.props.showUniversities}
          defaultLabels={defaultLabels} />
      </div>;
    }

    else {
      return <div>Loading...</div>;
    }
  }

  componentDidMount () {
    if (this.store) {
      this.unsubscribe = this.store.subscribe(function () {
        ///TODO: find a way to wrap up this code since we are using it in multiple components
        let state = {};
        for (let key of Object.keys(this.state)) state[key] = null; //we have to do this because replaceState no longer exists
        this.setState(Object.assign({}, state, this.store.getState()));

        //instead of setting the state to the redux store state, if we want to pass
        //access the redux store directly, we can replace setState with this.forceUpdate
      }.bind(this));
      let lang = "en-GB";
      if (this.props.lang) lang = this.props.lang;
      this.store.dispatch({type: "LOAD", "lang": lang});
    }
  }

  componentDidUpdate (prevProps, prevState) {
    switch (this.state.nextAction) {
      case "getOptions":
      case "getFormCollections":
      case "getLabels":
        this[this.state.nextAction]();
    }
  }

  getOptions () {
    let self = this;
    api.marketingCollections(this.props.api, {}, function (data) {
      self.store.dispatch({type: "START_GET_FORM_COLLECTION", data: data});
    }, function (error) {
      self.store.dispatch({type: "API_ERROR", "error": error});
    });
  }

  submit (payload) {
    let self = this;
    api.createGeneralEnquiry(this.props.api, payload, ::this.thanks,
      function (message, messageType) {
        self.store.dispatch({type: "SUBMIT_WITH_ERROR", "message": message, "messageType": messageType});
      });
  }

  thanks (response) {
    this.store.dispatch({type: "THANKS"});
  }

  getThanksMessage () {
    let thanksmessage = "THANKS!!!";
    if (this.props.thanksmessage) {
      let values = {
        BUILDING_NAME: "",
        LOCATION_NAME: "",
      };
      for (let location of this.state.allLocations) {
        if (location.buildings !== null && location.buildings !== "null") {
          for (let building of location.buildings) {
            if (building.lookupValue === this.state.values.building ) {
              values.BUILDING_NAME = building.displayValue;
              values.LOCATION_NAME = location.displayValue;
              break;
            }
          }
        }
      }
      thanksmessage = format_path(this.props.thanksmessage, values);
    }
    return thanksmessage;
  }

  updateFieldFromEvent (field, event) {
    this.store.dispatch({type: "UPDATE_FIELD", "field": field, "value": event.target.value});
  }

  updateBoolean (field, event) {
    this.store.dispatch({type: "UPDATE_BOOLEAN", "field": field, "value": event.target.value});
  }

  submittedWithErrors () {
    this.store.dispatch({type: "SUBMITTED_WITH_ERRORS"});
  }

  waiting () {
    this.store.dispatch({type: "WAITING"});
  }

  getFormControlProps () {
    return {
      values: this.state.values || {},
      errors: this.state.fieldsWithErrors ? this.state.fieldsWithErrors : {},
      updateField: this.updateFieldFromEvent.bind(this),
      submitted: this.state.submitted,
      waiting: this.state.waiting,
    };
  }

  booleanProps () {
    return {
      values: this.state.values || {},
      errors: this.state.fieldsWithErrors ? this.state.fieldsWithErrors : {},
      updateField: this.updateBoolean.bind(this),
      submitted: this.state.submitted,
      waiting: this.state.waiting,
    };
  }

  getFormCollections () {
    let self = this;
    api.getFormCollections(self.props.api, {}, function (data) {
      self.store.dispatch({type: "GET_FORM_COLLECTION_RESPONSE", "getFormCollectionsResponse": data});
    }, function (error) {
      self.store.dispatch({type: "API_ERROR", "error": error});
    });
  }

  getLabels () {
    let self = this;
    api.getLabels(this.props.api, {"languageIds": [this.state.lang], "group": "arrangeForm"}, function (response) {
      self.store.dispatch({ type: "GET_LABELS_OK", "response": response});
    }, function () {
      self.store.dispatch({"type": "API_ERROR", "error": "API error 9787746R - getLabels error"});
    });
  }
  selectCountry (country) {
    this.store.dispatch({type: "SELECT_COUNTRY", "countrySelected": JSON.parse(JSON.stringify(country))});
  }
  openCloseDropdown (collectionName) {
    this.store.dispatch({ type: "OPEN_CLOSE_DROPDOWN", "collectionName": collectionName});
  }
  clickOutside (collectionName) {
    this.store.dispatch({ type: "CLICK_OUTSIDE", "collectionName": collectionName});
  }
  onSearchTextHandler (event) {
    this.store.dispatch({ type: "SEARCH_FILTER_HANDLER", "value": event.target.value});
  }
}

// ArrangeViewingForm.propTypes = {
//   api: PropTypes.string.isRequired
// };

//TODO: add prop types for this back in - they were removed when we were using it as child element and then adding more props
function FormPex (props) {

  function translate (name) {
    return translateService.getLabelsTranslated(props.state.labels, "arrangeForm.", name, props.defaultLabels);
  }

  function handleSubmit (event) {
    event.preventDefault();
    if (!props.state.canSubmit) {
      props.submittedWithErrors();
      return;
    }

    //TODO: this is wrong you cannot setState in middle of operations. Use the logic of START_SOME_DISPATCHER and nextAction, to render the waiting properly(divFrozen ot anything)
    props.waiting();
    let date_to_submit = moment.unix(props.state.values.date); //TODO: inline

    //TODO: figure out what to do about naive dates

    let payload = {
      activity: {
        type: "ARRANGE",
        location: getLocationFromBuilding(props.state.values.building),
        building: props.state.values.building,
        text: props.state.values.comments ? props.state.values.roomType + " - " + props.state.values.comments : props.state.values.roomType,
        proposedDate: date_to_submit.format("YYYY-MM-DD") + "T" + ((props.state.values.time < 10) ? "0" : "") + props.state.values.time + ":00:00",
      },
      "user": {
        "username": props.state.values.emailAddress,
        "firstName": props.state.values.firstName,
        "lastName": props.state.values.lastName,
        "mobileCountry": props.state.values.mobileCountry,
        "mobileNumber": "+" + props.state.values.mobileCountry + props.state.values.mobileNumber,
      },
    };
    if (props.state.values.uniCollegeSchoolAttending) payload.user.university = props.state.values.uniCollegeSchoolAttending;
    if (props.state.values.bookingType && props.state.values.bookingType !== "-") payload.bookingType = props.state.values.bookingType;
    props.state.values.noSpam === "false" ? payload.user.noSpam = true : payload.user.noSpam = false;

    props.submit(payload);
  }

  function getLocationFromBuilding (buildingLookupValue) {
    for (let location of props.state.allLocations) {
      if (location.buildings !== null && location.buildings !== "null") {
        for (let building of location.buildings) {
          if (building.lookupValue === buildingLookupValue) {
            return location.lookupValue;
          }
        }
      }
    }
    return "no location corresponding to " + buildingLookupValue;
  }

  function roomTypesArray () {
    let roomType_options = [];
    if (props.state.values) {
      for (let location of props.state.allLocations) {
        if (location.buildings !== null && location.buildings !== "null") {
          for (let building of location.buildings) {
            if (building.lookupValue === props.state.values.building) {
              roomType_options = building.roomTypes.map ( function (roomType) {
                return <option key={roomType.lookupValue}
                  value={roomType.lookupValue}>{roomType.displayValue}</option>;
              });
              break;
            }
          }
        }
      }
    }
    return roomType_options;
  }

  function hoursArray () {
    let hours = [];
    for (let h = 0; h < 24; h++) {
      hours.push(<option key={h} value={h}>{h + ":00"}</option>);
    }
    return hours;
  }

  let propertyOptions = [];
  for (let location of props.state.allLocations) {
    let thisLocation = location;
    if (location.buildings !== null && location.buildings !== "null") {
      for (let building of location.buildings) {
        propertyOptions.push(<option key={building.lookupValue} value={building.lookupValue}>{building.displayValue}</option>);
      }
    }
  }

  let showUniversitiesDropdown = false;
  let universitiesMessage = "";

  if (props.showUniversities === "true") {
    if (props.state.getFormCollectionsResponse !== undefined && props.state.getFormCollectionsResponse.universities && props.state.getFormCollectionsResponse.universities.length >= 1) {
      var universities = [];
      showUniversitiesDropdown = true;
      for (let university of props.state.getFormCollectionsResponse.universities) universities.push(<option key={university.id} value={university.lookupValue}>{university.displayValue}</option>);
    } else {
      universitiesMessage = translate("universitiesMessage");
    }
  }

  let bookingTypeOptions = [];
  if (props.state.allLocations && props.state.values.building !== "-") {
    for (let location of props.state.allLocations.filter(function (el) {return el.lookupValue === getLocationFromBuilding(props.state.values.building);})) {
      if (location.bookingTypes && location.bookingTypes.length > 0) for (let bookType of location.bookingTypes) bookingTypeOptions.push(<option key={bookType.lookupValue} value={bookType.lookupValue}>{bookType.displayValue}</option>);
    }
  }

  const panel1header = (<div className="arrange-form-time-and-place-title text-center"><h3><strong>{translate("title")}</strong></h3></div>);
  const panel2header = (<div className="arrange-form-details-title text-center"><h3><strong>{translate("enterDetailsTitle")}</strong></h3></div>);

  return <Form className="enquiry" horizontal onSubmit={handleSubmit.bind(this)}>

    <Panel header = {panel1header} >

      <div className="arrange-form-errors">{props.state.error}</div>

      <PexDropDown fieldKey="building" className = "arrange-form-property" iconClassName="fa fa-list" label={translate("propertyLabel")} {...props.generalProps } >{ propertyOptions }</PexDropDown>

      <PexDropDown fieldKey="roomType" className = "arrange-form-room-type" iconClassName="fa fa-list" label={translate("roomTypeLabel")} {...props.generalProps } >{ roomTypesArray() }</PexDropDown>

      {props.state.addBookingType === "false" || props.state.addBookingType === false ? null : <PexDropDown fieldKey="bookingType" className = "arrange-form-booking-type" iconClassName="fa fa-list" label={translate("bookingTypeLabel")} {...props.generalProps } >{bookingTypeOptions}</PexDropDown>}

      <PexDatePicker fieldKey="date" className = "arrange-form-date" iconClassName="fa fa-calendar" label={translate("dateLabel")} getCurrentMoment={props.getCurrentMoment} { ...props.generalProps } />

      <PexDropDown fieldKey="time" className = "arrange-form-time" iconClassName="fa fa-clock-o" label={translate("hourSlotLabel")} {...props.generalProps } >{ hoursArray() }</PexDropDown>

    </Panel>

    <Panel header = {panel2header} >

      <PexText fieldKey="firstName" className = "arrange-form-first-name" iconClassName="fa fa-user" label={translate("firstNameLabel")} {...props.generalProps } />

      <PexText fieldKey="lastName" className = "arrange-form-last-name" iconClassName="fa fa-user" label={translate("lastNameLabel")} {...props.generalProps } />

      <PexCountryPhone fieldKey="countryPhone"
        countryKey="mobileCountry"
        phoneKey="mobileNumber"
        className = "arrange-form-phone-number"
        iconClassName="fa fa-phone"
        translate={translate.bind(this)}
        {...props.generalProps }
        label={translate("phoneLabel")}
        countriesData = {props.state.countries}
        countrySelected = {props.state.countrySelected}
        openCloseDropdown = {props.openCloseDropdown}
        clickOutside = {props.clickOutside}
        onSearchTextHandler = {props.onSearchTextHandler}
        isOpen = {props.state.isOpen}
        textValue = {translate("selectCountryLabel")}
        countryCollectionName = "country-prefix"
        textFilterValue = {props.state.textFilterValue}
        selectCountry = {props.selectCountry}/>

      <PexText fieldKey="emailAddress" className = "arrange-form-email-address"iconClassName="fa fa-envelope-o" label={translate("emailLabel")} {...props.generalProps } />

      { showUniversitiesDropdown ? <PexDropDown fieldKey="uniCollegeSchoolAttending" className = "arrange-form-uni-college-school-attending" iconClassName="fa fa-university" label={translate("universityLabel")} placeholder= {translate("universityPlaceholder")} {...props.generalProps } >{universities}</PexDropDown> : universitiesMessage }

      <PexTextArea fieldKey="comments" className = "arrange-form-comments" iconClassName="fa fa-pencil" label={translate("commentsLabel")} {...props.generalProps } placeholder={translate("commentsPlaceholder")} />

      <PexCheckbox fieldKey="noSpam" className="pex-register-no-spam" iconClassName="fa fa-lock" label={translate("noSpamLabel")} {...props.booleanProps} hideIcon={true} hideLabel={true} />

    </Panel>

    <ButtonGroup vertical block>
      <Button type="submit" className="btn-submit-arrange-form">{translate("sendButton") + " "}<span className="fa fa-send" /></Button>
      <SubmitError submitError={props.state.submitError}/>
    </ButtonGroup>

  </Form>;

}

function SubmitError (props) {
  let error = props.submitError;
  if (error) return <div className="arrange-form-submit-error">{error}</div>;
  return null;
}
