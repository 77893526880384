import React from 'react';
import Col from 'react-bootstrap/lib/Col';

export function ListElementPS (props) {

  function translateSearchFormTagLabels (lookupValue, displayValue) {
    if (props.labels && props.labels["searchApplet.searchForm.categorytag" + lookupValue]) return props.labels["searchApplet.searchForm.categorytag" + lookupValue];
    return displayValue;
  }

  return <div className={"pex-search-category pex-list-" + props.nameArray}><p className = "pex-list-label"><i className="fa fa-icon"></i>{props.label}</p><ul className="list-group"> {
    props.data.map(function (element) {
      let elementPSprops = {};
      elementPSprops.key = element.lookupValue;
      elementPSprops.nameArray = props.nameArray;
      elementPSprops.displayValue = translateSearchFormTagLabels (element.lookupValue, element.displayValue);
      elementPSprops.lookupValue = element.lookupValue;
      elementPSprops.count = element.count;
      elementPSprops.selected = isSelected (props.criteria, props.nameArray, element.lookupValue, props.parentsOfAreas);
      elementPSprops.disabled = isDisabled (element.count, elementPSprops.selected);
      elementPSprops.elementDisabled = props.elementDisabled;
      elementPSprops.categoryClick = function () {props.categoryClick(element.lookupValue, props.nameArray, elementPSprops.selected);};
      return <ElementPS {...elementPSprops} />;
    })
  } </ul>
  </div>;
}

function isSelected (criteria, arrayName, lookupValue, parentsOfAreas) {
  if (arrayName === "bookingTypes") return criteria["bookingTypes"] && criteria["bookingTypes"].indexOf(lookupValue) !== -1;
  if (arrayName === "PARENT-AREA" && parentsOfAreas) {
    let selected = false;
    for (let area of Object.keys(parentsOfAreas[lookupValue].areas)) {
      if (criteria.categories && criteria.categories["AREA"] && criteria.categories["AREA"].indexOf(area) !== -1) {
        selected = true;
      }
    }
    return selected;
  }
  return criteria.categories && criteria.categories[arrayName] && criteria.categories[arrayName].indexOf(lookupValue) !== -1;
}
function isDisabled (count, selected) {
  if (selected) return false;
  if (count === 0) return true;
  else return false;
}

function ElementPS (props) {
  let attributes = {};
  attributes.onClick = props.categoryClick;
  attributes.className = "list-group-item pex-element-" + props.nameArray + " pex-element-" + props.lookupValue;
  attributes.className += props.selected ? " pex-element-selected" : "";
  attributes.className += props.disabled ? " pex-element-disabled" : "";
  attributes.className += props.elementDisabled ? " disabled" : "";
  return <li {...attributes}><i className="fa fa-square-o"></i> {props.displayValue}</li>;
}
