import React from 'react';
import {combineReducers, createStore} from "redux";
import ajax from '../lib/ajax.js';
let api = require('../lib/api.js')(ajax);
import reducer from "./reducers/index.js";
import Table from 'react-bootstrap/lib/Table';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import Panel from 'react-bootstrap/lib/Panel';
import FormControl from 'react-bootstrap/lib/FormControl';
import PageHeader from 'react-bootstrap/lib/PageHeader';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import { PexPagination } from "./components/form-components/pex-pagination.jsx";
import PexTraduction from "./components/pex-traduction.jsx";
import PexTraductionEditor from "./components/pex-traduction-editor.jsx";
import translateService from './services/translate-service.js';

let utils = require("../lib/utils");
import classNames from 'classnames';

export default class TranslateAdminApplet extends React.Component {

  constructor (props) {
    super(props);
    this.store = createStore(reducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
    this.state = this.store.getState();
  }
  componentWillUnmount () {
    this.unsubscribe();
  }

  componentDidMount () {
    if (this.store) {
      this.unsubscribe = this.store.subscribe(function () {
        let state = {};
        for (let key of Object.keys(this.state)) state[key] = null;
        this.setState(Object.assign({}, state, this.store.getState()));
      }.bind(this));

      let configError = null;
      if (!this.props.api) configError = "configuration error - no api specified";
      if (configError) {
        this.store.dispatch({ type: "API_ERROR", "error": configError});
      }
      else this.load();
    }
  }

  //We cannot call api within reducers, so we manage the lifecycle like a wheel, each new spin(reducer -> render -> componentDidUpdate) we execute the next method with bookingProcess.nextAction
  componentDidUpdate (prevProps, prevState) {
    switch (this.state.translate.nextAction) {
      case "getLabels":
        this[this.state.translate.nextAction]();
    }
  }

  load () {
    this.store.dispatch({type: "LOAD"});
  }
  onSelectEntries (event) {
    this.store.dispatch({type: "SELECT_PAGINATION", "pagination": Number(event.target.value)});
  }


  render () {
    //for PIMP-86 the design is like using Table elements
    //https://react-bootstrap.github.io/components.html#tables
    let self = this;
    if (this.state.translate.waiting) return <div>Loading...</div>;
    if (this.state.translate && this.state.translate.error) return <div>{this.state.translate.error}</div>;
    //Filter by search criteria
    let data = {};
    //Paginate data
    if (this.state.translate.labelsFiltered) data = JSON.parse(JSON.stringify(this.state.translate.labelsFiltered));
    else data = JSON.parse(JSON.stringify(this.state.translate.labels));
    data = translateService.setPagination(data, this.state.translate.pagination);
    let lengthTotal = data.length;

    //Filter by pagination
    if (this.state.translate.pagination) {
      let activePage = this.state.translate.activePage ? Number(this.state.translate.activePage) : 1;
      data = data.filter(function (label) { return label.page === Number(activePage); });
    }

    return <div className="translate-admin-container"><Panel>
      <Row>
        <PageHeader><b>PEX Translation overview Module: 'searchApplet'</b></PageHeader>
      </Row>
      <Row>
        <SelectEntries onSelectEntries={this.onSelectEntries.bind(this)} value={this.state.translate.pagination}/>
        <LabelSearcher value={self.state.translate.searchCriteria} searchHandler={this.searchHandler.bind(this)} />
      </Row>
      <Row>
        <Table responsive>
          <TableHeader languages={this.state.translate.languages}/>
          <TableBody data={data} languages={this.state.translate.languages} updateLabelValue={this.updateLabelValue.bind(this)} openEditor={this.openEditor.bind(this)} modified={self.state.translate.modified} openEditorForKey={this.state.translate.openEditorForKey}/>
        </Table>
      </Row>
      <Row>
        <PexPagination dataLength={lengthTotal} paginationHandler={this.paginationHandler.bind(this)} activePage={this.state.translate.activePage ? Number(this.state.translate.activePage) : 1} pagination={this.state.translate.pagination}/>
      </Row>
      <Row>
        <pre>{JSON.stringify(this.state.translate.modified, null, '\t')}</pre>
      </Row>
    </Panel></div>;
  }

  updateLabelValue (lang, name, value) {
    this.store.dispatch({ type: "UPDATE_LABEL_VALUE", "lang": lang, "name": name, "value": value});
  }

  searchHandler (event) {
    this.store.dispatch({ type: "UPDATE_SEARCH", "searchCriteria": event.target.value});
  }

  paginationHandler (eventKey) {
    this.store.dispatch({ type: "UPDATE_ACTIVE_PAGE", "activePage": eventKey.activePage});
  }

  openEditor (labelKey, lang) {
    this.store.dispatch({ type: "OPEN_EDITOR", "openEditorForKey": labelKey, "lang": lang});
  }
  closeEditor (labelKey) {
    this.store.dispatch({ type: "CLOSE_EDITOR"});
  }

  //API CALLS
  getLabels () {
    let self = this;
    api.getLabels(this.props.api, {"languageIds": this.state.translate.languages, "group": "searchApplet"}, function (getLabelsResponse) {
      self.store.dispatch({ type: "GET_LABELS_OK", "getLabelsResponse": getLabelsResponse});
    }, function () {
      self.store.dispatch({"type": "API_ERROR", "error": "api error 2343242", "waitingObject": {"waitingForLoad": false}});
    });
  }
}

function LabelTranslations (props) {
  let translations = [];
  let someModified = false;
  let displayValue = "N/A";
  if (props.label && props.label.displayValue) displayValue = props.label.displayValue;
  let enGBvalue = "";
  let enGBvalueModified = false;
  if (props.modified && props.modified["en-GB"]) {
    enGBvalue = props.modified["en-GB"];
    enGBvalueModified = true;
  } else {
    enGBvalue = props.label.languages["en-GB"];
  }

  translations.push(<td key="keyValue"><span dangerouslySetInnerHTML={{__html: utils.sanitize(props.label.displayValue)}}></span></td>);
  translations.push(<PexTraduction key="en-GB" name={props.label.name} lang="en-GB" modified={enGBvalueModified} value={enGBvalue} updateLabelValue={ props.updateLabelValue} openEditor={props.openEditor} openEditorForKey={props.openEditorForKey}/>);//"en-GB" default language
  // translations.push(<LabelEditor key="ed-en-GB"/>);//"en-GB" default language
  for (let lang of props.languages) {
    let value = "";
    if (props.label && props.label.languages[lang] && props.label.languages[lang]) value = props.label.languages[lang];
    let modified = false;
    if (props.modified && props.modified[lang]) {
      someModified = true;
      modified = true;
      value = props.modified[lang];
    }
    if (lang !== "en-GB") translations.push(<PexTraduction key={lang} name={props.label.name} lang={lang} modified={modified} value={value} updateLabelValue={ props.updateLabelValue} openEditor={props.openEditor} openEditorForKey={props.openEditorForKey}/>);
  }
  let labelModified = {"pex-label-modified": someModified};
  let colSpan = props.languages.length + 1;
  return <tr className={classNames("pex-label", labelModified)}>
    {translations}
  </tr>;
}

function SelectEntries (props) {

  return <Col md={6}>
    <Row>
      <Col md={1}><p>Show</p></Col>
      <Col md={5}>
        <FormControl value={props.value || "-"} componentClass="select" id="entries-selector" name="entries-selector" onChange={props.onSelectEntries} >
          <option value="-">No pagination</option>
          <option value="5">5</option>
          <option value="10">10</option>
          <option value="20">20</option>
        </FormControl>
      </Col>
      <Col md={1}><p> entries</p></Col>
    </Row>
  </Col>;

}

function LabelSearcher (props) {
  return <Col md={6}>
    <Row>
      <Col md={1}><p>Search</p></Col>
      <Col md={10}><FormControl value={props.value || ""} type="text" id="label-searcher" name="label-searcher" value={props.value} onChange={props.searchHandler}></FormControl></Col>
    </Row>
  </Col>;
}

function LabelEditor (props) {

  let value = "";
  if (props.label && props.label.languages[props.openEditorForKey.lang] && props.label.languages[props.openEditorForKey.lang]) value = props.label.languages[props.openEditorForKey.lang];
  if (props.modified && props.modified[props.openEditorForKey.lang]) value = props.modified[props.openEditorForKey.lang];
  console.log();
  return <tr><td colSpan="8">
    <Panel>
      <p><b>Lang: </b>{props.openEditorForKey.lang}</p>
      <PexTraductionEditor value={value} name={props.openEditorForKey.name} lang={props.openEditorForKey.lang} updateLabelValue={ props.updateLabelValue} />
      <p><b>HTML VIEW:</b></p>
      <p dangerouslySetInnerHTML={{__html: utils.sanitize(value)}}></p>
    </Panel>
  </td></tr>;
}

function TableHeader (props) {
  let headers = [];
  headers.push(<th key="displayValue">key</th>);
  headers.push(<th key="defaultValue"><center>en-GB</center></th>);
  for (let lang of props.languages) if (lang !== "en-GB") headers.push(<th key={lang}><center>{lang}</center></th>);

  return <thead>
    <tr>
      {headers}
    </tr>
  </thead>;
}

function TableBody (props) {
  let translationProps = { "languages": props.languages, "updateLabelValue": props.updateLabelValue, "openEditor": props.openEditor, "openEditorForKey": props.openEditorForKey};
  let labels = [];
  console.log("Re-render TAble Body");
  props.data.map(function (label) {
    labels.push(<LabelTranslations key={label.key} {...translationProps} label={label} modified={props.modified[label.key]}/>);
    if (props.openEditorForKey && label.key === props.openEditorForKey.name)labels.push(<LabelEditor key={label.key + "-editor"} label={label} modified={props.modified[label.key]} openEditorForKey={props.openEditorForKey} updateLabelValue={props.updateLabelValue} />);
  });

  return <tbody>
    {labels}
  </tbody>;
}
