import React from 'react';
import Col from 'react-bootstrap/lib/Col';
import Row from 'react-bootstrap/lib/Row';
import Carousel from 'react-bootstrap/lib/Carousel';
import ButtonGroup from 'react-bootstrap/lib/ButtonGroup';
import Button from 'react-bootstrap/lib/Button';
import utils from '../../../lib/utils.js';
import {OfferList} from './offer-list.jsx';
import translateService from '../../services/translate-service.js';

export function PexRoom (props) {

  function translate (name) {
    return translateService.getLabelsTranslated(props.labels, "searchApplet.pexRoom.", name, props.defaultLabels);
  }

  function goToRoomDetails () {
    props.goToRoomDetails(props.hash, props.offers, props.roomData, props.shortlist);
  }

  return <div className="single-property">
    <Row>
      <Col md={4}>
        <div className="room-title above-image">
          <h3>{props.roomData.propertyType}</h3>
        </div>
        <Carousel>
          {props.searchService.getCarousel(props.roomData.images)}
        </Carousel>
      </Col>
      <Col xs={12} md={6} className="room-type-description">
        <div hidden className ="pex-room-position">{ props.roomData.positionRank }</div>
        <div className="room-title">
          <h3>{props.roomData.propertyType}</h3>
        </div>
        <p className="buildingAndArea"><b>{translate("addressLabel") + ": "}</b> {props.roomData.building + ", " + props.roomData.place} </p>
        <p className="features"><b>{translate("featuresLabel") + ": "}</b> {props.roomData.features} </p>
        <p className="floor"><b>{translate("floorLabel") + ": "}</b> {props.roomData.floor} </p>
        <p className="portfolio"><b>{translate("portfolioLabel") + ": "}</b> {props.roomData.portfolio} </p>
        <p className="shortDescription"><b>{translate("descriptionLabel") + ": "}</b> <span dangerouslySetInnerHTML={{__html: utils.sanitize(props.roomData.shortDescription)}}></span></p>
        <p className="extraFeatures"><b>{translate("extraFeaturesLabel") + ": "}</b><span dangerouslySetInnerHTML={{__html: utils.sanitize(props.roomData.feat)}}></span></p>
      </Col>
      <Col md={2}>
        <span className="room-price-number">{ props.roomData.priceStr } </span>
      </Col>
      <Col md={8} xs={12} className="buttons">
        <ButtonGroup className="details-buttons">
          {props.hideButtons && props.hideButtons.details ? null : <Button onClick={ ()=>{ goToRoomDetails(props.roomData);} } bsStyle="default" className="pex-room-details"><i className="fa fa-home hidden-xs"></i>{" " + translate("detailsButton")}</Button>}
          {props.hideButtons && props.hideButtons.enquire ? null : <a href={props.roomData.enquireLink} className="btn btn-default btn-outline pex-room-enquire"><i className="fa fa-comments-o hidden-xs"></i> <span className="">{translate("enquireButton")}</span></a>}
          {props.hideButtons && props.hideButtons.arrange ? null : <a href={props.roomData.arrangeLink} className="btn btn-default btn-outline pex-room-arrange"><i className="fa fa-eye hidden-xs"></i> <span className="">{translate("arrangeButton")}</span></a>}
        </ButtonGroup>
      </Col>
      <OfferList defaultLabels={props.defaultLabels} showbookingSummary={props.showbookingSummary} labels={props.labels} offers={props.offers} roomData={props.roomData} addShortlist={props.addShortlist} removeShortlist={props.removeShortlist} hash={props.hash} searchSimilar={props.searchSimilar} shortlist={props.shortlist}/>
      <SimilarRoomsInfo translate={translate.bind(this)} similarRoomsCounter={props.similarRoomsCounter}/>
    </Row>
  </div>;
}

function SimilarRoomsInfo (props) {
  let counter = Object.keys(props.similarRoomsCounter).length - 1;
  if (counter === 0) return null;

  return <Col xs={12} md={12} className="property-group text-right"><span><i className="fa fa-building-o"></i> {props.translate("similarPropertiesLabel") + ": " + counter} </span></Col>;
}

