import Button from 'react-bootstrap/lib/Button';

import React from "react";
import ajax from '../../lib/ajax.js';
let api = require('../../lib/api.js')(ajax);

export default class FakePaymentGateway extends React.Component {

  constructor (props) {
    super(props);
    this.state = {};
  }

  render () {
    return <div>
      <h3>Fake payment gateway</h3>
      <Button onClick={()=>this.paymentOk()} bsStyle="success">Payment OK</Button>
      <Button onClick={()=>this.paymentKo()} bsStyle="danger">Payment KO</Button>
    </div>;
  }


  paymentOk () {
    let self = this;
    api.makeAfakePayment(this.props.api, {"booking": window.location.href.split("?")[1].split("=")[1]}, function (response) {
      //redirect to ok page
      window.location.href = "http://localhost:8112/clients/grainger-booking-journey/notification.html?action=PAIDSUCCEDED";
    }, function (message, messageType) {
      //redirect to error page
      window.location.href = "http://localhost:8112/clients/grainger-booking-journey/notification.html?action=FAIL";
    });
  }

  paymentKo () {
    window.location.href = "http://localhost:8112/clients/grainger-booking-journey/notification.html?action=FAIL";
  }

}
