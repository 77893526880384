import React from 'react';
import Panel from 'react-bootstrap/lib/Panel';
import Col from 'react-bootstrap/lib/Col';
import Row from 'react-bootstrap/lib/Row';
import Button from 'react-bootstrap/lib/Button';
import DeclineAcceptInfo from './steps-components/decline-accept-info.jsx';
import {InfoBar} from './info-bar.jsx';
import classNames from 'classnames';
import Loading from "./loading.jsx";

export function DeclineAcceptDirectDebit (props) {

  function cancelDirectDebit () {
    props.cancelDirectDebit(props.currentDirectDebit.id);
  }
  function goToPersonalDetails () {
    props.goToPersonalDetails(props.currentDirectDebit);
  }
  let myFooter = <Row>
    <Col xs={12} className="text-right">
      <Button bsStyle="danger" className="decline-direct-debit" onClick={ ()=> { cancelDirectDebit(); } } ><i className="fa fa-arrow-left hidden-sm hidden-lg"></i><span className="hidden-xs">Decline</span></Button>
      <Button bsStyle="success" className="accept-direct-debit" onClick={ ()=> { goToPersonalDetails(); } } ><span className="hidden-xs">Accept</span> <i className="fa fa-arrow-right hidden-sm hidden-lg"></i></Button>
    </Col>
  </Row>;
  if (props.waiting) return <Loading waiting={props.waiting} />;
  return (
    <div className={classNames('container direct-debit dd-new decline-accept-step')}>
      <InfoBar infoBarData={props.infoBarData}/>
      <Row>
        <Col xs={12}>
          <Panel header="Direct Debit Request" footer={myFooter}>
            <DeclineAcceptInfo rent={props.infoBarData.rent}
              frequency={props.infoBarData.frequency}
              firstDD={props.infoBarData.firstDD}
              lastDD={props.infoBarData.lastDD}
              tenantName={props.infoBarData.tenantName}/>
          </Panel>
        </Col>
      </Row>
    </div>
  );

}
