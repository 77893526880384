import React from 'react';
import FormControl from 'react-bootstrap/lib/FormControl';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import InputGroup from 'react-bootstrap/lib/InputGroup';
import Button from 'react-bootstrap/lib/Button';
import Col from 'react-bootstrap/lib/Col';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import Panel from 'react-bootstrap/lib/Panel';
import Row from 'react-bootstrap/lib/Row';
import classNames from 'classnames';

export default class PexTraduction extends React.Component {
  constructor (props) {
    super(props);
    //save the first value, so we will know if it is modified to update it
    this.state = {
      "value": this.props.value,
      "firstValue": this.props.value,
      "modified": this.props.modified,
      "edited": this.props.edited,
    };
  }

  onChangeHandler (event) {
    //it is expensive to execute the render method each time that user presses a key so:
    //we use its own state, so here we don't execute the top render method,
    this.setState({value: event.target.value, edited: true});
  }

  updateValue () {
    //update the value using the top render method when the user leaves the box
    if (this.state.value !== this.state.firstValue || this.props.modified) this.props.updateLabelValue(this.props.lang, this.props.name, this.state.value);
  }
  openEditor () {
    this.props.openEditor(this.props.name, this.props.lang);
  }

  componentDidUpdate () {
    //When the value has been modified from the text box
    if (this.props.value !== this.state.value && !this.state.edited) this.setState({"value": this.props.value});
    //When the value has been modified from the textArea
  }

  render () {
    let self = this;
    let modifiedClass = { "pex-traduction-modified": self.props.modified};
    return <td key={this.props.lang}>
      <FormGroup>
        <InputGroup>
          <FormControl className={classNames("pex-traduction", modifiedClass)} value={this.state.value} type="text" onBlur={this.updateValue.bind(this)} onChange={this.onChangeHandler.bind(this)}></FormControl>
          <InputGroup.Button><Button onClick={ ()=>{ this.openEditor();} } className="pex-open-editor" >...</Button></InputGroup.Button>
        </InputGroup>
      </FormGroup>
    </td>;
  }

}
