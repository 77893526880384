import React from 'react';
import { PexCommonField } from "./pex-common-field.jsx";
import DatePicker from 'react-datepicker';
import moment from 'moment';

export class PexDatePicker extends React.Component {
  render () {
    return <PexCommonField {...this.props}><DateField maxDate={this.props.maxDate ? this.props.maxDate : null} /></PexCommonField>;
  }
}

class DateField extends React.Component {
  constructor (props) {
    super(props);
    this.state = {};
    this.state.myDate = this.props.value ? moment(this.props.value, "YYYY-MM-DD").format("DD/MM/YYYY") : null;
  }

  handleChange (date) {
    if (typeof date === "string") {
      this.state.myDate = date;
      this.props.onChange({target: {value: date ? date : ""}});
    } else {
      this.state.myDate = date.format("DD/MM/YYYY");
      this.props.onChange({target: {value: date ? date.format("YYYY-MM-DD") : ""}});
    }
  }

  handleChangeRaw (event) {
    this.state.myDate = event.target.value;
    if (moment(event.target.value, "DD/MM/YYYY", true).isValid()) {
      let myDate = moment(event.target.value, "DD/MM/YYYY");
      this.props.onChange({target: {value: myDate ? myDate.format("YYYY-MM-DD") : ""}});
    }
    else this.props.onChange(event);
  }

  render () {
    let selected = null;
    if (this.props.value && moment(this.props.value, "YYYY-MM-DD", true).isValid()) selected = moment(this.props.value);
    return <DatePicker dateFormat="DD/MM/YYYY" className="form-control" id={this.props.id} name={this.props.name}
      selected={selected}
      value = {this.state.myDate}
      maxDate={this.props.maxDate}
      placeholderText={this.props.placeholder}
      onChange={::this.handleChange}
      onChangeRaw={::this.handleChangeRaw}
      showYearDropdown
      dropdownMode="select" yearDropdownItemNumber={15} />;
  }
}
