import React from 'react';
import { PexCommonField } from "./pex-common-field.jsx";
import DatePicker from 'react-datepicker';
import moment from 'moment';

export class PexDatePicker extends React.Component {
  render () {
    return <PexCommonField {...this.props}>
      <DateField excludeDates={this.props.excludeDates ? this.props.excludeDates : []}
        maxDate={this.props.maxDate ? this.props.maxDate : null}
        minDate={this.props.minDate ? this.props.minDate : null}
        monthsShown={this.props.monthsShown ? this.props.monthsShown : 1}
        getCurrentMoment={this.props.getCurrentMoment ? this.props.getCurrentMoment : null}
        inline={this.props.inline ? this.props.inline : false}
        yearDropdownItemNumber={this.props.yearDropdownItemNumber ? this.props.yearDropdownItemNumber : null}
        showYearDropdown={this.props.showYearDropdown ? this.props.showYearDropdown : false}
      />
    </PexCommonField>;
  }
}

class DateField extends React.Component {
  handleChange (date) {
    this.props.onChange({target: {value: date.unix()}});
  }
  render () {

    return <DatePicker dateFormat="DD/MM/YY" minDate={this.props.getCurrentMoment} className="form-control"
      selected={this.props.value ? moment.unix(this.props.value) : null}
      placeholderText={this.props.placeholder} onChange={::this.handleChange}
      excludeDates={this.props.excludeDates ? this.props.excludeDates : []}
      maxDate={this.props.maxDate} disabled={this.props.disabled}
      monthsShown={this.props.monthsShown} inline={this.props.inline}
      yearDropdownItemNumber={this.props.yearDropdownItemNumber} showYearDropdown={this.props.showYearDropdown}/>;
  }
}
