import React from 'react';
import ReactDOM from 'react-dom';
import ContactForm from './search/plug-in/contact-form.jsx';
import ResultsInCity from './search/plug-in/results-in-city.jsx';
import ResultsInBuilding from './search/plug-in/results-in-building.jsx';
import ResultsInBuildingListAll from './search/plug-in/results-in-building-list-all.jsx';
import ArrangeViewingForm from './search/plug-in/arrange-form.jsx';
import ArrangeRoomViewingForm from './search/plug-in/arrange-room-viewing-form.jsx';
import EnquiryRoomForm from './search/plug-in/enquiry-room-form.jsx';
import InterestForm from './search/plug-in/interest-form.jsx';
import SearchApplet from './search/search-applet.jsx';
import EssentialWidget from './essential/essential-widget.jsx';
import SharerAgreement from './search/plug-in/sharer-agreement.jsx';
import DirectDebitApplet from './direct-debit/direct-debit-applet.jsx';
import WidgetDemo from './widget-demo/widget-demo.jsx';
import TranslateAdminApplet from './translate-admin/translate-admin-applet.jsx';
import LangSelector from './translate-admin/lang-selector.jsx';
import {IntlProvider} from 'react-intl';
import GenericWebformWidget from "./generic-widget/generic-webform-widget.jsx";
import Step0 from "./clients/grainger-booking-journey/components/step0.jsx";
import GraingerBookingJourneyApplet from "./clients/grainger-booking-journey/grainger-booking-journey-applet.jsx";
import FakePaymentGateway from "./clients/grainger-booking-journey/fake-payment-gateway.jsx";
import OTPLogin from "./otp-login/otp-login.jsx";

require('react-datepicker/dist/react-datepicker.css');
require('react-image-gallery/build/image-gallery.css');
require('./lib/css/layout.css');
require('./lib/css/search.css'); //this could also go in search-applet.jsx to keep related stuff together, but it would still apply to everything

for_every_element_with_class ("pex-contact-form", function (element) {
  //TODO: validate props passed in here since proptypes are only for dev mode
  //since this is the entry point for the module, console.error if api is not set or if building location and building are
  let props = {
    api: element.getAttribute("api"),
    location: element.getAttribute("location"),
    building: element.getAttribute("building"),
    thanksmessage: element.getAttribute("thanksmessage"),
    requiredFields: element.getAttribute("requiredFields"),
    showUniversities: element.getAttribute("showUniversities"),
    hideCityDropdown: element.getAttribute("hideCityDropdown"),
    gtmId: element.getAttribute("gtmId"),
  };
  ReactDOM.render(<ContactForm {...props} />, element);
});

for_every_element_with_class ("pex-results-in-city", function (element) {
  ReactDOM.render(<ResultsInCity location={element.getAttribute("location")} api={element.getAttribute("api")} viewing={element.getAttribute("viewing")} book={element.getAttribute("book")} enquire={element.getAttribute("enquire")} />, element);
});

for_every_element_with_class ("pex-results-in-building", function (element) {
  let props = {
    api: element.getAttribute("api"),
    building: element.getAttribute("building"),
    book: element.getAttribute("book"),
    enquire: element.getAttribute("enquire"),
    viewing: element.getAttribute("viewing"),
    roomTypeSearch: element.getAttribute("roomTypeSearch"),
    imageHandler: element.getAttribute("imageHandler"),
    noResultsMsg: element.getAttribute("noResultsMsg"),
    lang: element.getAttribute("lang"),
    charsBeforeEllipsis: element.getAttribute("charsBeforeEllipsis"),
  };
  ReactDOM.render(<ResultsInBuilding {...props} />, element);
});

for_every_element_with_class ("pex-results-in-building-list-all", function (element) {
  let props = {
    api: element.getAttribute("api"),
    building: element.getAttribute("building"),
    book: element.getAttribute("book"),
    enquire: element.getAttribute("enquire"),
    viewing: element.getAttribute("viewing"),
    roomTypeSearch: element.getAttribute("roomTypeSearch"),
    imageHandler: element.getAttribute("imageHandler"),
    noResultsMsg: element.getAttribute("noResultsMsg"),
    lang: element.getAttribute("lang"),
    charsBeforeEllipsis: element.getAttribute("charsBeforeEllipsis"),
  };
  ReactDOM.render(<ResultsInBuildingListAll {...props} />, element);
});

for_every_element_with_class ("pex-arrange-form", function (element) {
  let props = {
    api: element.getAttribute("api"),
    thanksmessage: element.getAttribute("thanksmessage"),
    requiredFields: element.getAttribute("requiredFields"),
    showUniversities: element.getAttribute("showUniversities"),
    gtmId: element.getAttribute("gtmId"),
  };
  ReactDOM.render(<ArrangeViewingForm {...props} />, element);
});

for_every_element_with_class ("pex-arrange-room-viewing-form", function (element) {
  let props = {
    api: element.getAttribute("api"),
    thanksmessage: element.getAttribute("thanksmessage"),
    room: element.getAttribute("room"),
    requiredFields: element.getAttribute("requiredFields"),
    showUniversities: element.getAttribute("showUniversities"),
    gtmId: element.getAttribute("gtmId"),
  };
  ReactDOM.render(<ArrangeRoomViewingForm {...props} />, element);
});

for_every_element_with_class ("pex-enquiry-room-form", function (element) {
  let props = {
    api: element.getAttribute("api"),
    thanksmessage: element.getAttribute("thanksmessage"),
    room: element.getAttribute("room"),
    requiredFields: element.getAttribute("requiredFields"),
    showUniversities: element.getAttribute("showUniversities"),
    gtmId: element.getAttribute("gtmId"),
  };
  ReactDOM.render(<EnquiryRoomForm {...props} />, element);
});

for_every_element_with_class ("pex-generic-webform-widget", function (element) {
  let props = {
    api: element.getAttribute("api"),
    thanksmessage: element.getAttribute("thanksmessage"),
    room: element.getAttribute("room"),
    requiredFields: element.getAttribute("requiredFields"),
    webform: element.getAttribute("webform"),
  };
  ReactDOM.render(<GenericWebformWidget {...props} />, element);
});

for_every_element_with_class ("pex-interest-form", function (element) {
  let props = {
    api: element.getAttribute("api"),
    thanksmessage: element.getAttribute("thanksmessage"),
    areas: element.getAttribute("areas"),
    gtmId: element.getAttribute("gtmId"),
  };
  ReactDOM.render(<InterestForm {...props} />, element);
});

for_every_element_with_class ("pex-search-applet", function (element) {
  let props = {
    api: element.getAttribute("api"),
    arrange: element.getAttribute("arrange"),
    book: element.getAttribute("book"),
    enquire: element.getAttribute("enquire"),
    sso: element.getAttribute("sso"),
    pagination: element.getAttribute("pagination"),
    preselect: element.getAttribute("preselect"),
    universitiesFilteredByLocation: element.getAttribute("universitiesFilteredByLocation"),
    timeOutBook: element.getAttribute("timeOutBook"),
    multiselectSearchForm: element.getAttribute("multiselectSearchForm"),
    gtmId: element.getAttribute("gtmId"),
  };
  if (element.getAttribute("lang")) props.lang = element.getAttribute("lang");
  else props.lang = "en-GB";

  if (element.getAttribute("searchByParentAreas")) props.searchByParentAreas = element.getAttribute("searchByParentAreas");
  if (element.getAttribute("apiUser")) props.apiUser = element.getAttribute("apiUser");
  if (element.getAttribute("apiPassword")) props.apiPassword = element.getAttribute("apiPassword");

  ReactDOM.render(<IntlProvider locale = {props.lang}><SearchApplet {...props} /></IntlProvider>, element);
});

for_every_element_with_class ("pex-essential-widget", function (element) {
  let props = {
    api: element.getAttribute("api"),
    singleSign: element.getAttribute("singleSign"),
    bookingSite: element.getAttribute("bookingSite"),
    bookingSearchSite: element.getAttribute("bookingSearchSite"),
    gtmId: element.getAttribute("gtmId"),
  };
  ReactDOM.render(<EssentialWidget {...props} />, element);
});

for_every_element_with_class ("pex-sharer-agreement", function (element) {
  let props = {
    api: element.getAttribute("api"),
    sso: element.getAttribute("sso"),
    stay: element.getAttribute("stay"),
  };
  ReactDOM.render(<SharerAgreement {...props} />, element);
});

for_every_element_with_class ("pex-direct-debit", function (element) {
  let props = {
    api: element.getAttribute("api"),
    sso: element.getAttribute("sso"),
  };
  ReactDOM.render(<DirectDebitApplet {...props} />, element);
});

for_every_element_with_class ("pex-translate-admin", function (element) {
  let props = {
    api: element.getAttribute("api"),
    lang: element.getAttribute("lang"),
  };
  ReactDOM.render(<TranslateAdminApplet {...props} />, element);
});
for_every_element_with_class ("pex-lang-selector", function (element) {
  let props = {};
  ReactDOM.render(<LangSelector {...props} />, element);
});
for_every_element_with_class ("grainger-booking-journey-step0", function (element) {
  let props = {
    api: element.getAttribute("api"),
    sso: element.getAttribute("sso"),
    step1URL: element.getAttribute("step1URL"),
  };
  if (element.getAttribute("lang")) props.lang = element.getAttribute("lang");
  else props.lang = "en-GB";
  ReactDOM.render(<Step0 {...props} />, element);
});

for_every_element_with_class ("grainger-booking-journey-applet", function (element) {
  let props = {
    api: element.getAttribute("api"),
    sso: element.getAttribute("sso"),
    step0url: element.getAttribute("step0url"),
  };
  if (element.getAttribute("lang")) props.lang = element.getAttribute("lang");
  else props.lang = "en-GB";
  ReactDOM.render(<GraingerBookingJourneyApplet {...props} />, element);
});

for_every_element_with_class ("otp-login-applet", function (element) {
  let props = {
    api: element.getAttribute("api"),
    sso: element.getAttribute("sso"),
    username: element.getAttribute("username"),
    password: element.getAttribute("password"),
    appName: element.getAttribute("appName"),
    targetUrl: element.getAttribute("targetUrl"),
  };
  if (element.getAttribute("lang")) props.lang = element.getAttribute("lang");
  else props.lang = "en-GB";
  ReactDOM.render(<OTPLogin {...props} />, element);
});


for_every_element_with_class ("pex-widget-demo", function (element) {
  let props = {
    api: element.getAttribute("api"),
    sso: element.getAttribute("sso"),
  };
  ReactDOM.render(<WidgetDemo {...props} />, element);
});

for_every_element_with_class ("fake-payment-gateway", function (element) {
  let props = {
    api: element.getAttribute("api"),
    sso: element.getAttribute("sso"),
  };
  ReactDOM.render(<FakePaymentGateway {...props} />, element);
});

function for_every_element_with_class (className, callback) {
  //ON you can't iterate through HTMLCollections like you can with arrays
  //so please don't replace this with another kind of iterator without testing on IE
  //(this was tested on ie 11)
  let elements = document.getElementsByClassName(className);
  for (let idx = 0; idx < elements.length; idx++) {
    callback(elements[idx]);
  }
}
