import React from 'react';
import Col from 'react-bootstrap/lib/Col';
import Row from 'react-bootstrap/lib/Row';
import Button from 'react-bootstrap/lib/Button';
import Panel from 'react-bootstrap/lib/Panel';
import Table from 'react-bootstrap/lib/Table';
import ButtonGroup from 'react-bootstrap/lib/ButtonGroup';
import directDebitService from "../../../services/direct-debit-service.js";

export default class DirectDebitsList extends React.Component {
  render () {
    let directDebitsArr = [];
    let i = 0;
    let self = this;

    if (this.props.directDebits) {
      let declineDirectDebitID = null;
      if (this.props.cancelInstances && Object.keys(this.props.cancelInstances).length > 0) {
        for (let key of Object.keys(this.props.cancelInstances)) {
          if (this.props.cancelInstances[key].status === "waiting") {
            declineDirectDebitID = this.props.cancelInstances[key].declineDirectDebitID;
            break;
          }
        }
      }

      for (let directDebit of this.props.directDebits) {
        i++;
        switch (directDebit.status) {
          case "ok": {
            directDebitsArr.push(<DirectDebitOk key={i} directDebit={directDebit}
              startCancelDirectDebit={this.props.startCancelDirectDebit}
              cancelling={this.props.cancelling}
              declineDirectDebitID={declineDirectDebitID}
              undoCancel={this.props.undoCancel}
              buttons={directDebitService.getButtonsForDD(declineDirectDebitID, directDebit.directDebitId, this.props.cancelling, "ok", this.props.personid, directDebit.holderid)} />);
            break;
          }
          case "progress": {
            directDebitsArr.push(<DirectDebitProgress key={i} directDebit={directDebit}
              startCancelDirectDebit={this.props.startCancelDirectDebit}
              amendDirectDebit={this.props.amendDirectDebit}
              cancelling={this.props.cancelling}
              declineDirectDebitID={declineDirectDebitID}
              undoCancel={this.props.undoCancel}
              buttons={directDebitService.getButtonsForDD(declineDirectDebitID, directDebit.directDebitId, this.props.cancelling, "progress", this.props.personid, directDebit.holderid)}
            />);
            break;
          }
          case "cancelled": {
            directDebitsArr.push(<DirectDebitCancelled key={i} directDebit={directDebit} />);
            break;
          }

          case "failed": {
            directDebitsArr.push(<DirectDebitFailed key={i} directDebit={directDebit} />);
            break;
          }

        }
      }
    }
    return <Row className="direct-debits">
      <Col xs={12}>
        <Panel bsStyle="success">
          <div className="table-responsive">
            <Table hover>
              <thead>
                <tr>
                  <th className="table-title"><span>Direct Debits</span></th>
                  <th className="hidden-xs">Property</th>
                  <th className="hidden-xs">Amount</th>
                  <th className="hidden-xs">Frequency</th>
                  <th className="hidden-xs">Period</th>
                  <th className="hidden-xs">Tenant Name</th>
                  <th className="status">Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {directDebitsArr.length === 0 ? <tr className="direct-debits-list-no-data"><td>No direct debits found</td></tr> : directDebitsArr}
              </tbody>
            </Table>
          </div>
        </Panel>
      </Col>
    </Row>;
  }
}

function DirectDebitProgress (props) {
  return <tr className="dd-status-progress active">
    <td><span className="hidden-xs">For Tenancy -</span> {props.directDebit.tenancyId}</td>
    <td className="hidden-xs">{props.directDebit.propertyDescription}</td>
    <td className="hidden-xs">{props.directDebit.amount}</td>
    <td className="hidden-xs">{props.directDebit.frequency}</td>
    <td className="hidden-xs">{props.directDebit.startDate + " - " + props.directDebit.endDate}</td>
    <td className="hidden-xs">{props.directDebit.tenantName}</td>
    <td className="text-muted"><span className="in-progress"><i className="fa fa-clock-o hidden-xs"></i> In progress</span></td>
    <td>
      <DirectDebitButtonGroup directDebit={props.directDebit} startCancelDirectDebit={props.startCancelDirectDebit} amendDirectDebit={props.amendDirectDebit} cancelling={props.cancelling}
        declineDirectDebitID={props.declineDirectDebitID} undoCancel={props.undoCancel} buttons={props.buttons} />
    </td>
  </tr>;
}

function DirectDebitOk (props) {
  return <tr className="dd-status-ok success">
    <td>{"For Tenancy - " + props.directDebit.tenancyId}</td>
    <td className="hidden-xs">{props.directDebit.propertyDescription}</td>
    <td className="hidden-xs">{props.directDebit.amount}</td>
    <td className="hidden-xs">{props.directDebit.frequency}</td>
    <td className="hidden-xs">{props.directDebit.startDate + " - " + props.directDebit.endDate}</td>
    <td className="hidden-xs">{props.directDebit.tenantName}</td>
    <td className="text-success"><span className="completed"><i className="fa fa-check-circle-o"></i> Ok</span></td>
    <td>
      <DirectDebitButtonGroup directDebit={props.directDebit} startCancelDirectDebit={props.startCancelDirectDebit} amendDirectDebit={false} cancelling={props.cancelling}
        declineDirectDebitID={props.declineDirectDebitID} undoCancel={props.undoCancel} buttons={props.buttons} />
    </td>
  </tr>;
}

function DirectDebitCancelled (props) {
  return <tr className="dd-status-cancelled danger">
    <td><span className="hidden-xs">For Tenancy -</span> {props.directDebit.tenancyId}</td>
    <td className="hidden-xs">{props.directDebit.propertyDescription}</td>
    <td className="hidden-xs">{props.directDebit.amount}</td>
    <td className="hidden-xs">{props.directDebit.frequency}</td>
    <td className="hidden-xs">{props.directDebit.startDate + " - " + props.directDebit.endDate}</td>
    <td className="hidden-xs">{props.directDebit.tenantName}</td>
    <td className="text-danger"><span className="cancelled"><i className="fa fa-exclamation-circle"></i> Cancelled</span></td>
    <td>
    </td>
  </tr>;
}

function DirectDebitFailed (props) {
  return <tr className="dd-status-failed active">
    <td>{"For Tenancy - " + props.directDebit.tenancyId}</td>
    <td>{props.directDebit.propertyDescription}</td>
    <td>{props.directDebit.amount}</td>
    <td>{props.directDebit.frequency}</td>
    <td>{props.directDebit.startDate + " - " + props.directDebit.endDate}</td>
    <td>{props.directDebit.tenantName}</td>
    <td className="text-center text-muted"><span className="failed"><i className="fa fa-times-circle"></i> Failed</span></td>
    <td>
    </td>
  </tr>;
}

function DirectDebitButtonGroup (props) {
  function amendDirectDebit () {
    props.amendDirectDebit(props.directDebit.tenancyId, props.directDebit.directDebitId, props.directDebit.original);
  }
  function startCancelDirectDebit () {
    props.startCancelDirectDebit(props.directDebit.directDebitId);
  }
  function undoCancel () {
    props.undoCancel(props.directDebit.directDebitId);
  }

  return <ButtonGroup bsSize="small">
    {props.buttons.cancel ? <Button bsStyle="danger" disabled={props.cancelling && props.declineDirectDebitID !== props.directDebit.directDebitId}
      onClick={ () => { startCancelDirectDebit(); } } className="button-cancel" >Cancel</Button> : null}
    {props.buttons.finish ? <Button bsStyle="primary" disabled={props.cancelling && props.declineDirectDebitID !== props.directDebit.directDebitId}
      onClick={ () => { amendDirectDebit(); } } className="button-amend" >Finish</Button> : null}
    {props.buttons.cancelling ? <Button disabled className="button-cancelling" ><i className="fa fa-spinner"></i>Cancelling...</Button> : null}
    {props.buttons.undo ? <Button bsStyle="success" className="button-undo" onClick={ () => { undoCancel(); } } >Undo</Button> : null}
  </ButtonGroup>;
}
