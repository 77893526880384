"use strict";

import moment from "moment/moment";

function initForm (formNames, labels, component) {
  let formValues = {};
  for (let formName of Object.keys(formNames)) {
    formValues[formName] = null;
  }

  let form = {
    "values": formValues,
    "formNames": formNames,
    "submitted": false,
    "fieldsWithErrors": checkForm(formValues, formNames, labels, component).fieldsWithErrors,
    "canSubmit": false,
    "APIerror": undefined,
  };

  return form;
}

function checkForm (values, formNames, labels, component) {
  let fieldsWithErrors = {};
  let has_errors = false;

  function checkMandatory (field) {
    if (!fieldsWithErrors[field.key] && (!values[field.key] || values[field.key] === "")) {
      has_errors = true;
      if (labels && labels[component + ".validation.isRequired"]) fieldsWithErrors[field.key] = labels[field.labelError] + " " + labels[component + ".validation.isRequired"];
    }
  }
  function checkEmailFormat (field) {
    var re = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    if (!fieldsWithErrors[field.key] && values[field.key] && !re.test(values[field.key])) {
      has_errors = true;
      if (labels && labels[component + ".validation.emailNotValid"]) fieldsWithErrors[field.key] = labels[field.labelError] + " " + labels[component + ".validation.emailNotValid"];
    }
  }
  function checkRegex (field) {
    let re = new RegExp(field.regex);
    if (!fieldsWithErrors[field.key] && values[field.key] && !re.test(values[field.key])) {
      has_errors = true;
      if (labels && labels[component + ".validation.regexNotValid"]) fieldsWithErrors[field.key] = labels[field.labelError] + " " + labels[component + ".validation.regexNotValid"];
    }
  }

  function checkDate (field) {
    if (!fieldsWithErrors[field.key] && values[field.key] && !moment(values[field.key], "YYYY-MM-DD", true).isValid()) {
      has_errors = true;
      if (labels && labels[component + ".validation.dateNotValid"]) fieldsWithErrors[field.key] = labels[field.labelError] + " " + labels[component + ".validation.dateNotValid"];
    }
  }

  for (let field of Object.keys(formNames)) {
    if (formNames[field].mandatory) checkMandatory(formNames[field]);
    if (formNames[field].email) checkEmailFormat(formNames[field]);
    if (formNames[field].date) checkDate(formNames[field]);
    if (formNames[field].regex) checkRegex(formNames[field]);
  }
  return {canSubmit: !has_errors, fieldsWithErrors: fieldsWithErrors};
}

module.exports = {
  initStep0cForm: function (labels) {
    let loginForm = {
      "username": { "key": "username", "labelError": "graingerBookingJourney.login.usernameLabel", "mandatory": true, "email": true},
      "password": { "key": "password", "labelError": "graingerBookingJourney.login.passwordLabel", "mandatory": true},
    };
    return initForm(loginForm, labels, "graingerBookingJourney.login");
  },
  initTenantAndGuarantorDetailsTenantForm: function (labels) {
    let tenantAndGuarantorDetailsTenantForm = {
      "firstName": { "key": "firstName", "labelError": "graingerBookingJourney.tenantAndGuarantorDetails.firstNameLabel", "mandatory": true, "regex": "^[a-zA-ZÀ-ÿ\u00f1\u00d1 ]+(\\s*[a-zA-ZÀ-ÿ\u00f1\u00d1 ]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1 ]*$"},
      "lastName": { "key": "lastName", "labelError": "graingerBookingJourney.tenantAndGuarantorDetails.lastNameLabel", "mandatory": true, "regex": "^[a-zA-ZÀ-ÿ\u00f1\u00d1 ]+(\\s*[a-zA-ZÀ-ÿ\u00f1\u00d1 ]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1 ]*$"},
      "email": { "key": "email", "labelError": "graingerBookingJourney.tenantAndGuarantorDetails.emailLabel", "mandatory": true, "email": true},
      "phoneNumber": { "key": "phoneNumber", "labelError": "graingerBookingJourney.tenantAndGuarantorDetails.phoneNumberLabel", "mandatory": true, "regex": "^[0+](1\\-\\d\\d\\d)?[0-9]{1,19}$"},
    };
    return initForm(tenantAndGuarantorDetailsTenantForm, labels, "graingerBookingJourney.tenantAndGuarantorDetails");
  },
  initTenantAndGuarantorDetailsGuarantorForm: function (labels) {
    let tenantAndGuarantorDetailsGuarantorForm = {
      "firstName": { "key": "firstName", "labelError": "graingerBookingJourney.tenantAndGuarantorDetails.firstNameLabel", "mandatory": true, "regex": "^[a-zA-ZÀ-ÿ\u00f1\u00d1 ]+(\\s*[a-zA-ZÀ-ÿ\u00f1\u00d1 ]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1 ]*$"},
      "lastName": { "key": "lastName", "labelError": "graingerBookingJourney.tenantAndGuarantorDetails.lastNameLabel", "mandatory": true, "regex": "^[a-zA-ZÀ-ÿ\u00f1\u00d1 ]+(\\s*[a-zA-ZÀ-ÿ\u00f1\u00d1 ]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1 ]*$"},
      "email": { "key": "email", "labelError": "graingerBookingJourney.tenantAndGuarantorDetails.emailLabel", "mandatory": true, "email": true},
      "phoneNumber": { "key": "phoneNumber", "labelError": "graingerBookingJourney.tenantAndGuarantorDetails.phoneNumberLabel", "mandatory": true, "regex": "^[0+](1\\-\\d\\d\\d)?[0-9]{1,19}$"},
    };
    return initForm(tenantAndGuarantorDetailsGuarantorForm, labels, "graingerBookingJourney.tenantAndGuarantorDetails");
  },
  initTenantAndGuarantorDetailsAdditionalOccupantForm: function (labels) {
    let tenantAndGuarantorDetailsTenantForm = {
      "email": { "key": "email", "labelError": "graingerBookingJourney.tenantAndGuarantorDetails.emailLabel", "mandatory": true, "email": true},
      "firstName": { "key": "firstName", "labelError": "graingerBookingJourney.tenantAndGuarantorDetails.firstNameLabel", "mandatory": true, "regex": "^[a-zA-ZÀ-ÿ\u00f1\u00d1 ]+(\\s*[a-zA-ZÀ-ÿ\u00f1\u00d1 ]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1 ]*$"},
      "lastName": { "key": "lastName", "labelError": "graingerBookingJourney.tenantAndGuarantorDetails.lastNameLabel", "mandatory": true, "regex": "^[a-zA-ZÀ-ÿ\u00f1\u00d1 ]+(\\s*[a-zA-ZÀ-ÿ\u00f1\u00d1 ]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1 ]*$"},
      "dob": { "key": "dob", "labelError": "graingerBookingJourney.tenantAndGuarantorDetails.dobLabel", "mandatory": true, "date": true},
      "disclosableInterest": { "key": "disclosableInterest", "labelError": "graingerBookingJourney.tenantAndGuarantorDetails.relationshipLabel", "mandatory": true},
    };
    return initForm(tenantAndGuarantorDetailsTenantForm, labels, "graingerBookingJourney.tenantAndGuarantorDetails");
  },
  checkForm: function (values, formNames, labels, component) {
    return checkForm(values, formNames, labels, component);
  },
  setBoolean: function (attribute, value) {
    return setBoolean(attribute, value);
  },
};
