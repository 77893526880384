import React from 'react';
import ajax from '../lib/ajax.js';
import utils from '../lib/utils.js';
import moment from 'moment';
import { createStore } from 'redux';
import translateService from './services/translate-service.js';
import Form from 'react-bootstrap/lib/Form';
import ButtonGroup from 'react-bootstrap/lib/ButtonGroup';
import Button from 'react-bootstrap/lib/Button';
import Panel from 'react-bootstrap/lib/Panel';
import { PexText } from "./components/form-components/pex-text.jsx";
import { PexDropDown } from "./components/form-components/pex-drop-down.jsx";
import { PexDatePicker } from "./components/form-components/pex-date-picker.jsx";
import {PexCountryPhone} from './components/form-components/pex-country-phone.jsx';
import {PexJobDate} from './components/form-components/pex-job-date.jsx';
import {PexDateWithCheckbox} from './components/form-components/pex-date-with-checkbox.jsx';
import {PexBudgetTextBox} from './components/form-components/pex-budget-text-box.jsx';
import {PexCheckBox} from './components/form-components/pex-checkbox.jsx';
import {PexTextArea} from './components/form-components/pex-text-area.jsx';
import {BedRoomSelection} from './components/form-components/bed-room-selection.jsx';
import webformService from "./services/webform-service";
import {PexPassword} from "./components/form-components/pex-password.jsx";
let format_path = utils.format_path;
let api = require('../lib/api.js')(ajax);
let getCurrentMoment = require('../lib/get-current-moment.js');
const defaultLabels = JSON.parse(JSON.stringify(require("../../lang/search-applet/en-GB.json"))).labels["en-GB"];

export default class GenericWebformWidget extends React.Component {
  constructor (props) {
    super(props);
    let myState = {};
    if (!this.props.api) myState.error = "configuration error - no api specified";
    else if (!this.props.webform) myState.error = "configuration error - no webform specified";
    myState.loaded = false;
    myState.isOpen = "";
    myState.textFilterValue = "";
    let self = this;
    this.store = createStore(function (state = myState, action ) {
      // console.log(action.type);
      let newState = {};
      switch (action.type) {
        case "LOAD": {
          newState.nextAction = "getLabels";
          newState.lang = action.lang;
          return Object.assign(state, newState);
        }
        case "API_ERROR": {
          let error = webformService.getError(action.error, action.messageType, null, state.labels, "", "genericWebformWidget.customAPIMessage.");
          newState.nextAction = "DONE";
          newState.loaded = true;
          newState.error = error;
          newState.waiting = false;
          return Object.assign(state, newState);
        }
        case "GET_LABELS_OK": {
          newState.nextAction = "getWebform";
          newState.labels = translateService.setLabels(action.response.labels[state.lang], defaultLabels);
          return Object.assign(state, newState);
        }
        case "GET_WEBFORM_OK": {
          newState.webForm = webformService.parseWebform(action.response.entityInfo, getCurrentMoment().unix(), self.props.room, self.state.labels);
          newState.nextAction = "getFormCollections";
          if (newState.webForm.callMarketingCollections) newState.nextAction = "getMarketingCollections";
          return Object.assign(state, newState);
        }
        case "GET_MARKETING_COLLECTIONS_OK": {
          newState.nextAction = "getRoomDetails";
          if (!self.props.room) newState.nextAction = "getFormCollections";
          newState.marketingCollections = action.response;
          newState.webForm = webformService.setMarketingCollections(JSON.parse(JSON.stringify(state.webForm)), action.response);
          return Object.assign(state, newState);
        }
        case "GET_ROOM_DETAILS_OK": {
          newState = {};
          newState.data = { "room": action.response.room };
          newState.nextAction = "getFormCollections";
          newState.webForm = webformService.setRoomValues(JSON.parse(JSON.stringify(state.webForm)), action.response.room);
          newState.webForm = webformService.refreshBookingTypeDropdown(newState.webForm, state.marketingCollections);
          newState.webForm = Object.assign(newState.webForm, webformService.checkWebForm(newState.webForm.formAttributes, self.state.labels));
          return Object.assign(state, newState);
        }
        case "GET_FORM_COLLECTION_OK": {
          newState.countries = webformService.getCountries(action.response.countries);
          newState.nextAction = "DONE";
          newState.getFormCollectionsResponse = action.response;
          newState.waiting = false;
          newState.loaded = true;
          if (props.room && state.webForm.callGetDisabledDates) {
            newState.nextAction = "getDisabledDates";
            newState.waiting = true;
            newState.loaded = false;
          }
          return Object.assign(state, newState);
        }
        case "GET_DISABLED_DATES_OK": {
          newState.nextAction = "getTimeSlots";
          newState.disabledDates = action.response.disabledDates;
          newState.maxDate = action.response.disabledDates.length > 0 ? moment(action.response.disabledDates[action.response.disabledDates.length - 1]) : null;
          newState = Object.assign(newState, webformService.processDates(newState, state.webForm));
          return Object.assign({}, state, newState);
        }
        case "GET_TIME_SLOTS_OK": {
          let hours = [];
          if (action.response.timeSlots && action.response.timeSlots.length > 0) for (let h of action.response.timeSlots) hours.push(<option key={h.substring(0, 5)} value={h.substring(0, 5)}>{h.substring(0, h.length - 3)}</option>);
          let hoursPlaceHolder = state.webForm.values && state.webForm.values["jobDate"] && hours.length === 0 ? "There are no hours available for your selected date" : "Hour slot";
          return Object.assign({}, state, {"timeSlots": action.response.timeSlots, "waiting": false, "loaded": true, "nextAction": "DONE", "hours": hours, "hoursPlaceHolder": hoursPlaceHolder});
        }
        case "UPDATE_MARKETING_DROPDOWN": {
          newState = { "webForm": state.webForm};
          //MARKETING DROPDOWN UPDATE
          if (action.field === "area" && (action.value === "-" || action.value === "" || action.value !== newState.webForm.formAttributes[action.field].value)) {
            newState.webForm = webformService.setArea (newState.webForm, action.value);
          } else if (action.field === "building" && (action.value === "-" || action.value === "" || action.value !== newState.webForm.formAttributes[action.field].value)) {
            newState.webForm = webformService.setBuilding (newState.webForm, action.value);
          } else {
            newState.webForm.formAttributes[action.field] = webformService.setWebFormValue (newState.webForm.formAttributes[action.field], action.value);
          }
          newState.webForm = webformService.refreshMarketingDropdowns(newState.webForm, state.marketingCollections);
          newState.webForm = Object.assign(newState.webForm, webformService.checkWebForm(newState.webForm.formAttributes, self.state.labels));
          return Object.assign(state, newState);
        }
        case "UPDATE_FORM_FIELD": {
          newState = { "webForm": state.webForm};
          newState.webForm.formAttributes[action.field] = webformService.setWebFormValue (newState.webForm.formAttributes[action.field], action.value);
          newState.webForm = Object.assign(newState.webForm, webformService.checkWebForm(newState.webForm.formAttributes, self.state.labels));
          return Object.assign(state, newState);
        }
        case "UPDATE_JOB_DATE": {
          newState = { "webForm": state.webForm};
          if (action.field === "time") {
            newState.webForm.formAttributes["jobDate"] = webformService.setWebFormJobDate(newState.webForm.formAttributes["jobDate"], newState.webForm.formAttributes["jobDate"].fieldProps.dateValue, action.value);
          } else {
            newState.webForm.formAttributes["jobDate"] = webformService.setWebFormJobDate(newState.webForm.formAttributes["jobDate"], action.value, newState.webForm.formAttributes["jobDate"].fieldProps.hourValue);

            if (self.props.room ) {
              newState.nextAction = "getTimeSlots";
              newState.waiting = true;
            }
          }
          newState.webForm = Object.assign(newState.webForm, webformService.checkWebForm(newState.webForm.formAttributes, self.state.labels));
          return Object.assign(state, newState);
        }
        case "UPDATE_PHONE_NUMBER": {
          newState = { "webForm": state.webForm};
          newState.webForm.formAttributes["guest.mobile.theNumber"] = webformService.setWebFormPhoneNumber(newState.webForm.formAttributes["guest.mobile.theNumber"], newState.webForm.formAttributes["guest.mobile.theNumber"].fieldProps.countryValue, action.value);
          newState.webForm = Object.assign(newState.webForm, webformService.checkWebForm(newState.webForm.formAttributes, self.state.labels));
          return Object.assign(state, newState);
        }
        case "UPDATE_NOT_SURE_DATE": {
          newState = { "webForm": state.webForm};
          if (action.field === "notSureCheckbox") {
            if (action.value === "false") action.value = "true"; else action.value = "false";
            newState.webForm.formAttributes["enquiry.start"] = webformService.setWebFormDateNotSure(newState.webForm.formAttributes["enquiry.start"], newState.webForm.formAttributes["enquiry.start"].fieldProps.dateValue, action.value);
          } else {
            newState.webForm.formAttributes["enquiry.start"] = webformService.setWebFormDateNotSure(newState.webForm.formAttributes["enquiry.start"], action.value, newState.webForm.formAttributes["enquiry.start"].fieldProps.checkBoxValue);
          }
          newState.webForm = Object.assign(newState.webForm, webformService.checkWebForm(newState.webForm.formAttributes, self.state.labels));
          return Object.assign(state, newState);
        }
        case "UPDATE_BOOLEAN": {
          newState = { "webForm": state.webForm};
          newState.webForm.formAttributes[action.field] = webformService.setWebFormBoolean(newState.webForm.formAttributes[action.field], action.value);
          newState.webForm = Object.assign(newState.webForm, webformService.checkWebForm(newState.webForm.formAttributes, self.state.labels));
          return Object.assign(state, newState);
        }
        case "UPDATE_BEDROOM": {
          newState = { "webForm": state.webForm};
          newState.webForm.formAttributes[action.field] = webformService.setBedRoom (newState.webForm.formAttributes[action.field], action.value);
          newState.webForm = Object.assign(newState.webForm, webformService.checkWebForm(newState.webForm.formAttributes, self.state.labels));
          return Object.assign(state, newState);
        }
        case "SUBMITTED_WITH_ERRORS": {
          newState = { "webForm": state.webForm};
          newState.webForm.submitted = true;
          return Object.assign(state, newState);
        }
        case "SELECT_COUNTRY": {
          newState = state;
          newState.countrySelected = action.countrySelected;
          newState.webForm.formAttributes["guest.mobile.theNumber"] = webformService.setWebFormPhoneNumber(newState.webForm.formAttributes["guest.mobile.theNumber"], action.countrySelected.diallingCode, newState.webForm.formAttributes["guest.mobile.theNumber"].fieldProps.phoneValue);
          newState.webForm = Object.assign(newState.webForm, webformService.checkWebForm(newState.webForm.formAttributes, self.state.labels));
          newState.isOpen = "";
          return Object.assign(state, {"textFilterValue": ""}, newState);
        }
        case "OPEN_CLOSE_DROPDOWN": {
          let isOpen = action.collectionName;
          if (state.isOpen && state.isOpen.length > 0 && state.isOpen === action.collectionName) isOpen = "";
          return Object.assign(state, {"isOpen": isOpen, "textFilterValue": ""});
        }
        case "CLICK_OUTSIDE": {
          return Object.assign(state, {"isOpen": ""});
        }
        case "SEARCH_FILTER_HANDLER": {
          return Object.assign(state, {"textFilterValue": action.value});
        }
        case "START_SUBMIT_WEBFORM": {
          newState = {};
          newState.waiting = true;
          newState.nextAction = "webFormSubmit";
          return Object.assign(state, newState);
        }
        case "SUBMIT_WEBFORM_OK": {
          newState = {};
          newState.waiting = false;
          newState.loaded = false;
          newState.sent = true;
          newState.nextAction = "DONE";
          return Object.assign(state, newState);
        }
        case "SUBMIT_WEBFORM_WITH_ERROR": {
          newState = {};
          let error = webformService.getError(action.error, action.messageType, null, state.labels, "", "genericWebformWidget.customAPIMessage.", "genericWebformWidget.", action.specificMessageType);
          newState.waiting = false;
          newState.loaded = true;
          newState.nextAction = "DONE";
          newState.submitError = error;
          return Object.assign(state, newState);
        }
        default: return state;
      }
    });
    this.state = this.store.getState();
  }

  componentDidUpdate () {
    // console.log(this.state.nextAction);
    switch (this.state.nextAction) {
      case "getLabels":
      case "getWebform":
      case "getMarketingCollections":
      case "getRoomDetails":
      case "getFormCollections":
      case "getDisabledDates":
      case "getTimeSlots":
      case "webFormSubmit":
        this[this.state.nextAction]();
    }
  }

  componentDidMount () {
    if (this.store) {
      this.unsubscribe = this.store.subscribe(function () {
        let state = {};
        for (let key of Object.keys(this.state)) state[key] = null;
        this.setState(Object.assign({}, state, this.store.getState()));
      }.bind(this));
      let lang = "en-GB";
      if (this.props.lang) lang = this.props.lang;
      this.store.dispatch({type: "LOAD", "lang": lang});
    }
  }

  componentWillUnmount () {
    this.unsubscribe();
  }

  render () {
    if (this.state && this.state.loaded && !this.state.error) {
      return <div className={(this.state.waiting) ? "divFrozen" : "container-fluid"}>
        <FormPex openCloseDropdown={this.openCloseDropdown.bind(this)}
          clickOutside={this.clickOutside.bind(this)}
          onSearchTextHandler={this.onSearchTextHandler.bind(this)}
          selectCountry={this.selectCountry.bind(this)}
          startSubmit={this.startSubmit.bind(this)}
          updateField={this.updateField.bind(this)}
          updateJobDate={this.updateJobDate.bind(this)}
          updatePhoneNumber={this.updatePhoneNumber.bind(this)}
          updateMarketingDropdown={this.updateMarketingDropdown.bind(this)}
          updateNotSureDate={this.updateNotSureDate.bind(this)}
          updateBoolean={this.updateBoolean.bind(this)}
          updateBedroom={this.updateBedroom.bind(this)}
          getCurrentMoment={getCurrentMoment().unix()}
          state={this.state}
          room={this.props.room}
          requiredFields={this.props.requiredFields}
          defaultLabels={defaultLabels}/>
      </div>;
    }
    else if (this.state && this.state.error) return <div dangerouslySetInnerHTML={{__html: utils.sanitize(this.state.error)}}></div>;
    else if (this.state && this.state.roomUnavailableToView) {
      return <div className="room-unavailable-to-view">{translateService.getLabelsTranslated(this.state.labels, "roomUnavailable")}</div>;
    } else if (this.state && this.state.sent) {
      return <ThanksMessage thanksMessage={this.getThanksMessage()}/>;
    }
    else {
      return <div>Loading...</div>;
    }
  }

  // API CALLS
  getLabels () {
    let self = this;
    api.getLabels(this.props.api, {"languageIds": [this.state.lang], "group": "genericWebformWidget"}, function (response) {
      self.store.dispatch({ type: "GET_LABELS_OK", "response": response});
    }, function (message, messageType) {
      self.store.dispatch({"type": "API_ERROR", "error": "GENERIC-WEBFORM-WIDGET-ERROR-0000001 API error code - " + messageType + " - getLabels - " + message});
    });
  }

  getWebform () {
    let self = this;
    api.getWebform(this.props.api, {"language": this.state.lang, "viewName": this.props.webform}, function (response) {
      self.store.dispatch({ type: "GET_WEBFORM_OK", "response": response});
    }, function (message, messageType) {
      self.store.dispatch({"type": "API_ERROR", "error": "GENERIC-WEBFORM-WIDGET-ERROR-0000002 API error code - " + messageType + " - getWebform - " + message, "messageType": messageType});
    });
  }

  getMarketingCollections () {
    let self = this;
    api.marketingCollections(this.props.api, {}, function (response) {
      self.store.dispatch({type: "GET_MARKETING_COLLECTIONS_OK", "response": response});
    }, function (message, messageType) {
      self.store.dispatch({"type": "API_ERROR", "error": "GENERIC-WEBFORM-WIDGET-ERROR-0000003 API error code - " + messageType + " - marketingCollections - " + message, "messageType": messageType});
    });
  }

  getRoomDetails () {
    let self = this;
    api.getRoomDetails(this.props.api, {"room": this.props.room }, function (response) {
      self.store.dispatch({type: "GET_ROOM_DETAILS_OK", "response": response});
    }, function (message, messageType) {
      self.store.dispatch({"type": "API_ERROR", "error": "GENERIC-WEBFORM-WIDGET-ERROR-0000004 API error code - " + messageType + " - getRoomDetails - " + message, "messageType": messageType});
    });
  }

  getFormCollections () {
    let self = this;
    let payload = {};
    if (this.props.room) payload = {"location": this.state.data.room.location.lookupValue };
    api.getFormCollections(self.props.api, payload, function (response) {
      self.store.dispatch({type: "GET_FORM_COLLECTION_OK", "response": response});
    }, function (message, messageType) {
      self.store.dispatch({"type": "API_ERROR", "error": "GENERIC-WEBFORM-WIDGET-ERROR-0000005 API error code - " + messageType + " - getFormCollections - " + message, "messageType": messageType});
    });
  }

  getDisabledDates () {
    let self = this;
    api.getDisabledDates(self.props.api, {"room": this.props.room }, function (response) {
      if (response.disabledDates.length > 0 && response.disabledDates[response.disabledDates.length - 1] < moment.unix(self.state.webForm.formAttributes.jobDate.dateValue).format("YYYY-MM-DD")) {
        self.store.dispatch({"type": "API_ERROR", "error": translateService.getLabelsTranslated(self.state.labels, "ERRORdatesInPast")});
      } else {
        self.store.dispatch({type: "GET_DISABLED_DATES_OK", "response": response});
      }
    }, function (message, messageType) {
      self.store.dispatch({"type": "API_ERROR", "error": "GENERIC-WEBFORM-WIDGET-ERROR-0000006 API error code - " + messageType + " - getDisabledDates - " + message, "messageType": messageType});
    });
  }

  getTimeSlots () {
    let self = this;
    let payload = {
      "room": this.props.room,
      "date": moment.unix(this.state.webForm.formAttributes.jobDate.dateValue).format("YYYY-MM-DD"),
    };
    api.getTimeSlots(self.props.api, payload, function (response) {
      self.store.dispatch({type: "GET_TIME_SLOTS_OK", "response": response});
    }, function (message, messageType) {
      self.store.dispatch({"type": "API_ERROR", "error": "GENERIC-WEBFORM-WIDGET-ERROR-0000007 API error code - " + messageType + " - getTimeSlots - " + message, "messageType": messageType});
    });
  }

  webFormSubmit () {
    let self = this;
    api.webFormSubmit(this.props.api, webformService.getPayload(this.state.webForm, this.state), function (response) {
      self.store.dispatch({type: "SUBMIT_WEBFORM_OK", "response": response});
    }, function (message, messageType) {
      self.store.dispatch({"type": "SUBMIT_WEBFORM_WITH_ERROR", "error": "GENERIC-WEBFORM-WIDGET-ERROR-0000008 API error code - " + messageType + " - webFormSubmit - " + message, "messageType": messageType});
    });
  }

  //METHODS
  updateField (field, event) {
    this.store.dispatch({type: "UPDATE_FORM_FIELD", "field": field, "value": event.target.value});
  }
  updateMarketingDropdown (field, event) {
    this.store.dispatch({type: "UPDATE_MARKETING_DROPDOWN", "field": field, "value": event.target.value});
  }
  updateJobDate (field, event) {
    this.store.dispatch({type: "UPDATE_JOB_DATE", "field": field, "value": event.target.value});
  }
  updatePhoneNumber (field, event) {
    this.store.dispatch({type: "UPDATE_PHONE_NUMBER", "field": field, "value": event.target.value});
  }
  updateNotSureDate (field, event) {
    this.store.dispatch({type: "UPDATE_NOT_SURE_DATE", "field": field, "value": event.target.value});
  }
  updateBoolean (field, event) {
    this.store.dispatch({type: "UPDATE_BOOLEAN", "field": field, "value": event.target.value});
  }
  updateBedroom (field, value) {
    this.store.dispatch({type: "UPDATE_BEDROOM", "field": field, "value": value});
  }
  //TODO: I think this method is not used, REMOVE
  submittedWithErrors () {
    this.store.dispatch({type: "SUBMITTED_WITH_ERRORS"});
  }

  selectCountry (country) {
    this.store.dispatch({type: "SELECT_COUNTRY", "countrySelected": JSON.parse(JSON.stringify(country))});
  }
  openCloseDropdown (collectionName) {
    this.store.dispatch({ type: "OPEN_CLOSE_DROPDOWN", "collectionName": collectionName});
  }
  clickOutside (collectionName) {
    this.store.dispatch({ type: "CLICK_OUTSIDE", "collectionName": collectionName});
  }
  onSearchTextHandler (event) {
    this.store.dispatch({ type: "SEARCH_FILTER_HANDLER", "value": event.target.value});
  }
  startSubmit (event) {
    event.preventDefault();
    if (!this.state.webForm.canSubmit) this.store.dispatch({type: "SUBMITTED_WITH_ERRORS"});
    else this.store.dispatch({type: "START_SUBMIT_WEBFORM"});
  }
  getThanksMessage () {
    let thanksMessage = translateService.getLabelsTranslated(this.state.labels, this.state.webForm.viewName + "_thanksMessage");
    return format_path(thanksMessage, webformService.getListValuesToReplace(this.props.room, this.state));
  }

}

function FormPex (props) {
  let webComponents = [];

  function translate (name) {
    return translateService.getLabelsTranslated(props.state.labels, name);
  }
  let generalProps = {
    values: webformService.formAtributesToValues(props.state.webForm),
    errors: props.state.webForm.fieldsWithErrors ? props.state.webForm.fieldsWithErrors : {},
    submitted: props.state.webForm.submitted,
    waiting: props.state.waiting,
  };
  let countryPhoneProps = {
    openCloseDropdown: props.openCloseDropdown,
    clickOutside: props.clickOutside,
    onSearchTextHandler: props.onSearchTextHandler,
    selectCountry: props.selectCountry,
    translate: translate.bind(this),
    isOpen: props.state.isOpen,
    countriesData: props.state.countries,
    countrySelected: props.state.countrySelected,
    textFilterValue: props.state.textFilterValue,
  };


  for (let key of Object.keys(props.state.webForm.formAttributes)) {
    if (props.state.webForm.formAttributes[key].show) {
      if (key === "guest.mobile.theNumber") generalProps.updateField = props.updatePhoneNumber;
      else if (key === "jobDate") generalProps.updateField = props.updateJobDate;
      else if (props.state.webForm.formAttributes[key].marketingDropdown) generalProps.updateField = props.updateMarketingDropdown;
      else if (props.state.webForm.formAttributes[key].dateWithCheckbox) generalProps.updateField = props.updateNotSureDate;
      else if (props.state.webForm.formAttributes[key].boolean) generalProps.updateField = props.updateBoolean;
      else if (key === "enquiry.numberOfBedrooms") generalProps.updateField = props.updateBedroom;
      else generalProps.updateField = props.updateField;

      if (key === "guest.mobile.theNumber") {
        webComponents.push(<PexCountryPhone key="countryPhone" fieldKey={key} countryKey="mobileCountry" phoneKey={key}
          className="generic-plugin-phone-number"
          countryCollectionName="country-prefix"
          iconClassName="fa fa-phone"
          {...generalProps}
          {...countryPhoneProps}
          textValue={translate("selectCountryLabel")}
          label={props.state.webForm.formAttributes[key].fieldProps.label}
        />);
      } else if (key === "jobDate") {
        webComponents.push(<PexJobDate key={key}
          hours={props.state.data && props.state.data.room ? props.state.hours : null}
          excludeDates={props.state.excludeDates} maxDate={props.state.maxDate}
          attribute={props.state.webForm.formAttributes[key]}
          {...props.state.webForm.formAttributes[key].fieldProps}
          {...generalProps}
          translate={translate.bind(this)}/>);
      } else if (key === "password") {
        webComponents.push(<PexPassword key={key} {...props.state.webForm.formAttributes[key].fieldProps} {...generalProps}/>);
      } else if (props.state.webForm.formAttributes[key].component === "BED_ROOMS") {
        webComponents.push(<BedRoomSelection key={key} {...props.state.webForm.formAttributes[key].fieldProps}
          {...generalProps}
          attribute={props.state.webForm.formAttributes[key]}
          options={props.state.webForm.formAttributes[key].selectOptions}
          hideIcon={true}/>);
      } else if (props.state.webForm.formAttributes[key].component === "BUDGET") {
        webComponents.push(<PexBudgetTextBox key={key} {...props.state.webForm.formAttributes[key].fieldProps} {...generalProps } translate={translate.bind(this)}
          attribute={props.state.webForm.formAttributes[key]} utils={utils} hideIcon={true}/>);
      } else if (props.state.webForm.formAttributes[key].component === "DATE_NOT_SURE") {
        webComponents.push(<PexDateWithCheckbox key={key}
          {...props.state.webForm.formAttributes[key].fieldProps}
          {...generalProps }
          getCurrentMoment={props.getCurrentMoment}
          attribute={props.state.webForm.formAttributes[key]}
          excludeDates={props.state.excludeDates} maxDate={props.state.maxDate}
          translate={translate.bind(this)}/>);
      } else if (props.state.webForm.formAttributes[key].component === "CHECKBOX") {
        webComponents.push(<PexCheckBox key={key} {...props.state.webForm.formAttributes[key].fieldProps}
          {...generalProps}
          attribute={props.state.webForm.formAttributes[key]}
          translate={translate.bind(this)}
          hideIcon={true}/>);
      } else if (props.state.webForm.formAttributes[key].fieldType === "TEXTFIELD") {
        webComponents.push(<PexText key={key}
          {...props.state.webForm.formAttributes[key].fieldProps}
          {...generalProps } />);
      } else if (props.state.webForm.formAttributes[key].fieldType === "DATE") {
        webComponents.push(<PexDatePicker key={key}
          {...props.state.webForm.formAttributes[key].fieldProps}
          {...generalProps }/>);
      } else if (props.state.webForm.formAttributes[key].fieldType === "DROPDOWN") {
        let options = [];
        if (props.state.webForm.formAttributes[key].selectOptions.length > 0) options = props.state.webForm.formAttributes[key].selectOptions.map(function (el) {
          return <option key={el.lookupValue} value={el.lookupValue}>{el.displayValue}</option>;});
        webComponents.push(<PexDropDown key={key}
          {...props.state.webForm.formAttributes[key].fieldProps}
          {...generalProps} >{options}</PexDropDown>);
      } else if (props.state.webForm.formAttributes[key].fieldType === "TEXTAREA") {
        webComponents.push(<PexTextArea key={key}
          {...props.state.webForm.formAttributes[key].fieldProps}
          {...generalProps}/>);
      }
    }
  }

  return <Form onSubmit={props.startSubmit} className="generic-webform-widget-form" horizontal>
    {webComponents}
    <ButtonGroup vertical block>
      <Button type="submit" className="btn-submit-generic-webform">{translate("sendButton") + " "}<span className="fa fa-send" /></Button>
    </ButtonGroup>
    <SubmitError submitError={props.state.submitError}/>
  </Form>;
}

function SubmitError (props) {
  let error = props.submitError;
  if (error) return <div className="generic-submit-error">{error}</div>;
  return null;
}
function ThanksMessage (props) {
  return <div className="generic-thanks-container">
    <Panel header={props.header ? props.header : null} footer={props.footer ? props.footer : null} bsStyle="success">
      {props.thanksMessage}
    </Panel>
  </div>;
}
