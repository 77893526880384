import React from 'react';
import { PexCommonField } from "./pex-common-field.jsx";
import Checkbox from 'react-bootstrap/lib/Checkbox';

export class PexCheckbox extends React.Component {

  render () {
    return <PexCommonField {...this.props}><CheckboxField {...this.props}/></PexCommonField>;
  }

}

function CheckboxField (props) {

  function hanldeOnChange (event, key) {
    props.onChange(event, key);
  }

  return <div className="-container">
    <Checkbox className="-checkbox"
      value={props.value || ""}
      checked={props.value === "true"}
      onChange={ (ev) =>{ hanldeOnChange(ev, props.fieldKey);}}>{props.label ? props.label : null}</Checkbox>
  </div>;
}
