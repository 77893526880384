import React from 'react';
import TenanciesList from "./steps-components/tenancies-list.jsx";
import DirectDebitsList from "./steps-components/direct-debits-list.jsx";
import classNames from 'classnames';
import Loading from "./loading.jsx";

export function Dashboard (props) {
  if (props.waiting) return <Loading waiting={props.waiting} />;

  return (
    <div className={classNames('container direct-debit dd-dashboard')}>
      <TenanciesList tenancies={props.tenancies} createNewDirectDebit={props.createNewDirectDebit} cancelling={props.cancelling} ></TenanciesList>
      <DirectDebitsList directDebits={props.directDebits} startCancelDirectDebit={props.startCancelDirectDebit} amendDirectDebit={props.amendDirectDebit}
        cancelling={props.cancelling} cancelInstances={props.cancelInstances} undoCancel={props.undoCancel} personid={props.personid}/>
    </div>
  );

}
