"use strict";
import translateService from '../services/translate-service.js';
export default function translate (state = initState (), action ) {
  switch (action.type) {
    case "LOAD": {
      //get defaultValues
      let defaultLabels = require("../../../lang/search-applet/en-GB.json");
      let labels = {};
      let filterGroup = "searchApplet.";
      Object.keys(defaultLabels.labels["en-GB"]).sort().map(function (key) {
        if (key.startsWith(filterGroup) ) {
          labels[key] = {
            "key": key,
            "name": key,
            "displayValue": key,
            "languages": {
              "en-GB": defaultLabels.labels["en-GB"][key],
            },
          };
        }
      });
      return Object.assign(state, {"nextAction": "getLabels", "waiting": true, "labels": labels, "filterGroup": filterGroup});
    }
    case "GET_LABELS_OK": {
      let labels = JSON.parse(JSON.stringify(state.labels));
      //method to merge labels and defaultLabels
      labels = translateService.parseLabels (labels, JSON.parse(JSON.stringify(action.getLabelsResponse)), JSON.parse(JSON.stringify(state.languages)), state.filterGroup, state.pagination);

      return Object.assign(state, {"nextAction": "DONE", "labels": labels, "getLabelsResponse": action.getLabelsResponse, "waiting": false});
    }
    case "UPDATE_ACTIVE_PAGE": return Object.assign(state, {"activePage": action.activePage});
    case "SELECT_PAGINATION": return Object.assign(state, {"pagination": action.pagination, "activePage": 1 });
    case "UPDATE_SEARCH": {
      //FILTER BY SEARCH_CRITERIA
      let labelsFiltered = null;
      if (action.searchCriteria.length > 2) {
        labelsFiltered = {};
        Object.keys(state.labels).map(function (key) {
          if (key.toLowerCase().indexOf(action.searchCriteria.toLowerCase()) > -1) {
            labelsFiltered[key] = JSON.parse(JSON.stringify(state.labels[key]));
            let indexSearchCriteria = key.toLowerCase().indexOf(action.searchCriteria.toLowerCase());
            labelsFiltered[key].displayValue = key.substring(0, indexSearchCriteria) + "<b>" + key.substring(indexSearchCriteria, indexSearchCriteria + action.searchCriteria.length) + "</b>"
                                              + key.substring(indexSearchCriteria + action.searchCriteria.length, key.length);
          }
        });

        return Object.assign(state, {"activePage": 1, "searchCriteria": action.searchCriteria, "labelsFiltered": labelsFiltered });
      }
      return Object.assign(state, {"searchCriteria": action.searchCriteria, "labelsFiltered": labelsFiltered });
    }
    case "UPDATE_LABEL_VALUE": {
      let modified = {};
      if (state.modified) modified = JSON.parse(JSON.stringify(state.modified));
      if (!modified[action.name]) modified[action.name] = {};
      modified[action.name][action.lang] = action.value;
      return Object.assign(state, { "modified": modified });
    }
    case "API_ERROR": {
      return Object.assign(state, {"nextAction": "DONE", "error": action.error, "waiting": false});
    }
    case "OPEN_EDITOR": {
      return Object.assign(state, {"openEditorForKey": { "name": action.openEditorForKey, "lang": action.lang}} );
    }
    case "CLOSE_EDITOR": {
      return Object.assign(state, {"openEditorForKey": null});
    }
    default: return state;
  }
};

function initState () {
  let myState = {
    "waiting": true,
    "activePage": 1,
    "pagination": 10,
    "searchCriteria": "",
    "languages": ["en-GB", "es-ES", "nl-NL", "de-DE", "pl-PL", "hu-HU", "zh-CN"],
    "modified": {},
  };
  return myState;
}
