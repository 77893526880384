import formService from '../services/form-service.js';
import directDebitService from '../services/direct-debit-service.js';
let utils = require("../../lib/utils");

export default function directDebit (state = initState (), action ) {
  // console.log(action.type);
  switch (action.type) {
    case "LOAD": return Object.assign(state, {"nextAction": "getSSOKeyAndValidateObject", "currentComponent": null, "waiting": true});
    case "CALL_GET_DETAILS": return Object.assign(state, {"nextAction": "getWebContent", "currentComponent": null, "waiting": true, "personid": action.personid, "currentDirectDebit": null, "declineDirectDebitID": null});
    case "API_ERROR": return Object.assign(state, {"nextAction": "DONE", "error": action.error, "waiting": false, "cancelling": false});
    case "GET_DD_DETAILS_RESPONSE": return Object.assign(state, {"nextAction": "DONE", "error": null, "waiting": false, "detailsResponse": action.response.details});
    case "USER_NOT_LOGGED_IN": return Object.assign(state, {"nextAction": "DONE", "error": action.error, "waiting": false});
    case "GO_TO_NEW_DIRECT_DEBIT": return Object.assign(state, { "tenancyID": action.tenancyID, "currentComponent": "new-direct-debit-step", "newDirectDebit": Object.assign({}, initNewDirectDebit()), "agreement": Object.assign({}, initAgreement())});
    case "GO_TO_DASHBOARD": return Object.assign(state, {"currentComponent": null});
    case "GO_TO_PERSONAL_DETAILS":
    {
      return Object.assign(state, {"currentComponent": "personal-details", "currentDirectDebit": action.currentDirectDebit, "waiting": true, "paymentDetails": Object.assign({}, initPaymentDetails()), "agreement": Object.assign({}, initAgreement()) });
    }
    case "GO_TO_DECLINE_ACCEPT": {
      return Object.assign(state, {"currentComponent": "decline-accept", "directDebitID": action.directDebitID, "tenancyID": action.tenancyID, "currentDirectDebit": action.currentDirectDebit});
    }
    case "GO_TO_PAYMENT_DETAILS": return Object.assign(state, {"currentComponent": "payment-details"});
    case "CHANGE_PAY_MODE": return Object.assign(state, {"paymentDetails": Object.assign({}, state.paymentDetails, {"paymentTypeSelected": action.paymentTypeSelected})});
    case "CHANGE_AGREEMENT_OPTION": return Object.assign(state, {"agreement": Object.assign({}, state.agreement, {"agreementYesNoValue": action.agreementYesNoValue})});
    case "SET_DIRECT_DEBIT_OWNER": {} return Object.assign(state, {"newDirectDebit": Object.assign({}, state.newDirectDebit, {"selfOwner": action.selfOwner})});
    case "UPDATE_EMAIL_DD_FOR_OTHER": {
      let newState = { "newDirectDebit": state.newDirectDebit};
      newState.newDirectDebit.form.values[action.field] = action.value;
      newState.newDirectDebit.form = Object.assign(newState.newDirectDebit.form, formService.checkForm(newState.newDirectDebit.form.values, newState.newDirectDebit.form.formNames));
      return Object.assign(state, newState);
    }
    case "NEW_DIRECT_DEBIT_FORM_SUBMITTED_WITH_ERRORS": return Object.assign(state, {"newDirectDebit": Object.assign(state.newDirectDebit, Object.assign(state.newDirectDebit.form, {"submitted": true}) )});
    case "DIRECT_DEBIT_FOR_OTHER": return Object.assign(state, { "nextAction": "createNewDirectDebitForOther", "waiting": true});
    case "DIRECT_DEBIT_FOR_OTHER_OK": return Object.assign(state, {"nextAction": "getSSOKeyAndGetDirectDebitDetails", "error": null, "currentComponent": null, "waiting": true});
    case "DIRECT_DEBIT_FOR_USER": return Object.assign(state, { "nextAction": "createNewDirectDebitForUser", "waiting": true});
    case "DIRECT_DEBIT_FOR_USER_OK": {
      return Object.assign(state, {"nextAction": "DONE", "error": null, "currentComponent": "personal-details", "currentDirectDebit": action.response.directDebitDetails, "paymentDetails": Object.assign({}, initPaymentDetails())});
    }
    case "SUBMIT_PERSONAL_DETAILS": return Object.assign(state, {"nextAction": "getSSOKeyAndWebFormSubmit", "error": null, "waiting": true});
    case "SUBMIT_PERSONAL_DETAILS_OK": return Object.assign(state, {"nextAction": "getDetailsAfterPersonalDetails", "error": null, "currentComponent": "payment-details", "waiting": true});
    case "GET_DD_DETAILS_RESPONSE_AFTER_PERSONAL_DETAILS": {
      let newState = state;
      newState.currentDirectDebit = action.response.details.directDebits.filter(function (dd) { return dd.id === state.currentDirectDebit.id; })[0];
      newState.nextAction = "listBank";
      newState.error = null;
      newState.currentComponent = "payment-details";
      newState.waiting = true;
      return Object.assign(state, newState);
    }
    case "START_CANCEL_DIRECT_DEBIT": {
      let cancelInstances = state.cancelInstances ? state.cancelInstances : {};
      cancelInstances[action.directDebitID] = {"declineDirectDebitID": action.directDebitID, "status": "waiting"};
      return Object.assign(state, {"nextAction": "DONE", "cancelInstances": cancelInstances, "error": null, "cancelling": true});
    }
    case "UNDO_CANCEL": {
      let cancelInstances = state.cancelInstances ? JSON.parse(JSON.stringify(state.cancelInstances)) : {};
      cancelInstances[action.directDebitID].status = "cancelled";
      return Object.assign(state, {"cancelInstances": cancelInstances, "error": null, "cancelling": false, "declineDirectDebitID": null});
    }
    case "CANCEL_DIRECT_DEBIT": return Object.assign(state, {"nextAction": "getSSOKeyAndDecline", "declineDirectDebitID": action.directDebitID, "error": null, "waiting": true});
    case "CANCEL_DIRECT_DEBIT_OK": {
      let cancelInstances = state.cancelInstances ? JSON.parse(JSON.stringify(state.cancelInstances)) : {};
      for (let key of Object.keys(cancelInstances)) {
        if (cancelInstances[key].status === "waiting") {
          cancelInstances[key].status = "done";
          break;
        }
      }
      return Object.assign(state, {"nextAction": "getSSOKeyAndGetDirectDebitDetails", "error": null, "currentComponent": null, "waiting": true, "cancelling": false, "cancelInstances": cancelInstances});
    }
    case "LIST_BANK_OK": {
      window.pexBankList = action.response;
      return Object.assign(state, {"nextAction": "DONE", "error": null, "waiting": false, "currentComponent": "payment-details"});
    }
    case "UPDATE_BANK_FORM": {
      let newState = { "paymentDetails": state.paymentDetails};
      if (action.field === "code") {
        let bsbCode = action.value;
        if (bsbCode.length === 4 ) bsbCode = action.value.replace(/^(\d{3})(\d{1}).*/, "$1-$2");
        else if (bsbCode.length === 5 ) bsbCode = action.value.replace(/^(\d{3})(\d{2}).*/, "$1-$2");
        else if (bsbCode.length === 6 ) bsbCode = action.value.replace(/^(\d{3})(\d{3}).*/, "$1-$2");
        else if (bsbCode.length === 7 ) bsbCode = action.value.replace(/^(\d{3})(\d{3}).*/, "$1-$2");

        newState.paymentDetails.bankForm.values[action.field] = bsbCode;
      } else {
        newState.paymentDetails.bankForm.values[action.field] = action.value;
      }
      newState.paymentDetails.bankForm = Object.assign(newState.paymentDetails.bankForm, formService.checkForm(newState.paymentDetails.bankForm.values, newState.paymentDetails.bankForm.formNames));
      if (action.field === "code") {
        newState.paymentDetails.bankSelected = {};
        newState.paymentDetails.bankForm.values["code_"] = "";
      }
      return Object.assign(state, newState);
    }
    case "SELECT_BANK": {
      let newState = { "paymentDetails": state.paymentDetails};
      newState.paymentDetails.bankSelected = action.bankSelected;
      newState.paymentDetails.bankForm.values["code"] = action.bankSelected.code;
      newState.paymentDetails.bankForm.values["code_"] = action.bankSelected.code;
      newState.paymentDetails.bankForm = Object.assign(newState.paymentDetails.bankForm, formService.checkForm(newState.paymentDetails.bankForm.values, newState.paymentDetails.bankForm.formNames));
      return Object.assign(state, newState);
    }
    case "BANK_DETAILS_SUBMITTED_WITH_ERRORS": {
      let newState = { "paymentDetails": state.paymentDetails};
      newState.paymentDetails.bankForm.submitted = true;
      return Object.assign(state, newState);
    }
    case "DEBIT_CARD_DETAILS_SUBMITTED_WITH_ERRORS": {
      let newState = { "paymentDetails": state.paymentDetails};
      newState.paymentDetails.debitCardForm.submitted = true;
      return Object.assign(state, newState);
    }
    case "UPDATE_DEBIT_CARD_FORM": {
      let newState = { "paymentDetails": state.paymentDetails};
      newState.paymentDetails.debitCardForm.values[action.field] = action.value;
      newState.paymentDetails.debitCardForm = Object.assign(newState.paymentDetails.debitCardForm, formService.checkForm(newState.paymentDetails.debitCardForm.values, newState.paymentDetails.debitCardForm.formNames));
      return Object.assign(state, newState);
    }
    case "GET_AGREEMENT": return Object.assign(state, {"nextAction": "getAgreement", "waiting": true});
    case "GET_AGREEMENT_OK": {
      let newState = { "agreement": state.agreement};
      newState.agreement.agreementUrl = action.response.urlBase + action.response.document.lookupValue;
      newState.agreement.document = action.response.document;
      return Object.assign(state, newState, {"currentComponent": "agreement", "nextAction": "DONE", "waiting": false, "error": null });
    };
    case "CONFIRM_DIRECT_DEBIT": {
      let newState = { "agreement": state.agreement};
      newState.agreement.confirmPayload.registration = state.currentDirectDebit.id;
      newState.agreement.confirmPayload.document = state.agreement.document.lookupValue;
      newState.agreement.confirmPayload.signed = true;
      let parameters = [];
      if (state.paymentDetails.paymentTypeSelected === "debitCard") {
        newState.agreement.confirmPayload.cardDetails = {};
        for (let key of Object.keys(state.paymentDetails.debitCardForm.values)) {
          if (state.paymentDetails.debitCardForm.values[key] !== "") {
            if (key === "expiryYear" || key === "expiryMonth") {
              newState.agreement.confirmPayload.cardDetails[key] = Number(state.paymentDetails.debitCardForm.values[key]);
            } else {
              newState.agreement.confirmPayload.cardDetails[key] = state.paymentDetails.debitCardForm.values[key];
            }
          }
        }
      } else {
        newState.agreement.confirmPayload.bankDetails = {};
        for (let key of Object.keys(state.paymentDetails.bankForm.values)) {
          if (!key.endsWith("_"))newState.agreement.confirmPayload.bankDetails[key] = state.paymentDetails.bankForm.values[key];
          if (key === "code") newState.agreement.confirmPayload.bankDetails["code"] = utils.replaceAll(newState.agreement.confirmPayload.bankDetails[key], "-", "");
        }
        newState.agreement.confirmPayload.bankDetails.accountReference = state.currentDirectDebit.tenancy.tenancyId;
      }
      return Object.assign(state, {"nextAction": "confirmDirectDebit", "waiting": true});
    }
    case "CONFIRM_DIRECT_DEBIT_OK": return Object.assign(state, {"nextAction": "getSSOKeyAndGetDirectDebitDetails", "currentComponent": null });
    case "BLOCK_SCREEN": return Object.assign(state, {"waiting": true});
    case "UNBLOCK_SCREEN": return Object.assign(state, {"waiting": false, "nextAction": "DONE"});
    case "GET_WEB_CONTENT_OK": {
      let webContents = directDebitService.parseWebContents(action.response);
      return Object.assign({}, state, { "nextAction": "getSSOKeyAndGetDirectDebitDetails", "webContents": webContents});
    }
    default: return state;
  }
};

function initState () {
  let myState = {};
  myState.loaded = false;
  myState.waiting = true;
  myState.stepLoaded = false;
  myState.newDirectDebit = initNewDirectDebit();
  myState.paymentDetails = initPaymentDetails();
  myState.agreement = initAgreement();

  return myState;
}

function initNewDirectDebit () {
  return {
    "form": Object.assign({}, formService.initNewDirectDebitForm()),
  };
}

function initPaymentDetails () {
  return {
    "paymentTypeSelected": "bankAccount",
    "bankForm": Object.assign({}, formService.initBankForm()),
    "debitCardForm": Object.assign({}, formService.initDebitCardForm()),
  };
}

function initAgreement () {
  return {
    "agreementYesNoValue": false,
    "confirmPayload": {},
  };
}
