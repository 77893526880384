import React from 'react';
import Panel from 'react-bootstrap/lib/Panel';
import Col from 'react-bootstrap/lib/Col';
import Row from 'react-bootstrap/lib/Row';
import Button from 'react-bootstrap/lib/Button';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import Radio from 'react-bootstrap/lib/Radio';
import InputGroup from 'react-bootstrap/lib/InputGroup';
import FormControl from 'react-bootstrap/lib/FormControl';
import DirectDebitUserSelection from './steps-components/direct-debit-user-selection.jsx';
import InfoDirecDebit from './steps-components/info-direct-debit.jsx';
import {InfoBar} from './info-bar.jsx';
import Form from 'react-bootstrap/lib/Form';
import classNames from 'classnames';
import Loading from "./loading.jsx";

export function NewDirectDebit (props) {
  let footer = <Row>
    <Col xs={6}>
      <Button bsStyle="primary" className="go-to-dashboard" onClick={()=>{props.validateNewDirectDebit("dashboard");}}>Dashboard</Button>
    </Col>
    <Col className="col-xs-6 text-right">
      <Button bsStyle="success" className="go-to-next-step" onClick={()=>{props.validateNewDirectDebit("next-step");}}>Next step</Button>
    </Col>
  </Row>;
  if (props.data.waiting) return <Loading waiting={props.waiting} />;
  return (
    <div className={classNames('container direct-debit dd-new new-direct-debit-step')}>
      <InfoBar infoBarData={props.infoBarData}/>
      <Row>
        <Col xs={12}>
          <Form>
            <Panel className="panel-min-height" header="Direct Debit Request" footer={footer}>
              <DirectDebitUserSelection optionSelected={props.data.optionSelected}
                form={props.data.form}
                waiting={props.data.waiting}
                yesNoAction={props.setDirectDebitOwner}
                emailChangeHandler={props.updateEmailDirectDebitForOther}
                webContents={props.webContents} />
              <InfoDirecDebit rent={props.infoBarData.rent}
                frequency={props.infoBarData.frequency}
                firstDD={props.infoBarData.firstDD}
                lastDD={props.infoBarData.lastDD}/>
            </Panel>
          </Form>
        </Col>
      </Row>
    </div>
  );

}
