import React from 'react';
import utils from "../../../lib/utils";
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import Popover from 'react-bootstrap/lib/Popover';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import Button from 'react-bootstrap/lib/Button';

export default class PaymentResponse extends React.Component {

  render () {

    if (this.props.state.component === "success") {
      let contactPopover = <Popover id="paymentOk-contact">
        <div className={"paymentOk-popover-content"} dangerouslySetInnerHTML={{__html: utils.sanitize(this.props.state.labels["graingerBookingJourney.paymentOk.contactPopoverContent"])}}></div>
      </Popover>;
      return <div className={""}>
        <Row>
          <Col sm={12}>
            <div className={"text-center paymentOk-message"}>
              <i className={"fa fa-check-circle fa-5x"}></i>
            </div>
            <div className={"message"}>
              <h2 className={"message-title"} dangerouslySetInnerHTML={{__html: utils.sanitize(this.props.state.labels["graingerBookingJourney.paymentOk.title"])}}></h2>
              <div dangerouslySetInnerHTML={{__html: utils.sanitize(this.props.state.labels["graingerBookingJourney.paymentOk.thanksMessage"])}}></div>
              <div dangerouslySetInnerHTML={{__html: utils.sanitize(this.props.state.labels["graingerBookingJourney.paymentOk.messageInfo"])}}></div>
              <div>
                {this.props.state.labels["graingerBookingJourney.paymentOk.mistakeLabel"]}
                <OverlayTrigger trigger="click" placement="right" overlay={contactPopover} rootClose>
                  <Button bsStyle="link" className={"paymentOk-popover-btn"}>{this.props.state.labels["graingerBookingJourney.paymentOk.contactUsLabel"]}</Button>
                </OverlayTrigger>
              </div>
            </div>
          </Col>
        </Row>
      </div>;
    } else {
      let contactPopover = <Popover id="paymentKo-contact">
        <div className={"paymentKo-popover-content"} dangerouslySetInnerHTML={{__html: utils.sanitize(this.props.state.labels["graingerBookingJourney.paymentKo.contactPopoverContent"])}}></div>
      </Popover>;
      return <div className={""}>
        <Row>
          <Col sm={12}>
            <div className={"message"}>
              <h2 className={"message-title"} dangerouslySetInnerHTML={{__html: utils.sanitize(this.props.state.labels["graingerBookingJourney.paymentKo.title"])}}></h2>
              <div dangerouslySetInnerHTML={{__html: utils.sanitize(this.props.state.labels["graingerBookingJourney.paymentKo.thanksMessage"])}}></div>
              <div>
                {this.props.state.labels["graingerBookingJourney.paymentKo.mistakeLabel"]}
                <OverlayTrigger trigger="click" placement="right" overlay={contactPopover} rootClose>
                  <Button bsStyle="link" className={"paymentKo-popover-btn"}>{this.props.state.labels["graingerBookingJourney.paymentKo.contactUsLabel"]}</Button>
                </OverlayTrigger>
              </div>
            </div>
          </Col>
        </Row>
      </div>;
    }
  }
}


