import React from 'react';
import Col from 'react-bootstrap/lib/Col';
import Row from 'react-bootstrap/lib/Row';
import Carousel from 'react-bootstrap/lib/Carousel';
import Panel from 'react-bootstrap/lib/Panel';
import ButtonGroup from 'react-bootstrap/lib/ButtonGroup';
import Button from 'react-bootstrap/lib/Button';
import utils from '../../../lib/utils.js';
import {OfferList} from './offer-list.jsx';
import {ShowError} from './show-error.jsx';
import Tabs from 'react-bootstrap/lib/Tabs';
import Tab from 'react-bootstrap/lib/Tab';
import translateService from '../../services/translate-service.js';
import PexCookiePolicy from "../../../legal/pex-cookie-policy.jsx";

export default class PexRoomDetails extends React.Component {

  render () {
    let self = this;
    function translate (name) {
      return translateService.getLabelsTranslated(self.props.labels, "searchApplet.pexRoomDetails.", name, self.props.defaultLabels);
    }

    if (!this.props.userLogged && this.props.from === "shortlist") {
      return <div>A user not logged in cannot see room details of a room from shortlist</div>;
    } else if (!this.props.unit) {
      let footerError = <Button className="go-to-search-btn" bsStyle="success" onClick={this.props.redirectToSearchApplet}>
        <i className="fa fa-icon"></i> <span className="hidden-xs">{translate("goToSearchButtonLabel")}</span>
      </Button>;
      return <ShowError error={translate("notAvailableRoomLabel")} classname={"room-details-error"} footer={footerError}/>;
    }
    let offerList = <OfferList defaultLabels={this.props.defaultLabels}
      labels={this.props.labels}
      offers={this.props.unit.offers}
      room={this.props.unit.room}
      handleBookNow={this.props.handleBookNow}
      hash={this.props.unit.hash}
      showbookingSummary={this.props.showbookingSummary}
      addShortlist={this.props.addShortlist}
      removeShortlist={this.props.removeShortlist}
      roomData={this.props.unit.room}
    />;

    return <Row className="pex-room-details-container">
      <Col md={12} className="col-no-padding" id="sec0">
        <Carousel className="pex-main-carousel">
          {this.props.searchService.getCarousel(this.props.unit.room.images)}
        </Carousel>
      </Col>
      <Col md={12} xs={12} className="col-no-padding pull-right">
        <div className="description">
          <Col md={3} xs={12} className="pull-right" id="book-box">
            <BookBox translate={translate.bind(this)} unit={this.props.unit} handleBookNow={this.props.handleBookNow} goToOfferTable={this.props.goToOfferTable}/>
          </Col>
          <Col md={9} xs={12}>
            <RoomDetailsTabs translate={translate.bind(this)} unit={this.props.unit} />
          </Col>
        </div>
        {offerList}
      </Col>
      <PexCookiePolicy colMd={12} />
    </Row>;
  }
}

function RoomDetailsTabs (props) {
  let tabArr = [];
  if (props.unit.room.description || props.unit.room.shortDescription || props.unit.room.summary || props.unit.room.displayAddress) {
    tabArr.push(
      <Tab tabClassName="room-details-tab room-description-tab" key={1} eventKey={1} title={props.translate("descriptionLabel")}>
        <div className="room-description">
          <h1 className="room-title">{props.unit.room.propertyType} - {props.unit.room.building}</h1>
          <div className="room-detailed-description">
            <h3 className="room-short-description" dangerouslySetInnerHTML={{__html: utils.sanitize(props.unit.room.shortDescription)}}></h3>
            <div className="room-main-description" dangerouslySetInnerHTML={{__html: utils.sanitize(props.unit.room.description)}}></div>
            <div className="room-summary" dangerouslySetInnerHTML={{__html: utils.sanitize(props.unit.room.summary)}}></div>
            <div className="room-main-displayAddress" dangerouslySetInnerHTML={{__html: utils.sanitize(props.unit.room.displayAddress)}}></div>
          </div>
        </div>
      </Tab>);
  }
  if (props.unit.room.tabs.fplan) {
    let showFplanDiv = null;
    if (props.unit.room.tabs.fplan.startsWith("http") || props.unit.room.tabs.fplan.startsWith("/")) showFplanDiv = <div className="room-fplan"><img className="img-responsive" src={utils.sanitize(props.unit.room.tabs.fplan)} /></div>;
    tabArr.push(
      <Tab tabClassName="room-details-tab room-fplan-tab" key={2} eventKey={2} title={props.translate("floorPlanLabel")}>
        {showFplanDiv}
      </Tab>);
  }
  if (props.unit.room.tabs.epc) {
    let showEPCDiv = null;
    if (props.unit.room.tabs.epc.startsWith("http") || props.unit.room.tabs.epc.startsWith("/")) showEPCDiv = <div className="room-EPC"><img className="img-responsive" src={utils.sanitize(props.unit.room.tabs.epc)} /></div>;
    tabArr.push(
      <Tab tabClassName="room-details-tab room-EPC-tab" key={3} eventKey={3} title={props.translate("epcLabel")}>
        {showEPCDiv}
      </Tab>);
  }
  if (tabArr.length === 0) return null;
  return <Row className="room-details-tab-container"><Tabs defaultActiveKey={1} id="room-details-tabs-component">{tabArr}</Tabs></Row>;
}

function BookBox (props) {
  let price = utils.format_rate(props.unit.offers[0].price.number, props.unit.offers[0].price.unit);
  if (props.unit.offers[0].price.number === -1) price = null;
  else if (props.unit.offers.length > 1) price = props.translate("fromLabel") + " " + price;

  return <Panel id="rightCol" header = {<span className="price-currency">{price}</span>} >
    <OfferButtons translate={props.translate} unit={props.unit} handleBookNow={props.handleBookNow} price={price} startDate={props.startDate} goToOfferTable={props.goToOfferTable}/>
    <StartDate translate={props.translate} unit={props.unit} />
    <Facilities facilities={props.unit.room.feat ? props.unit.room.feat : null} />
  </Panel>;
}

function Facilities (props) {
  if (!props.facilities) return null;
  return <div className="room-details-feats" dangerouslySetInnerHTML={{__html: utils.sanitize(props.facilities)}}></div>;
}

function OfferButtons (props) {
  return <ButtonGroup vertical block>
    <Button onClick={props.goToOfferTable} bsStyle="success" className="book-now-linked-offer-list">
      <i className="fa fa-bed hidden-xs"></i>{" " + props.translate("bookButton")}</Button>
    { props.price ? <a href={props.unit.room.enquireLink} className="btn btn-default btn-outline pex-room-enquire"><i className="fa fa-comments-o hidden-xs"></i> <span className="">{props.translate("enquireButton")}</span></a> : null }
    { props.price ? <a href={props.unit.room.arrangeLink} className="btn btn-default btn-outline pex-room-arrange"><i className="fa fa-eye hidden-xs"></i> <span className="">{props.translate("arrangeButton")}</span></a> : null }
  </ButtonGroup>;
}

function StartDate (props) {
  if (props.unit && props.unit.offers && props.unit.offers.length === 1) {
    return <div className="details">
      <div className="pex-room-availability-date well well-sm">
        <span className="pex-next-availability-date-label"><i className="fa fa-calendar-check-o"></i>{props.translate("startDateLabel") + ": "}</span>
        <span className="pex-next-availability-date-value">{props.unit.offers[0].startStr}</span> <br />
        <span className="pex-availability-end-date-label"><i className="fa fa-calendar-o"></i>{props.translate("endDateLabel") + ": "}</span>
        <span className="pex-availability-end-date-value">{props.unit.offers[0].endStr ? props.unit.offers[0].endStr : props.translate("notAvailableLabel")}</span>
      </div>
    </div>;
  }
  return <div className="details"></div>;
}
