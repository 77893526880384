import React from 'react';
import InputGroup from 'react-bootstrap/lib/InputGroup';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import classNames from 'classnames';
import Dropdown from 'react-bootstrap/lib/Dropdown';
import onClickOutside from 'react-onclickoutside';

class PexDropdownMultiSelect extends React.Component {
  constructor (props) {
    super(props);
    this.state = {};
  }
  handleClickOutside () {
    this.props.onClickOutside(this.props.collectionName);
  }
  error () {
    if (this.props.submitted && this.props.errors[this.props.fieldKey]) return true;
    return false;
  }

  componentDidMount () {
    let error = null;
    if (!this.props.collectionName) error = "No collectionName attribute";
    else if (!this.props.label) error = "No label attribute";
    else if (!this.props.iconClassName) error = "No iconClassName attribute";
    else if (!this.props.onClickOpen) error = "No onClickOpen attribute";
    else if (!this.props.onSelectOption) error = "No onSelectOption attribute";

    if (error) this.setState({"error": error});
  }

  render () {
    let classesParent = this.props.className;
    if (this.state.error) return <div className="error-found">{this.state.error}</div>;
    return <FormGroup className={classNames(classesParent) + " dropdown-category-container dropdown-category-container-" + this.props.collectionName} >
      <ControlLabel htmlFor={this.props.collectionName}>{this.props.label}</ControlLabel>
      <InputGroup>
        <InputGroup.Addon><i className={this.props.iconClassName}/></InputGroup.Addon>
        <MultiSelectField collectionName={this.props.collectionName}
          onClickOpen={this.props.onClickOpen}
          onSelectOption={this.props.onSelectOption}
          open={this.props.open}
          data={this.props.data}
          labels={this.props.labels}
          defaultLabels={this.props.defaultLabels}
          elementDisabled = {this.props.elementDisabled}
          parentsOfAreas = {this.props.parentsOfAreas}
        />
      </InputGroup>
    </FormGroup>;
  }
}
//This export is required to use the clickOutside event.
export default onClickOutside(PexDropdownMultiSelect);

function MultiSelectField (props) {
  function onClickOpen () {
    props.onClickOpen(props.collectionName);
  }
  //This event is required to manage the open attribute, if we remove the declaration it won't work
  function onToggle () {
    return;
  }

  let open = false;
  if (props.open && props.open.length > 0 && props.open === props.collectionName ) open = true;

  return <Dropdown id={props.collectionName} disabled={props.elementDisabled} className={"dropdown-category pex-list-" + props.collectionName} open={open} onToggle={onToggle}>
    <Dropdown.Toggle id={props.collectionName} className ={"category-toggle-btn dropdown-toggle-" + props.collectionName} onClick={ () => {onClickOpen();}}>
      <DropDownText data={props.data} labels={props.labels} defaultLabels={props.defaultLabels} parentsOfAreas = {props.parentsOfAreas} />
    </Dropdown.Toggle>
    <Dropdown.Menu className={"pex-menu-items-" + props.collectionName}>
      <DropDownOptions collectionName={props.collectionName} data={props.data} onSelectOption={props.onSelectOption} labels={props.labels} parentsOfAreas = {props.parentsOfAreas}/>
    </Dropdown.Menu>
  </Dropdown>;
}

function DropDownText (props) {
  let tagSelected = props.defaultLabels["searchApplet.searchForm.tagSelected"];
  if (props.labels && props.labels["searchApplet.searchForm.tagSelected"]) tagSelected = props.labels["searchApplet.searchForm.tagSelected"];
  let allTagsSelected = props.defaultLabels["searchApplet.searchForm.allTagsSelected"];
  if (props.labels && props.labels["searchApplet.searchForm.allTagsSelected"]) allTagsSelected = props.labels["searchApplet.searchForm.allTagsSelected"];
  let categoryNoData = props.defaultLabels["searchApplet.searchForm.categoryNoData"];
  if (props.labels && props.labels["searchApplet.searchForm.categoryNoData"]) categoryNoData = props.labels["searchApplet.searchForm.categoryNoData"];
  let categoryNoSelected = props.defaultLabels["searchApplet.searchForm.categoryNoSelected"];
  if (props.labels && props.labels["searchApplet.searchForm.categoryNoSelected"]) categoryNoSelected = props.labels["searchApplet.searchForm.categoryNoSelected"];

  if (!props.data || (props.data && Object.keys(props.data).length === 0)) return <span className="dropdown-text-no-data">{categoryNoData}</span>;
  let optionsSelectedLength = Object.keys(props.data).filter( function (el) { return props.data[el].selected; });
  if (optionsSelectedLength.length === Object.keys(props.data).length) return <span className="dropdown-text-all-selected">{allTagsSelected + " (" + optionsSelectedLength.length + ")"}</span>;
  if (optionsSelectedLength.length === 1) return <span className="dropdown-text-one-selected">{optionsSelectedLength.length + " " + tagSelected}</span>;
  if (optionsSelectedLength.length > 1) return <span className="dropdown-text-more-than-one-selected">{optionsSelectedLength.length + " " + tagSelected}</span>;
  return <span className="dropdown-text-none-selected">{categoryNoSelected}</span>;
}

function DropDownOptions (props) {
  function onSelectOption (option) {
    props.onSelectOption(option.lookupValue, props.collectionName, option.selected);
  }

  function translateSearchFormTagLabels (lookupValue, displayValue) {
    if (props.labels && props.labels["searchApplet.searchForm.categorytag" + lookupValue]) return props.labels["searchApplet.searchForm.categorytag" + lookupValue];
    return displayValue;
  }

  let finalElements = [];
  if (props.data && Object.keys(props.data).length > 0) {
    finalElements = Object.keys(props.data).map(function (key) {
      return <li id={props.data[key].lookupValue} key={props.data[key].lookupValue}
        className={
          classNames(
            "list-group-item pex-element-" + props.data[key].lookupValue,
            {"pex-element-selected": props.data[key].selected},
            {"pex-element-disabled": props.data[key].disabled}
          )
        }
        onClick={() => onSelectOption(props.data[key])} >
        <i className={"fa fa-square-o checkbox-" + props.data[key].lookupValue} />
        {translateSearchFormTagLabels(props.data[key].lookupValue, props.data[key].displayValue)}
      </li>;
    });
  }

  return <span>{finalElements}</span>;
}
