"use strict";

import React from 'react';
import moment from 'moment';

const tenanciesToDashboard = function tenanciesToDashboard (tenanciesResponse, directDebits) {
  let tenancies = [];

  for (let t of tenanciesResponse) {
    let myTenancy = {};
    myTenancy.tenancyId = t.lookupValue;
    myTenancy.newDirectDebit = false;
    if (t.ddStart) myTenancy.newDirectDebit = true;
    else {
      let ddOfTenancy = directDebits.filter(function (dd) {return dd.tenancy.tenancyId === t.lookupValue;});
      if (!ddOfTenancy || ddOfTenancy.length === 0) {
        myTenancy.status = "noDirectDebit";
      } else {
        //MORE THAN ONE DD
        if (ddOfTenancy && ddOfTenancy.length > 1) {
          let okDD = ddOfTenancy.filter(function (dd) {return dd.status === "OK";});
          //all ok
          if (okDD.length > 1) myTenancy.status = "moreThanOneDD";
          //one ok
          else if (okDD.length === 1) myTenancy.status = "ok";
          //no one ok - so we check in progress
          else if (okDD.length === 0) {
            //Check in progress
            let inProgressDD = ddOfTenancy.filter(function (dd) {return dd.status === "DIRECT_DEBIT_SETUP_IN_PROGRESS";});
            //more than one in progress
            if (inProgressDD.length > 1) myTenancy.status = "moreThanOneDD";
            //one ok
            else if (inProgressDD.length === 1) myTenancy.status = "progress";
            else {
              myTenancy.status = "noDirectDebit";
            }
          }
        }
        //ONE DD
        else if (ddOfTenancy[0].status === "OK") myTenancy.status = "ok";
        else if (ddOfTenancy[0].status === "DIRECT_DEBIT_SETUP_IN_PROGRESS") myTenancy.status = "progress";
        else if (ddOfTenancy[0].status === "DIRECT_DEBIT_SETUP_FAILED") myTenancy.status = "failed";
      }
    }
    myTenancy.startDate = moment(t.start).format('D MMM YYYY');
    myTenancy.endDate = moment(t.end).format('D MMM YYYY');
    myTenancy.propertyDescription = t.property;
    tenancies.push(myTenancy);
  }

  return tenancies;
};

const dataForNewDirectDebit = function dataForNewDirectDebit (data) {
  let rtn = {};
  if (data.newDirectDebit && data.newDirectDebit.selfOwner) rtn.optionSelected = data.newDirectDebit.selfOwner;
  else rtn.optionSelected = "";
  if (data.newDirectDebit && data.newDirectDebit.form) rtn.form = data.newDirectDebit.form;
  rtn.waiting = data.waiting;

  return rtn;
};

const dataForInfoBar = function dataForInfoBar (data) {
  let rtn = {};
  let tenancy = {};
  let directDebit = data.currentDirectDebit;
  tenancy = data.detailsResponse.tenancies.filter((ten) => {return data.tenancyID === ten.tenancyId;})[0];

  let ddCurrency = directDebit && directDebit.tenancy && directDebit.tenancy.property ? directDebit.tenancy.ddCurrency : tenancy.ddCurrency;

  rtn.description = directDebit && directDebit.tenancy && directDebit.tenancy.property ? directDebit.tenancy.property : tenancy.property;
  rtn.rent = directDebit && directDebit.tenancy && directDebit.tenancy.ddAmount ? ddCurrency + " " + directDebit.tenancy.ddAmount : ddCurrency + " " + tenancy.ddAmount;
  rtn.tenancyId = directDebit && directDebit.tenancy && directDebit.tenancy.tenancyId ? directDebit.tenancy.tenancyId : tenancy.tenancyId;
  rtn.frequency = directDebit && directDebit.tenancy && directDebit.tenancy.invoiceFrequency.displayValue ? directDebit.tenancy.invoiceFrequency.displayValue : tenancy.invoiceFrequency.displayValue;
  rtn.tenantName = directDebit && directDebit.tenancy && directDebit.tenancy.tenantName ? directDebit.tenancy.tenantName : tenancy.tenantName;
  rtn.payerName = directDebit && directDebit.directDebitHolder.displayValue ? directDebit.directDebitHolder.displayValue : tenancy.tenantName;
  if (data.currentComponent === "new-direct-debit-step") {
    rtn.firstDD = moment(tenancy.firstCollection).format('D MMM YYYY');
    rtn.lastDD = moment(tenancy.lastCollection).format('D MMM YYYY');
    rtn.payerName = "";
  } else {
    rtn.firstDD = directDebit && directDebit.firstCollection ? moment(directDebit.firstCollection).format('D MMM YYYY') : moment(tenancy.firstCollection).format('D MMM YYYY');
    rtn.lastDD = directDebit && directDebit.lastCollection ? moment(directDebit.lastCollection).format('D MMM YYYY') : moment(tenancy.lastCollection).format('D MMM YYYY');
  }
  if (data.paymentDetails.paymentTypeSelected === "bankAccount" && data.currentComponent === "agreement" ) {
    rtn.paymentType = "Bank Account";
    rtn.paymentTypeName = data.paymentDetails.bankSelected.name ;
  }
  if (data.paymentDetails.paymentTypeSelected === "debitCard" && data.currentComponent === "agreement") {
    rtn.paymentType = "Card details";
    rtn.paymentTypeName = "Debit card";
  }
  return rtn;
};

const dataForAgreement = function dataForAgreement (directDebit) {
  return { "holderName": directDebit.directDebitHolder.displayValue ? directDebit.directDebitHolder.displayValue : "",
    "receiver": directDebit.receiver ? directDebit.receiver : "",
    "receiverBacsReference": directDebit.receiverBacsReference ? directDebit.receiverBacsReference : "" };
};

const directDebitsToDashboard = function directDebitsToDashboard (directDebitsResponse) {

  let directDebits = [];

  for (let dd of directDebitsResponse) {
    let mydirectDebit = {};
    mydirectDebit.tenancyId = dd.tenancy.tenancyId;
    //TODO: format dates like in the Pawel design
    mydirectDebit.startDate = moment(dd.firstCollection).format('D MMM YYYY');
    mydirectDebit.endDate = moment(dd.lastCollection).format('D MMM YYYY');
    mydirectDebit.propertyDescription = dd.tenancy.property;
    if (dd.status === "OK") mydirectDebit.status = "ok";
    else if (dd.status === "DIRECT_DEBIT_SETUP_IN_PROGRESS") mydirectDebit.status = "progress";
    else if (dd.status === "DIRECT_DEBIT_SETUP_FAILED") mydirectDebit.status = "failed";
    else if (dd.status === "DIRECT_DEBIT_SETUP_CANCELLED") mydirectDebit.status = "cancelled";
    let ddCurrency = "";
    if (dd.tenancy.ddCurrency) ddCurrency = dd.tenancy.ddCurrency;
    mydirectDebit.amount = ddCurrency + " " + dd.tenancy.ddAmount;
    mydirectDebit.frequency = dd.tenancy.invoiceFrequency.displayValue;
    mydirectDebit.directDebitId = dd.id;
    mydirectDebit.tenantName = dd.tenancy.tenantName;
    mydirectDebit.holderid = dd.directDebitHolder.id;
    mydirectDebit.directDebitHolder = dd.directDebitHolder;
    mydirectDebit.original = dd;
    directDebits.push(mydirectDebit);
  }

  return directDebits;
};

const getErrorCodeForFunction = function getErrorCodeForFunction (error) {
  let errorNumber = '';
  switch (error.code) {
    case "DDA00002":
      errorNumber = "1";
      break;
    case "DDA00005":
      errorNumber = "2";
      break;
    case "DDA00006":
      errorNumber = "3";
      break;
    case "DDA00004":
      errorNumber = "4";
      break;
    case "DD30323":
      errorNumber = "5";
      break;
    case "DD100001":
      errorNumber = "6";
      break;
    case "DD100007":
      errorNumber = "7";
      break;
    case "DD100008":
      errorNumber = "8";
      break;
    case "DDA00001":
      errorNumber = "9";
      break;
    case "DDA00007":
      errorNumber = "10";
      break;
    case "DDA00008":
      errorNumber = "11";
      break;
    case "DDA00020":
      errorNumber = "12";
      break;
    case "DDA00021":
      errorNumber = "13";
      break;
  }

  return errorNumber;
};

const getButtonsForDD = function getButtonsForDD (declineDirectDebitID, directDebitId, cancelling, directDebitStatus, personid, directDebitHolderId) {
  let rtn = {
    "finish": directDebitStatus === "ok" ? false : declineDirectDebitID !== directDebitId && personid === directDebitHolderId,
    "cancel": declineDirectDebitID !== directDebitId && personid === directDebitHolderId,
    "undo": cancelling && declineDirectDebitID === directDebitId,
    "cancelling": cancelling && declineDirectDebitID === directDebitId,
  };
  return rtn;
};

const parseWebContents = function parseWebContents (response) {
  let rtn = {};
  if (response.webContent && response.webContent.length > 0) {
    for (let webContent of response.webContent) {
      rtn[webContent.name] = webContent.content;
    }
  }
  return rtn;
};

module.exports = {
  tenanciesToDashboard: function (tenancies, directDebits) {
    return tenanciesToDashboard(tenancies, directDebits);
  },
  directDebitsToDashboard: function (directDebits) {
    return directDebitsToDashboard(directDebits);
  },
  dataForNewDirectDebit: function (data) {
    return dataForNewDirectDebit(data);
  },
  dataForInfoBar: function (data) {
    return dataForInfoBar(data);
  },
  getErrorCodeForFunction: function (error) {
    return getErrorCodeForFunction(error);
  },
  getButtonsForDD: function (declineDirectDebitID, directDebitId, cancelling, directDebitStatus, personid, directDebitHolderId) {
    return getButtonsForDD(declineDirectDebitID, directDebitId, cancelling, directDebitStatus, personid, directDebitHolderId);
  },
  dataForAgreement: function (data) {
    return dataForAgreement(data);
  },
  parseWebContents: function (response) {
    return parseWebContents(response);
  },

};
