"use strict";

const newDirectDebitForm = {
  "email": { "key": "email", "labelError": "Email", "email": true, "mandatory": true},
  "confirmEmail": { "key": "confirmEmail", "labelError": "Confirm email", "sameValue": "email", "mandatory": true},
};

const bankForm = {
  "code": { "key": "code", "labelError": "BSB number", "mandatory": true, "valueFromList": "code_"},
  "code_": { "key": "code_", "labelError": "code_", "hidden": true},
  "accountName": { "key": "accountName", "labelError": "Full name on account", "mandatory": true},
  "accountNumber": { "key": "accountNumber", "labelError": "Account number", "mandatory": true},
};

const debitCardForm = {
  "cardNumber": { "key": "cardNumber", "labelError": "Card number", "mandatory": true},
  "nameOnCard": { "key": "nameOnCard", "labelError": "Card name", "mandatory": true},
  "expiryMonth": { "key": "expiryMonth", "labelError": "Expiration month", "mandatory": true},
  "expiryYear": { "key": "expiryYear", "labelError": "Expiration year", "mandatory": true},
  "securityCode": { "key": "securityCode", "labelError": "Security code", "mandatory": true},
};


function initForm (formNames, values) {
  let formValues = {};
  for (let formName of Object.keys(formNames)) formValues[formName] = "";

  for (let formName of Object.keys(formNames)) {
    if (values[formName]) {
      formValues[formName] = values[formName].value;
    } else {
      formValues[formName] = "";
    }
  }

  let form = {
    "values": formValues,
    "formNames": formNames,
    "submitted": false,
    "APIerror": undefined,
  };
  return Object.assign({}, form, checkForm(formValues, formNames));
}

function checkForm (values, formNames) {

  let error_list = [];
  let fieldsWithErrors = {};
  let has_errors = false;

  function checkEmailFormat (field) {
    var re = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    if (!fieldsWithErrors[field.key] && values[field.key] && !re.test(values[field.key])) {
      has_errors = true;
      fieldsWithErrors[field.key] = field.labelError + " is not valid";
    }
  }
  //
  function checkNumberValue (field) {
    // TODO: isNaN used to take values.phoneNumber but this form's number value is mobile so possibly need to change the property that is being checked
    if (!fieldsWithErrors[field.key] && values[field.key] && isNaN(values[field.key])) {
      has_errors = true;
      fieldsWithErrors[field.key] = formNames[field.key].labelError + " is not valid";
    }
  }
  function checkSameValue (field) {
    if (!fieldsWithErrors[field.key] && values[field.key] !== values[field.sameValue] ) {
      has_errors = true;
      fieldsWithErrors[field.key] = field.labelError + " is not the same as " + formNames[field.sameValue].labelError.toLowerCase();
    }
  }
  function checkValueFormList (field) {
    if (!fieldsWithErrors[field.key] && values[field.key] !== values[field.valueFromList] ) {
      has_errors = true;
      fieldsWithErrors[field.key] = "Please check your BSB and retry — or contact us to assist you";
    }
  }

  //First validation rules for each field. If a field is required, with format email and has to be equal than other field.
  //First we check it has value and we show this error, we check the format just if it has not a current error, same for all validations
  //that's fieldsWithErrors is empty when start the validation

  function checkMandatory (field) {
    if (!values[field.key] || values[field.key] === "") { //PRIORITY ONE. MANDATORY
      has_errors = true;
      fieldsWithErrors[field.key] = formNames[field.key].labelError + " is required";
    }
  }

  for (let field of Object.keys(formNames)) {
    if (formNames[field].mandatory) checkMandatory(formNames[field]);
    if (formNames[field].email) checkEmailFormat(formNames[field]);
    if (formNames[field].sameValue) checkSameValue(formNames[field]);
    if (formNames[field].number) checkNumberValue(formNames[field]);
    if (formNames[field].valueFromList) checkValueFormList(formNames[field]);
  }
  return {canSubmit: !has_errors, fieldsWithErrors: fieldsWithErrors};
}


module.exports = {
  // initGenericForm: function (formNames) {
  //   return initForm(formNames);
  // },
  //NOTE: NO CODE WITHOUT TESTS FIRST (RED-GREEN-REFACTOR)
  initGenericForm: function (formNames, formValues) {
    return initForm(formNames, formValues);
  },
  checkForm: function (values, formNames) {
    return checkForm(values, formNames);
  },
  initNewDirectDebitForm: function () {
    return initForm(newDirectDebitForm, []);
  },
  initBankForm: function () {
    return initForm(bankForm, []);
  },
  initDebitCardForm: function () {
    return initForm(debitCardForm, []);
  },

};
