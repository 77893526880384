import React from 'react';
import { PexCommonField } from "./pex-common-field.jsx";
import DatePicker from 'react-datepicker';
import moment from "moment/moment";
import Checkbox from 'react-bootstrap/lib/Checkbox';

export class PexDateWithCheckbox extends React.Component {

  render () {
    // console.log(this.props);
    //This is not a  common fiel we wouldn't use it but for now we set a singleValue, never mind because we don't use it to validate this field
    //we set somevalue but it never will be used
    return <PexCommonField {...this.props} hideIcon={true} ><DateCheckbox {...this.props}/></PexCommonField>;
  }

}
function DateCheckbox (props) {
  let maxDate = props.maxDate;
  if (props.attribute.maxDateFromComponent) {
    maxDate = moment().add(props.attribute.maxDateFromComponent, props.attribute.maxDateTypeFromComponent);
  }
  function hanldeOnChange (event, key) {
    props.onChange(event, key);
  }
  function handleChangeDate (date) {
    props.onChange({target: {value: date.unix()}}, "date");
  }
  return <div className="date-with-checkbox-container">
    <div className="date-container" hidden={props.attribute.fieldProps.checkBoxValue === "true"}>
      <DatePicker dateFormat="DD/MM/YY"
        className={props.className + "-date-picker"}
        selected={props.attribute.fieldProps.dateValue ? moment.unix(props.attribute.fieldProps.dateValue) : null}
        onChange={(aa) => {handleChangeDate(aa);}}
        minDate={moment.unix(props.getCurrentMoment)}
        maxDate={maxDate}
        monthsShown={2}
        inline={true}
        yearDropdownItemNumber={props.attribute.maxDateFromComponent ? Number(props.attribute.maxDateFromComponent) : null}
        showYearDropdown={true}/>
    </div>
    <div className="checkbox-container">
      <Checkbox className="not-sure-checkbox"
        value={props.attribute.fieldProps.checkBoxValue}
        checked={props.attribute.fieldProps.checkBoxValue === "true"}
        onClick={ (ev) =>{ hanldeOnChange(ev, "notSureCheckbox");}}>{props.attribute.text ? props.attribute.text : null}</Checkbox>
    </div>

  </div>;
}
