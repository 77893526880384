import React from 'react';
import Pagination from 'react-bootstrap/lib/Pagination';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import Table from 'react-bootstrap/lib/Table';

export function PexPagination (props) {
  function getInitialState () {
    return {
      activePage: 1,
    };
  }

  function getPages () {
    if (Number((props.dataLength / props.pagination) % 1) >= Number("0.5") || Number((props.dataLength / props.pagination) % 1) === 0) {
      return Number((props.dataLength / props.pagination).toFixed(0));
    }
    return Number(((props.dataLength / props.pagination) + 1).toFixed(0));
  }

  function selectPaginationPage (eventKey) {
    props.paginationHandler({
      activePage: eventKey,
    });
  }

  if (props.pagination < 1) return null;
  let maxEntry = Number(props.activePage) * Number(props.pagination);
  if (Number(props.activePage) * Number(props.pagination) >= props.dataLength) maxEntry = props.dataLength;
  return <div className="translate-pagination">
    <Table>
      <tbody>
        <tr>
          <td>
            <p>{"Showing " + ((Number(props.activePage) * Number(props.pagination)) - Number(props.pagination)) + " to " + maxEntry + " of " + props.dataLength + " entries"}</p>
          </td>
          <td>
            <Pagination
              prev
              next
              first
              last
              ellipsis
              boundaryLinks
              items={getPages ()}
              maxButtons={5}
              activePage={props.activePage}
              onSelect={selectPaginationPage.bind(this)} />
          </td>
        </tr>
      </tbody>
    </Table>
  </div>;
}
