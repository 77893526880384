import React from 'react';
import Row from 'react-bootstrap/lib/Row';

export default class Loading extends React.Component {
  render () {
    if (this.props.waiting) {
      return (
        <div className="container-fluid">
          <div className="loader-container">
            <Row className="loading-text">
              <div className="text-center">
                        Loading..
              </div>
            </Row>
            <Row className="loading-spinner">
              <div className="loader">
                <div className="r1">
                  <div></div>
                </div>
                <div className="r2">
                  <div></div>
                </div>
              </div>
            </Row>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}
