import React from 'react';
import FormControl from 'react-bootstrap/lib/FormControl';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import InputGroup from 'react-bootstrap/lib/InputGroup';
import Button from 'react-bootstrap/lib/Button';
import Col from 'react-bootstrap/lib/Col';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import Panel from 'react-bootstrap/lib/Panel';
import Row from 'react-bootstrap/lib/Row';
import classNames from 'classnames';

export default class PexTraductionEditor extends React.Component {
  constructor (props) {
    super(props);
    //save the first value, so we will know if it is modified to update it
    this.state = {
      "value": this.props.value,
      "firstValue": this.props.value,
    };
  }

  onChangeHandler (event) {
    //it is expensive to execute the render method each time that user presses a key so:
    //we use its own state, so here we don't execute the top render method,
    this.setState({value: event.target.value, modifiedFromTextArea: true});
  }

  updateValue () {
    //update the value using the top render method when the user leaves the box
    this.props.updateLabelValue(this.props.lang, this.props.name, this.state.value);
    this.setState({modifiedFromTextArea: false});
  }

  componentDidUpdate () {
    //When the value has been modified from the text box
    if (this.props.value !== this.state.value && !this.state.modifiedFromTextArea) this.setState({"value": this.props.value});
  }


  render () {
    let value = this.state.value;
    let self = this;
    return <FormGroup>
      <FormControl value={value} componentClass="textarea" onBlur={this.updateValue.bind(this)} onChange={this.onChangeHandler.bind(this)}></FormControl>
    </FormGroup>;
  }

}
