import React from 'react';
import utils from "../../../lib/utils";
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import Popover from 'react-bootstrap/lib/Popover';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import Button from 'react-bootstrap/lib/Button';

export default class Pay extends React.Component {

  componentDidMount () {

    this.props.loadPay();
  }

  render () {

    if (this.props.state && this.props.state.stepLoaded && !this.props.state.error) {

      return <div className={"pay"}>
        <h3>PAY STEP. IMPLEMENT ME!!!</h3>
      </div>;
    }
    else return <div>Loading step...</div>;
  }
}
