import React from 'react';
import FormControl from 'react-bootstrap/lib/FormControl';
import { PexCommonField } from "./pex-common-field.jsx";
import DatePicker from 'react-datepicker';
import moment from 'moment';

export class PexDatePicker extends React.Component {
  render () {
    return <PexCommonField {...this.props}><DateField maxDate={this.props.maxDate ? this.props.maxDate : null} /></PexCommonField>;
  }
}

class DateField extends React.Component {
  handleChange (date) {
    this.props.onChange({target: {value: date ? date : null}});
  }

  render () {
    return <DatePicker dateFormat="DD/MM/YY" className="form-control" id={this.props.id} name={this.props.name} selected={this.props.value ? moment(this.props.value) : null} maxDate={this.props.maxDate} placeholderText={this.props.placeholder} onChange={::this.handleChange} showYearDropdown dropdownMode="select" yearDropdownItemNumber={15} />;
  }
}
