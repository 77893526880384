import React from 'react';
import ajax from '../../lib/ajax.js';
let api = require('../../lib/api.js')(ajax);
import classNames from 'classnames';
import { createStore } from 'redux';
import {ShowError} from "./components/show-error.jsx";
import {WrapModal} from "./form-components/wrap-modal.jsx";
import {LoginForm} from "./components/login-form.jsx";
let getCurrentMoment = require('../../lib/get-current-moment.js');
import Button from 'react-bootstrap/lib/Button';
import utils from "../../lib/utils";
import moment from "moment/moment";
import formService from "./services/form-service";
import graingerService from "./services/grainger-service";
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import Alert from 'react-bootstrap/lib/Alert';
import Panel from 'react-bootstrap/lib/Panel';
import GetStartedA from "./components/getStartedA.jsx";
import GetStartedB from "./components/getStartedB.jsx";
import Addons from "./components/addons.jsx";
import TenantAndGuarantorDetails from "./components/tenantAndGuarantorDetails.jsx";
import PayAdvanced from "./components/payAdvanced.jsx";
import PaymentResponse from "./components/payment-response.jsx";
import ReferencingPre from "./components/referencingPre.jsx";
import Referencing from "./components/referencing.jsx";
import ReferencingHold from "./components/referencingHold.jsx";
import Sign from "./components/sign.jsx";
import Pay from "./components/pay.jsx";


const defaultLabels = JSON.parse(JSON.stringify(require("../../../lang/clients/grainger-booking-journey-applet/en-GB.json"))).labels["en-GB"];

function initState () {
  return {
    "nextAction": "getLabels",
    "waiting": true,
    "loaded": false,
    "error": null,
    "showModal": false,
    "stepLoaded": false,
    "getStartedBForm": {
      "fullTimeJob": false,
      "noFullTime": false,
      "credit": false,
      "citizen": false,
      "canCompleteStep": false,
    },
  };
}

export default class GraingerBookingJourneyApplet extends React.Component {

  constructor (props) {
    super(props);
    let self = this;
    this.store = createStore(function (state = {}, action) {
      // console.log(action.type);
      let newState = {};
      switch (action.type) {
        case "LOAD": {
          return Object.assign(state, initState(), { "lang": action.lang});
        }
        case "ERROR": {
          return Object.assign(state, {"nextAction": "DONE", "loaded": true, "error": action.error, "waiting": false, "stepLoaded": false});
        }
        case "GET_LABELS_OK": {
          let labels = defaultLabels;
          labels = graingerService.setLongLabels(labels);
          if (action.response.labels[state.lang])
            for (let key of Object.keys(action.response.labels[state.lang])) labels[key] = action.response.labels[state.lang][key];
          return Object.assign({}, state, {"labels": labels, "nextAction": "getSSOKeyAndValidateObject", "canLoadStep": false});
        }
        case "VALIDATE_OBJECT_OK": {
          return Object.assign(state, {"nextAction": "getSSOKeyAndTenancy"});
        }
        case "LOGIN_USER_OK": {
          return Object.assign(state, {"nextAction": "getSSOKeyAndTenancy"});
        }
        case "GET_TENANCY_RESPONSE_OK": {
          newState.stay = action.response.stay;
          newState.tenancyMonths = moment(action.response.stay.out).diff(action.response.stay.in, "months");
          newState.tenancyMonthsLabel = utils.format_path(state.labels["graingerBookingJourney.bookingSummaryDetails.tenancyMonthsSingular"], {"MONTHS": newState.tenancyMonths});
          if (newState.tenancyMonths > 1) newState.tenancyMonthsLabel = utils.format_path(state.labels["graingerBookingJourney.bookingSummaryDetails.tenancyMonthsPlural"], {"MONTHS": newState.tenancyMonths});
          newState.nextAction = "getSSOKeyAndWorkflow";
          return Object.assign(state, newState);
        }
        case "GET_WORKFLOW_RESPONSE_OK": {
          newState.workflowResponse = action.response;
          newState.nextAction = "getUser";
          newState.steps = action.response.skeleton.steps;
          newState.currentStep = action.currentStep;
          return Object.assign(state, newState);
        }
        case "CLOSE_MODAL": {
          newState.showModal = false;
          return Object.assign(state, newState);
        }
        case "SHOW_LOGIN_FORM": {
          newState.loginForm = Object.assign({}, formService.initStep0cForm(state.labels));
          newState.showModal = true;
          newState.waiting = false;
          newState.nextAction = "DONE";
          newState.stepLoaded = false;
          return Object.assign(state, newState);
        }
        case "LOGIN_FORM_SUBMITTED_WITH_ERRORS": {
          newState = { "loginForm": state.loginForm };
          newState.loginForm.submitted = true;
          return Object.assign(state, newState);
        }
        case "UPDATE_FIELD": {
          newState = { "loginForm": state.loginForm};
          newState.loginForm.values[action.field] = action.value;
          newState.loginForm = Object.assign(newState.loginForm, formService.checkForm(newState.loginForm.values, newState.loginForm.formNames, state.labels, "graingerBookingJourney.login"));
          return Object.assign(state, newState);
        }
        case "START_LOGIN": {
          newState.waiting = true;
          newState.nextAction = "loginUser";
          return Object.assign(state, newState);
        }
        case "LOGIN_ERROR": {
          newState.loginForm = state.loginForm;
          newState.loginForm.APIerror = action.error;
          newState.waiting = false;
          newState.nextAction = "DONE";
          newState.stepLoaded = false;
          return Object.assign(state, newState);
        }
        case "COMPLETE_STEP": {
          newState.waiting = true;
          newState.stepLoaded = false;
          newState.nextAction = "getSSOKeyAndCompleteStep";
          return Object.assign(state, newState);
        }
        case "COMPLETE_STEP_OK": {
          newState.nextAction = "getLabels";
          newState.stepLoaded = false;
          return Object.assign(state, newState);
        }
        case "GET_USER_OK": {
          newState.nextAction = "DONE";
          newState.loaded = true;
          newState.canLoadStep = true;
          newState.showModal = false;
          newState.waiting = true;
          newState.user = action.response.user;

          //If we have component success or failure we redirect here to there
          let path = location.hash.split("/");
          let component = null;
          let params = [];

          if (path.length > 1) for (let i = 1; i < path.length;i++) if (path[i] !== "" && path[i].indexOf("=") !== -1) params.push(path[i]);
          for (let param of params) {
            if (param.split("=")[0] === "component") component = param.split("=")[1];
          }
          if (component) {
            newState.waiting = false;
            newState.component = component;
          } else if (location.hash.indexOf("completeStepReloading") !== -1) {
            //ONLY FOR RELOAD AFTER SIGN IN TE SIGN STEP
            newState.nextAction = "getSSOKeyAndCompleteSignStep";
          }
          return Object.assign(state, newState);
        }
        //STEP 1A
        case "LOAD_STEP1A": {
          newState.waiting = false;
          newState.nextAction = "DONE";
          newState.stepLoaded = true;
          return Object.assign(state, newState);
        }
        //STEP 1B
        case "LOAD_STEP1B": {
          newState.waiting = true;
          newState.nextAction = "getFormCollections";
          return Object.assign(state, newState);
        }
        case "GET_FORM_COLLECTIONS_OK": {
          newState.waiting = false;
          newState.stepLoaded = true;
          newState.getFormCollections = action.response;
          newState.nextAction = "DONE";
          return Object.assign(state, newState);
        }
        case "UPDATE_STEP1B_FORM": {
          if (action.field) {
            newState = { "getStartedBForm": state.getStartedBForm};
            if (action.field.startsWith("full-time")) {
              newState.getStartedBForm.fullTimeJob = action.field;
              if (action.field === "full-time-yes") newState.getStartedBForm.noFullTime = false;
            }
            else if (action.field.startsWith("no-full-time")) newState.getStartedBForm.noFullTime = action.field;
            else if (action.field.startsWith("credit")) newState.getStartedBForm.credit = action.field;
            else if (action.field.startsWith("citizen")) newState.getStartedBForm.citizen = !newState.getStartedBForm.citizen;

            if (newState.getStartedBForm.fullTimeJob === "full-time-yes" && newState.getStartedBForm.credit) {
              newState.getStartedBForm.canCompleteStep = true;
            } else if (newState.getStartedBForm.fullTimeJob === "full-time-no" && newState.getStartedBForm.noFullTime && newState.getStartedBForm.credit) {
              newState.getStartedBForm.canCompleteStep = true;
            }

            if (!newState.getStartedBForm.citizen || (newState.getStartedBForm.fullTimeJob === "full-time-no" && !newState.getStartedBForm.noFullTime)) newState.getStartedBForm.canCompleteStep = false;

            return Object.assign(state, newState);
          }
        }
        case "UPDATE_PERSON_DETAILS_STEP1B": {
          newState.waiting = true;
          newState.stepLoaded = false;
          newState.nextAction = "getSSOKeyAndUpdatePersonDetails";
          return Object.assign(state, newState);
        }
        case "UPDATE_PERSON_DETAILS_STEP1B_OK": {
          newState.nextAction = "getSSOKeyAndCompleteStep";
          return Object.assign(state, newState);
        }
        //STEP 2
        case "LOAD_STEP2": {
          newState.waiting = true;
          newState.nextAction = "getSSOKeyAndAdditionalRentalUnits";
          newState.stepLoaded = false;
          newState.addons = {};
          return Object.assign(state, newState);
        }
        case "GET_ADDITIONAL_RENTAL_UNITS_OK": {
          newState = { "addons": state.addons};
          newState.waiting = false;
          newState.nextAction = "DONE";
          newState.stepLoaded = true;
          newState.addons.additionalRentalUnits = action.response;
          //Perhaps we have to get the specific parking offer, I don't see the requirement, but if we have to, search here the offer in additionalRentalUnits, assign to offerParking and it will be fixed.
          if (newState.addons.additionalRentalUnits.offers && newState.addons.additionalRentalUnits.offers.length > 0) newState.addons.offerParking = newState.addons.additionalRentalUnits.offers[0];
          return Object.assign(state, newState);
        }
        case "CLICK_PARKING": {
          newState = { "addons": state.addons};
          if (newState.addons.withParking) {
            let el = document.querySelector( ':focus' );
            if ( el ) el.blur();
            newState.addons.withParking = false;
          } else {
            newState.addons.withParking = true;
          }
          return Object.assign(state, newState);
        }
        case "BOOK_ADDITIONAL_RENTAL_UNITS_OK": {
          newState.nextAction = "getSSOKeyAndCompleteStep";
          return Object.assign(state, newState);
        }
        case "COMPLETE_STEP2_WITH_PARKING": {
          newState.waiting = true;
          newState.stepLoaded = false;
          newState.nextAction = "getSSOKeyAndBookAdditionalRentalUnit";
          return Object.assign(state, newState);
        }
        case "COMPLETE_STEP2": {
          newState.waiting = true;
          newState.stepLoaded = false;
          if (state.addons.withParking) newState.nextAction = "createGeneralEnquiry";
          else newState.nextAction = "getSSOKeyAndCompleteStep";
          return Object.assign(state, newState);
        }
        case "CREATE_GENERAL_ENQUIRY_OK": {
          newState.waiting = true;
          newState.stepLoaded = false;
          newState.nextAction = "getSSOKeyAndCompleteStep";
          return Object.assign(state, newState);
        }
        //TENANT_AND_GUARANTOR_DETAILS
        case "LOAD_TENANT_AND_GUARANTOR_DETAILS": {
          newState.waiting = false;
          newState.nextAction = "DONE";
          newState.stepLoaded = true;
          newState.tenantAndGuarantorDetails = {
            "tenantsNumber": 1,
            "maxOcuppancy": parseInt(2 * state.stay.room.numberOfBedrooms.features),
            "showGuarantorForm": false,
            "tenants": {
              "1": formService.initTenantAndGuarantorDetailsTenantForm(state.labels),
            },
            "guarantor": formService.initTenantAndGuarantorDetailsGuarantorForm(state.labels),
            "guarantorConfirm": false,
            "generalConfirm": false,
          };
          newState.tenantAndGuarantorDetails.tenants["1"].values = graingerService.formatUserValues(state.user);
          newState.tenantAndGuarantorDetails.tenants["1"] = Object.assign(newState.tenantAndGuarantorDetails.tenants["1"], formService.checkForm(newState.tenantAndGuarantorDetails.tenants["1"].values, newState.tenantAndGuarantorDetails.tenants["1"].formNames, state.labels, "graingerBookingJourney.tenantAndGuarantorDetails"));
          newState.tenantAndGuarantorDetails.tenants["1"].disabled = true;
          newState.tenantAndGuarantorDetails.guarantor.sentToApi = false;
          return Object.assign(state, newState);
        }
        case "UPDATE_TENANTS_NUMBER": {
          newState = { "tenantAndGuarantorDetails": JSON.parse(JSON.stringify(state.tenantAndGuarantorDetails))};
          newState.tenantAndGuarantorDetails.tenantsNumber = action.tenantsNumber;
          newState.tenantAndGuarantorDetails.tenants = {};
          for (let i = 1;i <= action.tenantsNumber;i++) {
            if (state.tenantAndGuarantorDetails.tenants.hasOwnProperty(i.toString())) newState.tenantAndGuarantorDetails.tenants[i.toString()] = state.tenantAndGuarantorDetails.tenants[i.toString()];
            else {
              newState.tenantAndGuarantorDetails.tenants[i.toString()] = formService.initTenantAndGuarantorDetailsTenantForm(state.labels);
              newState.tenantAndGuarantorDetails.tenants[i.toString()].sentToApi = false;
            }
          }
          newState.tenantAndGuarantorDetails.canComplete = graingerService.validateTenantAndGuarantorDetailsForms(newState.tenantAndGuarantorDetails);
          return Object.assign(state, newState);
        }
        case "UPDATE_STEP_TENANT_FORM": {
          newState = { "tenantAndGuarantorDetails": JSON.parse(JSON.stringify(state.tenantAndGuarantorDetails))};
          newState.tenantAndGuarantorDetails.tenants[action.index].values[action.field] = action.value;
          newState.tenantAndGuarantorDetails.tenants[action.index] = Object.assign(newState.tenantAndGuarantorDetails.tenants[action.index], formService.checkForm(newState.tenantAndGuarantorDetails.tenants[action.index].values, newState.tenantAndGuarantorDetails.tenants[action.index].formNames, state.labels, "graingerBookingJourney.tenantAndGuarantorDetails"));
          newState.tenantAndGuarantorDetails.canComplete = graingerService.validateTenantAndGuarantorDetailsForms(newState.tenantAndGuarantorDetails);
          return Object.assign(state, newState);
        }
        case "UPDATE_STEP_GUARANTOR_FORM": {
          newState = { "tenantAndGuarantorDetails": JSON.parse(JSON.stringify(state.tenantAndGuarantorDetails))};
          newState.tenantAndGuarantorDetails.guarantor.values[action.field] = action.value;
          newState.tenantAndGuarantorDetails.guarantor = Object.assign(newState.tenantAndGuarantorDetails.guarantor, formService.checkForm(newState.tenantAndGuarantorDetails.guarantor.values, newState.tenantAndGuarantorDetails.guarantor.formNames, state.labels, "graingerBookingJourney.tenantAndGuarantorDetails"));
          newState.tenantAndGuarantorDetails.canComplete = graingerService.validateTenantAndGuarantorDetailsForms(newState.tenantAndGuarantorDetails);
          return Object.assign(state, newState);
        }
        case "UPDATE_STEP_ADDITIONAL_OCCUPANT_FORM": {
          newState = { "tenantAndGuarantorDetails": JSON.parse(JSON.stringify(state.tenantAndGuarantorDetails))};
          newState.tenantAndGuarantorDetails.additionalOccupants[action.index].values[action.field] = action.value;
          newState.tenantAndGuarantorDetails.additionalOccupants[action.index] = Object.assign(newState.tenantAndGuarantorDetails.additionalOccupants[action.index], formService.checkForm(newState.tenantAndGuarantorDetails.additionalOccupants[action.index].values, newState.tenantAndGuarantorDetails.additionalOccupants[action.index].formNames, state.labels, "graingerBookingJourney.tenantAndGuarantorDetails"));
          newState.tenantAndGuarantorDetails.canComplete = graingerService.validateTenantAndGuarantorDetailsForms(newState.tenantAndGuarantorDetails);
          return Object.assign(state, newState);
        }
        case "CLICK_GUARANTOR": {
          newState = { "tenantAndGuarantorDetails": JSON.parse(JSON.stringify(state.tenantAndGuarantorDetails))};
          newState.tenantAndGuarantorDetails.showGuarantorForm = !state.tenantAndGuarantorDetails.showGuarantorForm;
          newState.tenantAndGuarantorDetails.canComplete = graingerService.validateTenantAndGuarantorDetailsForms(newState.tenantAndGuarantorDetails);
          return Object.assign(state, newState);
        }
        case "UPDATE_ADDITIONAL_OCCUPANTS_NUMBER": {
          newState = { "tenantAndGuarantorDetails": JSON.parse(JSON.stringify(state.tenantAndGuarantorDetails))};
          newState.tenantAndGuarantorDetails.additionalOccupantsNumber = action.additionalOccupantsNumber;
          newState.tenantAndGuarantorDetails.additionalOccupants = {};
          for (let i = 1;i <= action.additionalOccupantsNumber;i++) {
            if (state.tenantAndGuarantorDetails.additionalOccupants && state.tenantAndGuarantorDetails.additionalOccupants[i.toString()]) newState.tenantAndGuarantorDetails.additionalOccupants[i.toString()] = state.tenantAndGuarantorDetails.additionalOccupants[i.toString()];
            else {
              newState.tenantAndGuarantorDetails.additionalOccupants[i.toString()] = formService.initTenantAndGuarantorDetailsAdditionalOccupantForm(state.labels);
              newState.tenantAndGuarantorDetails.additionalOccupants[i.toString()].sentToApi = false;
            }
          }
          newState.tenantAndGuarantorDetails.canComplete = graingerService.validateTenantAndGuarantorDetailsForms(newState.tenantAndGuarantorDetails);
          return Object.assign(state, newState);
        }
        case "UPDATE_TENANT_AND_GUARANTOR_DETAILS_GENERAL_CHECKBOX": {
          newState = { "tenantAndGuarantorDetails": JSON.parse(JSON.stringify(state.tenantAndGuarantorDetails))};
          newState.tenantAndGuarantorDetails.generalConfirm = !newState.tenantAndGuarantorDetails.generalConfirm;
          newState.tenantAndGuarantorDetails.canComplete = graingerService.validateTenantAndGuarantorDetailsForms(newState.tenantAndGuarantorDetails);
          return Object.assign(state, newState);
        }
        case "UPDATE_STEP_GUARANTOR_CONFIRM": {
          newState = { "tenantAndGuarantorDetails": JSON.parse(JSON.stringify(state.tenantAndGuarantorDetails))};
          newState.tenantAndGuarantorDetails.guarantorConfirm = !newState.tenantAndGuarantorDetails.guarantorConfirm;
          newState.tenantAndGuarantorDetails.canComplete = graingerService.validateTenantAndGuarantorDetailsForms(newState.tenantAndGuarantorDetails);
          return Object.assign(state, newState);
        }
        case "COMPLETE_TENANT_AND_GUARANTOR_DETAILS": {
          newState.waiting = true;
          newState.stepLoaded = false;
          newState.nextAction = "getSSOKeyAndAddPersonToTenancy";
          if (state.tenantAndGuarantorDetails.showGuarantorForm) newState.nextAction = "getSSOKeyAndUpdatePersonDetailsTenantAndGuarantorDetails";
          return Object.assign(state, newState);
        }
        case "CHECK_ADD_MORE_TENANTS": {
          newState = { "tenantAndGuarantorDetails": JSON.parse(JSON.stringify(state.tenantAndGuarantorDetails))};
          let updateTenantAndGuarantorDetails = graingerService.updateTenantAdded(newState.tenantAndGuarantorDetails, action.tenantAdded);
          newState.tenantAndGuarantorDetails = updateTenantAndGuarantorDetails.tenantAndGuarantorDetails;
          newState.waiting = true;
          newState.stepLoaded = false;
          newState.nextAction = "getSSOKeyAndAddPersonToTenancy";
          if (updateTenantAndGuarantorDetails.stepCompleted)newState.nextAction = "getSSOKeyAndCompleteStep";
          return Object.assign(state, newState);
        }
        case "UPDATE_PERSON_DETAILS_TENANT_AND_GUARANTOR_DETAILS_OK": {
          newState.waiting = true;
          newState.stepLoaded = false;
          newState.nextAction = "getSSOKeyAndCompleteStep";
          if (Object.keys(state.tenantAndGuarantorDetails.tenants).length > 1 || state.tenantAndGuarantorDetails.additionalOccupants) {
            newState.nextAction = "getSSOKeyAndAddPersonToTenancy";
          }
          return Object.assign(state, newState);
        }
        case "START_CANCEL_ON_HOLD_BOOKING": {
          newState.waiting = true;
          newState.stepLoaded = false;
          newState.nextAction = "getSSOKeyAndCancelOnHoldBooking";
          return Object.assign(state, newState);
        }
        case "CANCEL_ON_HOLD_BOOKING_OK": {
          newState.nextAction = "goToStep0url";
          return Object.assign(state, newState);
        }
        // PAY_ADVANCED
        case "LOAD_PAY_ADVANCED": {
          newState.waiting = true;
          newState.nextAction = "getSSOKeyAndSetPaymentMethod";
          newState.stepLoaded = false;
          newState.payAdvanced = {
            "conditionsConfirm": false,
            "showIframe": false,
          };
          return Object.assign(state, newState);
        }
        case "GET_PAYMENTS_OK": {
          newState = { "payAdvanced": state.payAdvanced};
          newState.waiting = false;
          newState.nextAction = "DONE";
          newState.stepLoaded = true;
          newState.payAdvanced.getPaymentsResponse = action.response;
          return Object.assign(state, newState);
        }
        case "UPDATE_PAY_ADVANCED_CONDITIONS_CONFIRM": {
          newState = { "payAdvanced": JSON.parse(JSON.stringify(state.payAdvanced))};
          newState.payAdvanced.conditionsConfirm = !newState.payAdvanced.conditionsConfirm;
          newState.payAdvanced.canComplete = newState.payAdvanced.conditionsConfirm;
          return Object.assign(state, newState);
        }
        case "SET_PAYMENT_METHOD_OK": {
          newState.nextAction = "completeStepLoadingPayAdvance";
          return Object.assign(state, newState);
        }
        case "PAYMENT_PENDING": {
          newState.nextAction = "getSSOKeyAndGetPaymentUrl";
          return Object.assign(state, newState);
        }
        case "GET_PAYMENT_URL_OK": {
          newState = { "payAdvanced": JSON.parse(JSON.stringify(state.payAdvanced))};
          newState.nextAction = "getSSOKeyAndGetPayments";
          newState.payAdvanced.paymentUrl = action.response.paymentUrl;
          if (newState.payAdvanced.paymentUrl.indexOf("fake") !== -1) newState.payAdvanced.paymentUrl += "?booking=" + state.stay.lookupValue;
          newState.payAdvanced.amount = action.response.amount;
          return Object.assign(state, newState);
        }
        //TODO: change this by payment ok or remove this dispatcher
        case "LOAD_PAY_ADVANCED_B": {
          newState.waiting = false;
          newState.nextAction = "DONE";
          newState.stepLoaded = true;
          return Object.assign(state, newState);
        }
        case "SHOW_IFRAME_PAY_ADVANCED": {
          newState = { "payAdvanced": JSON.parse(JSON.stringify(state.payAdvanced))};
          newState.payAdvanced.showIframe = !newState.payAdvanced.showIframe;
          return Object.assign(state, newState);
        }
        // REFERENCING
        case "LOAD_REFERENCING_PRE": {
          newState.waiting = false;
          newState.nextAction = "DONE";
          newState.stepLoaded = true;
          return Object.assign(state, newState);
        }
        case "LOAD_REFERENCING": {
          newState.waiting = false;
          newState.nextAction = "DONE";
          newState.stepLoaded = true;
          return Object.assign(state, newState);
        }
        case "COMPLETE_REFERENCING": {
          newState.waiting = true;
          newState.stepLoaded = false;
          newState.nextAction = "getSSOKeyAndCompleteStep";
          return Object.assign(state, newState);
        }
        //REFERENCING_HOLD
        case "LOAD_REFERENCING_HOLD": {
          newState.waiting = false;
          //TODO: call getSSOKeyAnd..... I don't know what method will be. See referencing-hold.feature. At the moment it is DONE to show the component with hardcoded values
          newState.nextAction = "DONE";
          newState.stepLoaded = true;
          return Object.assign(state, newState);
        }
        case "COMPLETE_REFERENCING_HOLD": {
          newState.waiting = true;
          newState.stepLoaded = false;
          newState.nextAction = "getSSOKeyAndCompleteStep";
          return Object.assign(state, newState);
        }
        //SIGN
        case "LOAD_SIGN": {
          newState.waiting = true;
          //TODO:
          newState.nextAction = "getSSOKeyAndGetESignatureWidgetUrl";
          newState.stepLoaded = false;
          return Object.assign(state, newState);
        }
        case "COMPLETE_SIGN": {
          newState.waiting = true;
          newState.stepLoaded = false;
          newState.nextAction = "getSSOKeyAndCompleteStep";
          return Object.assign(state, newState);
        }
        case "GET_TENANCY_AGREEMENT_OK": {
          newState.waiting = false;
          newState.stepLoaded = true;
          newState.nextAction = "DONE";
          newState.sign = {};
          newState.sign.response = action.response;
          return Object.assign(state, newState);
        }
        //PAY
        case "LOAD_PAY": {
          newState.waiting = false;
          newState.nextAction = "DONE";
          newState.stepLoaded = true;
          return Object.assign(state, newState);
        }
        default:
          return state;
      }
    }, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
    this.state = this.store.getState();
  }

  componentDidUpdate () {
    // console.log(this.state.nextAction);
    switch (this.state.nextAction) {
      case "getLabels":
      case "getSSOKeyAndValidateObject":
      case "getSSOKeyAndTenancy":
      case "getSSOKeyAndWorkflow":
      case "loginUser":
      case "getUser":
      case "getFormCollections":
      case "getSSOKeyAndCompleteStep":
      case "getSSOKeyAndUpdatePersonDetails":
      case "getSSOKeyAndAdditionalRentalUnits":
      case "getSSOKeyAndBookAdditionalRentalUnit":
      case "createGeneralEnquiry":
      case "getSSOKeyAndAddPersonToTenancy":
      case "getSSOKeyAndCancelOnHoldBooking":
      case "goToStep0url":
      case "getSSOKeyAndGetPayments":
      case "getSSOKeyAndUpdatePersonDetailsTenantAndGuarantorDetails":
      case "getSSOKeyAndSetPaymentMethod":
      case "completeStepLoadingPayAdvance":
      case "getSSOKeyAndGetPaymentUrl":
      case "getSSOKeyAndGetESignatureWidgetUrl":
      case "getSSOKeyAndCompleteSignStep":
        this[this.state.nextAction]();
    }
  }

  componentDidMount () {
    if (this.store) {
      this.unsubscribe = this.store.subscribe(function () {
        let state = {};
        for (let key of Object.keys(this.state)) state[key] = null;
        this.setState(Object.assign({}, state, this.store.getState()));
      }.bind(this));

      if (!this.props.api) return this.store.dispatch({type: "ERROR", "error": "Configuration error - No api specified"});
      if (!this.props.sso) return this.store.dispatch({type: "ERROR", "error": "Configuration error - No sso specified"});
      if (!this.props.step0url) return this.store.dispatch({type: "ERROR", "error": "Configuration error - No step0url specified"});

      let lang = "en-GB";
      if (this.props.lang) lang = this.props.lang;

      window.onpopstate = function () { this.store.dispatch({type: "LOAD", "lang": lang}); }.bind(this);
      this.store.dispatch({type: "LOAD", "lang": lang});
    }
    return true;
  }

  componentWillUnmount () {
    this.unsubscribe();
  }

  render () {
    if (this.state && this.state.showModal && !this.state.error) {
      let buttonsFooter = [];
      buttonsFooter.push(<Button key="0" bsStyle="link" className={"login-back"} onClick={()=>{this.closeModal();}}>{this.state.labels["graingerBookingJourney.login.backButtonLabel"]}</Button>);
      buttonsFooter.push(<Button key="1" bsStyle="default" onClick={()=>{this.submitLoginForm();}} className={"login-start"}>{this.state.labels["graingerBookingJourney.login.startButtonLabel"]}</Button>);
      let divFrozen = "";
      if (this.state.waiting) divFrozen = " divFrozen";

      return <div className={"widget-content" + divFrozen}>
        <WrapModal showModal={this.state.showModal} modalContainer={this.modalContainer} closeModal={this.closeModal} buttonsFooter={buttonsFooter}>
          <LoginForm closeModal={this.closeModal.bind(this)}
            submitLoginForm={this.submitLoginForm.bind(this)}
            updateFieldFromEvent = {this.updateFieldFromEvent.bind(this)}
            state={this.state}
          />
        </WrapModal>
      </div>;
    }
    else if (this.state && this.state.error) return <ShowError error={this.state.error} />;
    else if (this.state && !this.state.error && this.state.loaded) {
      return <GraingerBookingJourney state={this.state}
        loadGetStartedA={this.loadGetStartedA.bind(this)}
        loadGetStartedB={this.loadGetStartedB.bind(this)}
        loadAddons={this.loadAddons.bind(this)}
        loadTenantAndGuarantorDetails={this.loadTenantAndGuarantorDetails.bind(this)}
        loadPayAdvanced={this.loadPayAdvanced.bind(this)}
        loadPayAdvancedB={this.loadPayAdvancedB.bind(this)}
        loadReferencingPre={this.loadReferencingPre.bind(this)}
        loadReferencing={this.loadReferencing.bind(this)}
        loadReferencingHold={this.loadReferencingHold.bind(this)}
        loadSign={this.loadSign.bind(this)}
        loadPay={this.loadPay.bind(this)}
        completeStep={this.completeStep.bind(this)}
        completeAddons={this.completeAddons.bind(this)}
        updateGetStartedBForm={this.updateGetStartedBForm.bind(this)}
        updatePersonDetailsGetStartedB={this.updatePersonDetailsGetStartedB.bind(this)}
        clickParking={this.clickParking.bind(this)}
        completeAddonsWithParking={this.completeAddonsWithParking.bind(this)}
        changeTenantsNumber={this.changeTenantsNumber.bind(this)}
        clickGuarantor={this.clickGuarantor.bind(this)}
        updateTenantAndGuarantorDetailsTenantForm={this.updateTenantAndGuarantorDetailsTenantForm.bind(this)}
        updateTenantAndGuarantorDetailsGuarantorForm={this.updateTenantAndGuarantorDetailsGuarantorForm.bind(this)}
        changeAdditionalOccupantsNumber={this.changeAdditionalOccupantsNumber.bind(this)}
        updateTenantAndGuarantorDetailsAdditionalOccupantForm={this.updateTenantAndGuarantorDetailsAdditionalOccupantForm.bind(this)}
        updateTenantAndGuarantorDetailsGeneralCheckbox={this.updateTenantAndGuarantorDetailsGeneralCheckbox.bind(this)}
        cancelOnHoldBooking={this.cancelOnHoldBooking.bind(this)}
        completeTenantAndGuarantorDetails={this.completeTenantAndGuarantorDetails.bind(this)}
        updateStep4ConditionsConfirm={this.updateStep4ConditionsConfirm.bind(this)}
        showIframePayAdvanced={this.showIframePayAdvanced.bind(this)}
        reload={this.reload.bind(this)}
        completeReferencing={this.completeReferencing.bind(this)}
        completeReferencingHold={this.completeReferencingHold.bind(this)}
        completeSign={this.completeSign.bind(this)}
      />;
    }
    else return <div>Loading...</div>;
  }

  // API CALLS
  getLabels () {
    let self = this;
    api.getLabels(this.props.api, {"languageIds": [this.state.lang], "group": "graingerBookingJourney"}, function (response) {
      self.store.dispatch({type: "GET_LABELS_OK", "response": response});
    }, function (message, messageType) {
      let apiErrorCode = "";
      if (messageType) apiErrorCode = messageType + " - ";
      self.store.dispatch({"type": "ERROR", "error": "GRAINGER-BOOKING-JOURNEY-ERROR-0000001 API error - " + apiErrorCode + " getLabels - " + message});
    });
  }
  getSSOKeyAndValidateObject () {
    let self = this;
    api.getSSOKeyAndValidateObject(this.props.sso, this.props.sso, {}, function (response) {
      if (!response.loggedIn) {
        return self.store.dispatch({"type": "SHOW_LOGIN_FORM", "response": response});
      } else {
        return self.store.dispatch({"type": "VALIDATE_OBJECT_OK", "response": response});
      }
    }, function (message, messageType) {
      let apiErrorCode = "";
      if (messageType) apiErrorCode = messageType + " - ";
      self.store.dispatch({"type": "ERROR", "error": "GRAINGER-BOOKING-JOURNEY-ERROR-0000005 API error - " + apiErrorCode + " validateObject - " + message});
    });
  }
  getSSOKeyAndTenancy () {
    let self = this;
    api.getSSOKeyAndTenancy(this.props.sso, this.props.api, {}, function (getSSOKeyAndTenancyResponse) {
      if (!getSSOKeyAndTenancyResponse.stay) self.store.dispatch({"type": "ERROR", "error": "GRAINGER-BOOKING-JOURNEY-ERROR-0000009 - User logged in has not a tenancy available"});
      else if (getSSOKeyAndTenancyResponse.stay && getSSOKeyAndTenancyResponse.stay.room && !getSSOKeyAndTenancyResponse.stay.room.numberOfBedrooms) self.store.dispatch({"type": "ERROR", "error": "GRAINGER-BOOKING-JOURNEY-ERROR-0000023 - Tenancy without number of bedrooms."});
      else if (getSSOKeyAndTenancyResponse.stay && getSSOKeyAndTenancyResponse.stay.room && getSSOKeyAndTenancyResponse.stay.room.numberOfBedrooms && !getSSOKeyAndTenancyResponse.stay.room.numberOfBedrooms.features) self.store.dispatch({"type": "ERROR", "error": "GRAINGER-BOOKING-JOURNEY-ERROR-0000024 - Tenancy without number of bedrooms with features attribute."});
      else self.store.dispatch({ type: "GET_TENANCY_RESPONSE_OK", "response": getSSOKeyAndTenancyResponse});
    }, function () {
      self.store.dispatch({"type": "ERROR", "error": "GRAINGER-BOOKING-JOURNEY-ERROR Session invalid"});
    }, function (message, messageType) {
      let apiErrorCode = "";
      if (messageType) apiErrorCode = messageType + " - ";
      self.store.dispatch({"type": "ERROR", "error": "GRAINGER-BOOKING-JOURNEY-ERROR-0000008 API error - " + apiErrorCode + " getSSOKeyAndTenancy - " + message});
    });
  }

  getSSOKeyAndWorkflow () {
    let self = this;
    api.getSSOKeyAndWorkflow(this.props.sso, this.props.api, { "tenancy": this.state.stay.lookupValue }, function (getSSOKeyAndWorkflowResponse) {
      if (!getSSOKeyAndWorkflowResponse.workflow) self.store.dispatch({"type": "ERROR", "error": "GRAINGER-BOOKING-JOURNEY-ERROR-0000011 - No workflow available for this tenancy"});
      else if (!getSSOKeyAndWorkflowResponse.skeleton) self.store.dispatch({"type": "ERROR", "error": "GRAINGER-BOOKING-JOURNEY-ERROR-0000014 - Workflow has not skeleton"});
      else if (!getSSOKeyAndWorkflowResponse.skeleton.steps) self.store.dispatch({"type": "ERROR", "error": "GRAINGER-BOOKING-JOURNEY-ERROR-0000015 - Workflow has not steps"});
      else {
        let currentStep = null;
        for (let step of getSSOKeyAndWorkflowResponse.workflow.steps) if (step.current) currentStep = step;
        if (!currentStep) self.store.dispatch({"type": "ERROR", "error": "GRAINGER-BOOKING-JOURNEY-ERROR-0000013 - Workflow has not an active step"});
        else self.store.dispatch({ type: "GET_WORKFLOW_RESPONSE_OK", "response": getSSOKeyAndWorkflowResponse, "currentStep": currentStep});
      }
    }, function () {
      self.store.dispatch({"type": "ERROR", "error": "GRAINGER-BOOKING-JOURNEY-ERROR Session invalid"});
    }, function (message, messageType) {
      let apiErrorCode = "";
      if (messageType) apiErrorCode = messageType + " - ";
      self.store.dispatch({"type": "ERROR", "error": "GRAINGER-BOOKING-JOURNEY-ERROR-0000010 API error - " + apiErrorCode + " getSSOKeyAndWorkflow - " + message});
    });
  }

  loginUser () {
    let self = this;
    api.singleSignOnLoginobject(this.props.sso, {"username": this.state.loginForm.values.username, "password": this.state.loginForm.values.password}, function (loginResponse) {
      if (loginResponse.loggedIn) self.store.dispatch({ type: "LOGIN_USER_OK"});
      else self.store.dispatch({type: "LOGIN_ERROR", "error": loginResponse.message});
    }, function (message, messageType) {
      let apiErrorCode = "";
      if (messageType) apiErrorCode = messageType + " - ";
      self.store.dispatch({"type": "ERROR", "error": "GRAINGER-BOOKING-JOURNEY-ERROR-0000012 API error - " + apiErrorCode + " loginUser - " + message});
    });
  }

  getUser () {
    let self = this;
    api.getSSOKeyAndGetUser(this.props.sso, this.props.api, {}, function (response) {
      self.store.dispatch({ type: "GET_USER_OK", "response": response});
    }, function (response) {
      self.store.dispatch({"type": "ERROR", "error": "GRAINGER-BOOKING-JOURNEY-ERROR Session invalid"});
    }, function (message, messageType) {
      let apiErrorCode = "";
      if (messageType) apiErrorCode = messageType + " - ";
      self.store.dispatch({"type": "ERROR", "error": "GRAINGER-BOOKING-JOURNEY-ERROR-0000016 API error - " + apiErrorCode + " getUser - " + message});
    });
  }

  getFormCollections () {
    let self = this;
    api.getFormCollections(this.props.api, {}, function (response) {
      if (!response.userPreferences || response.userPreferences.length < 1) {
        self.store.dispatch({"type": "ERROR", "error": "GRAINGER-BOOKING-JOURNEY-ERROR - getFormCollections - User preferences not found"});
      } else self.store.dispatch({ type: "GET_FORM_COLLECTIONS_OK", "response": response});
    }, function (message, messageType) {
      let apiErrorCode = "";
      if (messageType) apiErrorCode = messageType + " - ";
      self.store.dispatch({"type": "ERROR", "error": "GRAINGER-BOOKING-JOURNEY-ERROR-0000017 API error - " + apiErrorCode + " getFormCollections - " + message});
    });
  }

  getSSOKeyAndCompleteStep () {
    let self = this;
    api.getSSOKeyAndCompleteStep(this.props.sso, this.props.api, {"step": this.state.currentStep.lookupValue}, function (response) {
      self.store.dispatch({"type": "COMPLETE_STEP_OK", "response": response});
    }, function () {
      self.store.dispatch({"type": "ERROR", "error": "GRAINGER-BOOKING-JOURNEY-ERROR Session invalid"});
    }, function (message, messageType) {
      let apiErrorCode = "";
      if (messageType) apiErrorCode = messageType + " - ";
      self.store.dispatch({"type": "ERROR", "error": "GRAINGER-BOOKING-JOURNEY-ERROR-00000018 API error - " + apiErrorCode + " getSSOKeyAndCompleteStep - " + message});
    });
  }

  getSSOKeyAndCompleteSignStep () {
    let self = this;
    api.getSSOKeyAndCompleteStep(this.props.sso, this.props.api, {"step": this.state.currentStep.lookupValue}, function (response) {
      self.reload();
    }, function () {
      self.store.dispatch({"type": "ERROR", "error": "GRAINGER-BOOKING-JOURNEY-ERROR Session invalid"});
    }, function (message, messageType) {
      let apiErrorCode = "";
      if (messageType) apiErrorCode = messageType + " - ";
      self.store.dispatch({"type": "ERROR", "error": "GRAINGER-BOOKING-JOURNEY-ERROR-00000018 API error - " + apiErrorCode + " getSSOKeyAndCompleteStep - " + message});
    });
  }

  completeStepLoadingPayAdvance () {
    let self = this;
    api.getSSOKeyAndCompleteStep(this.props.sso, this.props.api, {"step": this.state.currentStep.lookupValue}, function (response) {
      self.reload();
    }, function () {
      self.store.dispatch({"type": "ERROR", "error": "GRAINGER-BOOKING-JOURNEY-ERROR Session invalid"});
    }, function (message, messageType) {
      self.store.dispatch({"type": "PAYMENT_PENDING"});
    });
  }
  getSSOKeyAndUpdatePersonDetails () {
    let self = this;
    let payload = {
      "user": {
        "username": this.state.user.username,
        "userPreferences": graingerService.getUpdatePersonDetailsPayload(this.state.getStartedBForm),
      },
    };

    if (!this.state.user.address1) payload.user.address1 = "-";
    if (!this.state.user.address2) payload.user.address2 = "-";
    if (!this.state.user.city) payload.user.city = "-";
    if (!this.state.user.address4) payload.user.address4 = "-";
    if (!this.state.user.postcode) payload.user.postcode = "-";
    if (!this.state.user.country) payload.user.country = "GB";
    api.getSSOKeyAndUpdatePersonDetails(this.props.sso, this.props.api, payload, function (response) {
      self.store.dispatch({"type": "UPDATE_PERSON_DETAILS_STEP1B_OK", "response": response});
    }, function () {
      self.store.dispatch({"type": "ERROR", "error": "GRAINGER-BOOKING-JOURNEY-ERROR Session invalid"});
    }, function (message, messageType) {
      let apiErrorCode = "";
      if (messageType) apiErrorCode = messageType + " - ";
      self.store.dispatch({"type": "ERROR", "error": "GRAINGER-BOOKING-JOURNEY-ERROR-00000019 API error - " + apiErrorCode + " getSSOKeyAndUpdatePersonDetails - " + message});
    });
  }
  getSSOKeyAndAdditionalRentalUnits () {
    let self = this;
    let payload = {"booking": { "lookupValue": this.state.stay.lookupValue} };
    api.getSSOKeyAndAdditionalRentalUnits(this.props.sso, this.props.api, payload, function (response) {
      self.store.dispatch({"type": "GET_ADDITIONAL_RENTAL_UNITS_OK", "response": response});
    }, function () {
      self.store.dispatch({"type": "ERROR", "error": "GRAINGER-BOOKING-JOURNEY-ERROR Session invalid"});
    }, function (message, messageType) {
      let apiErrorCode = "";
      if (messageType) apiErrorCode = messageType + " - ";
      self.store.dispatch({"type": "ERROR", "error": "GRAINGER-BOOKING-JOURNEY-ERROR-00000020 API error - " + apiErrorCode + " getSSOKeyAndAdditionalRentalUnits - " + message});
    });
  }
  getSSOKeyAndBookAdditionalRentalUnit () {
    let self = this;
    let payload = {"booking": { "lookupValue": this.state.stay.lookupValue}, "offer": {"lookupValue": this.state.addons.offerParking.lookupValue}};
    api.getSSOKeyAndBookAdditionalRentalUnit(this.props.sso, this.props.api, payload, function (response) {
      self.store.dispatch({"type": "BOOK_ADDITIONAL_RENTAL_UNITS_OK", "response": response});
    }, function () {
      self.store.dispatch({"type": "ERROR", "error": "GRAINGER-BOOKING-JOURNEY-ERROR Session invalid"});
    }, function (message, messageType) {
      let apiErrorCode = "";
      if (messageType) apiErrorCode = messageType + " - ";
      self.store.dispatch({"type": "ERROR", "error": "GRAINGER-BOOKING-JOURNEY-ERROR-00000021 API error - " + apiErrorCode + " getSSOKeyAndBookAdditionalRentalUnit - " + message});
    });
  }
  getSSOKeyAndCancelOnHoldBooking () {
    let self = this;
    api.getSSOKeyAndCancelOnHoldBooking(this.props.sso, this.props.api, {"stay": this.state.stay.lookupValue}, function (response) {
      self.store.dispatch({"type": "CANCEL_ON_HOLD_BOOKING_OK", "response": response});
    }, function () {
      self.store.dispatch({"type": "ERROR", "error": "GRAINGER-BOOKING-JOURNEY-ERROR Session invalid"});
    }, function (message, messageType) {
      let apiErrorCode = "";
      if (messageType) apiErrorCode = messageType + " - ";
      self.store.dispatch({"type": "ERROR", "error": "GRAINGER-BOOKING-JOURNEY-ERROR-00000023 API error - " + apiErrorCode + " getSSOKeyAndCancelOnHoldBooking - " + message});
    });
  }

  createGeneralEnquiry () {
    let self = this;
    let enquiryText = utils.format_path(this.state.labels["graingerBookingJourney.addons.joinListEnquiryText"], {"LOCATION": this.state.stay.room.location.displayValue});
    let payload = {
      "activity": {
        "type": "WEBENQ",
        "room": this.state.stay.room.lookupValue,
        "location": this.state.stay.room.location.lookupValue,
        "text": enquiryText,
      },
      "user": {
        "username": this.state.user.username,
        "firstName": this.state.user.firstName,
        "lastName": this.state.user.lastName,
      },
    };
    api.createGeneralEnquiry(this.props.api, payload, function (response) {
      self.store.dispatch({"type": "CREATE_GENERAL_ENQUIRY_OK"});
    }, function (message, messageType) {
      let apiErrorCode = "";
      if (messageType) apiErrorCode = messageType + " - ";
      self.store.dispatch({"type": "ERROR", "error": "GRAINGER-BOOKING-JOURNEY-ERROR-00000022 API error - " + apiErrorCode + " createGeneralEnquiry - " + message});
    });
  }

  getSSOKeyAndAddPersonToTenancy () {
    let self = this;
    let persontoTenancy = graingerService.getPayloadToAddPersonToTenancy(this.state.tenantAndGuarantorDetails);
    if (persontoTenancy === "completed") {
      this.store.dispatch({type: "COMPLETE_STEP"});
    } else {
      persontoTenancy.payload.tenancy = this.state.stay.lookupValue;
      api.getSSOKeyAndAddPersonToTenancy(this.props.sso, this.props.api, persontoTenancy.payload, function (response) {
        self.store.dispatch({"type": "CHECK_ADD_MORE_TENANTS", "tenantAdded": persontoTenancy});
      }, function () {
        self.store.dispatch({"type": "ERROR", "error": "GRAINGER-BOOKING-JOURNEY-ERROR Session invalid"});
      }, function (message, messageType) {
        let apiErrorCode = "";
        if (messageType) apiErrorCode = messageType + " - ";
        self.store.dispatch({"type": "ERROR", "error": "GRAINGER-BOOKING-JOURNEY-ERROR-00000023 API error - " + apiErrorCode + " getSSOKeyAndAddPersonToTenancy - " + message});
      });
    }
  }

  getSSOKeyAndGetPayments () {
    let self = this;
    api.getSSOKeyAndGetPayments(this.props.sso, this.props.api, {"stay": this.state.stay.lookupValue}, function (response) {
      self.store.dispatch({"type": "GET_PAYMENTS_OK", "response": response});
    }, function () {
      self.store.dispatch({"type": "ERROR", "error": "GRAINGER-BOOKING-JOURNEY-ERROR Session invalid"});
    }, function (message, messageType) {
      let apiErrorCode = "";
      if (messageType) apiErrorCode = messageType + " - ";
      self.store.dispatch({"type": "ERROR", "error": "GRAINGER-BOOKING-JOURNEY-ERROR-00000025 API error - " + apiErrorCode + " getSSOKeyAndGetPayments - " + message});
    });
  }

  getSSOKeyAndUpdatePersonDetailsTenantAndGuarantorDetails () {
    let payload = {
      "user": {
        "guarantor": this.state.tenantAndGuarantorDetails.guarantor.values,
      },
    };
    let self = this;
    api.getSSOKeyAndUpdatePersonDetails(this.props.sso, this.props.api, payload, function (response) {
      self.store.dispatch({"type": "UPDATE_PERSON_DETAILS_TENANT_AND_GUARANTOR_DETAILS_OK", "response": response});
    }, function () {
      self.store.dispatch({"type": "ERROR", "error": "GRAINGER-BOOKING-JOURNEY-ERROR Session invalid"});
    }, function (message, messageType) {
      let apiErrorCode = "";
      if (messageType) apiErrorCode = messageType + " - ";
      self.store.dispatch({"type": "ERROR", "error": "GRAINGER-BOOKING-JOURNEY-ERROR-00000026 API error - " + apiErrorCode + " getSSOKeyAndUpdatePersonDetails - " + message});
    });
  }

  getSSOKeyAndSetPaymentMethod () {
    let payload = {
      "stay": this.state.stay.lookupValue,
      "paymentMethod": "PAYMENTCARD",
    };
    let self = this;
    api.getSSOKeyAndSetPaymentMethod(this.props.sso, this.props.api, payload, function (response) {
      self.store.dispatch({"type": "SET_PAYMENT_METHOD_OK"});
    }, function () {
      self.store.dispatch({"type": "ERROR", "error": "GRAINGER-BOOKING-JOURNEY-ERROR Session invalid"});
    }, function (message, messageType) {
      let apiErrorCode = "";
      if (messageType) apiErrorCode = messageType + " - ";
      self.store.dispatch({"type": "ERROR", "error": "GRAINGER-BOOKING-JOURNEY-ERROR-00000027 API error - " + apiErrorCode + " getSSOKeyAndSetPaymentMethod - " + message});
    });
  }

  getSSOKeyAndGetPaymentUrl () {
    let redirectUrl = window.location.origin + window.location.pathname;
    redirectUrl = redirectUrl.replace("grainger-booking-journey.html", "notification.html");
    let payload = {
      "stay": this.state.stay.lookupValue,
      "step": this.state.currentStep.lookupValue,
      "redirectUrl": redirectUrl,
    };
    let self = this;
    api.getSSOKeyAndGetPaymentUrl(this.props.sso, this.props.api, payload, function (response) {
      self.store.dispatch({"type": "GET_PAYMENT_URL_OK", "response": response});
    }, function () {
      self.store.dispatch({"type": "ERROR", "error": "GRAINGER-BOOKING-JOURNEY-ERROR Session invalid"});
    }, function (message, messageType) {
      let apiErrorCode = "";
      if (messageType) apiErrorCode = messageType + " - ";
      self.store.dispatch({"type": "ERROR", "error": "GRAINGER-BOOKING-JOURNEY-ERROR-00000028 API error - " + apiErrorCode + " getSSOKeyAndGetPaymentUrl - " + message});
    });
  }
  getSSOKeyAndGetESignatureWidgetUrl () {
    //TODO: I add ?fix=me because the method is returning resirectUrl + "&id=23453242342323233" so I have to ad a parameter before to avoid the fail
    let redirectUrl = window.location.origin + window.location.pathname + "?fix=me";
    redirectUrl = redirectUrl.replace("grainger-booking-journey.html", "docSigned.html");
    let payload = {
      "tenancy": this.state.stay.lookupValue,
      "redirectUrl": redirectUrl,
    };
    let self = this;
    api.getSSOKeyAndGetESignatureWidgetUrl(this.props.sso, this.props.api, payload, function (response) {
      self.store.dispatch({"type": "GET_TENANCY_AGREEMENT_OK", "response": response});
    }, function () {
      self.store.dispatch({"type": "ERROR", "error": "GRAINGER-BOOKING-JOURNEY-ERROR Session invalid"});
    }, function (message, messageType) {
      let apiErrorCode = "";
      if (messageType) apiErrorCode = messageType + " - ";
      self.store.dispatch({"type": "ERROR", "error": "GRAINGER-BOOKING-JOURNEY-ERROR-00000029 API error - " + apiErrorCode + " getSSOKeyAndGetESignatureWidgetUrl - " + message});
    });
  }

  //METHODS
  closeModal () {
    this.store.dispatch({"type": "CLOSE_MODAL"});
  }

  submitLoginForm () {
    if (!this.state.loginForm.canSubmit) return this.store.dispatch({ type: "LOGIN_FORM_SUBMITTED_WITH_ERRORS"});
    else return this.store.dispatch({ type: "START_LOGIN"});

  }

  updateFieldFromEvent (field, event) {
    let value = "";
    if (field === "privacyPolicy") value = event;
    else value = event.target.value;
    this.store.dispatch({type: "UPDATE_FIELD", "field": field, "value": value});
  }

  loadGetStartedA () {
    this.store.dispatch({type: "LOAD_STEP1A"});
  }

  loadGetStartedB () {
    this.store.dispatch({type: "LOAD_STEP1B"});
  }

  loadAddons () {
    this.store.dispatch({type: "LOAD_STEP2"});
  }

  loadTenantAndGuarantorDetails () {
    this.store.dispatch({type: "LOAD_TENANT_AND_GUARANTOR_DETAILS"});
  }

  loadPayAdvanced () {
    this.store.dispatch({type: "LOAD_PAY_ADVANCED"});
  }

  loadPayAdvancedB () {
    this.store.dispatch({type: "LOAD_PAY_ADVANCED_B"});
  }
  loadReferencingPre () {
    this.store.dispatch({type: "LOAD_REFERENCING_PRE"});
  }

  loadReferencing () {
    this.store.dispatch({type: "LOAD_REFERENCING"});
  }

  loadReferencingHold () {
    this.store.dispatch({type: "LOAD_REFERENCING_HOLD"});
  }

  loadSign () {
    this.store.dispatch({type: "LOAD_SIGN"});
  }

  loadPay () {
    this.store.dispatch({type: "LOAD_PAY"});
  }

  completeStep () {
    this.store.dispatch({type: "COMPLETE_STEP"});
  }

  completeAddons () {
    this.store.dispatch({type: "COMPLETE_STEP2"});
  }

  completeTenantAndGuarantorDetails () {
    this.store.dispatch({type: "COMPLETE_TENANT_AND_GUARANTOR_DETAILS"});
  }

  completeReferencing () {
    this.store.dispatch({type: "COMPLETE_REFERENCING"});
  }

  completeReferencingHold () {
    this.store.dispatch({type: "COMPLETE_REFERENCING_HOLD"});
  }

  completeSign () {
    this.store.dispatch({type: "COMPLETE_SIGN"});
  }

  updateGetStartedBForm (field) {
    this.store.dispatch({type: "UPDATE_STEP1B_FORM", "field": field});
  }

  updatePersonDetailsGetStartedB () {
    this.store.dispatch({type: "UPDATE_PERSON_DETAILS_STEP1B"});
  }

  clickParking () {
    this.store.dispatch({type: "CLICK_PARKING"});
  }

  completeAddonsWithParking () {
    this.store.dispatch({type: "COMPLETE_STEP2_WITH_PARKING"});
  }

  changeTenantsNumber (tenantsNumber) {
    this.store.dispatch({type: "UPDATE_TENANTS_NUMBER", "tenantsNumber": tenantsNumber});
  }

  changeAdditionalOccupantsNumber (additionalOccupantsNumber) {
    this.store.dispatch({type: "UPDATE_ADDITIONAL_OCCUPANTS_NUMBER", "additionalOccupantsNumber": additionalOccupantsNumber});
  }

  clickGuarantor () {
    this.store.dispatch({type: "CLICK_GUARANTOR"});
  }

  updateTenantAndGuarantorDetailsTenantForm (field, event, index) {
    this.store.dispatch({type: "UPDATE_STEP_TENANT_FORM", "field": field, "value": event.target.value, "index": index.toString()});
  }

  updateTenantAndGuarantorDetailsAdditionalOccupantForm (field, event, index) {
    this.store.dispatch({type: "UPDATE_STEP_ADDITIONAL_OCCUPANT_FORM", "field": field, "value": event.target.value, "index": index.toString()});
  }

  updateTenantAndGuarantorDetailsGuarantorForm (field, event) {
    if (field === "guarantor-confirm") this.store.dispatch({type: "UPDATE_STEP_GUARANTOR_CONFIRM"});
    else this.store.dispatch({type: "UPDATE_STEP_GUARANTOR_FORM", "field": field, "value": event.target.value});
  }

  updateTenantAndGuarantorDetailsGeneralCheckbox () {
    this.store.dispatch({type: "UPDATE_TENANT_AND_GUARANTOR_DETAILS_GENERAL_CHECKBOX"});
  }

  updateStep4ConditionsConfirm () {
    this.store.dispatch({type: "UPDATE_PAY_ADVANCED_CONDITIONS_CONFIRM"});
  }

  showIframePayAdvanced () {
    this.store.dispatch({type: "SHOW_IFRAME_PAY_ADVANCED"});
  }

  cancelOnHoldBooking () {
    this.store.dispatch({type: "START_CANCEL_ON_HOLD_BOOKING"});
  }

  goToStep0url () {
    window.location.replace(this.props.step0url);
  }

  reload () {
    window.location.replace(window.location.origin + window.location.pathname);
  }
}

function GraingerBookingJourney (props) {
  let bookingStepClassName = "booking-step-";
  if (props.state.currentStep) bookingStepClassName += props.state.currentStep.position;

  return <div className={classNames("widget-content", props.state.waiting ? "divFrozen" : "")}>
    <Row className={bookingStepClassName}>
      <BookingStep state={props.state}
        loadGetStartedA={props.loadGetStartedA}
        loadGetStartedB={props.loadGetStartedB}
        loadAddons={props.loadAddons}
        loadTenantAndGuarantorDetails={props.loadTenantAndGuarantorDetails}
        loadPayAdvanced={props.loadPayAdvanced}
        loadPayAdvancedB={props.loadPayAdvancedB}
        loadReferencingPre={props.loadReferencingPre}
        loadReferencing={props.loadReferencing}
        loadReferencingHold={props.loadReferencingHold}
        loadSign={props.loadSign}
        loadPay={props.loadPay}
        completeStep={props.completeStep}
        completeAddons={props.completeAddons}
        completeTenantAndGuarantorDetails={props.completeTenantAndGuarantorDetails}
        updateGetStartedBForm={props.updateGetStartedBForm}
        updatePersonDetailsGetStartedB={props.updatePersonDetailsGetStartedB}
        clickParking={props.clickParking}
        completeAddonsWithParking={props.completeAddonsWithParking}
        changeTenantsNumber={props.changeTenantsNumber}
        clickGuarantor={props.clickGuarantor}
        updateTenantAndGuarantorDetailsTenantForm={props.updateTenantAndGuarantorDetailsTenantForm}
        updateTenantAndGuarantorDetailsGuarantorForm={props.updateTenantAndGuarantorDetailsGuarantorForm}
        changeAdditionalOccupantsNumber={props.changeAdditionalOccupantsNumber}
        updateTenantAndGuarantorDetailsAdditionalOccupantForm={props.updateTenantAndGuarantorDetailsAdditionalOccupantForm}
        updateTenantAndGuarantorDetailsGeneralCheckbox={props.updateTenantAndGuarantorDetailsGeneralCheckbox}
        cancelOnHoldBooking={props.cancelOnHoldBooking}
        updateStep4ConditionsConfirm={props.updateStep4ConditionsConfirm}
        showIframePayAdvanced={props.showIframePayAdvanced}
        reload={props.reload}
        completeReferencing={props.completeReferencing}
        completeReferencingHold={props.completeReferencingHold}
      />
      <BookingSummaryDetails state={props.state}/>
    </Row>
  </div>;
}


function BookingStep (props) {
  let currentStep = null;
  let stepFooterButtons = null;

  if (props.state.component) {
    currentStep = <PaymentResponse state={props.state} />;
    if (props.state.component === "success") stepFooterButtons = <Button className={"paymentOk-next-btn"} onClick={props.reload} bsStyle={"success"}>{props.state.labels["graingerBookingJourney.paymentOk.nextButton"]}</Button>;
    else stepFooterButtons = <Button className={"paymentKo-next-btn"} onClick={props.reload} bsStyle={"success"}>{props.state.labels["graingerBookingJourney.paymentKo.nextButton"]}</Button>;
  } else {
    if (props.state.currentStep.viewReference === "GET_STARTED_A") {
      currentStep = <GetStartedA state={props.state} loadGetStartedA={props.loadGetStartedA}/>;
      stepFooterButtons = <Button className={"getStartedA-next-btn"} onClick={props.completeStep} bsStyle={"success"}>{props.state.labels["graingerBookingJourney.getStartedA.nextButton"]}</Button>;
    }
    else if (props.state.currentStep.viewReference === "GET_STARTED_B") {
      currentStep = <GetStartedB state={props.state}
        loadGetStartedB={props.loadGetStartedB}
        updateGetStartedBForm={props.updateGetStartedBForm}/>;
      stepFooterButtons = <Button className={"getStartedB-next-btn"} disabled={!props.state.getStartedBForm.canCompleteStep}
        onClick={props.updatePersonDetailsGetStartedB} bsStyle={"success"}>{props.state.labels["graingerBookingJourney.getStartedA.nextButton"]}</Button>;
    } else if (props.state.currentStep.viewReference === "ADD_ONS") {
      currentStep = <Addons state={props.state} loadAddons={props.loadAddons} clickParking={props.clickParking} cancelOnHoldBooking={props.cancelOnHoldBooking}/>;
      if (props.state && props.state.stepLoaded) {
        if (props.state.addons.withParking && props.state.addons.offerParking) {
          stepFooterButtons = <Button className={"addons-next-btn"} onClick={props.completeAddonsWithParking} bsStyle={"success"}>{props.state.labels["graingerBookingJourney.addons.nextButtonWithParking"]}</Button>;
        } else {
          stepFooterButtons = <Button className={"addons-next-btn"} onClick={props.completeAddons} bsStyle={"success"}>{props.state.labels["graingerBookingJourney.addons.nextButtonWithoutParking"]}</Button>;
        }
      }
    } else if (props.state.currentStep.viewReference === "TENANT_AND_GUARANTOR_DETAILS") {
      currentStep = <TenantAndGuarantorDetails state={props.state}
        loadTenantAndGuarantorDetails={props.loadTenantAndGuarantorDetails}
        changeTenantsNumber={props.changeTenantsNumber}
        clickGuarantor={props.clickGuarantor}
        updateTenantAndGuarantorDetailsTenantForm={props.updateTenantAndGuarantorDetailsTenantForm}
        updateTenantAndGuarantorDetailsGuarantorForm={props.updateTenantAndGuarantorDetailsGuarantorForm}
        changeAdditionalOccupantsNumber={props.changeAdditionalOccupantsNumber}
        updateTenantAndGuarantorDetailsAdditionalOccupantForm={props.updateTenantAndGuarantorDetailsAdditionalOccupantForm}
        updateTenantAndGuarantorDetailsGeneralCheckbox={props.updateTenantAndGuarantorDetailsGeneralCheckbox}
      />;
      if (props.state.tenantAndGuarantorDetails && props.state.tenantAndGuarantorDetails.canComplete) stepFooterButtons = <Button className={"tenantAndGuarantorDetails-next-btn"} onClick={props.completeTenantAndGuarantorDetails} bsStyle={"success"}>{props.state.labels["graingerBookingJourney.tenantAndGuarantorDetails.nextButton"]}</Button>;
      else stepFooterButtons = <Button className={"tenantAndGuarantorDetails-next-btn"} onClick={props.completeTenantAndGuarantorDetails} bsStyle={"success"} disabled>{props.state.labels["graingerBookingJourney.tenantAndGuarantorDetails.nextButton"]}</Button>;
    } else if (props.state.currentStep.viewReference === "PAY_ADVANCED") {
      currentStep = <PayAdvanced state={props.state} loadPayAdvanced={props.loadPayAdvanced} updateStep4ConditionsConfirm={props.updateStep4ConditionsConfirm}
        showIframePayAdvanced={props.showIframePayAdvanced}/>;
    } else if (props.state.currentStep.viewReference === "REFERENCING_PRE") {
      currentStep = <ReferencingPre state={props.state} loadReferencingPre={props.loadReferencingPre}/>;
      stepFooterButtons = <Button className={"referencingPre-next-btn"} onClick={props.completeStep} bsStyle={"success"}>{props.state.labels["graingerBookingJourney.referencingPre.nextButton"]}</Button>;
    } else if (props.state.currentStep.viewReference === "REFERENCING") {
      currentStep = <Referencing state={props.state} loadReferencing={props.loadReferencing}/>;
      stepFooterButtons = <Button className={"referencing-next-btn"} onClick={props.completeReferencing} bsStyle={"success"}>{props.state.labels["graingerBookingJourney.referencing.nextButton"]}</Button>;
    } else if (props.state.currentStep.viewReference === "REFERENCING_HOLD") {
      currentStep = <ReferencingHold state={props.state} loadReferencingHold={props.loadReferencingHold}/>;
      stepFooterButtons = <Button className={"referencingHold-next-btn"} onClick={props.completeReferencingHold} bsStyle={"success"}>{props.state.labels["graingerBookingJourney.referencingHold.nextButton"]}</Button>;
    } else if (props.state.currentStep.viewReference === "SIGN") {
      currentStep = <Sign state={props.state} loadSign={props.loadSign}/>;
    } else if (props.state.currentStep.viewReference === "PAY") {
      currentStep = <Pay state={props.state} loadPay={props.loadPay}/>;
    }

  }

  if (!props.state.canLoadStep) currentStep = <div>Loading step...</div>;

  return <Col className={"booking-step-container"} sm={10}>
    <div className="panel panel-default">
      <NavigationInfo state={props.state}/>
      <div className="panel-body">
        {currentStep}
      </div>
      <Footer state={props.state} stepFooterButtons={stepFooterButtons}/>
    </div>
  </Col>;
}

function NavigationInfo (props) {

  let navigationSteps = [];
  let labelsToShow = {};
  let steps = props.state.workflowResponse.skeleton.steps;
  let currentStepFound = false;
  for (let index = 0;index < steps.length;index++) {
    labelsToShow[steps[index].displayValue] = {
      "active": false,
      "lock": false,
      "completed": false,
    };
    if (props.state.workflowResponse.workflow.steps.length) {
      let stepsCompleted = props.state.workflowResponse.workflow.steps.filter( function (step) {
        return step.displayValue === steps[index].displayValue && step.completed;
      });
      let stepsSkeleton = props.state.workflowResponse.skeleton.steps.filter( function (step) {
        return step.displayValue === steps[index].displayValue;
      });
      if (stepsCompleted.length > 0 && stepsCompleted.length === stepsSkeleton.length) labelsToShow[steps[index].displayValue].completed = true;
    }

    if (props.state.currentStep.displayValue === steps[index].displayValue) {
      labelsToShow[steps[index].displayValue].active = true;
      currentStepFound = true;
    }

    if (typeof steps[index + 1] !== 'undefined') {
      if (index > 1 && steps[index].group !== steps[index + 1].group) {
        if (currentStepFound) labelsToShow[steps[index].displayValue].lock = "lock-open fa fa-unlock-alt";
        else labelsToShow[steps[index].displayValue].lock = "lock-closed fa fa-lock";
      }
    }
  }

  let stepIndex = 0;
  let lockIndex = 0;

  for (let key of Object.keys(labelsToShow)) {
    stepIndex++;
    let classes = [
      "step" + (stepIndex) + "-tab",
      "nav-element",
      {"active": labelsToShow[key].active ? true : false},
      {"step-completed": labelsToShow[key].completed ? true : false},
    ];
    navigationSteps.push(<li key={stepIndex} className={classNames(classes)}><a>{key}</a></li>);
    if (labelsToShow[key].lock) {
      let classesLock = [
        "lock",
        labelsToShow[key].lock,
      ];
      navigationSteps.push(<li key={"lock-" + lockIndex} className={"nav-element"}><span className={classNames(classesLock)}></span></li>);
      lockIndex++;
    }

  }

  return <div className="panel-heading step-navigation-info">
    <span className="panel-title">
      <ul className="nav nav-pills nav-justified">
        {navigationSteps}
      </ul>
    </span>
  </div>;
}

function Footer (props) {
  return <div className="panel-footer text-center booking-step-footer">{props.stepFooterButtons}</div>;
}

function BookingSummaryDetails (props) {
  let bedRooms = "";

  if (props.state.stay.room && props.state.stay.room.numberOfBedrooms) {
    bedRooms = props.state.stay.room.numberOfBedrooms.features + " " + props.state.labels["graingerBookingJourney.bookingSummaryDetails.singularBedrooms"];
    if (Number(props.state.stay.room.numberOfBedrooms.features) > 1) bedRooms = props.state.stay.room.numberOfBedrooms.features + " " + props.state.labels["graingerBookingJourney.bookingSummaryDetails.pluralBedrooms"];
  }
  let roomDescription = bedRooms;
  let size = props.state.stay.room.features.filter (function (feature) { return feature.type.lookupValue === "SIZE";})[0];
  if (size) roomDescription += ", " + size.text;

  let panelHeader = <div className={"title"}>{props.state.labels["graingerBookingJourney.bookingSummaryDetails.panelTitleLabel"]}</div>;
  return <Col sm={2} className={"booking-summary-details"}>
    <Alert className={"changes-saved"} bsStyle={"success"}><i className={"fa fa-check"}></i>{props.state.labels["graingerBookingJourney.bookingSummaryDetails.alertLabel"]}</Alert>
    <Panel className={"details-panel"} header={panelHeader}>
      <div className={"building-description well well-sm"}>
        <div className={"name"}>{props.state.stay.room.title}</div>
        <div className={"price"}>{props.state.labels["graingerBookingJourney.bookingSummaryDetails.currency"] + Number(props.state.stay.room.price) + " " + props.state.labels["graingerBookingJourney.bookingSummaryDetails.period"]}</div>
        <div className={"description"}>{roomDescription}</div>
        <div className={"details-url"}>
          <Button className={"details-button"} bsStyle={"info"}>{props.state.labels["graingerBookingJourney.bookingSummaryDetails.detailsLabel"]}</Button>
        </div>
      </div>
      <div className={"tenancy-period well well-sm"}>
        <div className={"period"}>{props.state.tenancyMonthsLabel}</div>
      </div>
      <div className={"start-date well well-sm"}>
        <span className={"start-date-label"}>{props.state.labels["graingerBookingJourney.bookingSummaryDetails.startDate"]}</span>
        <strong className={"start-date-content"}>{moment(props.state.stay.in).format('DD MMM YYYY')}</strong>
      </div>
    </Panel>

  </Col>;
}
