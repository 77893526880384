import React from 'react';
import Col from 'react-bootstrap/lib/Col';
import Row from 'react-bootstrap/lib/Row';
import Panel from 'react-bootstrap/lib/Panel';

export function ShowError (props) {

  return <div className={props.classname || ""}>
    <Row>
      <Col xs={12}>
        <Panel className="panel-min-height" bsStyle="danger" header={props.header || null} footer={props.footer || null} >
          <Row>
            {props.error}
          </Row>
        </Panel>
      </Col>
    </Row>
  </div>;
}
