import React from 'react';
import Panel from 'react-bootstrap/lib/Panel';
import Col from 'react-bootstrap/lib/Col';
import Row from 'react-bootstrap/lib/Row';
import Button from 'react-bootstrap/lib/Button';
import AgreementPDFAccept from './steps-components/agreement-pdf-accept.jsx';
import {InfoBar} from './info-bar.jsx';
import classNames from 'classnames';
import Loading from "./loading.jsx";

export function Agreement (props) {
  let myFooter = <Row>
    <Col xs={6}>
      <Button bsStyle="primary" className="go-to-dashboard" onClick={props.goToDashboard}>Dashboard</Button>
    </Col>
    <Col xs={6} className="steps-nav text-right">
      <Button className="go-to-previous-step" onClick={props.goToPaymentDetails} ><i className="fa fa-arrow-left hidden-sm hidden-lg"></i><span className="hidden-xs">Previous step</span></Button>
      <Button bsStyle="success" className="confirm-and-finish" disabled={!props.agreementYesNoValue || props.agreementYesNoValue === "no"} onClick={props.validateAgreement}>
        <span className="hidden-xs">Confirm and finish</span> <i className="fa fa-arrow-right hidden-sm hidden-lg"></i>
      </Button>
    </Col>
  </Row>;

  if (props.waiting) return <Loading waiting={props.waiting} />;
  return (
    <div className={classNames('container direct-debit dd-new agreement-step')}>
      <InfoBar infoBarData={props.infoBarData}/>
      <Row>
        <Col xs={12}>
          <Panel className="panel-min-height" header="Direct Debit Request" footer={myFooter}>
            <AgreementPDFAccept agreementYesNoAction={props.agreementYesNoAction}
              agreementYesNoValue={props.agreementYesNoValue}
              agreementUrl={props.agreementUrl}
              validateAgreement={props.validateAgreement}
              holderName={props.holderName}
              receiver={props.receiver}
              receiverBacsReference={props.receiverBacsReference}
            />
          </Panel>
        </Col>
      </Row>

    </div>
  );

}
