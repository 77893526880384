import React from 'react';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import Checkbox from 'react-bootstrap/lib/Checkbox';
import Radio from 'react-bootstrap/lib/Radio';
import Popover from 'react-bootstrap/lib/Popover';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import Button from 'react-bootstrap/lib/Button';
import Col from 'react-bootstrap/lib/Col';
import Row from 'react-bootstrap/lib/Row';
import utils from "../../../lib/utils";
import graingerService from "../services/grainger-service.js";

export default class GetStartedB extends React.Component {

  componentDidMount () {
    this.props.loadGetStartedB();
  }

  handleChange (event) {
    this.props.updateGetStartedBForm(event.target.name);
  }
  render () {
    let guarantorPopover = <Popover id="getStartedB-guarantor">
      <div className={"getStartedB-guarantor-popover-content"} dangerouslySetInnerHTML={{__html: utils.sanitize(this.props.state.labels["graingerBookingJourney.getStartedB.guarantorPopoverLabel"])}}></div>
    </Popover>;

    let ccjPopover = <Popover id="getStartedB-CCJ">
      <div className={"getStartedB-CCJ-popover-content"} dangerouslySetInnerHTML={{__html: utils.sanitize(this.props.state.labels["graingerBookingJourney.getStartedB.CCJPopoverLabel"])}}></div>
    </Popover>;

    let contactUsPopover = <Popover id="getStartedB-contact">
      <div className={"getStartedB-contact-popover-content"} dangerouslySetInnerHTML={{__html: utils.sanitize(this.props.state.labels["graingerBookingJourney.getStartedB.contactPopoverLabel"])}}></div>
    </Popover>;

    if (this.props.state && this.props.state.stepLoaded && !this.props.state.error) {
      let annual_combined_salary_str_x_30 = graingerService.getPriceFormatted(this.props.state.lang, "currency", "GBP", this.props.state.stay.room.price, 30);
      let infoLabel = utils.format_path(this.props.state.labels["graingerBookingJourney.getStartedB.infoLabel"],
        {"ROOM_PRICE_X_30": annual_combined_salary_str_x_30, "ROOM_PRICE": this.props.state.stay.room.price});

      let annual_combined_salary_str_x_40 = graingerService.getPriceFormatted(this.props.state.lang, "currency", "GBP", this.props.state.stay.room.price, 40);
      let guarantorPreference = this.props.state.getFormCollections.userPreferences.filter(function (preference) {
        return preference.lookupValue === "GUARANTOR_THAT_EXCEEDS";
      })[0];
      let guarantor = utils.format_path(guarantorPreference.displayValue, {"ROOM_PRICE_X_40": annual_combined_salary_str_x_40});

      let fullTimeJob = utils.format_path(this.props.state.labels["graingerBookingJourney.getStartedB.fullTimeJob"],
        {"ROOM_PRICE_X_30": annual_combined_salary_str_x_30});

      let rentInAdvancePreference = this.props.state.getFormCollections.userPreferences.filter(function (preference) {
        return preference.lookupValue === "PAY_IN_ADVANCE";
      })[0];
      let rentInAdvance = utils.format_path(rentInAdvancePreference.displayValue, {"MONTHS": this.props.state.tenancyMonths});

      let userPreferenceYes = this.props.state.getFormCollections.userPreferences.filter(function (preference) {
        return preference.lookupValue === "YES";
      })[0];
      let userPreferenceNo = this.props.state.getFormCollections.userPreferences.filter(function (preference) {
        return preference.lookupValue === "NO";
      })[0];
      return <div className={""}>
        <h3 className={"getStartedB-title"} dangerouslySetInnerHTML={{__html: utils.sanitize(this.props.state.labels["graingerBookingJourney.getStartedB.title"])}}></h3>
        <div className={"message"}>
          <div className="select-income">
            <p className={"getStartedB-info"} dangerouslySetInnerHTML={{__html: utils.sanitize(infoLabel)}}></p>

            <p dangerouslySetInnerHTML={{__html: utils.sanitize(fullTimeJob)}}></p>
            <div dangerouslySetInnerHTML={{__html: utils.sanitize(this.props.state.labels["graingerBookingJourney.getStartedB.fullTimeJob2"])}}></div>
            <FormGroup onChange={(ev)=>this.handleChange(ev)}>
              <Radio className="full-time-yes" name="full-time-yes" inline
                checked={this.props.state.getStartedBForm.fullTimeJob === "full-time-yes"}>Yes</Radio>
              <Radio className="full-time-no" name="full-time-no" inline
                checked={this.props.state.getStartedBForm.fullTimeJob === "full-time-no"}>No</Radio>
            </FormGroup>
            <div dangerouslySetInnerHTML={{__html: utils.sanitize(this.props.state.labels["graingerBookingJourney.getStartedB.noFullJob"])}}></div>
            <FormGroup onChange={(ev)=>this.handleChange(ev)}>
              <Radio className="no-full-time-guarantor" name="no-full-time-guarantor"
                checked={this.props.state.getStartedBForm.noFullTime === "no-full-time-guarantor"}
                disabled={this.props.state.getStartedBForm.fullTimeJob === "full-time-yes" || !this.props.state.getStartedBForm.fullTimeJob}>
                <span dangerouslySetInnerHTML={{__html: utils.sanitize(guarantor)}}/>
                <OverlayTrigger trigger="click" placement="right" overlay={guarantorPopover} rootClose>
                  <Button bsStyle="link" className={"getStartedB-guarantor-popover-btn"}><i className='fa fa-question-circle'></i></Button>
                </OverlayTrigger>
              </Radio>
              <Radio className="no-full-time-advance" name="no-full-time-advance"
                checked={this.props.state.getStartedBForm.noFullTime === "no-full-time-advance"}
                disabled={this.props.state.getStartedBForm.fullTimeJob === "full-time-yes" || !this.props.state.getStartedBForm.fullTimeJob}>
                <span dangerouslySetInnerHTML={{__html: utils.sanitize(rentInAdvance)}}/>
              </Radio>
            </FormGroup>
            <div>
              <span dangerouslySetInnerHTML={{__html: utils.sanitize(this.props.state.labels["graingerBookingJourney.getStartedB.credit"])}}></span>
              <OverlayTrigger trigger="click" placement="right" overlay={ccjPopover} rootClose>
                <Button bsStyle="link" className={"getStartedB-CCJ-popover-btn"}><i className='fa fa-question-circle'></i></Button>
              </OverlayTrigger>
            </div>
            <div dangerouslySetInnerHTML={{__html: utils.sanitize(this.props.state.labels["graingerBookingJourney.getStartedB.creditWarning"])}}></div>
            <FormGroup onChange={(ev)=>this.handleChange(ev)}>
              <Radio className="credit-yes" name="credit-yes" inline
                checked={this.props.state.getStartedBForm.credit === "credit-yes"}><span dangerouslySetInnerHTML={{__html: utils.sanitize(userPreferenceYes.displayValue)}}/></Radio>
              <Radio className="credit-no" name="credit-no" inline
                checked={this.props.state.getStartedBForm.credit === "credit-no"}><span dangerouslySetInnerHTML={{__html: utils.sanitize(userPreferenceNo.displayValue)}}/></Radio>
            </FormGroup>
            <Checkbox name={"citizen"} onChange={(ev)=>this.handleChange(ev)} checked={this.props.state.getStartedBForm.citizen}>
              <div dangerouslySetInnerHTML={{__html: utils.sanitize(this.props.state.labels["graingerBookingJourney.getStartedB.citizen"])}}></div>
            </Checkbox>
            <span>
              {this.props.state.labels["graingerBookingJourney.getStartedB.contactUs"]}
              <OverlayTrigger trigger="click" placement="right" overlay={contactUsPopover} rootClose>
                <a className={"getStartedB-contact-popover-btn"}>{this.props.state.labels["graingerBookingJourney.getStartedB.contactUsLink"]}</a>
              </OverlayTrigger>
            </span>
          </div>
        </div>
      </div>;
    }
    else return <div>Loading step...</div>;
  }
}

