import React from 'react';
import { PexThanks } from "../components/form-components/pex-thanks.jsx";
import { PexText } from "../components/form-components/pex-text.jsx";
import { PexTextArea } from "../components/form-components/pex-text-area.jsx";
import { PexDropDown } from "../components/form-components/pex-drop-down.jsx";
import { PexDatePicker } from "../components/form-components/pex-date-picker.jsx";
import { PexCheckbox } from "../components/form-components/pex-checkbox.jsx";
import formService from '../services/form-service.js';
import searchService from '../services/search-service.js';
import ajax from '../../lib/ajax.js';
let api = require('../../lib/api.js')(ajax);
import utils from '../../lib/utils.js';
import Form from 'react-bootstrap/lib/Form';
import Panel from 'react-bootstrap/lib/Panel';
import ButtonGroup from 'react-bootstrap/lib/ButtonGroup';
import Button from 'react-bootstrap/lib/Button';
import moment from 'moment';
import { createStore } from 'redux';
import translateService from '../services/translate-service.js';
import {PexCountryPhone} from '../components/form-components/pex-country-phone.jsx';
import TagManager from 'react-gtm-module';

const defaultLabels = JSON.parse(JSON.stringify(require("../../../lang/search-applet/en-GB.json"))).labels["en-GB"];

let format_path = utils.format_path;
let getCurrentMoment = require('../../lib/get-current-moment.js'); //TODO: how do we do an es6 import for this?
export default class ArrangeRoomViewingForm extends React.Component {
  constructor (props) {
    super(props);
    let myState = {};
    if (!this.props.room) myState = {data: {error: "configuration error - no room specified"}};
    else if (!this.props.api) myState = {data: {error: "configuration error - no api specified"}};
    else if (this.props.requiredFields) {
      for (let row of this.props.requiredFields.split(";").filter(function (el) {return el.length !== 0;})) {
        if ((row.match(new RegExp(":")) || []).length > 1 || (row.match(new RegExp(":")) || []).length === 0) {
          myState = {error: "configuration error - required field attribute is incorrect"};
        }
      }
    }

    if (props.gtmId) {
      let tagManagerArgs = { "gtmId": props.gtmId};
      TagManager.initialize(tagManagerArgs);
    }

    myState = Object.assign({}, myState, formService.initArrangeRoomViewingForm(this.props.requiredFields));
    //NOTE: the library react-datepicker has a bug disabling dates https://github.com/Hacker0x01/react-datepicker/issues/747
    //so when we have dates to exclude the date picker will have a default value
    myState.values.date = getCurrentMoment().unix();
    myState.loaded = false;
    myState.isOpen = "";
    myState.textFilterValue = "";
    let self = this;
    this.store = createStore(function (state = myState, action ) {
      switch (action.type) {
        case "MERGE_STATE": return Object.assign({}, state, action.state);
        case "LOAD": return Object.assign(state, {"nextAction": "getLabels", "lang": action.lang});
        case "API_ERROR": return Object.assign({}, state, {data: {error: action.error}, "waiting": false, "loaded": true, "nextAction": "DONE"});
        case "GET_DISABLED_DATES_RESPONSE": {
          let newState = Object.assign({}, {"nextAction": "getTimeSlots", "disabledDates": action.disabledDates, "maxDate": action.disabledDates.length > 0 ? moment(action.disabledDates[action.disabledDates.length - 1]) : null});
          if (newState.disabledDates && newState.disabledDates.length > 0 && newState.disabledDates.filter( function (d) { return d === moment.unix(state.values.date).format("YYYY-MM-DD");}).length > 0) {
            //if today is in the disabledDates list we have to search the net available date if we have it, so we loop until the last date and we check if is disabled(founde=true) if we don't find then "roomUnavailableToView": true
            let found = false;
            let i = 0;
            while (newState.disabledDates[newState.disabledDates.length - 1] !== moment.unix(state.values.date).add(i, "days").format("YYYY-MM-DD") && !found) {
              i++;
              if (newState.disabledDates.filter( function (d) { return d === moment.unix(state.values.date).add(i, "days").format("YYYY-MM-DD");}).length === 0) found = true;
            }
            if (found) newState.values = Object.assign(state.values, { "date": moment.unix(state.values.date).add(i, "days").unix()});
            else newState = Object.assign({ "data": null, "roomUnavailableToView": true, "nextAction": "DONE"});
          }
          newState.excludeDates = [];
          if (newState.disabledDates && newState.disabledDates.length > 0) for (let d of newState.disabledDates) newState.excludeDates.push(moment(d));
          return Object.assign({}, state, newState);
        }
        case "GET_TIME_SLOTS_RESPONSE": {
          let hours = [];
          if (action.timeSlots && action.timeSlots.length > 0) for (let h of action.timeSlots) hours.push(<option key={h.substring(0, 5)} value={h.substring(0, 5)}>{h.substring(0, h.length - 3)}</option>);
          let hoursPlaceHolder = state.values && state.values["date"] && hours.length === 0 ? "hourSlotPlaceholderNoHours" : "hourSlotPlaceholder";
          return Object.assign({}, state, {"timeSlots": action.timeSlots, "waiting": false, "loaded": true, "nextAction": "DONE", "hours": hours, "hoursPlaceHolder": hoursPlaceHolder});
        }
        case "GET_ROOM_DETAILS_RESPONSE": {
          return Object.assign({}, state, { "data": { "room": action.room }, "nextAction": "getFormCollections"});
        }
        case "THANKS": return Object.assign({}, state, {waiting: false, state: "sent"});
        case "SUBMIT_WITH_ERROR": {
          let newState = {};
          let error = searchService.getError(action.message, action.messageType, null, self.state.labels, null, "arrangeRoomViewingForm.customAPIMessage.");
          newState.submitError = error;
          newState.waiting = false;
          return Object.assign({}, state, newState);
        }
        case "UPDATE_FIELD": {
          let values = state.values;
          values[action.field] = action.value;
          let new_state = Object.assign({}, state, formService.checkForm(values, state.formNames, state.labels, "arrangeRoomViewingForm"), values);
          if (action.field === "date") new_state = Object.assign({"nextAction": "getTimeSlots", "waiting": true});
          return Object.assign({}, state, new_state);
        }
        case "UPDATE_BOOLEAN": {
          let values = state.values;
          values[action.field] = formService.setNoSpamBoolean(values[action.field], action.value);
          let new_state = Object.assign({}, state, formService.checkForm(values, state.formNames, state.labels, "arrangeRoomViewingForm"), values);
          return Object.assign(state, new_state);
        }
        case "SUBMITTED_WITH_ERRORS": return Object.assign(state, {submitted: true});
        case "WAITING": return Object.assign(state, {waiting: true});
        case "GET_FORM_COLLECTION_RESPONSE": {
          let countries = action.formCollection.countries.map(
            function (country) {
              let obj = {};
              obj.lookupValue = country.lookupValue;
              obj.diallingCode = country.diallingCode;
              obj.displayValue = country.displayValue + " (" + country.diallingCode + ")";
              return obj;
            }
          ).filter(function (el) {return el.diallingCode && Number.isInteger(Number(el.diallingCode.substring(0, 1)));});
          return Object.assign(state, { "getFormCollectionsResponse": action.formCollection, "nextAction": "getDisabledDates", "countries": countries});
        }
        case "GET_LABELS_OK": {
          let formArrangeViewingForm = formService.initArrangeRoomViewingForm(self.props.requiredFields, action.response.labels[state.lang]);
          formArrangeViewingForm.values["noSpam"] = formService.setNoSpamBoolean(formArrangeViewingForm.values["noSpam"], "true");
          formArrangeViewingForm.values.date = getCurrentMoment().unix();
          return Object.assign({}, state, {"labels": action.response.labels[state.lang], "nextAction": "getOptions"}, formArrangeViewingForm);
        }
        case "SELECT_COUNTRY": {
          let newState = state;
          newState.countrySelected = action.countrySelected;
          newState.values["mobileCountry"] = action.countrySelected.diallingCode;
          newState = Object.assign(newState, formService.checkForm(newState.values, newState.formNames, newState.labels, "arrangeRoomViewingForm"));
          newState.isOpen = "";
          return Object.assign(state, {"textFilterValue": ""}, newState);
        }
        case "OPEN_CLOSE_DROPDOWN": {
          let isOpen = action.collectionName;
          if (state.isOpen && state.isOpen.length > 0 && state.isOpen === action.collectionName) isOpen = "";
          return Object.assign(state, {"isOpen": isOpen, "textFilterValue": ""});
        }
        case "CLICK_OUTSIDE": {
          return Object.assign(state, {"isOpen": ""});
        }
        case "SEARCH_FILTER_HANDLER": {
          return Object.assign(state, {"textFilterValue": action.value});
        }
        case "GET_OPTIONS": {
          return Object.assign(state, { "marketingLocationsData": action.data, "nextAction": "getRoomDetails", "addBookingType": action.data.settings["marketing.locations.addBookingTypes"]});
        }
        default: return state;
      }
    });
    this.state = this.store.getState();
  }

  componentWillUnmount () {
    //I think I could have just put
    //this.componentWillUnmount = this.store.subscribe ...
    //above.  that would have been pretty cool
    this.unsubscribe();
  }

  render () {
    let _self = this;
    if (this.state && this.state.state === "sent") {
      return <PexThanks message={::this.getThanksMessage} />;
    } else if (this.state && this.state.data && this.state.data.room && this.state.loaded) {
      return <div className={(this.state.waiting) ? "divFrozen" : "container-fluid"}>
        <FormPex submit={::this.submit}
          submittedWithErrors={::this.submittedWithErrors}
          waiting= {::this.waiting}
          generalProps={this.getFormControlProps()}
          booleanProps={this.booleanProps()}
          openCloseDropdown={this.openCloseDropdown.bind(this)}
          clickOutside={this.clickOutside.bind(this)}
          onSearchTextHandler={this.onSearchTextHandler.bind(this)}
          selectCountry={this.selectCountry.bind(this)}
          state={this.state}
          requiredFields={this.props.requiredFields}
          showUniversities={_self.props.showUniversities}
          defaultLabels={defaultLabels}/>
      </div>;
    } else if (this.state && this.state.data && this.state.data.error) {
      return <div dangerouslySetInnerHTML={{__html: utils.sanitize(this.state.data.error)}}></div>;
    } else if (this.state && this.state.roomUnavailableToView) {
      return <div className="room-unavailable-to-view">This room is currently unavailable to view.</div>;
    } else {
      return <div>just a sec</div>;
    }
  }

  componentDidMount () {
    if (this.store) {
      this.unsubscribe = this.store.subscribe(function () {
        ///TODO: find a way to wrap up this code since we are using it in multiple components
        let state = {};
        for (let key of Object.keys(this.state)) state[key] = null; //we have to do this because replaceState no longer exists
        this.setState(Object.assign({}, state, this.store.getState()));

        //instead of setting the state to the redux store state, if we want to pass
        //access the redux store directly, we can replace setState with this.forceUpdate
      }.bind(this));
      let lang = "en-GB";
      if (this.props.lang) lang = this.props.lang;
      this.store.dispatch({type: "LOAD", "lang": lang});
    }
  }

  //we cannot call the API inside the ajax suscess because if not we have not API calls secuencially
  //it simulates promises
  componentDidUpdate (prevProps, prevState) {
    switch (this.state.nextAction) {
      case "getRoomDetails":
      case "getDisabledDates":
      case "getTimeSlots":
      case "getFormCollections":
      case "getLabels":
      case "getOptions":
        this[this.state.nextAction]();
    }
  }

  getRoomDetails () {
    let self = this;
    api.getRoomDetails(this.props.api, {"room": this.props.room }, function (data) {
      self.store.dispatch({type: "GET_ROOM_DETAILS_RESPONSE", "room": data.room});
    }, function () {
      self.store.dispatch({type: "API_ERROR", "error": "API error 2373828A"});
    });
  }

  getDisabledDates () {
    let self = this;
    api.getDisabledDates(self.props.api, {"room": this.props.room }, function (getDisabledDatesData) {
      let newState = {};
      if (getDisabledDatesData.disabledDates.length > 0 && getDisabledDatesData.disabledDates[getDisabledDatesData.disabledDates.length - 1] < moment.unix(self.state.values.date).format("YYYY-MM-DD")) {
        //if API returns as last date a date minor than today we throw an error, in theory it never has to happens but if happens we will know that's an API error
        self.store.dispatch({type: "API_ERROR", "error": "API error 6533999A"});
      } else {
        self.store.dispatch({type: "GET_DISABLED_DATES_RESPONSE", "disabledDates": getDisabledDatesData.disabledDates});
      }
    }, function () {
      self.store.dispatch({type: "API_ERROR", "error": "API error 6533765A"});
    });
  }

  submit (payload) {
    let self = this;
    api.createGeneralEnquiry(this.props.api, payload, ::this.thanks,
      function (message, messageType) {
        self.store.dispatch({type: "SUBMIT_WITH_ERROR", "message": message, "messageType": messageType});
      });
  }

  thanks (response) {
    this.store.dispatch({type: "THANKS"});
  }

  getThanksMessage () {
    if (this.props.thanksmessage) return format_path(this.props.thanksmessage, {BUILDING_NAME: this.state.data.room.building.displayValue, LOCATION_NAME: this.state.data.room.location.displayValue});
    return "THANKS!!!";
  }

  getTimeSlots () {
    let self = this;
    api.getTimeSlots(self.props.api, {"room": this.props.room, "date": moment.unix(this.state.values.date).format("YYYY-MM-DD") }, function (getTimeSlotsData) {
      self.store.dispatch({type: "GET_TIME_SLOTS_RESPONSE", "timeSlots": getTimeSlotsData.timeSlots});
    }, function () {
      self.store.dispatch({type: "API_ERROR", "error": "API error 6733765A"});
    });
  }

  updateFieldFromEvent (field, event) {
    this.store.dispatch({type: "UPDATE_FIELD", "field": field, "value": event.target.value});
  }

  updateBoolean (field, event) {
    this.store.dispatch({type: "UPDATE_BOOLEAN", "field": field, "value": event.target.value});
  }

  submittedWithErrors () {
    this.store.dispatch({type: "SUBMITTED_WITH_ERRORS"});
  }

  waiting () {
    this.store.dispatch({type: "WAITING"});
  }

  getFormControlProps () {
    return {
      values: this.state.values || {},
      errors: this.state.fieldsWithErrors ? this.state.fieldsWithErrors : {},
      updateField: this.updateFieldFromEvent.bind(this),
      submitted: this.state.submitted,
      waiting: this.state.waiting,
    };
  }

  booleanProps () {
    return {
      values: this.state.values || {},
      errors: this.state.fieldsWithErrors ? this.state.fieldsWithErrors : {},
      updateField: this.updateBoolean.bind(this),
      submitted: this.state.submitted,
      waiting: this.state.waiting,
    };
  }

  getFormCollections () {
    let self = this;
    api.getFormCollections(self.props.api, {"location": this.state.data.room.location.lookupValue }, function (getFormCollectionsResponse) {
      self.store.dispatch({type: "GET_FORM_COLLECTION_RESPONSE", "formCollection": getFormCollectionsResponse});
    }, function () {
      self.store.dispatch({type: "API_ERROR", "error": "API error 9782545 - getFormCollections error"});
    });
  }

  getLabels () {
    let self = this;
    api.getLabels(this.props.api, {"languageIds": [this.state.lang], "group": "arrangeRoomViewingForm"}, function (response) {
      self.store.dispatch({ type: "GET_LABELS_OK", "response": response});
    }, function () {
      self.store.dispatch({"type": "API_ERROR", "error": "API error 9787746A - getLabels error"});
    });
  }
  getOptions () {
    let self = this;
    api.marketingCollections(this.props.api, {}, function (data) {
      self.store.dispatch({type: "GET_OPTIONS", data: data});
    }, function (error) {
      self.store.dispatch({type: "API_ERROR", "error": error});
    });
  }
  selectCountry (country) {
    this.store.dispatch({type: "SELECT_COUNTRY", "countrySelected": JSON.parse(JSON.stringify(country))});
  }
  openCloseDropdown (collectionName) {
    this.store.dispatch({ type: "OPEN_CLOSE_DROPDOWN", "collectionName": collectionName});
  }
  clickOutside (collectionName) {
    this.store.dispatch({ type: "CLICK_OUTSIDE", "collectionName": collectionName});
  }
  onSearchTextHandler (event) {
    this.store.dispatch({ type: "SEARCH_FILTER_HANDLER", "value": event.target.value});
  }
}

function FormPex (props) {

  function translate (name) {
    return translateService.getLabelsTranslated(props.state.labels, "arrangeRoomViewingForm.", name, props.defaultLabels);
  }

  function handleSubmit (event) {
    event.preventDefault();
    if (!props.state.canSubmit) {
      props.submittedWithErrors();
      return;
    }

    props.waiting();
    let date_to_submit = moment.unix(props.state.values.date); //TODO: inline

    let payload = {
      activity: {
        type: "ARRANGE",
        room: props.state.data.room.lookupValue,
        location: props.state.data.room.location.lookupValue,
        text: props.state.data.room.building.lookupValue + " - " + props.state.values.comments,
        proposedDate: date_to_submit.format("YYYY-MM-DD") + "T" + props.state.values.time + ":00",
      },
      "user": {
        "username": props.state.values.emailAddress,
        "firstName": props.state.values.firstName,
        "lastName": props.state.values.lastName,
        "mobileCountry": props.state.values.mobileCountry,
        "mobileNumber": "+" + props.state.values.mobileCountry + props.state.values.mobileNumber,
      },
    };
    if (props.state.values.bookingType && props.state.values.bookingType !== "-") payload.bookingType = props.state.values.bookingType;
    if (props.state.values.uniCollegeSchoolAttending) payload.user.university = props.state.values.uniCollegeSchoolAttending;
    props.state.values.noSpam === "false" ? payload.user.noSpam = true : payload.user.noSpam = false;
    props.submit(payload);
  }

  let showUniversitiesDropdown = false;
  let universitiesMessage = "";

  if (props.showUniversities === "true") {
    if (props.state.getFormCollectionsResponse !== undefined && props.state.getFormCollectionsResponse.universities && props.state.getFormCollectionsResponse.universities.length >= 1) {
      var universities = [];
      showUniversitiesDropdown = true;
      for (let university of props.state.getFormCollectionsResponse.universities) universities.push(<option key={university.id} value={university.lookupValue}>{university.displayValue}</option>);
    } else {
      universitiesMessage = translate("universitiesMessage");
    }
  }

  let bookingTypeOptions = [];
  if (props.state.marketingLocationsData && props.state.marketingLocationsData.locations) {
    for (let location of props.state.marketingLocationsData.locations.filter(function (el) {return el.lookupValue === props.state.data.room.location.lookupValue;})) {
      if (location.bookingTypes && location.bookingTypes.length > 0) for (let bookType of location.bookingTypes) bookingTypeOptions.push(<option key={bookType.lookupValue} value={bookType.lookupValue}>{bookType.displayValue}</option>);
    }
  }

  const panel1header = (<div className="arrange-room-viewing-form-time-and-place-title text-center"><h3><strong>{translate("title")}</strong></h3></div>);
  const panel2header = (<div className="arrange-room-viewing-form-details-title text-center"><h3><strong>{translate("enterDetailsTitle")}</strong></h3></div>);
  const footer = (<ButtonGroup vertical block>
    <Button type="submit" className="btn-submit-arrange-viewing-form">{translate("sendButton") + " "}<span className="fa fa-send" /></Button>
    <SubmitError submitError={props.state.submitError}/>
  </ButtonGroup>);
  return <Form horizontal onSubmit={handleSubmit.bind(this)} className={(props.state.waiting) ? "divFrozen" : ""} >
    <Panel header = {panel1header} >
      <div className="arrange-room-viewing-form-errors">{props.state.error}</div>
      <PexText fieldKey="property" className="arrange-room-viewing-form-property" iconClassName="fa fa-list" label={translate("propertyLabel")} placeholder={props.state.data.room.location.displayValue + " (" + props.state.data.room.building.displayValue + ")"} disabled {...props.generalProps } />
      <PexText fieldKey="roomType" className = "arrange-room-viewing-form-room-type" iconClassName="fa fa-list" label={translate("roomTypeLabel")} placeholder={props.state.data.room.roomType.displayValue} disabled {...props.generalProps} />
      {props.state.addBookingType === "false" || props.state.addBookingType === false ? null : <PexDropDown fieldKey="bookingType" className = "arrange-room-viewing-form-booking-type" iconClassName="fa fa-list" label={translate("bookingTypeLabel")} {...props.generalProps } >{bookingTypeOptions}</PexDropDown>}
      <PexDatePicker fieldKey="date" className = "arrange-room-viewing-form-date" iconClassName="fa fa-calendar" label={translate("dateLabel")} { ...props.generalProps } excludeDates={props.state.excludeDates} maxDate={props.state.maxDate} getCurrentMoment={getCurrentMoment()}/>
      <PexDropDown fieldKey="time" className = "arrange-room-viewing-form-time" iconClassName="fa fa-clock-o" label={translate("hourSlotLabel")} placeholder={translate(props.state.hoursPlaceHolder)} {...props.generalProps } >{props.state.hours}</PexDropDown>
    </Panel>
    <Panel header = {panel2header} footer = {footer} >
      <PexText fieldKey="firstName" className = "arrange-room-viewing-form-first-name" iconClassName="fa fa-user" label={translate("firstNameLabel")} {...props.generalProps } />
      <PexText fieldKey="lastName" className = "arrange-room-viewing-form-last-name" iconClassName="fa fa-user" label={translate("lastNameLabel")} {...props.generalProps } />
      <PexCountryPhone fieldKey="countryPhone"
        countryKey="mobileCountry"
        phoneKey="mobileNumber"
        className = "arrange-room-viewing-form-phone-number"
        iconClassName="fa fa-phone"
        translate={translate.bind(this)}
        {...props.generalProps }
        label={translate("phoneLabel")}
        countriesData = {props.state.countries}
        countrySelected = {props.state.countrySelected}
        openCloseDropdown = {props.openCloseDropdown}
        clickOutside = {props.clickOutside}
        onSearchTextHandler = {props.onSearchTextHandler}
        isOpen = {props.state.isOpen}
        textValue = {translate("selectCountryLabel")}
        countryCollectionName = "country-prefix"
        textFilterValue = {props.state.textFilterValue}
        selectCountry = {props.selectCountry}/>
      <PexText fieldKey="emailAddress" className = "arrange-room-viewing-form-email-address"iconClassName="fa fa-envelope-o" label={translate("emailLabel")} {...props.generalProps } />
      {showUniversitiesDropdown ?
        <PexDropDown fieldKey="uniCollegeSchoolAttending" className = "arrange-room-viewing-form-uni-college-school-attending" iconClassName="fa fa-university" label={translate("universityLabel")} placeholder={translate("universityPlaceholder")} {...props.generalProps } >{universities}</PexDropDown>
        :
        universitiesMessage
      }
      <PexTextArea fieldKey="comments" className = "arrange-room-viewing-form-comments" iconClassName="fa fa-pencil" label={translate("commentsLabel")} {...props.generalProps } placeholder={translate("commentsPlaceholder")} />

      <PexCheckbox fieldKey="noSpam" className="pex-register-no-spam" iconClassName="fa fa-lock" label={translate("noSpamLabel")} {...props.booleanProps} hideIcon={true} hideLabel={true} />

    </Panel>
  </Form>;
}

function SubmitError (props) {
  let error = props.submitError;
  if (error) return <div className="arrange-room-viewing-form-submit-error">{error}</div>;
  return null;
}
