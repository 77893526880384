import React from 'react';
import { createStore } from 'redux';
import {ShowError} from "./show-error.jsx";
import Button from 'react-bootstrap/lib/Button';
import Form from 'react-bootstrap/lib/Form';
import utils from "../../../lib/utils";
import formService from "../services/form-service";
import { PexText } from "../form-components/pex-text.jsx";
import { PexCheckbox } from "../form-components/pex-checkbox.jsx";
import { PexPassword } from "../form-components/pex-password.jsx";

export function LoginForm (props) {

  function getFormControlProps () {
    return {
      values: props.state.loginForm.values || {},
      errors: props.state.loginForm.fieldsWithErrors ? props.state.loginForm.fieldsWithErrors : {},
      updateField: props.updateFieldFromEvent,
      submitted: props.state.loginForm.submitted,
    };
  }

  return <div className={"modal-body"}>
    <div className={"login-content"}>
      <h4 className={"login-title-1"} dangerouslySetInnerHTML={{__html: utils.sanitize(props.state.labels["graingerBookingJourney.login.loginTitle"])}}></h4>
      <Form className="login-form">
        <PexText fieldKey="username" className = "login-username" hideIcon={true} label={props.state.labels["graingerBookingJourney.login.usernameLabel"]}
          {...getFormControlProps() } hideInputAndCol={true}/>
        <PexPassword fieldKey="password" className = "login-password" hideIcon={true} label={props.state.labels["graingerBookingJourney.login.passwordLabel"]}
          {...getFormControlProps() } hideInputAndCol={true}/>
        {props.state.loginForm.APIerror ? <p className="pex-login-api-error"> {props.state.loginForm.APIerror}</p> : null}
      </Form>
    </div>
  </div>;
}
