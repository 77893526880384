import React from 'react';
import Panel from 'react-bootstrap/lib/Panel';
import Col from 'react-bootstrap/lib/Col';
import Row from 'react-bootstrap/lib/Row';


export function InfoBar (props) {
  return (
    <Row className="info-bar">
      <Col xs={12}>
        <Panel>
          <Col sm={2}>
            <div className="info-bar-property-description">{props.infoBarData.description}</div>
            <div className="info-bar-tenancy-number small">{"Tenancy number: " + props.infoBarData.tenancyId}</div>
          </Col>
          <Col sm={2}>
            <div className="info-bar-rent">{props.infoBarData.rent}</div>
            <div className="info-bar-frequency small">{props.infoBarData.frequency}</div>
          </Col>
          <Col sm={2}>
            <div className="info-bar-first-payment">{props.infoBarData.firstDD}</div>
            <div className="small">First payment</div>
          </Col>
          <Col sm={2}>
            <div className="info-bar-last-payment">{props.infoBarData.lastDD}</div>
            <div className="small">Last payment</div>
          </Col>
          <Col sm={2}>
            <div className="info-bar-payer-name">{props.infoBarData.payerName ? props.infoBarData.payerName : ""}</div>
            <div className="small">{props.infoBarData.payerName ? "Direct Debit Payer" : ""}</div>
          </Col>
          <Col sm={2}>
            <div className="info-bar-type-payment-name">{props.infoBarData.paymentTypeName ? props.infoBarData.paymentTypeName : ""}</div>
            <div className="info-bar-type-payment">{props.infoBarData.paymentType ? props.infoBarData.paymentType : ""}</div>
          </Col>
        </Panel>
      </Col>
    </Row>
  );

}
