import React from 'react';
import Panel from 'react-bootstrap/lib/Panel';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import { PexPagination } from "./pex-pagination.jsx";
import { PexRoom } from "./pex-room.jsx";
import translateService from '../../services/translate-service.js';
import TagManager from 'react-gtm-module';

export default class MyShortlist extends React.Component {

  render () {
    let self = this;
    function translate (name) {
      return translateService.getLabelsTranslated(self.props.labels, "searchApplet.myShortlist.", name, self.props.defaultLabels);
    }
    if (this.props.userLogged === false) {
      return <Col xs={12}>
        <div className="my-shortlist my-shortlist-container">
          <h1>{translate("title")}</h1>
          <Panel>
            <h2>Error 3242452342 User not logged in has not access to this view.</h2>
          </Panel>
        </div></Col>;
    }
    if (!this.props.shortlistData || !this.props.shortlistData.length) {
      return <Col xs={12}>
        <div className="my-shortlist my-shortlist-container">
          <h1>{translate("title")}</h1>
          <Panel>
            <h2>{translate("emptyLabel")}</h2>
          </Panel>
        </div></Col>;
    };

    let activePage = this.props.activePageShortlist ? Number(this.props.activePageShortlist) : 1;

    function getData () {
      if (self.props.pagination && Number(self.props.pagination) > 0) return self.props.shortlistData.filter(function (room) { return room.page === Number(activePage); });
      return self.props.shortlistData;
    }

    function getPagination () {
      if (self.props.pagination && Number.isInteger(Number(self.props.pagination)) && Number(self.props.pagination) > 0) {
        return <PexPagination className="pex-pagination" pagination={self.props.pagination} dataLength={self.props.shortlistData.length} activePage={activePage} selectPaginationPage={self.props.selectPaginationPage} />;
      }
      return null;
    }

    function getRooms () {
      let data = getData();
      if (data.length > 0 ) return data.map(function (pexRoom) {
        return <PexRoom searchService={self.props.searchService} key={pexRoom.room.lookupValue} {...pexRoom} linkTemplates={self.props.linkTemplates} bookNow={self.props.bookNow}
          addShortlist={self.props.addShortlist} removeShortlist={self.props.removeShortlist} hideButtons = {{"arrange": true, "enquire": true, "details": false}} searchSimilar={self.props.searchSimilar} shortlist={true}
          handleBookNow={self.props.handleBookNow} goToRoomDetails={self.props.goToRoomDetails} showbookingSummary={self.props.showbookingSummary}
          defaultLabels={self.props.defaultLabels} labels={self.props.labels} />;
      });
      return <div className="pex-no-rooms">{EMPTY_MSG}</div>;
    }

    return <Row className="my-shortlist my-shortlist-container">
      {self.props.error}
      <Panel header={<div className="results-title"><h4>{translate("title")}</h4></div>}>
        { getRooms () }
      </Panel>
      { getPagination () }
    </Row>;
  }
}
