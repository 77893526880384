import React from 'react';
import Col from 'react-bootstrap/lib/Col';

export default class InfoDirecDebit extends React.Component {
  render () {
    return (
      <Col sm={6} className="right-side-info border-left">
        <p>Please check the value of the Direct Debit</p>
        <div className="info-dd-rent">
          <div className="info-dd-rent-label">Amount</div>
          <div><h4>{this.props.rent}</h4></div>
        </div>
        <div className="info-dd-frequency">
          <div className="info-dd-frequency-label">Frequency</div>
          <div><h4>{this.props.frequency}</h4></div>
        </div>
        <div className="info-dd-firstDD">
          <div className="info-dd-firstDD-label">Date of the first DD</div>
          <div><h4>{this.props.firstDD}</h4></div>
        </div>
        <div className="info-dd-lastDD">
          <div className="info-dd-lastDD-label">Date of the last DD</div>
          <div><h4>{this.props.lastDD}</h4></div>
        </div>
      </Col>
    );
  }
}
