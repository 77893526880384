import React from 'react';
import Panel from 'react-bootstrap/lib/Panel';
import Col from 'react-bootstrap/lib/Col';
import { PexPagination } from "./pex-pagination.jsx";
import { PexRoom } from "./pex-room.jsx";
import FormControl from 'react-bootstrap/lib/FormControl';
import utils from '../../../lib/utils.js';
import translateService from '../../services/translate-service.js';

export default class SearchResults extends React.Component {

  render () {
    let self = this;
    function translate (name) {
      return translateService.getLabelsTranslated(self.props.labels, "searchApplet.searchResults.", name, self.props.defaultLabels);
    }
    //TODO: add the class search-results to have the same format for every message
    //change the test you should not see "search-results" by you should see "search-results alert alert-success"
    let noResultsYetMessage = <div className="alert alert-success">{translate("noResultsLabel")}</div>;
    if (this.props.webContents && this.props.webContents["search_result.no_search_done_yet"]) {
      noResultsYetMessage = <div className="alert alert-success" dangerouslySetInnerHTML={{__html: utils.sanitize(this.props.webContents["search_result.no_search_done_yet"])}}></div>;
    }

    let noResultsMatched = <div className="search-results alert alert-success">{translate("noMatchesLabel")}</div>;
    if (this.props.webContents && this.props.webContents["search_result.no_search_results"]) {
      noResultsMatched = <div className="search-results alert alert-success" dangerouslySetInnerHTML={{__html: utils.sanitize(this.props.webContents["search_result.no_search_results"])}}></div>;
    }

    if (!this.props.searchData) return <Col xs={12} md={10}><div className="alert alert-success">{noResultsYetMessage}</div></Col>;
    if (!this.props.searchData.length) return <Col xs={12} md={10}>{noResultsMatched}</Col>;

    let activePage = this.props.activePage ? Number(this.props.activePage) : 1;

    function getData () {
      if (self.props.pagination && Number(self.props.pagination) > 0) return self.props.searchData.filter(function (room) { return room.page === Number(activePage); });
      return self.props.searchData;
    }

    function getPagination () {
      if (self.props.pagination && Number.isInteger(Number(self.props.pagination)) && Number(self.props.pagination) > 0) {
        return <PexPagination className="pex-pagination" pagination={self.props.pagination} dataLength={self.props.searchData.length} activePage={activePage} selectPaginationPage={self.props.selectPaginationPage} />;
      }
      return null;
    }

    function getRooms () {
      let data = getData();
      if (data.length > 0 ) return data.map(function (pexRoom) {
        return <PexRoom key={pexRoom.offers[0].lookupValue} showbookingSummary={self.props.showbookingSummary} searchService={self.props.searchService} {...pexRoom} linkTemplates={self.props.linkTemplates} bookNow={self.props.bookNow} addShortlist={self.props.addShortlist} removeShortlist={self.props.removeShortlist} shortlist={false}
          handleBookNow={self.props.handleBookNow} goToRoomDetails={self.props.goToRoomDetails}
          labels={self.props.labels} defaultLabels={self.props.defaultLabels}/>;
      });
      return <div className="pex-no-rooms">{translate("noRoomsLabel")}</div>;
    }

    return <Col xs={12} md={10} className="col-no-padding">
      <div className="search-results">
        <Panel header = {searchResultHeader(this.props)}>
          { getRooms () }
        </Panel>
        { getPagination () }
      </div>
    </Col>;
  }
}

function searchResultHeader (props) {

  function translate (name) {
    return translateService.getLabelsTranslated(props.labels, "searchApplet.searchResults.", name, props.defaultLabels);
  }

  function sortPicker () {
    let sortSelected = props.sortSelected ? props.sortSelected : "price-asc";
    let orderOptions = [];
    if (props.strictGender) {
      sortSelected = props.sortSelected ? props.sortSelected : "gender-price-sorting";
      orderOptions.push(<option key="gender-price-sorting" value="gender-price-sorting">{translate("genderSortingLabel")}</option>);
    }

    orderOptions.push(<option key="price-asc" value="price-asc">{translate("priceLowestToHighestLabel")}</option>);
    orderOptions.push(<option key="price-desc" value="price-desc">{translate("priceHighestToLowestLabel")}</option>);
    if (!props.criteria.startDate) orderOptions.push(<option key="date-asc" value="date-asc">{translate("dateSoonestToLatestLabel")}</option>);
    if (!props.criteria.startDate) orderOptions.push(<option key="date-desc" value="date-desc">{translate("dateLatestToSoonestLabel")}</option>);

    function handleChange (event) {
      sortSelected = event.target.value;
      props.selectSearch(sortSelected);
    }
    return <FormControl className="sort-picker" value={sortSelected} componentClass="select" onChange={handleChange.bind()}>
      {orderOptions}
    </FormControl>;
  }

  return <div className="">
    <div className="results-title"></div>
    <div className="results-sorting text-right form-inline">
      <label className="title">{translate("sortByLabel")}</label>
      {sortPicker()}
    </div>
    <div className="results-number col-xs-2 pull-right text-right form-inline">
    </div>
  </div>;
}
