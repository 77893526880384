"use strict";
import _ from 'underscore';
import moment from 'moment';
import roomService from './room-service.js';
let utils = require("../../lib/utils");
let intl;
let wrapIntl;

function setIntl (pIntl) {
  intl = pIntl;
  wrapIntl = require("../../lib/wrapIntl")(intl);
}

function offersToUnits (units, offers, searchSettings, formSettings, shortlist) {
  /***
  We want this structure: units: [{ room:{...}, offers: [{...}] }, {...more units...}]
  LOGIC: loop the units, and check:
  -if exist a unit with a room with same categories and details
  ---FALSE: we add a new unit
  ---TRUE: we loop the unit.offers to check if the unit has some similar offer
  --------FALSE: we add the offer to the unit
  --------TRUE: we don't add this offer to the unit
  ***/
  let strictGenderOn = false;
  let shortlist_keys = getKeysOfOffersInShortlist(shortlist);
  let PRS_GROUP = false;
  if (formSettings && formSettings["booking.strictGenders"]) strictGenderOn = true;
  if (searchSettings && searchSettings["pmx.defaults.progressiveSearch.groupingStrategy"] && searchSettings["pmx.defaults.progressiveSearch.groupingStrategy"] === "NONE") PRS_GROUP = true;

  if (!PRS_GROUP) {
    //Format offers. Group rooms and offers inside units
    for (let offer of offers) {
      let similarRoom = findSimilarRoom(offer);
      if (!similarRoom) units.push( createUnit( JSON.parse(JSON.stringify(offer.room)), JSON.parse(JSON.stringify(offer))));
      else {
        checkAddOfferToUnit(similarRoom, offer);
      }
    }
  } else {
    for (let offer of offers) {
      let unitByRoom = findUnitByRoomLookupValue(offer);
      if (!unitByRoom) units.push( createUnit( JSON.parse(JSON.stringify(offer.room)), JSON.parse(JSON.stringify(offer))));
      else {
        let newOffer = JSON.parse(JSON.stringify(offer));
        newOffer.roomLookupValue = newOffer.room.lookupValue;
        delete newOffer.room;
        unitByRoom.offers.push(newOffer);
      }
    }

  }

  return units;

  function findSimilarRoom (offer) {
    // console.log("OFFER:" + offer.lookupValue)
    if (units && units.length > 0) for (let unit of units) {
      //if both have details sort to compare(it is an array so the _.isEqual checks the exact object in the same order)
      let sortableUnitDetails = JSON.parse(JSON.stringify(unit.room.details));
      let sortableOfferDetails = JSON.parse(JSON.stringify(offer.room.details));
      if (sortableUnitDetails && sortableOfferDetails) {
        sortableUnitDetails = _(sortableUnitDetails).
          chain().
          sortBy(function (detail) { return detail.value ? detail.value : ""; }).
          sortBy(function (detail) { return detail.type; }).value();

        sortableOfferDetails = _(sortableOfferDetails).
          chain().
          sortBy(function (detail) { return detail.value ? detail.value : ""; }).
          sortBy(function (detail) { return detail.type; }).value();

      }
      // console.log("strictGenderOn:"+strictGenderOn)
      // console.log("Are the categories equals?")
      // console.log(_.isEqual(unit.room.categories, offer.room.categories ))
      // console.log("Are the details equals?")
      // console.log(_.isEqual(sortableUnitDetails, sortableOfferDetails))
      // console.log("These are the details:")
      // console.log(sortableUnitDetails)
      // console.log(sortableOfferDetails)
      // console.log("Are the gender equals?")
      // console.log(_.isEqual(unit.gender, offer.gender))
      // console.log(unit.gender)
      // console.log(offer.gender)
      // console.log("-----------------------------------------------------")
      if (strictGenderOn) {
        if (_.isEqual(unit.room.categories, offer.room.categories ) && _.isEqual(sortableUnitDetails, sortableOfferDetails) && _.isEqual(unit.gender, offer.gender)) {
          return unit;
        }
      } else {
        if (_.isEqual(unit.room.categories, offer.room.categories ) && _.isEqual(sortableUnitDetails, sortableOfferDetails) ) {
          return unit;
        }
      }
    }
    // console.log("NO SIMILAR FOUND")
    return null;
  }

  function createUnit (room, offer) {
    let newUnit = {};
    newUnit.room = room;
    newUnit.room.minPrice = offer.price;
    newUnit.hash = utils.getHash(newUnit.room, PRS_GROUP);
    newUnit.offers = [];
    newUnit.similarRoomsCounter = {[newUnit.room.lookupValue]: true};
    if (strictGenderOn) {
      newUnit.gender = offer.gender ? offer.gender : null;
    }
    let newOffer = JSON.parse(JSON.stringify(offer));
    newOffer.roomLookupValue = newOffer.room.lookupValue;
    if (shortlist_keys && shortlist_keys[newOffer.lookupValue]) newOffer.inShortlist = true;
    else newOffer.inShortlist = false;
    delete newOffer.room;
    newUnit.offers.push(newOffer);
    return newUnit;
  }

  function checkAddOfferToUnit (similarRoom, offer) {
    similarRoom.similarRoomsCounter[offer.room.lookupValue] = true;
    if (strictGenderOn) {
      if (similarRoom.offers.length > 0) {
        for (let myOffer of similarRoom.offers) {
          if (_.isEqual(myOffer.bookingType, offer.bookingType ) &&
            _.isEqual(myOffer.availability, offer.availability) &&
            _.isEqual(myOffer.price, offer.price) &&
            _.isEqual(myOffer.gender, offer.gender) &&
            myOffer.start === offer.start &&
            myOffer.end === offer.end &&
            myOffer.startUntil === offer.startUntil)
            return;
        }
      }
    } else {
      if (similarRoom.offers.length > 0) {
        for (let myOffer of similarRoom.offers) {
          if (_.isEqual(myOffer.bookingType, offer.bookingType ) &&
            _.isEqual(myOffer.availability, offer.availability) &&
            _.isEqual(myOffer.price, offer.price) &&
            myOffer.start === offer.start &&
            myOffer.end === offer.end &&
            myOffer.startUntil === offer.startUntil)
            return;
        }
      }
    }

    let newOffer = JSON.parse(JSON.stringify(offer));
    newOffer.roomLookupValue = newOffer.room.lookupValue;
    if (shortlist_keys && shortlist_keys[newOffer.lookupValue]) newOffer.inShortlist = true;
    else newOffer.inShortlist = false;
    delete newOffer.room;
    similarRoom.offers.push(newOffer);
    return;
  }

  function findUnitByRoomLookupValue (offer) {
    let unitByRoom = units.filter(function (unit) {
      return unit.room.lookupValue === offer.room.lookupValue;
    });
    if (unitByRoom.length) return unitByRoom[0];
    else return null;
  }

}
//TODO: have only one element with labels and default labels, when we do that refactor pass only that object here like in generic-widget
function sortResults (units, sortType, pagination, searchSettings, formSettings, linkTemplates, labels) {

  //Format offers. We do after create the array with rooms and offers inside
  units = formatOffersForRanking ();

  if (formSettings && formSettings["booking.strictGenders"]) {
    if (!sortType || (sortType && sortType.startsWith("gender"))) units = sortByGender ();
    else if (sortType && sortType.startsWith("price")) units = sortByPrice ();
    else units = sortByDate ();
  } else {
    if (!sortType || (sortType && sortType.startsWith("price"))) units = sortByPrice ();
    else units = sortByDate ();
  }

  //Set position after sort
  let position = 0;
  for (let unit of units) {
    unit.room.position = position;
    //TODO: try to use roomData instead of room whre we are using it
    unit.roomData = {};
    // console.log(unit);
    roomService.processRoomInfo(unit.roomData, unit.room, unit.nearestOffer, unit.offers, linkTemplates, unit.hash, labels);
    position++;
  }
  //set page after sorting, it is important to maintain the order
  if (pagination) units = setPagination ();
  // console.log("ORDER BY: " + sortType);
  return units;

  function formatOffersForRanking () {
    return units.map(function (unit) {
      unit.offers = unit.offers.map(function (offer) { return processDataOfOffer(unit, offer); });
      return unit;
    });

    function processDataOfOffer (unit, offer) {
      offer.startStr = wrapIntl.formatDate(moment(offer.start));
      if (offer.startUntil) offer.startUntilStr = wrapIntl.formatDate(moment(offer.startUntil));
      offer.end ? offer.endStr = wrapIntl.formatDate(moment(offer.end)) : "NA";
      if ((!unit.room.minPrice && unit.room.minPrice.number > 0 ) || (1.0 * offer.price.number <= 1.0 * unit.room.minPrice.number && offer.price.number > 0)) unit.room.minPrice = offer.price;
      if (searchSettings && searchSettings["pmx.defaults.progressiveSearch.sortingStrategy"] && offer.start && offer.end) offer.length = moment(offer.end).diff(moment(offer.start), 'days');
      if (sortType) {
        if (sortType === "date-asc") {
          if (!unit.nearestOffer) unit.nearestOffer = offer; else if (unit.nearestOffer.start > offer.start) unit.nearestOffer = offer;
        } else if ("date-desc") {
          if (!unit.nearestOffer) unit.nearestOffer = offer; else if (unit.nearestOffer.start < offer.start) unit.nearestOffer = offer;
        }
      }
      return offer;
    }

  }

  function sortOffersByStrategy (sortingStrategy, unit) {
    if (sortingStrategy === "LENGTH-DESC_THEN_START") {
      //First we sort by length, offers with the same length will be sorted by lower start date
      //I substract 100000 to the length to be able to compare length and lower date with the same condition
      unit.offers = unit.offers.sort(function (a, b) {
        return ((a.length - 100000) + "-" + moment(a.start).valueOf()).localeCompare((b.length - 100000) + "-" + moment(b.start).valueOf());
      });
    }
    else if (sortingStrategy === "START_THEN_LENGTH") {
      //First we sort by start date, offers with the same start date will be sorted by length
      unit.offers = unit.offers.sort(function (a, b) {
        return a.start + a.length > b.start + b.length;
      });
    };
  }

  function sortByGender () {
    units = units.sort(function (a, b) { return a.room.minPrice.number - b.room.minPrice.number; });
    //sort by price
    for (let unit of units) {
      if (searchSettings && searchSettings["pmx.defaults.progressiveSearch.sortingStrategy"]) sortOffersByStrategy(searchSettings["pmx.defaults.progressiveSearch.sortingStrategy"], unit);
      else unit.offers = unit.offers.sort(function (a, b) { return a.price.number - b.price.number; });
    }
    //show first units with gender and after without gender
    return units.filter(function (unit) { return unit.gender; }).concat(units.filter (function (unit) { return !unit.gender;}));
  }

  function sortByPrice () {
    if (!sortType || sortType === "price-asc") {
      units = units.sort(function (a, b) { return a.room.minPrice.number - b.room.minPrice.number; });
      for (let unit of units) {
        if (searchSettings && searchSettings["pmx.defaults.progressiveSearch.sortingStrategy"]) sortOffersByStrategy(searchSettings["pmx.defaults.progressiveSearch.sortingStrategy"], unit);
        else unit.offers = unit.offers.sort(function (a, b) { return a.price.number - b.price.number; });
      }
    } else {
      units = units.sort(function (a, b) { return b.room.minPrice.number - a.room.minPrice.number; });
      for (let unit of units) {
        if (searchSettings && searchSettings["pmx.defaults.progressiveSearch.sortingStrategy"]) sortOffersByStrategy(searchSettings["pmx.defaults.progressiveSearch.sortingStrategy"], unit);
        else unit.offers = unit.offers.sort(function (a, b) { return b.price.number - a.price.number; });
      }
    }
    return units;
  }

  function sortByDate () {
    if (sortType === "date-asc") {
      units = units.sort(function (a, b) { return a.nearestOffer.start.localeCompare(b.nearestOffer.start); });
      for (let unit of units) {
        if (searchSettings && searchSettings["pmx.defaults.progressiveSearch.sortingStrategy"]) sortOffersByStrategy(searchSettings["pmx.defaults.progressiveSearch.sortingStrategy"], unit);
        else unit.offers = unit.offers.sort(function (a, b) { return a.start.localeCompare(b.start); });
      }
    } else {
      units = units.sort(function (a, b) { return b.nearestOffer.start.localeCompare(a.nearestOffer.start); });
      for (let unit of units) {
        if (searchSettings && searchSettings["pmx.defaults.progressiveSearch.sortingStrategy"]) sortOffersByStrategy(searchSettings["pmx.defaults.progressiveSearch.sortingStrategy"], unit);
        else unit.offers = unit.offers.sort(function (a, b) { return b.start.localeCompare(a.start); });
      }
    }
    return units;
  }

  function setPagination () {
    let page = 1;
    let pageLimit = 0;
    return units = units.map(function (unit) {
      let newUnit = JSON.parse(JSON.stringify(unit));
      newUnit.page = page;
      pageLimit++;
      if (pageLimit === Number(pagination) ) {
        pageLimit = 0;
        page++;
      }
      return newUnit;
    });
  }

}

function parseWebContents (response) {
  let rtn = {};
  if (response.webContent && response.webContent.length > 0) {
    for (let webContent of response.webContent) {
      rtn[webContent.name] = webContent.content;
    }
  }
  return rtn;
}

function getError (message, messageType, webContents, labels, webcontentKey, labelKey) {
  let rtn = message;
  if (messageType && labels[labelKey + messageType]) return labels[labelKey + messageType];
  else if (messageType && webContents && webContents[webcontentKey + messageType]) return webContents[webcontentKey + messageType];
  return rtn;
}

function getKeysOfOffersInShortlist (shortlist) {
  if (shortlist && shortlist.length > 0) {
    let shortlist_keys = {};
    for (let offer_shortlist of shortlist) shortlist_keys[offer_shortlist.lookupValue] = offer_shortlist.lookupValue;
    return shortlist_keys;
  } else {
    return null;
  }
}

module.exports = {
  categories: function (categories, initialData) {
    if (!categories) throw "No categories 2";
    return initialData.categories.map(function (category) {
      if (category.lookupValue === "PARENT-AREA" && categories["AREA"]) {
        let myTags = [];
        for (let area of categories["AREA"]) myTags.push({"lookupValue": area});
        return { lookupValue: "AREA", tags: myTags };
      } else {
        return {
          lookupValue: category.lookupValue,
          tags: category.tags.filter(function (tag) {
            return categories[category.lookupValue] && categories[category.lookupValue].indexOf(tag.lookupValue) !== -1;
          }).map(function (tag) {
            ///TODO: remove this and update tests - it should work with these properties left in
            let new_tag = JSON.parse(JSON.stringify(tag));
            delete new_tag.count;
            delete new_tag.displayValue;
            return new_tag;
          }).sort(function (a, b) { return b.lookupValue < a.lookupValue; }),
        };
      }
    }).filter(function (category) {
      return category.tags && category.tags.length;
    }).sort(function (a, b) { return b.lookupValue < a.lookupValue; });
  },
  parseCollectionToArray: function (criteria, collectionName) {
    let arr = [];
    for (let opt of Object.keys(criteria[collectionName])) arr.push({ "lookupValue": criteria.bookingTypes[opt]});
    return arr;
  },
  prepareSearchResult: function (offers, pagination, state, searchSettings, formSettings) {
    if (!offers) return null;
    let units = [];
    units = offersToUnits (units, offers, searchSettings, formSettings, state.shortlist);
    units = sortResults (units, state.sortSelected, pagination, searchSettings, formSettings, state.links, state.labels);
    return units;
  },
  parseCriteriaToPayload: function (criteria, initialData, currentPath) {
    let payload = {};
    if (criteria.categories && Object.keys(criteria.categories).length > 0 ) payload = {"categories": this.categories(criteria.categories, initialData)};
    if (criteria.budget && criteria.budget !== '-') payload["budget"] = 1.0 * criteria.budget;
    if (criteria.minBudget && criteria.minBudget !== '-') payload["minBudget"] = 1.0 * criteria.minBudget;
    if (criteria.bookingTypes && Object.keys(criteria.bookingTypes).length > 0) payload["bookingTypes"] = this.parseCollectionToArray(criteria, "bookingTypes");
    if (criteria.gender && criteria.gender !== '-') payload["genders"] = [{ "lookupValue": criteria.gender}];
    if (criteria.startDate) payload["startDate"] = criteria.startDate;
    if (currentPath && currentPath.component === "roomDetails" && currentPath.room) payload.unitMda = currentPath.room;
    return payload;
  },
  createSearchCriteria: function (component, criteria, initialData) {
    let searchCriteria = component + "searchcriteria=";
    if (Object.keys(criteria.categories).length > 0 ) for (let cat of this.categories(criteria.categories, initialData)) for (let tag of cat.tags) searchCriteria += cat.lookupValue + ":" + tag.lookupValue + ";";
    if (criteria.budget && criteria.budget !== '-') searchCriteria += "BUDGET:" + (1.0 * criteria.budget) + ";";
    if (criteria.minBudget && criteria.minBudget !== '-') searchCriteria += "MINBUDGET:" + (1.0 * criteria.minBudget) + ";";
    if (criteria.startDate) searchCriteria += "SEARCH_DATE:" + criteria.startDate + ";";
    if (criteria.gender && criteria.gender !== '-') searchCriteria += "GENDER:" + criteria.gender + ";";
    if (criteria.bookingTypes && Object.keys(criteria.bookingTypes).length > 0) for (let type of this.parseCollectionToArray(criteria, "bookingTypes")) searchCriteria += "BOOKINGTYPES:" + type.lookupValue + ";";
    //When there is no option selected we put in the url searchcriteria=ALL, becaise:
    //- if we put nothing is not valid, because when user refreshs the page/navigate back we have to know that a search went done
    //so if we have  searchcriteria=ALL a search without parameters will be done, if we have not searchCriteria the search doesn't done so we do nothing
    if (searchCriteria === component + "searchcriteria=") searchCriteria += "ALL";

    return searchCriteria;
  },
  checkBudgetInitialData: function (budget, initialData) {
    //we show the priceRange from low to high priceRange value, showing prices multipe of 100 so we adapt the budget to the low, high or range.
    if (!Number.isInteger(Number(budget))) return null;
    if (budget < initialData.priceRange.low) return getMultipleOf(100, initialData.priceRange.low);
    if (budget > initialData.priceRange.high) return getMultipleOf(100, initialData.priceRange.high);
    if (budget % 100 !== 0) return getMultipleOf(100, budget);
    return budget;

    function getMultipleOf (multiple, number) {
      number = Math.floor(number);
      while (number % multiple !== 0) number++;
      return 1 * number;
    }
  },
  checkMinBudgetInitialData: function (minBudget, initialData) {
    //we show the priceRange from low to high priceRange value, showing prices multipe of 100 so we adapt the minBudget to the low, high or range.
    if (!Number.isInteger(Number(minBudget))) return null;
    if (minBudget < initialData.priceRange.low) return getMultipleOf(100, initialData.priceRange.low);
    if (minBudget >= initialData.priceRange.high) return getMultipleOf(100, initialData.priceRange.high);
    if (minBudget % 100 !== 0) return getMultipleOf(100, minBudget);
    return minBudget;

    function getMultipleOf (multiple, number) {
      number = Math.floor(number);
      while (number % multiple !== 0) number--;
      return 1 * number;
    }
  },
  checkPreselectInInitialData: function (initialData, criteriaValue) {
    let catOK = false;
    let lookupValueOK = false;

    for (let category of initialData.categories) {
      if (category.lookupValue === criteriaValue[0]) catOK = true;
      for (let tag of category.tags) {
        if (tag.lookupValue === criteriaValue[1]) lookupValueOK = true;
        if (catOK && lookupValueOK) return true;
      }
      catOK = false;
      lookupValueOK = false;
    }
    return false;
  },
  checkGenderInitialData: function (initialData, criteriaValue) {
    for (let gender of initialData.genders) {
      if (gender.lookupValue === criteriaValue[0]) {
        return gender.lookupValue;
      }
    }
    return null;
  },
  updateCategory: function (criteria, elementLookupValue, arrayName, selected) {
    //TODO: try to use the same method for updateCollection and updateCategory receiving the array directly
    if (selected) {
      //remove from criteria, we will have to manage on diff way values which are not arrays
      let index = criteria.categories[arrayName].indexOf(elementLookupValue);
      if (index > -1) criteria.categories[arrayName].splice(index, 1);
      if (criteria.categories[arrayName].length === 0) delete criteria.categories[arrayName];
    } else {
      if (criteria.categories[arrayName]) {
        criteria.categories[arrayName].push(elementLookupValue);
      } else {
        criteria.categories[arrayName] = [elementLookupValue];
      }
    }
    return criteria;
  },
  updateParentArea: function (criteria, elementLookupValue, selected, initialData) {
    if (selected) {
      for (let areaLookupValue of Object.keys(initialData.parentsOfAreas[elementLookupValue].areas)) {
        let index = criteria.categories["AREA"].indexOf(areaLookupValue);
        if (index > -1) criteria.categories["AREA"].splice(index, 1);
        if (criteria.categories["AREA"].length === 0) delete criteria.categories["AREA"];
      }
    } else {
      //ADD ALL AREA LOOKUPVALUES FOR elementLookupValue
      if (criteria.categories["AREA"]) {
        let areaCriteria = {};
        criteria.categories["AREA"].map(function (area) { areaCriteria[area] = area; });
        for (let areaLookupValue of Object.keys(initialData.parentsOfAreas[elementLookupValue].areas)) areaCriteria[areaLookupValue] = areaLookupValue;
        criteria.categories["AREA"] = [];
        for (let area of Object.keys(areaCriteria)) criteria.categories["AREA"].push(area);
      } else {
        criteria.categories["AREA"] = [];
        for (let areaLookupValue of Object.keys(initialData.parentsOfAreas[elementLookupValue].areas))criteria.categories["AREA"].push(areaLookupValue);
      }
    }
    return criteria;
  },
  updateCollection: function (criteria, elementLookupValue, arrayName, selected) {
    //TODO: try to use the same method for updateCollection and updateCategory receiving the array directly
    if (selected) {
      let index = criteria[arrayName].indexOf(elementLookupValue);
      if (index > -1) criteria[arrayName].splice(index, 1);
      if (criteria[arrayName].length === 0) delete criteria[arrayName];
    } else {
      if (criteria[arrayName]) {
        criteria[arrayName].push(elementLookupValue);
      } else {
        criteria[arrayName] = [elementLookupValue];
      }
    }
    return criteria;
  },
  processSearchFormSelectedValues: function (data, criteria, searchcriteria, getcurrentDate, user, userLogged, initialData, searchByParentAreas, parentAreas) {
    let self = this;
    let rtn = {"criteria": {}, "someCriteria": false};
    let startDate = null;
    let budget = null;
    let minBudget = null;
    let gender = null;
    searchcriteria = searchcriteria.substring(searchcriteria.indexOf("=") + 1, searchcriteria.length);
    if (searchcriteria !== "ALL") {
      for (let row of searchcriteria.split(";").filter(function (el) {return el.length !== 0;})) {
        let criteriaValue = row.split(":");
        if (criteriaValue[0] === "SEARCH_DATE") {
          //We check with format because we are not interested in milliseconds
          if (criteriaValue[1] === "TODAY") {
            //We receive the currentDate from the high component because we cant mock it properly out of there
            startDate = getcurrentDate.unix();
            rtn.someCriteria = true;
          } else if (moment(criteriaValue[1], "YYYY-MM-DD").isValid()) {
            //IMPORTANT: this format is YYYY-MM-DD because we get it from attribute so DON'T change to UK date
            startDate = moment(criteriaValue[1], "YYYY-MM-DD").format("YYYY-MM-DD") >= getcurrentDate.format("YYYY-MM-DD") ? moment(criteriaValue[1], "YYYY-MM-DD") : null;
            if (startDate) {
              startDate = startDate.unix();
              rtn.someCriteria = true;
            }
          }
        } else if (criteriaValue[0] === "BUDGET") {
          budget = self.checkBudgetInitialData(criteriaValue[1], data);
        } else if (criteriaValue[0] === "MINBUDGET") {
          minBudget = self.checkMinBudgetInitialData(criteriaValue[1], data);
        } else if (criteriaValue[0] === "BOOKINGTYPES") {
          criteria = self.updateCollection(criteria, criteriaValue[1], "bookingTypes", false);
        } else if (criteriaValue[0] === "GENDER") {
          gender = self.checkGenderInitialData(data, criteriaValue[1]);
        } else {
          if (searchByParentAreas && criteriaValue[0] === "AREA") {
            let parentLookupValue = null;
            if (parentAreas[criteriaValue[1]]) {
              for (let attribute of parentAreas[criteriaValue[1]].attributes) {
                if (attribute.name === "parent") {
                  parentLookupValue = attribute.bobValue.lookupValue;
                  break;
                }
              }
              if (parentLookupValue) criteria = self.updateParentArea(criteria, parentLookupValue, false, initialData);
            }
          } else {
            if (self.checkPreselectInInitialData(data, criteriaValue)) criteria = self.updateCategory(criteria, criteriaValue[1], criteriaValue[0], false);
          }
        }
      }
    }

    //if strictGenders is true API fails if we don't send a gender.
    //USER LOGGED IN we get the gender from user entity
    //USER NO LOGGED IN we get the gender from URL or we disable all except the gender in the searchForm
    if (userLogged) {
      if (data.settings && data.settings["booking.strictGenders"]) {
        if (user && user.gender) {
          criteria.gender = user.gender.lookupValue;
          rtn.someCriteria = true;
        } else if (gender) {
          criteria.gender = gender;
          rtn.someCriteria = true;
        }
      }
    } else {
      if (gender) {
        criteria.gender = gender;
        rtn.someCriteria = true;
      }
    }


    if (budget) {
      criteria.budget = budget;
      rtn.someCriteria = true;
    }
    if (minBudget) {
      criteria.minBudget = minBudget;
      rtn.someCriteria = true;
    }
    //TODO: Remove the startDate from the state to use the startDate of Criteria, now is hard but when this branch be on master we should to refactor it
    if (startDate) {
      criteria.startDate = moment.unix(startDate).format("YYYY-MM-DD");
      rtn.someCriteria = true;
    }
    if (Object.keys(criteria.categories).length > 0) rtn.someCriteria = true;
    if (Object.keys(criteria.bookingTypes).length > 0) rtn.someCriteria = true;
    rtn.criteria = criteria;
    rtn.searchcriteria = searchcriteria;

    return rtn;
  },
  getKeysOfOffersInShortlist: function (shortlist) {
    return getKeysOfOffersInShortlist (shortlist);
  },
  sortBy: function (units, sortType, pagination, searchSettings, formSettings, linkTemplates, labels) {
    return sortResults (units, sortType, pagination, searchSettings, formSettings, linkTemplates, labels);
  },
  parseWebContents: function (response) {
    return parseWebContents(response);
  },
  processRoomInfo: function (myRoom, room, nearestOffer, offers, linkTemplates, hash, labels) {
    return roomService.processRoomInfo(myRoom, room, nearestOffer, offers, linkTemplates, hash, labels);
  },
  createBookCriteria: function (payload) {
    return roomService.createBookCriteria(payload);
  },
  getCarousel: function (images) {
    return roomService.getCarousel (images);
  },
  setIntl: function (pIntl) {
    return setIntl(pIntl);
  },
  getError: function (message, messageType, webContents, labels, webcontentKey, labelKey) {
    return getError(message, messageType, webContents, labels, webcontentKey, labelKey);
  },
  formatDate: function (date) {
    return wrapIntl.formatDate(date);
  },
  getIncludeDates: function (startRanges) {
    let includeDates = [];
    if (startRanges) {
      for (let range of startRanges) {
        includeDates[range.from] = moment(range.from);
        if (range.to) {
          includeDates[range.to] = moment(range.to);
          //add dates of the middle
          let daysIn_Middle = moment(range.to).diff(moment(range.from), 'days');
          if (daysIn_Middle >= 0) {
            for (let i = 1;i < daysIn_Middle + 1;i++) {
              let newDate = moment(range.from).add(i, "days");
              includeDates[newDate.format("YYYY-MM-DD")] = newDate;
            }
          }

        }
      }
    }
    return includeDates;
  },
  createSearchAppletEvents: function () {
    let userLoggedInSearchAppletEvent = document.createEvent('Event');
    userLoggedInSearchAppletEvent.initEvent('userLoggedInSearchApplet', true, true);
    let userLoggedOutSearchAppletEvent = document.createEvent('Event');
    userLoggedOutSearchAppletEvent.initEvent('userLoggedOutSearchApplet', true, true);

    return {
      "userLoggedInSearchAppletEvent": userLoggedInSearchAppletEvent,
      "userLoggedOutSearchAppletEvent": userLoggedOutSearchAppletEvent,
    };
  },
  getElementsDisabled: function (criteria, settings, mandatoryStartDate) {
    let strictGender = settings && settings["booking.strictGenders"];

    let elementsDisabled = {
      "bookingTypes": false,
      "dropdowns": false,
      "minBudget": false,
      "budget": false,
      "startDate": false,
      "gender": false,
      "searchButton": false,
      "resetButton": false,
      "myShortListButton": false,
      "stopSearch": false,
    };

    if (strictGender && mandatoryStartDate) {
      //gender and startDate enabled, other fields depending of the value of both
      for (let key of Object.keys(elementsDisabled)) {
        if (key === "gender") elementsDisabled[key] = false;
        else if (key === "startDate") elementsDisabled[key] = false;
        else elementsDisabled[key] = !criteria || criteria && criteria.gender === "-" || criteria.startDate === "";
      }
    } else if (strictGender) {
      //gender enabled, rest of values depending of the value of gender
      for (let key of Object.keys(elementsDisabled)) {
        if (key === "gender") elementsDisabled[key] = false;
        else elementsDisabled[key] = !criteria || criteria.gender === "-";
      }
    } else if (mandatoryStartDate) {
      //startDate enabled, rest of values depending of the value of startDate
      for (let key of Object.keys(elementsDisabled)) {
        if (key === "startDate") elementsDisabled[key] = false;
        else elementsDisabled[key] = !criteria || criteria.startDate === "";
      }
    }
    // console.log(elementsDisabled)
    return elementsDisabled;
  },
  createParentArea (parentAreas, initialData) {

    function initParentArea (lookupValue, displayValue, areaLookupValue) {
      return {"lookupValue": lookupValue, "displayValue": displayValue, "areas": { [areaLookupValue]: true} };
    }

    let areas = initialData.categories.filter(function (el) { return el.lookupValue === "AREA";})[0];

    //1 - Relate areas with parents
    let parentsOfAreas = {};

    for (let area of areas.tags) {
      let parent = parentAreas[area.lookupValue].attributes.filter(function (attribute) { return attribute.name === "parent";});
      if (parent[0]) {
        if (!parentsOfAreas[parent[0].bobValue.lookupValue]) parentsOfAreas[parent[0].bobValue.lookupValue] = initParentArea(parent[0].bobValue.lookupValue, parent[0].bobValue.displayValue, area.lookupValue);
        else parentsOfAreas[parent[0].bobValue.lookupValue].areas[area.lookupValue] = true;
      } else {
        //The area has not parent, it can be EARTH
        parentsOfAreas[area.lookupValue] = initParentArea(area.lookupValue, area.displayValue, area.lookupValue);
      }
    }

    //2 - convert parentAreas in tags
    let tags = [];
    for (let key of Object.keys(parentsOfAreas)) tags.push({"lookupValue": parentsOfAreas[key].lookupValue, "displayValue": parentsOfAreas[key].displayValue});
    let areaParentCollection = { "lookupValue": "PARENT-AREA", "displayValue": "Area", "singleChoice": true, "tags": tags };

    //3- remove area categories and add the new parent area categories
    let categoriesWithoutArea = initialData.categories.filter(function (el) { return el.lookupValue !== "AREA";});
    categoriesWithoutArea.push(areaParentCollection);
    initialData.categories = null;
    initialData.categories = categoriesWithoutArea;

    //4- save in the initialData the parent-areas relations too, to know what values we have to send to searchCriteria
    initialData.parentsOfAreas = parentsOfAreas;

    return initialData;
  },
};
