import React from 'react';
import Panel from 'react-bootstrap/lib/Panel';
import Col from 'react-bootstrap/lib/Col';
import Row from 'react-bootstrap/lib/Row';
import Button from 'react-bootstrap/lib/Button';
import WebForm from './steps-components/web-form.jsx';
import {InfoBar} from './info-bar.jsx';
import Form from 'react-bootstrap/lib/Form';
import classNames from 'classnames';
import Loading from "./loading.jsx";

export default class PersonalDetails extends React.Component {
  render () {
    let myFooter = <Row>
      <Col xs={6}>
        <Button bsStyle="primary" className="go-to-dashboard" onClick={this.props.goToDashboard}>Dashboard</Button>
      </Col>
      <Col xs={6} className="text-right">
        <Button className="go-to-previous-step" onClick={this.props.goToDashboard} ><i className="fa fa-arrow-left hidden-sm hidden-lg"></i><span className="hidden-xs">Previous step</span></Button>
        <Button type="submit" bsStyle="success" className="go-to-next-step"><span className="hidden-xs">Next step</span> <i className="fa fa-arrow-right hidden-sm hidden-lg"></i></Button>
      </Col>
    </Row>;
    if (!this.props.webFormData.stepLoaded || this.props.waiting) return <Loading waiting={this.props.waiting} />;
    return (
      <div className={classNames('container direct-debit dd-new personal-details')}>
        <InfoBar infoBarData={this.props.infoBarData} />
        <Row>
          <Col xs={12}>
            <Form onSubmit={this.props.validatePersonalDetails}>
              <Panel header="Direct Debit Request" footer={myFooter}>
                <Row className="personal-details-errors">{this.props.error}</Row>
                <Row>
                  <Col xs={12}>
                          Please fill the form below - all fields with * are mandatory:
                  </Col>
                </Row>
                <Col xs={12}>
                  <WebForm {...this.props.webFormData} updateField={this.props.updateField} />
                </Col>
              </Panel>
            </Form>
          </Col>
        </Row>
      </div>
    );
  }

  componentDidMount () {
    this.props.getWebForm("Website_extranet.directdebit.personaldetails");
  }


}
