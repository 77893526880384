import formService from '../services/form-service.js';
import workflowService from '../services/webform-service.js';
export default function webForm (state = initState (), action ) {
  switch (action.type) {
    case "GET_WEBFORM_RESPONSE": {
      let newState = {};
      newState.webForm = workflowService.parseWebform(action.response.entityInfo);
      newState.webForm = Object.assign({}, newState.webForm, formService.initGenericForm(newState.webForm.formNames, newState.webForm.formValues));
      return Object.assign(state, {"stepLoaded": true}, newState);
    }
    case "UPDATE_PERSONAL_DETAIL_FORM": {
      let newState = { "webForm": state.webForm};
      newState.webForm.values[action.field] = action.value;
      newState.webForm = Object.assign(newState.webForm, formService.checkForm(newState.webForm.values, newState.webForm.formNames));
      return Object.assign(state, newState);
    }
    case "SUBMITTED_WITH_ERRORS": {
      let newState = { "webForm": state.webForm};
      newState.webForm.submitted = true;
      return Object.assign(state, newState);
    }
    default: return state;
  }
};

function initState () {
  let myState = {};
  myState.webForm = {};
  return myState;
}
