import React from 'react';
import Col from 'react-bootstrap/lib/Col';
import Row from 'react-bootstrap/lib/Row';
import ButtonDownloadPdf from '../../form-components/button-download-pdf.jsx';
import Button from 'react-bootstrap/lib/Button';
import ButtonGroup from 'react-bootstrap/lib/ButtonGroup';

export default class AgreementPDFAccept extends React.Component {

  render () {
    if (!this.props.agreementYesNoAction) return <div className="agreement-error-no-function">Message error: No agreementYesNoAction function. Error DD40001</div>;
    return (
      <div className="agreement-pdf-accept">
        <Row>
          <Col xs={12}><p>Please read and confirm your acceptance of this Direct Debit Services Agreement:</p></Col>
        </Row>
        <Row>
          <Col xs={12} className="agreement-pdf-file">
            <iframe className="pdf-agreement-window hidden-sm hidden-xs" src={this.props.agreementUrl} width="100%" height="375" />
            <ButtonDownloadPdf className="download-agreement" label="Download Agreement" agreementUrl={this.props.agreementUrl} nameForFileDownloaded="Agreement"/>
          </Col>
        </Row>
        <Row>
          <Col sm={10} className="agreement-user-data">
            {"I, " + this.props.holderName + ", request and authorise " + this.props.receiver + " (Direct Debit User ID: " + this.props.receiverBacsReference + " ) to arrange and debit your account " +
            "held at your Financial Institution nominated by you through the Bulk Electronic Clearing System (BECS) at any amount " + this.props.receiver + " has deemed payable by you."}
          </Col>
          <Col sm={2}>
            <AgreementRadioElements agreementYesNoValue={this.props.agreementYesNoValue ? this.props.agreementYesNoValue : "no" } agreementYesNoAction={this.props.agreementYesNoAction}/>
          </Col>
        </Row>
      </div>
    );
  }
}

function AgreementRadioElements (props) {
  let options = [];
  if (props.agreementYesNoValue === 'yes') {
    options.push(<Button key ="1" className="agreement-click-yes active" bsStyle="success" value="yes" onClick={props.agreementYesNoAction} >YES</Button>);
    options.push(<Button key ="2" className="agreement-click-no" value="no" onClick={props.agreementYesNoAction}>NO</Button>);
  } else {
    options.push(<Button key ="1" className="agreement-click-yes" value="yes" onClick={props.agreementYesNoAction} >YES</Button>);
    options.push(<Button key ="2" className="agreement-click-no active" bsStyle="danger" value="no" onClick={props.agreementYesNoAction}>NO</Button>);
  }

  return (
    <div className="accept-agreement">
      <ButtonGroup name="agreement-switch" >{options}</ButtonGroup>
    </div>
  );
}
