import React from 'react';
import { PexCommonField } from "./pex-common-field.jsx";
import { PexDropDown } from "./pex-drop-down.jsx";
import { PexDatePicker } from "./pex-date-picker.jsx";
import Col from 'react-bootstrap/lib/Col';

export class PexJobDate extends React.Component {

  render () {
    //This is not a  common fiel we wouldn't use it but for now we set a singleValue, never mind because we don't use it to validate this field
    //we set somevalue but it never will be used
    return <PexCommonField {...this.props} hideIcon={true} ><DateTime {...this.props}/></PexCommonField>;
  }

}

function DateTime (props) {

  let hours = [];
  for (let h = 0; h < 24; h++) hours.push(<option key={h + ":00"} value={h + ":00"}>{h + ":00"}</option>);
  return <div className={"date-time-container"}>
    <Col md={6} className="date">
      <PexDatePicker key={props.attribute.fieldProps.fieldKey} fieldKey={props.attribute.fieldProps.fieldKey} className={props.className + "date-picker"} hideLabel={true}
        value={props.attribute.fieldProps.dateValue} errors={props.errors} updateField={props.updateField}
        label={props.attribute.fieldProps.label} iconClassName={props.iconClassName} excludeDates={props.excludeDates} maxDate={props.maxDate}/>
    </Col>
    <Col md={6} className="time-container">
      <PexDropDown key="time" fieldKey="time" className="time"
        iconClassName={props.iconClassName} label={props.translate("timeLabel")}
        value={props.attribute.fieldProps.hourValue} errors={props.errors} updateField={props.updateField}>{props.hours ? props.hours : hours}</PexDropDown>
    </Col>
  </div>;
}
