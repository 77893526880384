let $ = require('jquery');

module.exports = {
  getSSOkey: function (api, path, success, failure) {
    if (path.length && !path.startsWith("/")) {
      failure("if you have a non blank path, it must start with slash");
      return;
    }
    $.ajax({
      url: api + path,
      xhrFields: {
        withCredentials: true,
      },
      contentType: 'text/plain',
      success: success,
      error: failure,
    });
  },
  singleSign: function (api, path, payload, success, failure) {
    if (path.length && !path.startsWith("/")) {
      failure("if you have a non blank path, it must start with slash");
      return;
    }
    $.ajax({
      url: api + "/rs/singleSignOn" + path,
      context: this,
      data: JSON.stringify(payload),
      dataType: 'json',
      type: "POST", //always POST for now, but that could change
      contentType: 'application/json',
      success: success,
      error: failure,
    });
  },
};
