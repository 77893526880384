import React from 'react';
import ajax from '../lib/ajax.js';
let api = require('../lib/api.js')(ajax);
import {ShowError} from "./components/show-error.jsx";
import NewPassword from "./components/new-password.jsx";
import { createStore } from 'redux';
import formService from "./services/form-service";


const defaultLabels = JSON.parse(JSON.stringify(require("../../lang/otp-login/en-GB.json"))).labels["en-GB"];

function initState () {
  return {
    "nextAction": "getLabels",
    "waiting": true,
    "error": null,
    "loaded": false,
  };
}

export default class OTPLogin extends React.Component {

  constructor (props) {
    super(props);
    let self = this;
    this.store = createStore(function (state = {}, action) {
      // console.log(action.type);
      let newState = {};
      switch (action.type) {
        case "LOAD": {
          return Object.assign(state, initState(), { "lang": action.lang});
        }
        case "ERROR": {
          return Object.assign(state, {"nextAction": "DONE", "loaded": true, "error": action.error, "waiting": false});
        }
        case "GET_LABELS_OK": {
          let labels = defaultLabels;
          if (action.response.labels[state.lang])
            for (let key of Object.keys(action.response.labels[state.lang])) labels[key] = action.response.labels[state.lang][key];
          return Object.assign({}, state, {"labels": labels, "nextAction": "singleSignOnPeekUser"});
        }
        case "PEEK_USER_OK": {
          newState.nextAction = "startLogin";
          return Object.assign(state, newState);
        }
        case "PEEK_USER_PASSWORD_EXPIRED": {
          newState.nextAction = "DONE";
          newState.loaded = true;
          newState.waiting = false;
          newState.changePasswordTitle = action.response.message;
          newState.changePasswordForm = Object.assign({}, formService.initChangePasswordForm(state.labels));
          return Object.assign(state, newState);
        }
        case "UPDATE_CHANGE_PASSWORD_FORM": {
          newState = { "changePasswordForm": state.changePasswordForm};
          newState.changePasswordForm.values[action.field] = action.value;
          newState.changePasswordForm = Object.assign(newState.changePasswordForm, formService.checkForm(newState.changePasswordForm.values, newState.changePasswordForm.formNames, state.labels, "otpLogin.changePassword"));
          return Object.assign(state, newState);
        }
        case "CHANGE_PASSWORD_FORM_SUBMITTED_WITH_ERRORS": {
          newState = { "changePasswordForm": state.changePasswordForm};
          newState.changePasswordForm = Object.assign(newState.changePasswordForm, {"submitted": true});
          return Object.assign(state, newState);
        }
        case "START_CHANGE_PASSWORD": {
          newState.nextAction = "getSSOKeyAndChangePassword";
          newState.waiting = true;
          return Object.assign(state, newState);
        }
        case "CHANGE_PASSWORD_ERROR": {
          newState.changePasswordForm = state.changePasswordForm;
          if (action.response.messages && action.response.messages[0].message) newState.changePasswordForm.APIerror = action.response.messages[0].message;
          newState.waiting = false;
          newState.nextAction = "DONE";
          return Object.assign(state, newState);
        }
        case "CHANGE_PASSWORD_OK": {
          newState.nextAction = "redirectToTargetURL";
          return Object.assign(state, newState);
        }
        case "LOGIN_ERROR": {
          newState.changePasswordForm = state.changePasswordForm;
          newState.changePasswordForm.APIerror = action.error;
          newState.waiting = false;
          newState.nextAction = "DONE";
          return Object.assign(state, newState);
        }
        case "USER_LOGGED_OK": {
          newState.nextAction = "redirectToTargetURL";
          return Object.assign(state, newState);
        }
        default:
          return state;
      }
    }, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
    this.state = this.store.getState();
  }

  componentDidUpdate () {
    // console.log(this.state.nextAction);
    switch (this.state.nextAction) {
      case "getLabels":
      case "singleSignOnPeekUser":
      case "getSSOKeyAndChangePassword":
      case "startLogin":
      case "redirectToTargetURL":
        this[this.state.nextAction]();
    }
  }

  componentDidMount () {
    if (this.store) {
      this.unsubscribe = this.store.subscribe(function () {
        let state = {};
        for (let key of Object.keys(this.state)) state[key] = null;
        this.setState(Object.assign({}, state, this.store.getState()));
      }.bind(this));

      if (!this.props.api) return this.store.dispatch({type: "ERROR", "error": "Configuration error - No api specified"});
      if (!this.props.sso) return this.store.dispatch({type: "ERROR", "error": "Configuration error - No sso specified"});
      if (!this.props.username) return this.store.dispatch({type: "ERROR", "error": "Configuration error - No username specified"});
      if (!this.props.password) return this.store.dispatch({type: "ERROR", "error": "Configuration error - No password specified"});
      if (!this.props.targetUrl) return this.store.dispatch({type: "ERROR", "error": "Configuration error - No targetUrl specified"});
      if (!this.props.appName) return this.store.dispatch({type: "ERROR", "error": "Configuration error - No appName specified"});

      let lang = "en-GB";
      if (this.props.lang) lang = this.props.lang;

      window.onpopstate = function () { this.store.dispatch({type: "LOAD", "lang": lang}); }.bind(this);
      this.store.dispatch({type: "LOAD", "lang": lang});
    }
    return true;
  }

  componentWillUnmount () {
    this.unsubscribe();
  }

  render () {
    let divFrozen = "";
    if (this.state && !this.state.error && this.state.loaded) {
      if (this.state.waiting) divFrozen = " divFrozen";

      return <div className={"otp-login-content" + divFrozen}>
        <NewPassword state={this.state}
          updateChangePasswordForm={this.updateChangePasswordForm.bind(this)}
          startChangePassword={this.startChangePassword.bind(this)}/>
      </div>;
    }
    else if (this.state && this.state.error) return <ShowError error={this.state.error} />;
    else return <div>Loading...</div>;
  }

  // API CALLS
  getLabels () {
    let self = this;
    api.getLabels(this.props.api, {"languageIds": [this.state.lang], "group": "otpLogin"}, function (response) {
      self.store.dispatch({type: "GET_LABELS_OK", "response": response});
    }, function (message, messageType) {
      let apiErrorCode = "";
      if (messageType) apiErrorCode = messageType + " - ";
      self.store.dispatch({"type": "ERROR", "error": "OTP-LOGIN-ERROR-0000001 API error - " + apiErrorCode + " getLabels - " + message});
    });
  }

  singleSignOnPeekUser () {
    let self = this;
    api.singleSignOnPeekUser(this.props.sso, {"username": this.props.username, "password": this.props.password}, function (response) {
      if (response.passwordExpired) self.store.dispatch({"type": "PEEK_USER_PASSWORD_EXPIRED", "response": response});
      else self.store.dispatch({"type": "PEEK_USER_OK", "response": response});
    }, function (message, messageType) {
      let apiErrorCode = "";
      if (messageType) apiErrorCode = messageType + " - ";
      self.store.dispatch({"type": "ERROR", "error": "OTP-LOGIN-ERROR-00000002 API error - " + apiErrorCode + " singleSignOnPeekUser - " + message});
    });
  }
  getSSOKeyAndChangePassword () {
    let self = this;
    let payload = {
      "username": this.props.username,
      "password": this.props.password,
      "newPassword": this.state.changePasswordForm.values.confirmPassword,
      "context": this.props.appName,
    };
    api.getSSOKeyAndChangePassword(this.props.sso, this.props.sso, payload, function (response) {
      if (response.status === "WARNING") self.store.dispatch({"type": "CHANGE_PASSWORD_ERROR", "response": response});
      else self.store.dispatch({"type": "CHANGE_PASSWORD_OK", "response": response});
    }, function (message, messageType) {
      let apiErrorCode = "";
      if (messageType) apiErrorCode = messageType + " - ";
      self.store.dispatch({"type": "ERROR", "error": "OTP-LOGIN-ERROR-00000003 API error - " + apiErrorCode + " getSSOKeyAndChangePassword - " + message});
    });
  }

  startLogin () {
    let self = this;
    api.singleSignOnLoginobject(this.props.sso, {"username": this.props.username, "password": this.props.password}, function (loginResponse) {
      if (loginResponse.loggedIn) {
        self.store.dispatch({ type: "USER_LOGGED_OK"});
      }
      else {
        self.store.dispatch({type: "ERROR", "error": loginResponse.message});
      }
    }, function (message, messageType) {
      let apiErrorCode = "";
      if (messageType) apiErrorCode = messageType + " - ";
      self.store.dispatch({"type": "ERROR", "error": "NORMAL-LOGIN-ERROR-00000004 API error - " + apiErrorCode + " singleSignOnLoginobject - " + message});
    });
  }

  // METHODS

  updateChangePasswordForm (field, event) {
    this.store.dispatch({type: "UPDATE_CHANGE_PASSWORD_FORM", "field": field, "value": event.target.value});
  }

  startChangePassword (event) {
    event.preventDefault();
    if (!this.state.changePasswordForm.canSubmit) {
      this.store.dispatch({ type: "CHANGE_PASSWORD_FORM_SUBMITTED_WITH_ERRORS"});
      return;
    }
    this.store.dispatch({ type: "START_CHANGE_PASSWORD"});
  }

  redirectToTargetURL (event) {
    window.location.replace(this.props.targetUrl);
  }

}
