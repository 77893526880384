import React from 'react';
import utils from '../../lib/utils.js';
import ImageGallery from 'react-image-gallery';
import ajax from '../../lib/ajax.js';
import translateService from "../services/translate-service";
let api = require('../../lib/api.js')(ajax);
let format_path = utils.format_path; //TODO: inline
let sanitize = require('../../lib/utils.js').sanitize;
const defaultLabels = JSON.parse(JSON.stringify(require("../../../lang/search-applet/en-GB.json"))).labels["en-GB"];

export default class ResultsInBuildingListAll extends React.Component {

  constructor (props) {
    super(props);
    this.state = {};
  }

  render () {
    if (this.state.data && this.state.data.locations && this.state.data.locations.length === 1 && this.state.imageHandler) {
      let linkTemplates = {
        book: this.props.book,
        enquire: this.props.enquire,
        viewing: this.props.viewing,
      };

      //return React.cloneElement(this.props.children, {imageHandler:this.props.imageHandler, linkTemplates:linkTemplates, location: this.state.data.locations[0].location, data: this.state.data.locations[0].roomTypes, activatedRoomType: this.state.activatedRoomType, handleClick:(::this.handleClick)});
      return <Results RoomType={this.props.RoomType}
        labels={this.state.labels}
        imageHandler={this.state.imageHandler}
        linkTemplates={linkTemplates}
        location={this.state.data.locations[0].location}
        data={this.state.data.locations[0].roomTypes}
        activatedRoomType={this.state.activatedRoomType}
        charsBeforeEllipsis={Number(this.props.charsBeforeEllipsis)}
        handleClick={::this.handleClick} />;

    } else if (this.state.data && this.state.data.error) {
      return <div>{this.state.data.error}</div>;
    } else if (this.state.data && this.state.imageHandler) {
      return <div>unknown error: <pre>{ JSON.stringify(this.state.data) }</pre></div>;
    } else {
      return <div>just a sec</div>;
    }
  }

  handleClick (roomTypeLookupValue) {
    if (this.state.activatedRoomType !== roomTypeLookupValue) {
      this.setState({activatedRoomType: roomTypeLookupValue});
    } else {
      this.setState({activatedRoomType: undefined});
    }
  }

  componentDidMount () {
    let self = this;

    if (!this.props.building) {
      self.setState({data: {error: "configuration error - no building specified"}});
      return;
    }

    if (this.props.imageHandler) {
      self.setState({data: {error: "configuration error - imageHandler attribute no longer exists"}});
      return;
    }

    if (!this.props.api) {
      self.setState({data: {error: "configuration error - no api specified"}});
      return;
    }

    let criteria = {};
    let arrRoomType = new Array();
    if (this.props.roomTypeSearch) {
      arrRoomType = this.props.roomTypeSearch.split(',');
      criteria = {
        "roomType": arrRoomType,
      };
    }

    api.searchAvailability(this.props.api, {
      "criteria": criteria, //could get api to filter on location so that we had a bit less work to do over here, but that would mean having the location beforehand (two api calls or making them pass it in as a param)
    }, function (data) {
      if (!data.status) {
        self.setState({data: {error: "API error 2373826"}});
      } else if (data.status === "OK") {
        let locations = data.locations.filter(function (location) {
          return location.building.lookupValue === self.props.building;
        });

        if (!locations.length) {
          let noResultsMsg = self.props.noResultsMsg || "no results for " + self.props.building;
          self.setState({data: {error: noResultsMsg}});
        } else {
          self.setState({
            data: {locations: locations},
            activatedRoomType: locations[0].roomTypes[0].roomType.lookupValue});
        }
      } else {
        self.setState({data: {error: "API error 2373824 " + data.messages[0].message}});
      }
    }, function () {
      self.setState({data: {error: "API error 2373828E"}});
    });

    api.init(this.props.api, {}, function (data) {
      if (!data.imageHandler) {
        self.setState({data: {error: "API error 2373839.1"}});
      } else {
        self.setState({imageHandler: data.imageHandler});
      }
    }, function () {
      self.setState({data: {error: "API error 2373838"}});
    });

    let lang = "en-GB";
    if (this.props.lang) lang = this.props.lang;
    api.getLabels(this.props.api, {"languageIds": [lang], "group": "resultsInBuilding"}, function (response) {
      self.setState({labels: response.labels[lang]});
    }, function () {
      self.setState({data: {error: "API error 237555 getLabels"}});
    });
  }

}

class RoomType extends React.Component {
  render () {
    let rooms = this.props.rooms.filter(function (room) {return room.bookable;});
    let self = this;
    if (rooms.length > 0) {
      return <div className="pex-room-type">
        {rooms.length ? rooms.map(function (room) {
          let props = {};
          //if one room has more than one availability appears more than once, so avoid duplicated keys
          //and like lookupVAlue is numeric we concat with ""
          props.key = room.lookupValue + "" + room.availability.lookupValue;
          props.linkTemplates = self.props.linkTemplates;
          props.location = self.props.location;
          props.roomType = self.props.roomType;
          props.roomTypeDescription = self.props.description;
          props.thumbList = new Array();
          props.currency = room.currency.symbol;
          props.labels = self.props.labels;
          props.charsBeforeEllipsis = self.props.charsBeforeEllipsis;
          if (room.features) {
            props.thumbList = room.features.filter(function (feature) {return feature.type && feature.type.lookupValue && feature.type.lookupValue === "IMAGE";});
          } else {
            //TODO possibly append a message to the "no carousel" div to replace the "test" text.
          }
          props.imageHandler = self.props.imageHandler;
          return <Room {...room} {...props} />;
        }) : <div className="pex-room not-available">Not available</div>}
      </div>;
    } else {
      return null;
    }
  }
}

class Room extends React.Component {

  translate (name) {
    return translateService.getLabelsTranslated(this.props.labels, "resultsInBuilding.", name, defaultLabels);
  }

  render () {
    //arrange.html?room=<ROOM>&location=<LOCATION>&roomType=<ROOM_TYPE>&portfolio=<PORTFOLIO>&availability=<AVAILABILITY>&bookingType=<BOOKING_TYPE>&returnUrl=<RETURN_URL>
    let values = {
      ROOM: this.props.lookupValue,
      LOCATION: this.props.location.lookupValue,
      ROOM_TYPE: this.props.roomType.lookupValue,
      PORTFOLIO: this.props.portfolio.lookupValue,
      BOOKING_TYPE: this.props.bookingType.lookupValue,
      AVAILABILITY: this.props.availability.lookupValue,
      LOCATION_ID: this.props.location.id,
      ROOM_TYPE_ID: this.props.roomType.id,
      PORTFOLIO_ID: this.props.portfolio.id,
      BOOKING_TYPE_ID: this.props.bookingType.id,
      AVAILABILITY_ID: this.props.availability.id,
    };

    let imageList = new Array();
    if (typeof this.props.thumbList === 'object' && this.props.thumbList.length > 0) {
      for (let i = 0; i < this.props.thumbList.length; i++) {
        //TODO change filter function (line 34) to append imageHandler to image string when adding to the array
        if (this.props.thumbList[i].displayValue === "No image") {
          imageList.push({ original: this.props.thumbList[i].image, thumbnail: this.props.thumbList[i].image});
        }
        imageList.push({ original: this.props.imageHandler + this.props.thumbList[i].image, thumbnail: this.props.imageHandler + this.props.thumbList[i].image});
      }
    }
    if (typeof this.props.features !== 'undefined' && this.props.features.length > 0) {
      for (let feature of this.props.features) {
        if (feature.type && feature.type.lookupValue && feature.type.lookupValue === "FPLAN" && feature.image) {
          imageList.push({ original: this.props.imageHandler + feature.image, thumbnail: this.props.imageHandler + feature.image});
        }
      }
    }
    let myCarousel = <ImageGallery showThumbnails={false} items={imageList}/>;

    let buttons = new Array();
    if (this.props.linkTemplates.book) {
      buttons.push(<a key="book"className="pex-room-book btn btn-sm btn-success" href={format_path(this.props.linkTemplates.book, values)}><i className="fa fa-bed hidden-xs"></i>{" " + this.translate("bookButtonLabel")}</a>);
    }
    if (this.props.linkTemplates.enquire) {
      buttons.push(<a key="enquire" className="pex-room-enquire btn btn-sm btn-default" href={format_path(this.props.linkTemplates.enquire, values)}><i className="fa fa-comments-o hidden-xs"></i>{" " + this.translate("enquiryButtonLabel")}</a>);
    }
    if (this.props.linkTemplates.viewing) {
      buttons.push(<a key="viewing" className="pex-room-viewing btn btn-sm btn-default" href={format_path(this.props.linkTemplates.viewing, values)}><i className="fa fa-eye hidden-xs"></i>{" " + this.translate("viewingButtonLabel")}</a>);
    }

    if (!this.props.currency) {
      throw "ERROR 094398899x9";
    }
    let roomDescription = this.props.roomTypeDescription;
    if (this.props.charsBeforeEllipsis && roomDescription.length >= this.props.charsBeforeEllipsis) {
      roomDescription = roomDescription.substring(0, this.props.charsBeforeEllipsis) + "...";
    }

    return <div className="pex-room panel panel-default">
      <div className="panel-heading text-center" onClick={this.props.handleClick}>
        <div className=""><h3><strong>{ this.props.roomType.displayValue }</strong></h3></div>
        <div className=""><span className="roomIdentifier"></span><span className="room-number">{this.props.title}</span></div>
      </div>
      <div className="pex-room-images">
        {typeof imageList === 'object' && imageList.length > 0 ? myCarousel : <div className="no carousel">test</div> }
      </div>
      <div className=""><span className="roomIdentifier"></span><span className="room-number">{this.props.title}</span></div>
      <div className="col-md-4">
        <span className="price-currency">{ this.props.currency }</span><span className="pex-room-price">{ this.props.price }</span> <span className="pex-room-frequency"><i>{ this.props.frequency.displayValue }</i></span>
      </div>
      <div className="room-type-description col-xs-12" dangerouslySetInnerHTML={{__html: sanitize(roomDescription)}} />
      <div className="well col-xs-12">
        <div className="col-md-4">
          <RoomNextAvailabilityDate nextAvailabilityDate = {this.props.availability.start} />
          <div className="pex-room-availability"><i className="fa fa-calendar"></i> { this.props.availability.displayValue }</div>
        </div>
        <div className="col-xs-12 col-md-8"><div className="btn-group">{ buttons }</div></div>
      </div>

    </div>;
  }
}

class RoomNextAvailabilityDate extends React.Component {
  render () {
    if (this.props.nextAvailabilityDate) {
      let nextAvailabilityDate = this.props.nextAvailabilityDate.split("-");
      return <div className="pex-next-availability-date">
        <span className="pex-next-availability-date-label"><i className="fa fa-calendar-check-o"></i> Start Date </span>
        <span className="pex-next-availability-date-value">{ nextAvailabilityDate[2] + "/" + nextAvailabilityDate[1] + "/" + nextAvailabilityDate[0]}</span>
      </div>;
    }
    return null;
  }
}


class Results extends React.Component {
  handleClick (roomTypeLookupValue) {
    this.props.handleClick(roomTypeLookupValue);
  }

  render () {
    let self = this;

    return <div className="pex-rooms-in-building"> {
      self.props.data.map(function (roomType) {
        let props = {};
        props.handleClick = self.handleClick.bind(self, roomType.roomType.lookupValue);
        props.roomType = roomType.roomType;
        props.rooms = roomType.rooms;
        props.activated = roomType.roomType.lookupValue === self.props.activatedRoomType;
        props.key = roomType.roomType.lookupValue;
        props.image = roomType.thumbList && roomType.thumbList.length ? roomType.thumbList[0] : null;
        props.imagesList = roomType.thumbList && roomType.thumbList.length ? roomType.thumbList : null;
        props.description = roomType.description ? roomType.description : "";
        props.linkTemplates = self.props.linkTemplates;
        props.location = self.props.location;
        props.imageHandler = self.props.imageHandler;
        props.labels = self.props.labels;
        props.charsBeforeEllipsis = self.props.charsBeforeEllipsis;
        return <RoomType {...props} />;
      })
    } </div>;
  }
}
