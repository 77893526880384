import React from 'react';
import Button from 'react-bootstrap/lib/Button';
import Col from 'react-bootstrap/lib/Col';
import Row from 'react-bootstrap/lib/Row';
import Panel from 'react-bootstrap/lib/Panel';
import translateService from "../../services/translate-service";
import PexCookiePolicy from '../../../legal/pex-cookie-policy.jsx';

export function ValidateAccount (props) {
  let specificMessage = null;
  if (props.validateAccountAction === "book") specificMessage = <p className="validate-account-book-message">{translate("validateBookMessage")}</p>;
  else if (props.validateAccountAction === "addtoshortlist") {
    specificMessage = <p className="validate-account-add-to-shortlist-message">{translate("validateShortlistMessage")}</p>;
  }

  let errorTitle = <p><i className="fa fa-frown-o"></i>{translate("oopsLabel") + "!"}</p>;
  let footer = <Button className="btn-validate-account" bsStyle="success" onClick={props.startValidateAccount}>
    <i className="fa fa-book hidden-sm hidden-lg"></i> <span className="hidden-xs">{translate("validateButtonLabel")}</span>
  </Button>;

  function translate (name) {
    return translateService.getLabelsTranslated(props.labels, "searchApplet.validateAccount.", name, props.defaultLabels);
  }

  return <div className="validate-account-container">
    <Row>
      <Col xs={12}>
        <Panel className="panel-min-height" header={errorTitle} footer={footer} bsStyle="danger">
          <Row>
            <p className="validate-account-generic-message">{translate("validateMessage")}</p>
          </Row>
          <Row>
            {specificMessage}
          </Row>
        </Panel>
      </Col>
      <PexCookiePolicy colMd={9} />
    </Row>
  </div>;
}
