import React from 'react';
import { PexValidationMessage } from "./pex-validation-message.jsx";
import InputGroup from 'react-bootstrap/lib/InputGroup';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import Col from 'react-bootstrap/lib/Col';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import classNames from 'classnames';
import FormControl from 'react-bootstrap/lib/FormControl';
import PropTypes from 'prop-types';

export class PexCommonField extends React.Component {

  value () {
    return this.props.values[this.props.fieldKey] || '';
  }

  handleChange (event) {
    this.props.updateField(this.props.fieldKey, event);
  }

  childProps () {
    let childProps =
      {
        value: this.value(),
        onChange: ::this.handleChange,
        placeholder: this.props.label,
        id: this.props.id,
        name: this.props.name,
      };
    if (this.props.disabled) {
      childProps.disabled = true;
    }

    if (this.props.placeholder) {
      childProps.placeholder = this.props.placeholder;
    } else {
      childProps.placeholder = this.props.label;
    }
    return childProps;
  }

  error () {
    if (this.props.submitted && this.props.errors[this.props.fieldKey]) return true;
    return false;
  }


  render () {
    let classesParent = this.props.className;
    let classes = {fieldError: this.error()};
    let validationState = "";
    if (this.props.submitted || this.props.values[this.props.fieldKey]) {
      if (this.props.errors[this.props.fieldKey]) validationState = { "validationState": "error" };
      else validationState = { "validationState": "success" };
    }
    let label = null;
    if (this.props.label) label = <ControlLabel htmlFor={this.props.name}>{this.props.label}</ControlLabel>;

    if (this.props.iconClassName) {
      return <FormGroup className={classNames(classesParent, classes)} {...validationState}>
        {label}
        <InputGroup>
          <InputGroup.Addon><i className={this.props.iconClassName}></i></InputGroup.Addon>
          { React.cloneElement(this.props.children, this.childProps()) }<FormControl.Feedback />
        </InputGroup>
        <PexValidationMessage {...this.props }/>
      </FormGroup>;
    } else {
      return <FormGroup className={classNames(classesParent, classes)} {...validationState}>
        {label}
        { React.cloneElement(this.props.children, this.childProps()) }<FormControl.Feedback />
        <PexValidationMessage {...this.props }/>
      </FormGroup>;
    }
  }

}

PexCommonField.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  updateField: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
  className: PropTypes.string.isRequired,
};
