import React from 'react';
import Col from 'react-bootstrap/lib/Col';
import Row from 'react-bootstrap/lib/Row';
import Button from 'react-bootstrap/lib/Button';
import Form from 'react-bootstrap/lib/Form';
import Panel from 'react-bootstrap/lib/Panel';
import { PexPassword } from "../form-components/pex-password.jsx";


export default class NewPassword extends React.Component {

  render () {

    let self = this;
    let formProps = {
      values: this.props.state.changePasswordForm.values,
      errors: this.props.state.changePasswordForm.fieldsWithErrors,
      submitted: this.props.state.changePasswordForm.submitted,
      updateField: this.props.updateChangePasswordForm,
    };
    let errorMessage = this.props.state.changePasswordForm.APIerror ? <p className="change-password-error">{this.props.state.changePasswordForm.APIerror}</p> : null;
    let title = <h3 className="change-password-form-title"><strong>{this.props.state.changePasswordTitle}</strong></h3>;
    return <Row>
      <Col sm={6} className="change-password-container col-centered">
        <Form horizontal onSubmit={this.props.startChangePassword}>
          <Panel header = {title} >
            <PexPassword fieldKey="password" className = "change-password-form-password" iconClassName="fa fa fa-lock"
              placeholder={this.props.state.labels["otpLogin.changePassword.passwordPlaceholder"]}
              label={this.props.state.labels["otpLogin.changePassword.passwordLabel"]} hideLabel={true} {...formProps } />
            <PexPassword fieldKey="confirmPassword" className = "change-password-form-confirm-password" iconClassName="fa fa fa-lock"
              placeholder={this.props.state.labels["otpLogin.changePassword.confirmPasswordPlaceholder"]}
              label={this.props.state.labels["otpLogin.changePassword.confirmPasswordLabel"]} hideLabel={true} {...formProps } />
            <Button bsStyle="success" className="change-password-save-button" type="submit">{this.props.state.labels["otpLogin.changePassword.changePasswordButton"]}</Button>
            {errorMessage}
          </Panel>
        </Form>
      </Col>
    </Row>;
  }

}
