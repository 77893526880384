import React from 'react';
import Button from 'react-bootstrap/lib/Button';
import classNames from 'classnames';
import DatePicker from 'react-datepicker';
import Dropdown from 'react-bootstrap/lib/Dropdown';
import onClickOutside from 'react-onclickoutside';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import ButtonGroup from 'react-bootstrap/lib/ButtonGroup';
import moment from 'moment';

class SearchDateSelector extends React.Component {

  handleClickOutside () {
    this.props.onClickOutside(this.props.fieldKey);
  }

  render () {
    let open = false;
    if (this.props.open && this.props.open.length > 0 && this.props.open === this.props.fieldKey ) open = true;

    let selectedDate = null;
    if (this.props.selectedDate) selectedDate = this.props.selectedDate;
    else if (this.props.value) selectedDate = moment(this.props.value);
    return <div className={classNames(this.props.className)} >
      <div className="dropdown-no-category-container form-group">
        <label className="control-label">{this.props.label}</label>
        <DateField {...this.props} selectedDate={selectedDate}
          selectDate={this.props.setSelectedDate}
          open={open}
          cancelChangeSearchDate={this.props.cancelChangeSearchDate}></DateField>
      </div>
    </div>;
  }

}
export default onClickOutside(SearchDateSelector);

function DateField (props) {
  function onClickOpen () {
    props.onClickOpen(props.fieldKey);
  }
  //This event is required to manage the open attribute, if we remove the declaration it won't work
  function onToggle () {
    return;
  }


  let searchSelector = <DateSearchSelector updateSearchDate={props.updateSearchDate}
    selectDate={props.selectDate}
    selectedDate={props.selectedDate}
    currentMoment={props.currentMoment}
    includeDates={props.includeDates}
    cancelChangeSearchDate={props.cancelChangeSearchDate}
    translate={props.translate}
  />;
  if (!props.open) searchSelector = null;

  return <Dropdown id={props.fieldKey} disabled={props.elementDisabled} className={"dropdown-category pex-search-date pex-list-" + props.fieldKey} open={props.open} onToggle={onToggle}>
    <Dropdown.Toggle id={props.fieldKey} className ={"category-toggle-btn dropdown-toggle-" + props.fieldKey} onClick={ () => {onClickOpen();}}>
      <DropDownText label={props.label}
        searchService={props.searchService}
        value={props.value} />
    </Dropdown.Toggle>
    <Dropdown.Menu className={"pex-menu-items-" + props.fieldKey}>
      {searchSelector}
    </Dropdown.Menu>
  </Dropdown>;
}

function DropDownText (props) {
  let text = props.label;
  if (props.value) text = props.searchService.formatDate(moment(props.value));
  return <span className="search-text">{text}</span>;
}

function DateSearchSelector (props) {
  //thanks https://hacker0x01.github.io/react-datepicker/

  function selectFirstDateAvailable () {

    props.selectDate(props.includeDates[sortedKeys[0]]);
  }

  function updateSearchDate () {
    props.updateSearchDate(moment(props.selectedDate).format("YYYY-MM-DD"));
  }

  function cancel () {
    props.cancelChangeSearchDate();
  }

  let parseIncludeDates = [];
  let datesInPast = [];
  let sortedKeys = [];
  //Short dates
  //Identify which of them are in the past to add a special class
  Object.keys(props.includeDates).sort(function (a, b) { return a.localeCompare(b);})
    .map(function (key) {
      sortedKeys.push(key);
      parseIncludeDates.push(props.includeDates[key]);
      if (props.includeDates[key].diff(moment(props.currentMoment)) < 0) datesInPast.push(props.includeDates[key]);
    });
  let dateInfo = <p className="select-search-date-info">{props.translate("selectSearchDateInfo")}</p>;
  if (moment(props.selectedDate).diff(moment(props.currentMoment.format("YYYY-MM-DD"))) < 0) {
    // console.log("DATE IN THE PAST");
    //TODO: see https://reactdatepicker.com to check the parameter to custom dates
    dateInfo = <p className="date-in-past-selected-info">{props.translate("dateSelectedInPastInfo")}</p>;
  }

  return <div className="search-date-wrapper">
    <div className="left-side col-sm-6">
      <DatePicker
        inline
        dateFormat="DD/MM/YY"
        highlightDates = {[ { "search-date-in-the-past": datesInPast }]}
        includeDates={parseIncludeDates}
        minDate={sortedKeys[0] ? props.includeDates[sortedKeys[0]] : []}
        selected={props.selectedDate ? moment(props.selectedDate) : null}
        onChange={props.selectDate}
        scrollableYearDropdown
        showMonthDropdown
        showYearDropdown />
      <Button className="select-first-available-date" bsStyle="primary" onClick={() => {selectFirstDateAvailable();}} >{props.translate("selectFirstAvailable")}</Button>
    </div>
    <div className="right-side col-sm-6">
      {dateInfo}
      <ButtonGroup>
        <Button className="cancel-change-search-date" bsStyle="danger" onClick={() => {cancel();}} >{props.translate("cancelSearchDateButton")}</Button>
        <Button className="confirm-search-date"bsStyle="success" onClick={()=>{updateSearchDate();}} >{props.translate("confirmSearchDateButton")}</Button>
      </ButtonGroup>
    </div>
  </div>;

}
