"use strict";

import moment from "moment/moment";
let format_path = require("../../lib/utils").format_path;
import translateService from "./translate-service";

const parseWebform = function parseWebform (entityInfo, currentTimeInMillis, room, labels) {
  function translate (name) {
    return translateService.getLabelsTranslated(labels, name);
  }
  let webForm = {
    "label": entityInfo.label,
    "viewName": entityInfo.viewName,
    "formAttributes": {},
    "entityInfo": entityInfo,
    "formValues": {},
    "callGetDisabledDates": false,
    "submitted": false,
    "APIerror": undefined,
  };
  webForm.entityInfo.attributes.sort(function (a, b) {return Number(a.position) - Number(b.position);});
  for (let formAttribute of webForm.entityInfo.attributes) {
    let fieldProps = {
      "fieldKey": formAttribute.path,
      "label": formAttribute.label,
      "iconClassName": "fa fa-icon",
      "className": formAttribute.path,
      "errorLabel": formAttribute.label,
    };
    webForm.formAttributes[formAttribute.path] = { "key": formAttribute.path, "errorLabel": formAttribute.label, "fieldType": formAttribute.fieldType, "value": "", "show": true};
    webForm.formAttributes[formAttribute.path].fieldProps = fieldProps;
    if (formAttribute.additionalAttributes) {
      for (let additionalAttribute of formAttribute.additionalAttributes.split(";")) {
        if (additionalAttribute.split("=")[0] === "className") fieldProps.className = fieldProps.className + " " + additionalAttribute.split("=")[1];
        if (additionalAttribute.split("=")[0] === "iconClassName") fieldProps.iconClassName = additionalAttribute.split("=")[1];
        if (additionalAttribute.split("=")[0] === "regex") webForm.formAttributes[formAttribute.path].regex = additionalAttribute.replace("regex=", "");
        if (additionalAttribute.split("=")[0] === "component") webForm.formAttributes[formAttribute.path].component = additionalAttribute.split("=")[1];
        if (additionalAttribute.split("=")[0] === "minDate") webForm.formAttributes[formAttribute.path].minDateFromComponent = additionalAttribute.split("=")[1];
        if (additionalAttribute.split("=")[0] === "maxDate") webForm.formAttributes[formAttribute.path].maxDateFromComponent = additionalAttribute.split("=")[1];
        if (additionalAttribute.split("=")[0] === "maxDateType") webForm.formAttributes[formAttribute.path].maxDateTypeFromComponent = additionalAttribute.split("=")[1];
        if (additionalAttribute.split("=")[0] === "minValue") webForm.formAttributes[formAttribute.path].minValue = additionalAttribute.split("=")[1];
        if (additionalAttribute.split("=")[0] === "maxValue") webForm.formAttributes[formAttribute.path].maxValue = additionalAttribute.split("=")[1];
        if (additionalAttribute.split("=")[0] === "maxSize") webForm.formAttributes[formAttribute.path].maxSize = additionalAttribute.split("=")[1];
        if (additionalAttribute.split("=")[0] === "mandatory") webForm.formAttributes[formAttribute.path].mandatory = "true" === additionalAttribute.split("=")[1];
        if (additionalAttribute.split("=")[0] === "text") webForm.formAttributes[formAttribute.path].text = translate(additionalAttribute.split("=")[1]);
        if (additionalAttribute.split("=")[0] === "currencyLabel") webForm.formAttributes[formAttribute.path].currencyLabel = translate(additionalAttribute.split("=")[1]);
        if (additionalAttribute.split("=")[0] === "optTrue") webForm.formAttributes[formAttribute.path].optTrue = additionalAttribute.split("=")[1];
        if (additionalAttribute.split("=")[0] === "optFalse") webForm.formAttributes[formAttribute.path].optFalse = additionalAttribute.split("=")[1];
        if (additionalAttribute.split("=")[0] === "valueNoSubmittable") webForm.formAttributes[formAttribute.path].valueNoSubmittable = additionalAttribute.split("=")[1];
        if (additionalAttribute.split("=")[0] === "errorLabel") {
          webForm.formAttributes[formAttribute.path].errorLabel = translate(additionalAttribute.split("=")[1]);
          webForm.formAttributes[formAttribute.path].fieldProps.errorLabel = translate(additionalAttribute.split("=")[1]);
        }
        if (additionalAttribute.split("=")[0] === "label") {
          webForm.formAttributes[formAttribute.path].label = translate(additionalAttribute.split("=")[1]);
          webForm.formAttributes[formAttribute.path].fieldProps.label = translate(additionalAttribute.split("=")[1]);
        }
      }
    }
    if (formAttribute.selectOptions) webForm.formAttributes[formAttribute.path].selectOptions = formAttribute.selectOptions;
    if (formAttribute.mandatory === true) webForm.formAttributes[formAttribute.path].mandatory = true;
    if (formAttribute.dataType === "number") webForm.formAttributes[formAttribute.path].number = true;

    if (formAttribute.path === "guest.mobile.theNumber") {
      webForm.formAttributes[formAttribute.path].phone = true;
      webForm.formAttributes[formAttribute.path] = setWebFormPhoneNumber(webForm.formAttributes[formAttribute.path]);
    } else if (formAttribute.path === "jobDate") {
      webForm.callGetDisabledDates = true;
      webForm.formAttributes[formAttribute.path].jobDate = true;
      webForm.formAttributes[formAttribute.path] = setWebFormJobDate(webForm.formAttributes[formAttribute.path], currentTimeInMillis, "-");
    } else if (formAttribute.path === "enquiry.numberOfBedrooms") {
      webForm.formAttributes[formAttribute.path].sendValues = {};
      for (let el of webForm.formAttributes[formAttribute.path].selectOptions) webForm.formAttributes[formAttribute.path].sendValues[el.lookupValue] = el.id;
    }

    let component = webForm.formAttributes[formAttribute.path].component;
    if (formAttribute.fieldType === "DROPDOWN") {
      if (component && component.startsWith("MARKETING_") && room && formAttribute.path !== "enquiry.tmZone" ) {
        webForm.formAttributes[formAttribute.path].fieldProps.noPlaceHolder = true;
        webForm.formAttributes[formAttribute.path].marketingDropdown = true;
      }
      if (component && component.startsWith("MARKETING_")) {
        if (formAttribute.path !== "enquiry.tmZone") webForm.formAttributes[formAttribute.path].marketingDropdown = true;
        webForm.callMarketingCollections = true;
      }
    }
    if (component && component === "DATE_NOT_SURE" && formAttribute.fieldType === "DATE") {
      webForm.formAttributes[formAttribute.path].dateWithCheckbox = true;
      webForm.formAttributes[formAttribute.path] = setWebFormDateNotSure(webForm.formAttributes[formAttribute.path], currentTimeInMillis, "false");
    }
    if (component && component === "BUDGET") {
      webForm.formAttributes[formAttribute.path].number = true;
      webForm.formAttributes[formAttribute.path].mandatoryRange = true;
    }
    if (component && component === "CHECKBOX") {
      webForm.formAttributes[formAttribute.path].boolean = true;
      webForm.formAttributes[formAttribute.path] = setWebFormValue(webForm.formAttributes[formAttribute.path], "false");
    }
  }
  webForm.fieldsWithErrors = checkWebForm(webForm.formAttributes, labels).fieldsWithErrors;
  return webForm;
};


const setWebFormPhoneNumber = function setWebFormPhoneNumber (webFormAttribute, countryValue, phoneValue) {
  if (webFormAttribute.phone) {
    webFormAttribute.countryValue = countryValue ? countryValue : "";
    webFormAttribute.phoneValue = phoneValue ? phoneValue : "";
    webFormAttribute.fieldProps.countryValue = countryValue ? countryValue : "";
    webFormAttribute.fieldProps.phoneValue = phoneValue ? phoneValue : "";
  }
  return webFormAttribute;
};
const setWebFormJobDate = function setWebFormJobDate (webFormAttribute, dateValue, hourValue) {
  if (webFormAttribute.jobDate) {
    webFormAttribute.dateValue = dateValue ? dateValue : "";
    webFormAttribute.hourValue = hourValue ? hourValue : "";
    webFormAttribute.fieldProps.dateValue = dateValue;
    webFormAttribute.fieldProps.hourValue = hourValue;
  }
  return webFormAttribute;
};
const setWebFormDateNotSure = function setWebFormDateNotSure (webFormAttribute, dateValue, checkBoxValue) {
  if (webFormAttribute.dateWithCheckbox) {
    webFormAttribute.dateValue = dateValue ? dateValue : "";
    webFormAttribute.checkBoxValue = checkBoxValue ? checkBoxValue : "";
    webFormAttribute.fieldProps.dateValue = dateValue;
    webFormAttribute.fieldProps.checkBoxValue = checkBoxValue;
  }
  return webFormAttribute;
};
const setWebFormValue = function setWebFormValue (webFormAttribute, value) {
  webFormAttribute.value = value ? value : "";
  webFormAttribute.fieldProps.value = value ? value : "";
  return webFormAttribute;
};
const setWebFormBoolean = function setWebFormBoolean (webFormAttribute, value) {
  if (value === "false") value = "true";
  else value = "false";
  webFormAttribute.value = value;
  webFormAttribute.fieldProps.value = value;
  return webFormAttribute;
};

function checkWebForm (formAttributes, labels) {
  let fieldsWithErrors = {};
  let has_errors = false;

  function translate (name) {
    return translateService.getLabelsTranslated(labels, name);
  }

  function checkRegex (field) {
    let re = new RegExp(field.regex);
    if (!fieldsWithErrors[field.key] && field.value && !re.test(field.value)) {
      has_errors = true;
      fieldsWithErrors[field.key] = format_path(translate("validation.regexNotValid"), {"ERROR_LABEL": field.errorLabel});
    }
  }
  function checkNumberValue (field) {
    if (!fieldsWithErrors[field.key] && field.value && isNaN(field.value)) {
      has_errors = true;
      fieldsWithErrors[field.key] = format_path(translate("validation.numberNotValid"), {"ERROR_LABEL": field.errorLabel});
    }
  }
  function checkRange (field) {
    if (!fieldsWithErrors[field.key] && field.minValue && field.maxValue && (Number(field.value) < Number(field.minValue) || Number(field.value) > Number(field.maxValue))) {
      has_errors = true;
      fieldsWithErrors[field.key] = format_path(translate("validation.mandatoryRange"), {"ERROR_LABEL": field.errorLabel});
    }
  }
  function checkMaxSizeString (field) {
    if (!fieldsWithErrors[field.key] && field.value.length > field.maxSize) {
      let valuesForLabel = {
        "ERROR_LABEL": field.errorLabel,
        "MAX_SIZE": field.maxSize,
        "LENGTH_OF_VALUE": field.value.length,
      };
      has_errors = true;
      fieldsWithErrors[field.key] = format_path(translate("validation.mandatoryStringSize"), valuesForLabel);
    }
  }

  function checkMandatory (field) {
    if (field.key === "jobDate") {
      if (field.fieldProps.hourValue === "" || field.fieldProps.hourValue === "-" || field.fieldProps.dateValue === "" || field.fieldProps.dateValue === "-") {
        has_errors = true;
        fieldsWithErrors[field.key] = format_path(translate("validation.isRequired"), {"ERROR_LABEL": field.errorLabel});
      }
    }
    else if (field.key === "guest.mobile.theNumber") {
      if (field.fieldProps.phoneValue === "" || field.fieldProps.phoneValue === "-" || field.fieldProps.countryValue === "" || field.fieldProps.countryValue === "-") {
        has_errors = true;
        fieldsWithErrors[field.key] = format_path(translate("validation.isRequired"), {"ERROR_LABEL": field.errorLabel});
      }
    }
    else if (!field.value || field.value === "" || field.value === "-" || field.value === "false") {
      has_errors = true;
      fieldsWithErrors[field.key] = format_path(translate("validation.isRequired"), {"ERROR_LABEL": field.errorLabel});
    }
  }
  for (let field of Object.keys(formAttributes)) {
    if (formAttributes[field].mandatory) checkMandatory(formAttributes[field]);
    if (formAttributes[field].regex) checkRegex(formAttributes[field]);
    if (formAttributes[field].number) checkNumberValue(formAttributes[field]);
    if (formAttributes[field].mandatoryRange) checkRange(formAttributes[field]);
    if (formAttributes[field].maxSize) checkMaxSizeString(formAttributes[field]);
  }
  return {canSubmit: !has_errors, fieldsWithErrors: fieldsWithErrors};
}

function formAtributesToValues (webForm) {
  let rtn = {};
  for (let key of Object.keys(webForm.formAttributes)) {
    rtn[key] = webForm.formAttributes[key].value;
    if (key === "jobDate") {
      rtn[key] = {
        "dateValue": webForm.formAttributes[key].dateValue,
        "hourValue": webForm.formAttributes[key].hourValue,
      };
    } else if (key === "guest.mobile.theNumber") {
      rtn[key] = {
        "countryValue": webForm.formAttributes[key].countryValue,
        "phoneValue": webForm.formAttributes[key].phoneValue,
      };
    } else if (key === "enquiry.start") {
      rtn[key] = {
        "dateValue": webForm.formAttributes[key].dateValue,
        "checkBoxValue": webForm.formAttributes[key].checkBoxValue,
      };
    }
  }
  return rtn;
}

function getRoomDisplayValues (state, key, room) {

  let rtn = {};
  if (key === "area") rtn = state.marketingCollections.locations.filter(function (el) { return el.lookupValue === state.webForm.formAttributes.area.value;})[0].displayValue;
  else if (key === "building") {
    rtn = state.marketingCollections.locations.filter(function (el) { return el.lookupValue === state.webForm.formAttributes.area.value;})[0].buildings.filter( function (building) {
      return building.lookupValue === state.webForm.formAttributes.building.value;})[0].displayValue;
  } else if (key === "enquiry.propertyType") {
    rtn = state.marketingCollections.locations.filter(function (el) { return el.lookupValue === state.webForm.formAttributes.area.value;})[0].buildings.filter( function (building) {
      return building.lookupValue === state.webForm.formAttributes.building.value;})[0].roomTypes.filter(function (roomType) {
      return roomType.lookupValue === state.webForm.formAttributes["enquiry.propertyType"].value;})[0].displayValue;
  } else if (key === "enquiry.tmZone") {
    if (room) {
      rtn = state.marketingCollections.locations.filter(function (el) { return el.lookupValue === state.data.room.location.lookupValue;})[0].bookingTypes.filter( function (bookType) {
        return bookType.lookupValue === state.webForm.formAttributes["enquiry.tmZone"].value;})[0].displayValue;
    }
    else rtn = state.marketingCollections.locations.filter(function (el) { return el.lookupValue === state.webForm.formAttributes.area.value;})[0].bookingTypes.filter( function (bookType) {
      return bookType.lookupValue === state.webForm.formAttributes["enquiry.tmZone"].value;})[0].displayValue;
  }
  return rtn;
}

function refreshBookingTypeDropdown (webForm, marketingCollections) {
  //BOOKING TYPE - it depends of area
  webForm.formAttributes["enquiry.tmZone"].selectOptions = [];
  if (marketingCollections.locations && webForm.formAttributes.area.value !== "-" && webForm.formAttributes.area.value !== "") {
    for (let area of marketingCollections.locations.filter(function (el) {
      if (webForm.formAttributes.area.value === el.lookupValue) return el.lookupValue === webForm.formAttributes.area.value;
      return el.lookupValue === webForm.formAttributes.area.value;})) {
      if (area.bookingTypes && area.bookingTypes.length > 0) for (let bookType of area.bookingTypes) webForm.formAttributes["enquiry.tmZone"].selectOptions.push(bookType);
    }
  }
  return webForm;
}

module.exports = {
  parseWebform: function (attributes, currentTimeInMillis, room, labels) {
    return parseWebform(attributes, currentTimeInMillis, room, labels);
  },
  getPayload: function (webForm, state) {
    let rtn = {
      "viewName": webForm.viewName,
      "submitParameters": [],
    };

    for (let path of Object.keys(webForm.formAttributes)) {
      if (webForm.formAttributes[path].valueNoSubmittable) continue;
      if (path === "guest.mobile.theNumber" && webForm.formAttributes["guest.mobile.theNumber"].fieldProps.phoneValue !== "") {
        let countryValue = webForm.formAttributes["guest.mobile.theNumber"].fieldProps.countryValue;
        if (countryValue !== "") rtn.submitParameters.push({ "name": path, "value": "+" + countryValue + webForm.formAttributes["guest.mobile.theNumber"].fieldProps.phoneValue});
        else rtn.submitParameters.push({ "name": path, "value": webForm.formAttributes["guest.mobile.theNumber"].fieldProps.phoneValue});
      } else if (path === "jobDate" && webForm.formAttributes["jobDate"].fieldProps.dateValue !== "") {
        let hourValue = webForm.formAttributes["jobDate"].fieldProps.hourValue;
        let dateValue = webForm.formAttributes["jobDate"].fieldProps.dateValue;
        if (hourValue !== "" && hourValue !== "-") rtn.submitParameters.push({ "name": path, "value": moment.unix(dateValue).format("YYYY-MM-DD") + "T" + ((hourValue < 10) ? "0" : "") + hourValue + ":00"});
        else rtn.submitParameters.push({ "name": path, "value": moment.unix(dateValue).format("YYYY-MM-DD")});
      } else if (path === "enquiry.start") {
        let dateValue = moment.unix(webForm.formAttributes["enquiry.start"].fieldProps.dateValue).format("YYYY-MM-DD");
        if (webForm.formAttributes["enquiry.start"].fieldProps.checkBoxValue === "true") dateValue = "";
        rtn.submitParameters.push({ "name": path, "value": dateValue});
      } else if (webForm.formAttributes[path].boolean) {
        if (webForm.formAttributes[path].optTrue || webForm.formAttributes[path].optFalse) {
          if (webForm.formAttributes[path].fieldProps.value === "true") rtn.submitParameters.push({ "name": webForm.formAttributes[path].optTrue, "value": true});
          else rtn.submitParameters.push({ "name": webForm.formAttributes[path].optFalse, "value": true});
        } else {
          rtn.submitParameters.push({ "name": path, "value": webForm.formAttributes[path].fieldProps.value === "true"});
        }
      } else if (path === "enquiry.numberOfBedrooms" && webForm.formAttributes[path].value.length) {
        if (webForm.formAttributes[path].value.length === 1) {
          rtn.submitParameters.push({ "name": "enquiry.numberOfBedrooms", "value": webForm.formAttributes[path].sendValues[webForm.formAttributes[path].value[0]]});
          rtn.submitParameters.push({ "name": "enquiry.maxBeds", "value": webForm.formAttributes[path].sendValues[webForm.formAttributes[path].value[0]]});
        } else {
          let valuesSorted = webForm.formAttributes[path].value.sort();
          rtn.submitParameters.push({ "name": "enquiry.numberOfBedrooms", "value": webForm.formAttributes[path].sendValues[valuesSorted[0]]});
          rtn.submitParameters.push({ "name": "enquiry.maxBeds", "value": webForm.formAttributes[path].sendValues[valuesSorted[valuesSorted.length - 1]]});
        }
      } else if (webForm.formAttributes[path].value !== "") {
        rtn.submitParameters.push({ "name": path, "value": webForm.formAttributes[path].value});
      }
    }
    return rtn;
  },
  checkWebForm: function (formAttributes, labels) {
    return checkWebForm(formAttributes, labels);
  },
  formAtributesToValues: function (webForm) {
    return formAtributesToValues(webForm);
  },
  setWebFormPhoneNumber: function (webFormAttribute, countryValue, phoneValue)
  {
    return setWebFormPhoneNumber(webFormAttribute, countryValue, phoneValue);
  },
  setWebFormJobDate: function (webFormAttribute, dateValue, hourValue) {
    return setWebFormJobDate(webFormAttribute, dateValue, hourValue);
  },
  setWebFormDateNotSure: function (webFormAttribute, dateValue, checkboxValue) {
    return setWebFormDateNotSure(webFormAttribute, dateValue, checkboxValue);
  },
  setWebFormBoolean: function (webFormAttribute, value) {
    return setWebFormBoolean(webFormAttribute, value);
  },
  setBedRoom: function (webFormAttribute, value) {
    webFormAttribute.value = value;
    webFormAttribute.fieldProps.value = value;
    return webFormAttribute;
  },
  setArea: function (webForm, lookupValue) {
    webForm.formAttributes.building.value = "-";
    webForm.formAttributes.building.fieldProps.value = "-";
    webForm.formAttributes["enquiry.propertyType"].value = "-";
    webForm.formAttributes["enquiry.propertyType"].fieldProps.value = "-";
    webForm.formAttributes["enquiry.tmZone"].fieldProps.value = "-";
    webForm.formAttributes["enquiry.tmZone"].value = "-";

    webForm.formAttributes.area.value = lookupValue;
    webForm.formAttributes.area.fieldProps.value = lookupValue;

    return webForm;
  },
  setBuilding: function (webForm, lookupValue, displayValue) {
    webForm.formAttributes["enquiry.propertyType"].value = "-";
    webForm.formAttributes["enquiry.propertyType"].fieldProps.value = "-";
    webForm.formAttributes.building.value = lookupValue;
    webForm.formAttributes.building.value = lookupValue;
    webForm.formAttributes.building.fieldProps.value = lookupValue;

    return webForm;
  },
  setWebFormValue: function (webFormAttribute, value) {
    return setWebFormValue(webFormAttribute, value);
  },
  setRoomValues: function (webForm, room) {
    if (webForm.formAttributes["enquiry.propertyType"]) {
      webForm.formAttributes["enquiry.propertyType"].value = room.roomType.lookupValue;
      //TODO: add the object lookupValue and display value to value, instead of only the value, after we will see how manage that when we draw
      webForm.formAttributes["enquiry.propertyType"].selectOptions = [{
        "lookupValue": room.roomType.lookupValue,
        "displayValue": room.roomType.displayValue,
      }];
    }
    if (webForm.formAttributes["area"]) {
      webForm.formAttributes["area"].value = room.location.lookupValue;
      webForm.formAttributes["area"].selectOptions = [{
        "lookupValue": room.location.lookupValue,
        "displayValue": room.location.displayValue,
      }];
    }
    if (webForm.formAttributes["building"]) {
      webForm.formAttributes["building"].value = room.building.lookupValue;
      webForm.formAttributes["building"].selectOptions = [{
        "lookupValue": room.building.lookupValue,
        "displayValue": room.building.displayValue,
      }];
    }
    return webForm;
  },
  getListValuesToReplace: function (room, state) {
    let rtn = {};
    if (room) {
      if (state.webForm.formAttributes["area"] && state.webForm.formAttributes["area"].value !== "" && state.webForm.formAttributes["area"].value !== "-") rtn.LOCATION_NAME = state.webForm.formAttributes["area"].selectOptions[0].displayValue ;
      if (state.webForm.formAttributes["building"] && state.webForm.formAttributes["building"].value !== "" && state.webForm.formAttributes["building"].value !== "-") rtn.BUILDING_NAME = state.webForm.formAttributes["building"].selectOptions[0].displayValue;
      if (state.webForm.formAttributes["enquiry.propertyType"] && state.webForm.formAttributes["enquiry.propertyType"].value !== "" && state.webForm.formAttributes["enquiry.propertyType"].value !== "-") rtn.ROOMTYPE_NAME = state.webForm.formAttributes["enquiry.propertyType"].selectOptions[0].displayValue;
      if (state.webForm.formAttributes["enquiry.tmZone"] && state.webForm.formAttributes["enquiry.tmZone"].value !== "" && state.webForm.formAttributes["enquiry.tmZone"].value !== "-") rtn.BOOKINGTYPE_NAME = getRoomDisplayValues(state, "enquiry.tmZone", room);
    } else {
      if (state.webForm.formAttributes["area"] && state.webForm.formAttributes["area"].value !== "" && state.webForm.formAttributes["area"].value !== "-") rtn.LOCATION_NAME = getRoomDisplayValues(state, "area");
      if (state.webForm.formAttributes["building"] && state.webForm.formAttributes["building"].value !== "" && state.webForm.formAttributes["building"].value !== "-") rtn.BUILDING_NAME = getRoomDisplayValues(state, "building");
      if (state.webForm.formAttributes["enquiry.propertyType"] && state.webForm.formAttributes["enquiry.propertyType"].value !== "" && state.webForm.formAttributes["enquiry.propertyType"].value !== "-") rtn.ROOMTYPE_NAME = getRoomDisplayValues(state, "enquiry.propertyType");
      if (state.webForm.formAttributes["enquiry.tmZone"] && state.webForm.formAttributes["enquiry.tmZone"].value !== "" && state.webForm.formAttributes["enquiry.tmZone"].value !== "-") rtn.BOOKINGTYPE_NAME = getRoomDisplayValues(state, "enquiry.tmZone");
    }
    return rtn;
  },
  refreshMarketingDropdowns (webForm, marketingCollections) {

    //BUILDING - it depends of area
    webForm.formAttributes["building"].selectOptions = [];
    if (marketingCollections.locations && webForm.formAttributes.area.value !== "-" && webForm.formAttributes.area.value !== "") {
      let location = marketingCollections.locations.filter(function (el) {return el.lookupValue === webForm.formAttributes.area.value;})[0];
      if (location) for (let building of location.buildings) webForm.formAttributes["building"].selectOptions.push(building);
    }
    //ROOMTYPE - it depends fo building
    webForm.formAttributes["enquiry.propertyType"].selectOptions = [];
    if (marketingCollections.locations && webForm.formAttributes.area.value !== "-" && webForm.formAttributes.area.value !== "") {
      let location = marketingCollections.locations.filter(function (el) {return el.lookupValue === webForm.formAttributes.area.value;})[0];
      if (location && marketingCollections.locations && webForm.formAttributes.building.value !== "-" && webForm.formAttributes.building.value !== "") {
        let building = location.buildings.filter(function (el) {return el.lookupValue === webForm.formAttributes.building.value;})[0];
        if (building) for (let room of building.roomTypes) webForm.formAttributes["enquiry.propertyType"].selectOptions.push(room);
      }
    }

    webForm = refreshBookingTypeDropdown(webForm, marketingCollections);
    return webForm;
  },
  refreshBookingTypeDropdown (webForm, marketingCollections) {
    return refreshBookingTypeDropdown(webForm, marketingCollections);
  },
  setMarketingCollections (webForm, marketingCollections) {
    for (let key of Object.keys(webForm.formAttributes)) {
      if (webForm.formAttributes[key].marketingDropdown) {
        //Select options
        if (key === "area") webForm.formAttributes["area"].selectOptions = marketingCollections.locations;
        else if (key === "enquiry.tmZone") webForm.formAttributes[key].selectOptions = [];
        else webForm.formAttributes[key].selectOptions = [];
        //settings from API
        if (key === "enquiry.tmZone") {
          let addBookintTypes = marketingCollections.settings["marketing.locations.addBookingTypes"];
          if (!addBookintTypes || addBookintTypes === "false")webForm.formAttributes[key].show = false;
        }
      }
    }
    return webForm;
  },
  processDates (newState, webForm) {

    if (newState.disabledDates && newState.disabledDates.length > 0 && newState.disabledDates.filter( function (d) { return d === moment.unix(webForm.formAttributes.jobDate.dateValue).format("YYYY-MM-DD");}).length > 0) {
      //if today is in the disabledDates list we have to search the net available date if we have it, so we loop until the last date and we check if is disabled(founde=true) if we don't find then "roomUnavailableToView": true
      let found = false;
      let i = 0;
      while (newState.disabledDates[newState.disabledDates.length - 1] !== moment.unix(webForm.formAttributes.jobDate.dateValue).add(i, "days").format("YYYY-MM-DD") && !found) {
        i++;
        if (newState.disabledDates.filter( function (d) { return d === moment.unix(webForm.formAttributes.jobDate.dateValue).add(i, "days").format("YYYY-MM-DD");}).length === 0) found = true;
      }
      if (found) newState.values = Object.assign(webForm.values, { "jobDate": moment.unix(webForm.formAttributes.jobDate.dateValue).add(i, "days").unix()});
      else newState = Object.assign({ "data": null, "roomUnavailableToView": true, "nextAction": "DONE"});
    }
    newState.excludeDates = [];
    if (newState.disabledDates && newState.disabledDates.length > 0) for (let d of newState.disabledDates) newState.excludeDates.push(moment(d));

    return newState;
  },
  getError (message, messageType, webContents, labels, webcontentKey, labelKey) {
    let rtn = message;
    if (messageType && labels[labelKey + messageType]) return labels[labelKey + messageType];
    else if (messageType && webContents && webContents[webcontentKey + messageType]) return webContents[webcontentKey + messageType];
    return rtn;
  },
  getCountries (countries) {
    let rtn = countries.map(function (country) {
      let obj = {};
      obj.lookupValue = country.lookupValue;
      obj.diallingCode = country.diallingCode;
      obj.displayValue = country.displayValue + " (" + country.diallingCode + ")";
      return obj;
    }).filter(function (el) {return el.diallingCode && Number.isInteger(Number(el.diallingCode.substring(0, 1)));});
    return rtn;
  },
};
