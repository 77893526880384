import React from 'react';
import ImageGallery from 'react-image-gallery';
import { createStore } from 'redux';
import ajax from '../../lib/ajax.js';
import translateService from '../services/translate-service.js';
import roomService from "../services/room-service";
import searchService from "../services/search-service";
let api = require('../../lib/api.js')(ajax);
let format_path = require('../../lib/utils.js').format_path;
let sanitize = require('../../lib/utils.js').sanitize;
const defaultLabels = JSON.parse(JSON.stringify(require("../../../lang/search-applet/en-GB.json"))).labels["en-GB"];

function initState () {
  //TODO: bookingTypes doesn't follow the logic to init values.
  return {
    "roomTypes": null,
    "nextAction": "getLabels",
  };
}

export default class ResultsInBuilding extends React.Component {

  constructor (props) {
    super(props);
    this.store = createStore(function (state = {}, action ) {
      // console.log(action.type);
      switch (action.type) {
        case "LOAD": return Object.assign({}, state, initState ());
        case "GET_LABELS_OK": return Object.assign({}, state, {"labels": action.labels, "nextAction": "progressiveSearchForm"});
        case "PROGRESSIVE_SEARCH_FORM_OK": return Object.assign({}, state, {"nextAction": "getMarketingCollections"});
        case "GET_MARKETING_COLLECTIONS_OK": {
          let newState = {
            "roomTypes": action.roomTypes,
            "activatedRoomType": null,
            "locationLookupValue": action.locationLookupValue,
            "nextAction": "search",
          };
          return Object.assign({}, state, newState);
        }
        case "SEARCH_OK": return Object.assign({}, state, {"searchData": action.searchData, "nextAction": "DONE"});
        case "SHOW_ERROR": return Object.assign({}, state, {data: {error: action.message}, "nextAction": "DONE"});
        case "UPDATE_ACTIVATED_ROOM_TYPE": return Object.assign({}, state, {activatedRoomType: action.activatedRoomType});
        default:
          return state;
      }
    }, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
    this.state = {};
  }

  render () {
    if (this.state.roomTypes) {
      let linkTemplates = { book: this.props.book, enquire: this.props.enquire, viewing: this.props.viewing};

      return <Results state ={this.state}
        building={this.props.building}
        linkTemplates={linkTemplates}
        charsBeforeEllipsis={Number(this.props.charsBeforeEllipsis)}
        handleClick={::this.handleClick} />;
    } else if (this.state.data && this.state.data.error) {
      return <div>{this.state.data.error}</div>;
    } else {
      return <div>just a sec</div>;
    }
  }

  handleClick (roomTypeLookupValue) {
    if (this.state.activatedRoomType !== roomTypeLookupValue) {
      this.store.dispatch({type: "UPDATE_ACTIVATED_ROOM_TYPE", activatedRoomType: roomTypeLookupValue});
    } else {
      this.store.dispatch({type: "UPDATE_ACTIVATED_ROOM_TYPE", activatedRoomType: null});
    }
  }

  componentDidMount () {
    this.unsubscribe = this.store.subscribe(function () {
      let state = {};
      for (let key of Object.keys(this.state)) state[key] = null;
      this.setState(Object.assign({}, state, this.store.getState()));
    }.bind(this));

    let error = null;

    if (!this.props.building) error = "Configuration error - no building specified";
    else if (!this.props.api) error = "Configuration error - no api specified";

    if (error) {
      this.store.dispatch({type: "SHOW_ERROR", message: error});
      return;
    }

    window.onpopstate = function () { this.store.dispatch({type: "LOAD"}); }.bind(this);
    this.store.dispatch({type: "LOAD"});

  }

  componentDidUpdate () {
    // console.log(this.state.nextAction);
    switch (this.state.nextAction) {
      case "getLabels":
      case "getMarketingCollections":
      case "search":
      case "progressiveSearchForm":
        this[this.state.nextAction]();
    }
  }

  getLabels () {
    let lang = "en-GB";
    if (this.props.lang) lang = this.props.lang;
    let self = this;
    api.getLabels(this.props.api, {"languageIds": [lang], "group": "resultsInBuilding"}, function (response) {
      self.store.dispatch({type: "GET_LABELS_OK", "labels": response.labels[lang]});
    }, function () {
      self.store.dispatch({type: "SHOW_ERROR", message: "API error 237555 getLabels"});
    });
  }

  getMarketingCollections () {
    let self = this;
    api.marketingCollections(this.props.api, {}, function (response) {
      let roomTypes = {};
      let filterRoomType = [];
      let locationLookupValue = null;
      if (self.props.roomTypeSearch) filterRoomType = self.props.roomTypeSearch.split(',');
      for (let location of response.locations) {
        if (location.buildings) {
          let mybuilding = location.buildings.filter(function (el) { return el.lookupValue === self.props.building;});
          if (mybuilding.length) {
            roomTypes = {};
            locationLookupValue = location.lookupValue;
            for (let roomType of mybuilding[0].roomTypes.sort(function (a, b) { return a.displayValue > b.displayValue; })) {
              if (filterRoomType.length) {
                if (filterRoomType.includes(roomType.lookupValue)) roomTypes[roomType.lookupValue] = roomType;
              } else {
                roomTypes[roomType.lookupValue] = roomType;
              }
            }
            break;
          }
        }
      }
      if (!Object.keys(roomTypes).length) {
        let noResultsMsg = self.props.noResultsMsg || "No results for " + self.props.building;
        self.store.dispatch({type: "SHOW_ERROR", message: noResultsMsg});
      } else {
        self.store.dispatch({type: "GET_MARKETING_COLLECTIONS_OK", "roomTypes": roomTypes, "locationLookupValue": locationLookupValue });
      }
    }, function () {
      self.store.dispatch({type: "SHOW_ERROR", message: "API error 2342342 getMarketingCollections"});
    });

  }

  search () {
    let self = this;
    let tags = [];
    let payload = {"categories": [{"lookupValue": "BUILDING", "tags": [{"lookupValue": this.props.building}]}]};
    if (this.props.gender) payload.genders = [{"lookupValue": this.props.gender}];
    for (let key of Object.keys(this.state.roomTypes)) tags.push({"lookupValue": key});
    if (tags.length) payload.categories.push({"lookupValue": "PROPERTYTYPE", "tags": tags});

    api.progressiveSearchSearch(this.props.api, payload, function (searchData) {

      function parseOffer (offer) {
        offer.roomData = {};
        offer.roomData = roomService.getDetails(offer.roomData, offer.room);
        offer.roomData = roomService.getCategories(offer.roomData, offer.room);
        return offer;
      }

      let roomTypes = self.state.roomTypes;
      if (searchData.offers) {
        for (let offer of searchData.offers) offer = parseOffer(offer);

        for (let key of Object.keys(roomTypes)) {
          let myOffer = roomTypes[key].offer = searchData.offers.filter(function (offer) { return offer.roomData.roomType.lookupValue === key; });
          if (myOffer.length) roomTypes[key].offer = myOffer[0];
          else roomTypes[key].offer = null;
        }
      }

      self.store.dispatch({type: "SEARCH_OK", "roomTypes": roomTypes });
    }, function () {
      self.store.dispatch({type: "SHOW_ERROR", message: "API error 2342352 progressiveSearchSearch"});
    });
  }

  progressiveSearchForm () {
    let self = this;
    api.progressiveSearchForm(this.props.api, {}, function (searchFormResponse) {
      let strictGender = false;
      if (searchFormResponse.settings && searchFormResponse.settings["booking.strictGenders"]) strictGender = searchFormResponse.settings["booking.strictGenders"];
      if (strictGender && !self.props.gender) self.store.dispatch({type: "SHOW_ERROR", message: "Configuration error - Strict gender activated. You need gender attribute to use this plug-in"});
      else self.store.dispatch({ type: "PROGRESSIVE_SEARCH_FORM_OK"});
    }, function (err) {
      self.store.dispatch({type: "SHOW_ERROR", message: "API error 2349235 progressiveSearchForm"});
    });
  }

}

class Results extends React.Component {
  handleClick (roomTypeLookupValue) {
    this.props.handleClick(roomTypeLookupValue);
  }

  render () {
    let self = this;

    if (!this.props.state.roomTypes) return <div>No results</div>;

    let roomTypes = [];
    for (let key of Object.keys(this.props.state.roomTypes)) {
      let props = {};
      props.images = "Get me from the first offer of this roomType. When you can filter by roomTpe";
      props.description = "Get me from the first offer of this roomType. When you can filter by roomTpe";
      props.roomType = this.props.state.roomTypes[key];
      props.building = this.props.building;
      props.activated = key === self.props.state.activatedRoomType;
      props.handleClick = self.handleClick.bind(self, key);
      props.linkTemplates = self.props.linkTemplates;
      props.labels = self.props.labels;
      props.charsBeforeEllipsis = self.props.charsBeforeEllipsis;
      props.location = self.props.state.locationLookupValue;
      roomTypes.push(<RoomType key={key} {...props} />);
    }

    return <div className="pex-rooms-in-building"> {roomTypes}</div>;
  }
}

class RoomType extends React.Component {
  render () {
    let imageList = new Array();
    if (this.props.roomType.offer && this.props.roomType.offer.roomData && this.props.roomType.offer.roomData.images) {
      for (let i = 0; i < this.props.roomType.offer.roomData.images.length; i++) {
        imageList.push({ original: this.props.roomType.offer.roomData.images[i].src, thumbnail: this.props.roomType.offer.roomData.images[i].src });
      }
      if (this.props.roomType.offer.roomData.tabs.fplan) imageList.push({ original: this.props.roomType.offer.roomData.tabs.fplan, thumbnail: this.props.roomType.offer.roomData.tabs.fplan });
    }

    let myCarousel = <ImageGallery showThumbnails={false} items={imageList}/>;
    let roomDescription = "";
    if (this.props.roomType.offer && this.props.roomType.offer.roomData && this.props.roomType.offer.roomData.shortDescription) {
      roomDescription = this.props.roomType.offer.roomData.shortDescription;
    }
    if (this.props.roomType.offer && this.props.roomType.offer.roomData && this.props.roomType.offer.roomData.shortDescription &&
         this.props.charsBeforeEllipsis && roomDescription.length >= this.props.charsBeforeEllipsis) {
      roomDescription = roomDescription.substring(0, this.props.charsBeforeEllipsis) + "...";
    }
    if (roomDescription !== "") roomDescription = <div className="room-type-description col-xs-12"><p dangerouslySetInnerHTML={{__html: sanitize(roomDescription)}} /></div>;
    else if (!this.props.roomType.offer) roomDescription = <div className="room-type-description col-xs-12 alert alert-danger not-available">{"There are no " + this.props.roomType.displayValue + " rooms available right now "}</div>;

    if (!this.props.roomType) {
      return null;
    } else {

      return <div className={"pex-room-type panel panel-default" + (this.props.activated ? " pex-active" : "")}>
        <div className="panel-heading text-center" onClick={this.props.handleClick}>
          <div className=""><h3><strong>{ this.props.roomType.displayValue }</strong></h3></div>
        </div>
        <div className="panel-body">
          <div className="room-type-image">
            {imageList.length > 0 ? myCarousel : <div className="no carousel">test</div> }
          </div>
          <div className="text-center below-image" onClick={this.props.handleClick}>
            <div className=""><h3><strong>{ this.props.roomType.displayValue }</strong></h3></div>
          </div>
          {roomDescription}
          <div className="room-type-rooms col-xs-12">
            <div className="room-type-headings"></div>
            <RoomTypeInfo {...this.props} />
          </div>
        </div>
      </div>;
    }

  }
}

class RoomTypeInfo extends React.Component {

  translate (name) {
    return translateService.getLabelsTranslated(this.props.labels, "resultsInBuilding.", name, defaultLabels);
  }

  render () {
    let values = { LOCATION: this.props.location, ROOM_TYPE: this.props.roomType.lookupValue, BUILDING: this.props.building};
    if (this.props.roomType.offer) {
      values.ROOM = this.props.roomType.offer.room.lookupValue;
      values.OFFER = this.props.roomType.offer.lookupValue;
    }
    let buttons = [];

    if (this.props.linkTemplates.enquire) {
      buttons.push(<a key="enquire" className="pex-room-enquire btn btn-sm btn-default" href={format_path(this.props.linkTemplates.enquire, values)}><i className="fa fa-comments-o hidden-xs"></i>{" " + this.translate("enquiryButtonLabel")}</a>);
    }
    if (this.props.linkTemplates.viewing) {
      buttons.push(<a key="viewing" className="pex-room-viewing btn btn-sm btn-default" href={format_path(this.props.linkTemplates.viewing, values)}><i className="fa fa-eye hidden-xs"></i>{" " + this.translate("viewingButtonLabel")}</a>);
    }
    if (this.props.linkTemplates.book && this.props.roomType.offer) {
      buttons.push(<a key="book"className="pex-room-book btn btn-sm btn-success" href={format_path(this.props.linkTemplates.book, values)}><i className="fa fa-bed hidden-xs"></i>{" " + this.translate("bookButtonLabel")}</a>);
    }

    return <div className="pex-room row">
      <div className="well col-xs-12">
        <div className="col-md-4">
          <span className="price-currency">{ "From " }</span><span className="pex-room-price">{ this.props.roomType.marketingRent.number }</span> <span className="pex-room-frequency"><i>{ this.props.roomType.marketingRent.unit }</i></span>
        </div>
        <div className="col-xs-12 col-md-4"><div className="btn-group">{ buttons }</div></div>
      </div>
    </div>;
  }
}
