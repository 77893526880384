//inject ajax lib so that mocking doesn't have to change in tests

//function (api, path, payload, success, failure, ignoreStatus, additionalArgs) {
//hopefully we can mock this with this: http://stackoverflow.com/a/994406/5203563
module.exports = function (ajax) {
  //TODO: add all other endpoints into here and use this in other widgets
  return {
    progressiveSearchForm: function (api, payload, success, failure) {
      if (typeof payload === "function") throw "wrong arguments";
      ajax(api, "/rs/progressiveSearch/form", payload, success, failure);
    },
    progressiveSearchSearch: function (api, payload, success, failure) {
      if (typeof payload === "function") throw "wrong arguments";
      ajax(api, "/rs/progressiveSearch/search", payload, success, failure);
    },
    additionalRentalUnits: function (api, payload, success, failure) {
      if (typeof payload === "function") throw "wrong arguments";
      ajax(api, "/rs/progressiveSearch/additionalRentalUnits", payload, success, failure);
    },
    webServiceUserRegistration: function (api, payload, success, failure) {
      if (typeof payload === "function") throw "wrong arguments";
      ajax(api, "/rs/webService/userRegistration", payload, success, failure);
    },
    webServiceGetTenancyAgreement: function (api, payload, success, failure) {
      if (typeof payload === "function") throw "wrong arguments";
      ajax(api, "/rs/webService/getTenancyAgreement", payload, success, failure);
    },
    webServiceSignTenancyAgreement: function (api, payload, success, failure) {
      if (typeof payload === "function") throw "wrong arguments";
      ajax(api, "/rs/webService/signTenancyAgreement", payload, success, failure);
    },
    getUser: function (api, payload, success, failure) {
      if (typeof payload === "function") throw "wrong arguments";
      ajax(api, "/rs/webService/getUser", payload, success, failure);
    },
    updatePersonDetails: function (api, payload, success, failure) {
      if (typeof payload === "function") throw "wrong arguments";
      ajax(api, "/rs/webService/updatePersonDetails", payload, success, failure);
    },
    singleSignOnValidateobject: function (api, payload, success, failure) {
      if (typeof payload === "function") throw "wrong arguments";
      ajax(api, "/rs/singleSignOn/validateobject", payload, success, failure, true);
    },
    singleSignOnLoginobject: function (sso, payload, success, failure) {
      if (typeof payload === "function") throw "wrong arguments";
      ajax(sso, "/rs/singleSignOn/loginobject", payload, success, failure, true, {xhrFields: {withCredentials: true}});
    },
    singleSignOnLoginotp: function (sso, payload, success, failure) {
      if (typeof payload === "function") throw "wrong arguments";
      ajax(sso, "/rs/singleSignOn/loginotp", payload, success, failure, true, {xhrFields: {withCredentials: true}});
    },
    onlineBookingBook: function (api, payload, success, failure) {
      if (typeof payload === "function") throw "wrong arguments";
      ajax(api, "/rs/onlinebooking/book", payload, success, failure);
    },
    bookAdditionalRentalUnit: function (api, payload, success, failure) {
      if (typeof payload === "function") throw "wrong arguments";
      ajax(api, "/rs/onlinebooking/bookAdditionalRentalUnit", payload, success, failure);
    },
    addShortlist: function (api, payload, success, failure) {
      if (typeof payload === "function") throw "wrong arguments";
      ajax(api, "/rs/shortlist/add", payload, success, failure);
    },
    listShortlist: function (api, payload, success, failure) {
      if (typeof payload === "function") throw "wrong arguments";
      ajax(api, "/rs/shortlist/list", payload, success, failure);
    },
    removeShortlist: function (api, payload, success, failure) {
      if (typeof payload === "function") throw "wrong arguments";
      ajax(api, "/rs/shortlist/remove", payload, success, failure);
    },
    getDisabledDates: function (api, payload, success, failure) {
      if (typeof payload === "function") throw "wrong arguments";
      ajax(api, "/rs/viewing/getDisabledDates", payload, success, failure);
    },
    getTimeSlots: function (api, payload, success, failure) {
      if (typeof payload === "function") throw "wrong arguments";
      ajax(api, "/rs/viewing/getTimeSlots", payload, success, failure);
    },
    getTenancy: function (api, payload, success, failure) {
      if (typeof payload === "function") throw "wrong arguments";
      ajax(api, "/rs/webService/getTenancy", payload, success, failure);
    },
    getWorkflow: function (api, payload, success, failure) {
      if (typeof payload === "function") throw "wrong arguments";
      ajax(api, "/rs/workflowService/getWorkflow", payload, success, failure);
    },
    getRoomDetails: function (api, payload, success, failure) {
      if (typeof payload === "function") throw "wrong arguments";
      ajax(api, "/rs/webService/getRoomDetails", payload, success, failure);
    },
    createGeneralEnquiry: function (api, payload, success, failure) {
      if (typeof payload === "function") throw "wrong arguments";
      ajax(api, "/rs/webService/createGeneralEnquiry", payload, success, failure);
    },
    completeStep: function (api, payload, success, failure) {
      if (typeof payload === "function") throw "wrong arguments";
      ajax(api, "/rs/workflowService/completeStep", payload, success, failure);
    },
    getWebContent: function (api, payload, success, failure) {
      if (typeof payload === "function") throw "wrong arguments";
      ajax(api, "/rs/cmsService/getWebContent", payload, success, failure);
    },
    getFormCollections: function (api, payload, success, failure) {
      if (typeof payload === "function") throw "wrong arguments";
      ajax(api, "/rs/webService/getFormCollections", payload, success, failure);
    },
    init: function (api, payload, success, failure) {
      if (typeof payload === "function") throw "wrong arguments";
      ajax(api, "/rs/webService/init", payload, success, failure);
    },
    getWebform: function (api, payload, success, failure) {
      if (typeof payload === "function") throw "wrong arguments";
      ajax(api, "/rs/datasource/webform", payload, success, failure);
    },
    webFormSubmit: function (api, payload, success, failure) {
      if (typeof payload === "function") throw "wrong arguments";
      ajax(api, "/rs/datasource/webformsubmit", payload, success, failure);
    },
    getDirectDebitDetails: function (api, payload, success, failure) {
      if (typeof payload === "function") throw "wrong arguments";
      ajax(api, "/rs/directDebit/details", payload, success, failure);
    },
    newDirectDebit: function (api, payload, success, failure) {
      if (typeof payload === "function") throw "wrong arguments";
      ajax(api, "/rs/directDebit/newDirectDebit", payload, success, failure);
    },
    decline: function (api, payload, success, failure) {
      if (typeof payload === "function") throw "wrong arguments";
      ajax(api, "/rs/directDebit/decline", payload, success, failure);
    },
    getDirectDebitAgreement: function (api, payload, success, failure) {
      if (typeof payload === "function") throw "wrong arguments";
      ajax(api, "/rs/directDebit/agreement", payload, success, failure);
    },
    confirmDirectDebit: function (api, payload, success, failure) {
      if (typeof payload === "function") throw "wrong arguments";
      ajax(api, "/rs/directDebit/confirm", payload, success, failure);
    },
    searchAvailability: function (api, payload, success, failure) {
      if (typeof payload === "function") throw "wrong arguments";
      ajax(api, "/rs/webService/searchAvailability", payload, success, failure);
    },
    listBank: function (api, success, failure) {
      if (typeof success !== "function") throw "wrong arguments";
      ajax(api, "/rs/validatebank/list", undefined, success, failure, false, { type: 'GET'});
    },
    bankInfo: function (api, code, success, failure) {
      if (typeof success !== "function") throw "wrong arguments";
      if (typeof code !== 'string') throw "wrong arguments";
      ajax(api, "/rs/validatebank/info" + code, undefined, success, failure, true, { type: 'GET'});
    },
    bankExists: function (api, code, success, failure) {
      if (typeof success !== "function") throw "wrong arguments";
      if (typeof code !== 'string') throw "wrong arguments";
      ajax(api, "/rs/validatebank/exists" + code, undefined, success, failure, true, { type: 'GET'});
    },
    getLabels: function (api, payload, success, failure) {
      if (typeof payload === "function") throw "wrong arguments";
      ajax(api, "/rs/cmsService/getLabels", payload, success, failure);
    },
    marketingCollections: function (api, payload, success, failure) {
      if (typeof payload === "function") throw "wrong arguments";
      ajax(api, "/rs/marketing/marketingCollections", payload, success, failure);
    },
    lostPassword: function (api, payload, success, failure) {
      if (typeof payload === "function") throw "wrong arguments";
      ajax(api, "/rs/webService/lostPassword", payload, success, failure);
    },
    lostPasswordConfirmation: function (api, payload, success, failure) {
      if (typeof payload === "function") throw "wrong arguments";
      ajax(api, "/rs/webService/lostPasswordConfirmation", payload, success, failure);
    },
    getPaymentSchedules: function (api, payload, success, failure) {
      if (typeof payload === "function") throw "wrong arguments";
      ajax(api, "/rs/webService/getPaymentSchedules", payload, success, failure);
    },
    setPaymentFrequency: function (api, payload, success, failure) {
      if (typeof payload === "function") throw "wrong arguments";
      ajax(api, "/rs/webService/setPaymentFrequency", payload, success, failure);
    },
    setPaymentMethod: function (api, payload, success, failure) {
      if (typeof payload === "function") throw "wrong arguments";
      ajax(api, "/rs/webService/setPaymentMethod", payload, success, failure);
    },
    addPersonToTenancy: function (api, payload, success, failure) {
      if (typeof payload === "function") throw "wrong arguments";
      ajax(api, "/rs/webService/addPersonToTenancy", payload, success, failure);
    },
    cancelOnHoldBooking: function (api, payload, success, failure) {
      if (typeof payload === "function") throw "wrong arguments";
      ajax(api, "/rs/webService/cancelOnHoldBooking", payload, success, failure);
    },
    getPayments: function (api, payload, success, failure) {
      if (typeof payload === "function") throw "wrong arguments";
      ajax(api, "/rs/webService/getPayments", payload, success, failure);
    },
    getPaymentUrl: function (api, payload, success, failure) {
      if (typeof payload === "function") throw "wrong arguments";
      ajax(api, "/rs/webService/getPaymentUrl", payload, success, failure);
    },
    listActivity: function (api, payload, success, failure) {
      if (typeof payload === "function") throw "wrong arguments";
      ajax(api, "/rs/webService/listActivity", payload, success, failure);
    },
    signingDocumentUrl: function (api, payload, success, failure) {
      if (typeof payload === "function") throw "wrong arguments";
      ajax(api, "/rs/webService/signingDocumentUrl", payload, success, failure);
    },
    createSigningDocument: function (api, payload, success, failure) {
      if (typeof payload === "function") throw "wrong arguments";
      ajax(api, "/rs/webService/createSigningDocument", payload, success, failure);
    },
    getESignatureWidgetUrl: function (api, payload, success, failure) {
      if (typeof payload === "function") throw "wrong arguments";
      ajax(api, "/rs/webService/getESignatureWidgetUrl", payload, success, failure);
    },
    removePersonFromTenancy: function (api, payload, success, failure) {
      if (typeof payload === "function") throw "wrong arguments";
      ajax(api, "/rs/webService/removePersonFromTenancy", payload, success, failure);
    },
    singleSignOnPeekUser: function (sso, payload, success, failure) {
      if (typeof payload === "function") throw "wrong arguments";
      ajax(sso, "/rs/singleSignOn/peekUser", payload, success, true, failure);
    },
    singleSignOnChangePassword: function (sso, payload, success, failure) {
      if (typeof payload === "function") throw "wrong arguments";
      ajax(sso, "/rs/singleSignOn/changePassword", payload, success, failure, true);
    },
    makeAfakePayment: function (api, payload, success, failure) {
      if (typeof payload === "function") throw "wrong arguments";
      ajax(api, "/rs/webService/makeAfakePayment", payload, success, failure);
    },
    getAll: function (api, payload, success, failure) {
      if (typeof payload === "function") throw "wrong arguments";
      ajax(api, "/rs/datasource/all", payload, success, failure);
    },
    logout: function (sso, sso_key, success, failure) {
      if (typeof success !== "function") throw "wrong arguments1";
      if (typeof sso_key !== 'string') throw "wrong arguments2";
      ajax(sso, "/rs/singleSignOn/logout/" + sso_key, undefined, success, failure, true, { type: 'GET'});
    },
    callSSOandMethod: function (sso, api, payload, success, notLoggedIn, failure, method, uuid) {
      let self = this;
      this.getSSOKey(sso, function (sso_key) {
        let payload_copy = JSON.parse(JSON.stringify(payload));
        if (uuid) payload_copy.uuid = sso_key;
        else payload_copy.sessionId = sso_key;
        self[method](api, payload_copy, success, function (message, messageType) {
          if (message.indexOf("The session token is invalid") + 1) {
            notLoggedIn(message);
          } else {
            failure(message, messageType);
          }
        });
      }, failure);
    },
    getSSOKey: function (sso, success, failure) {
      if (typeof success !== "function") throw "wrong arguments";
      ajax(sso, "/getSSOKey", null, success, failure, true, {
        xhrFields: {
          withCredentials: true,
        },
        contentType: 'text/plain',
        dataType: undefined,
        type: undefined,
        data: undefined,
      });
    },
    getSSOKeyAndBook: function (sso, api, payload, success, notLoggedIn, failure) {
      this.callSSOandMethod(sso, api, payload, success, notLoggedIn, failure, "onlineBookingBook");
    },
    getSSOKeyAndBookAdditionalRentalUnit: function (sso, api, payload, success, notLoggedIn, failure) {
      this.callSSOandMethod(sso, api, payload, success, notLoggedIn, failure, "bookAdditionalRentalUnit");
    },
    getSSOKeyAndGetPaymentSchedules: function (sso, api, payload, success, notLoggedIn, failure) {
      this.callSSOandMethod(sso, api, payload, success, notLoggedIn, failure, "getPaymentSchedules");
    },
    getSSOKeyAndSetPaymentFrequency: function (sso, api, payload, success, notLoggedIn, failure) {
      this.callSSOandMethod(sso, api, payload, success, notLoggedIn, failure, "setPaymentFrequency");
    },
    getSSOKeyAndSetPaymentMethod: function (sso, api, payload, success, notLoggedIn, failure) {
      this.callSSOandMethod(sso, api, payload, success, notLoggedIn, failure, "setPaymentMethod");
    },
    getSSOKeyAndGetTenancyAgreement: function (sso, api, payload, success, notLoggedIn, failure) {
      this.callSSOandMethod(sso, api, payload, success, notLoggedIn, failure, "webServiceGetTenancyAgreement");
    },
    getSSOKeyAndSignTenancyAgreement: function (sso, api, payload, success, notLoggedIn, failure) {
      this.callSSOandMethod(sso, api, payload, success, notLoggedIn, failure, "webServiceSignTenancyAgreement");
    },
    getSSOKeyAndUpdatePersonDetails: function (sso, api, payload, success, notLoggedIn, failure) {
      this.callSSOandMethod(sso, api, payload, success, notLoggedIn, failure, "updatePersonDetails");
    },
    getSSOKeyAndAddShortlist: function (sso, api, payload, success, notLoggedIn, failure) {
      this.callSSOandMethod(sso, api, payload, success, notLoggedIn, failure, "addShortlist");
    },
    getSSOKeyAndListShortlist: function (sso, api, payload, success, notLoggedIn, failure) {
      this.callSSOandMethod(sso, api, payload, success, notLoggedIn, failure, "listShortlist");
    },
    getSSOKeyAndRemoveShortlist: function (sso, api, payload, success, notLoggedIn, failure) {
      this.callSSOandMethod(sso, api, payload, success, notLoggedIn, failure, "removeShortlist");
    },
    getSSOKeyAndGetUser: function (sso, api, payload, success, notLoggedIn, failure) {
      this.callSSOandMethod(sso, api, payload, success, notLoggedIn, failure, "getUser");
    },
    getSSOKeyAndTenancy: function (sso, api, payload, success, notLoggedIn, failure) {
      this.callSSOandMethod(sso, api, payload, success, notLoggedIn, failure, "getTenancy");
    },
    getSSOKeyAndWorkflow: function (sso, api, payload, success, notLoggedIn, failure) {
      this.callSSOandMethod(sso, api, payload, success, notLoggedIn, failure, "getWorkflow");
    },
    getSSOKeyAndCompleteStep: function (sso, api, payload, success, notLoggedIn, failure) {
      this.callSSOandMethod(sso, api, payload, success, notLoggedIn, failure, "completeStep");
    },
    getSSOKeyAndWebform: function (sso, api, payload, success, notLoggedIn, failure) {
      this.callSSOandMethod(sso, api, payload, success, notLoggedIn, failure, "getWebform");
    },
    getSSOKeyAndWebFormSubmit: function (sso, api, payload, success, notLoggedIn, failure) {
      this.callSSOandMethod(sso, api, payload, success, notLoggedIn, failure, "webFormSubmit");
    },
    getSSOKeyAndGetDirectDebitDetails: function (sso, api, payload, success, notLoggedIn, failure) {
      this.callSSOandMethod(sso, api, payload, success, notLoggedIn, failure, "getDirectDebitDetails");
    },
    getSSOKeyAndNewDirectDebit: function (sso, api, payload, success, notLoggedIn, failure) {
      this.callSSOandMethod(sso, api, payload, success, notLoggedIn, failure, "newDirectDebit");
    },
    getSSOKeyAndDecline: function (sso, api, payload, success, notLoggedIn, failure) {
      this.callSSOandMethod(sso, api, payload, success, notLoggedIn, failure, "decline");
    },
    getSSOKeyAndAdditionalRentalUnits: function (sso, api, payload, success, notLoggedIn, failure) {
      this.callSSOandMethod(sso, api, payload, success, notLoggedIn, failure, "additionalRentalUnits");
    },
    getSSOKeyAndGetDirectDDagreement: function (sso, api, payload, success, notLoggedIn, failure) {
      this.callSSOandMethod(sso, api, payload, success, notLoggedIn, failure, "getDirectDebitAgreement");
    },
    getSSOKeyAndConfirmDirectDebit: function (sso, api, payload, success, notLoggedIn, failure) {
      this.callSSOandMethod(sso, api, payload, success, notLoggedIn, failure, "confirmDirectDebit");
    },
    getSSOKeyAndAddPersonToTenancy: function (sso, api, payload, success, notLoggedIn, failure) {
      this.callSSOandMethod(sso, api, payload, success, notLoggedIn, failure, "addPersonToTenancy");
    },
    getSSOKeyAndGetPaymentUrl: function (sso, api, payload, success, notLoggedIn, failure) {
      this.callSSOandMethod(sso, api, payload, success, notLoggedIn, failure, "getPaymentUrl");
    },
    getSSOKeyAndCancelOnHoldBooking: function (sso, api, payload, success, notLoggedIn, failure) {
      this.callSSOandMethod(sso, api, payload, success, notLoggedIn, failure, "cancelOnHoldBooking");
    },
    getSSOKeyAndGetPayments: function (sso, api, payload, success, notLoggedIn, failure) {
      this.callSSOandMethod(sso, api, payload, success, notLoggedIn, failure, "getPayments");
    },
    getSSOKeyAndListActivity: function (sso, api, payload, success, notLoggedIn, failure) {
      this.callSSOandMethod(sso, api, payload, success, notLoggedIn, failure, "listActivity");
    },
    getSSOKeyAndSigningDocumentUrl: function (sso, api, payload, success, notLoggedIn, failure) {
      this.callSSOandMethod(sso, api, payload, success, notLoggedIn, failure, "signingDocumentUrl");
    },
    getSSOKeyAndCreateSigningDocument: function (sso, api, payload, success, notLoggedIn, failure) {
      this.callSSOandMethod(sso, api, payload, success, notLoggedIn, failure, "createSigningDocument");
    },
    getSSOKeyAndGetESignatureWidgetUrl: function (sso, api, payload, success, notLoggedIn, failure) {
      this.callSSOandMethod(sso, api, payload, success, notLoggedIn, failure, "getESignatureWidgetUrl");
    },
    getSSOKeyAndRemovePersonFromTenancy: function (sso, api, payload, success, notLoggedIn, failure) {
      this.callSSOandMethod(sso, api, payload, success, notLoggedIn, failure, "removePersonFromTenancy");
    },
    getSSOKeyAndChangePassword: function (sso, api, payload, success, failure) {
      this.callSSOandMethod(sso, api, payload, success, failure, failure, "singleSignOnChangePassword", "uuid");
    },
    getSSOKeyAndValidateObject: function (sso, api, payload, success, failure) {
      this.callSSOandMethod(sso, api, payload, success, failure, failure, "singleSignOnValidateobject", "uuid");
    },
    getSSOKeyAndLogout: function (sso, success, failure) {
      let self = this;
      this.getSSOKey(sso, function (sso_key) {
        self["logout"](sso, sso_key, success, failure);
      }, failure);
    },
  };
};
