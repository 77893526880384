import React from 'react';
import Col from 'react-bootstrap/lib/Col';
import ButtonGroup from 'react-bootstrap/lib/ButtonGroup';
import Button from 'react-bootstrap/lib/Button';
import utils from '../../../lib/utils.js';
import translateService from '../../services/translate-service.js';

export function OfferList (props) {

  function translate (name) {
    return translateService.getLabelsTranslated(props.labels, "searchApplet.offerList.", name, props.defaultLabels);
  }

  let buttonPeriodListLines = [];
  let offerIndex = 0;
  for (let offer of props.offers) {
    //if we have position means that we are ranking by date so
    let infoForPosition = <div hidden>{props.hash + "-" + offer.lookupValue + "-" + offerIndex + "-" + offer.price.number + "-" + offer.startStr}</div>;
    let addRemoveShortlistButtons = null;
    if (props.removeShortlist && props.addShortlist) {
      addRemoveShortlistButtons = offer.inShortlist ? <Button onClick={ ()=>{ props.removeShortlist(offer); } } bsStyle="primary" className="remove-from-shortlist"><i className="fa fa-star hidden-xs"></i>{" " + translate("addedButton")}</Button>
        : <Button onClick={ ()=>{ props.addShortlist(offer); } } bsStyle="primary" className="add-to-shortlist"><i className="fa fa-star-o hidden-xs"></i>{" " + translate("addToShortListButton")}</Button>;
    }
    let searchSimilarButton = null;
    if (props.shortlist && offer.price.number === -1 ) {
      searchSimilarButton = <Button onClick={ ()=>{ props.searchSimilar(props.hash); } } bsStyle="primary" className="search-similar"><i className="fa fa-search hidden-xs"></i>{" " + translate("searchSimilarButton")}</Button>;
    }
    let bookNowProps = {};
    bookNowProps.bsStyle = "success";
    bookNowProps.className = "pex-book-now";
    bookNowProps.onClick = function () { props.showbookingSummary(offer, props.roomData); };
    if (!offer.bookable || offer.price.number === -1) {
      bookNowProps.disabled = true;
    }

    buttonPeriodListLines.push(<tr key={offer.lookupValue} className="pex-offer">
      <Gender offer={offer}/>
      <StartDate offer={offer}/>
      <EndDate offer={offer} notAvailableLabel={translate("notAvailableLabel")} />
      <td>{infoForPosition}{offer.availability.displayValue}</td>
      <Price offer={offer} notAvailableLabel={translate("notAvailableLabel")}/>
      <td className="text-right">
        <ButtonGroup bsSize="small">
          {searchSimilarButton}
          <Button {...bookNowProps} ><i className="fa fa-bed hidden-xs"></i>{" " + translate("bookButton")}</Button>
          {addRemoveShortlistButtons}
        </ButtonGroup>
      </td>
    </tr>);
    offerIndex++;
  }
  return <Col xs={12} md={8} className="room-list table-responsive" id="room-list">
    <table className="table table-condensed">
      <tbody>
        <tr>
          <th className="gender">{translate("genderLabel")}</th>
          <th className="contractStart">{translate("contractStartLabel")}</th>
          <th className="contractEnd">{translate("contractEndLabel")}</th>
          <th>{translate("periodLabel")}</th>
          <th>{translate("priceLabel")}</th>
          <th></th>
        </tr>
        {buttonPeriodListLines}
      </tbody>
    </table>
  </Col>;
}

function StartDate (props) {
  if (!props.offer.startUntil) return <td className="offer-date-range">{props.offer.startStr}</td>;
  return <td className="offer-date-range">
    <span className="start">{props.offer.startStr}</span><span className="dash"> - </span>
    <span className="end">{props.offer.startUntilStr}</span>
  </td>;
}

function EndDate (props) {
  return <td className="offer-date-range-end">
    {props.offer.endStr ? props.offer.endStr : props.notAvailableLabel}
  </td>;
}

function Price (props) {
  let priceStr = utils.format_rate(props.offer.price.number, props.offer.price.unit);
  if (props.offer.price.number < 0) priceStr = props.notAvailableLabel;
  return <td>{priceStr}</td>;
}

function Gender (props) {
  return <td className="offer-gender">
    <i className={props.offer.gender ? "gender-" + props.offer.gender.lookupValue : "gender-NA"}></i>
    <p>{props.offer.gender ? props.offer.gender.displayValue : "" }</p>
  </td>;
}
