import React from 'react';


export class PexValidationMessage extends React.Component {
  constructor (props) {
    super(props);
    this.state = {};
  }
  render () {
    if ((this.props.submitted || this.props.values[this.props.fieldKey]) && this.props.errors[this.props.fieldKey]) {
      return <span className="error-message">{this.props.errors[this.props.fieldKey]}</span>;
    } else {
      return null;
    }
  }
}
