import React from 'react';
import Col from 'react-bootstrap/lib/Col';
import Row from 'react-bootstrap/lib/Row';
import Button from 'react-bootstrap/lib/Button';
import Panel from 'react-bootstrap/lib/Panel';
import Table from 'react-bootstrap/lib/Table';

export default class TenanciesList extends React.Component {
  render () {
    if (!this.props.createNewDirectDebit) return <div className="tenancies-list-error">Message error: No createNewDirectDebit function DD00016</div>;
    let tenanciesArr = [];
    let i = 0;
    if (this.props.tenancies) {
      for (let tenancy of this.props.tenancies) {
        i++;
        if (tenancy.newDirectDebit) {
          tenanciesArr.push(<NewDirectDebit key={i} tenancy={tenancy} createNewDirectDebit={this.props.createNewDirectDebit} cancelling={this.props.cancelling}/>);
        } else {
          switch (tenancy.status) {
            case "ok": tenanciesArr.push(<TenancyWithDDOk key={i} tenancy={tenancy} status={tenancy.status}/>);
              break;
            case "progress": tenanciesArr.push(<TenancyWithDDProgress key={i} tenancy={tenancy} status={tenancy.status}/>);
              break;
            case "noDirectDebit": tenanciesArr.push(<TenancyWithoutDD key={i} tenancy={tenancy} status={tenancy.status}/>);
              break;
            case "moreThanOneDD": tenanciesArr.push(<TenancyWithMoreThanOneDD key={i} tenancy={tenancy} status={tenancy.status}/>);
              break;
            case "failed": tenanciesArr.push(<TenancyWithDDFailed key={i} tenancy={tenancy} status={tenancy.status}/>);
              break;
          }
        }
      }
    }

    return <Row className="info-bar">
      <Col xs={12}>
        <Panel bsStyle="primary">
          <div className="table-responsive">
            <Table hover>
              <thead>
                <tr>
                  <th className="table-title"><span>Tenancies</span></th>
                  <th className="hidden-xs">Property</th>
                  <th className="hidden-xs">Period</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {tenanciesArr.length === 0 ? <tr className="tenancies-list-no-data"><td>No tenancies found</td></tr> : tenanciesArr}
              </tbody>
            </Table>
          </div>
        </Panel>
      </Col>
    </Row>;
  }
}

function NewDirectDebit (props) {
  function createNewDirectDebit () {
    props.createNewDirectDebit(props.tenancy.tenancyId);
  }

  return <tr className="new-direct-debit">
    <td><span className="hidden-xs">Future Tenancy -</span> {props.tenancy.tenancyId}</td>
    <td className="hidden-xs">{props.tenancy.propertyDescription}</td>
    <td className="hidden-xs">{props.tenancy.startDate + " - " + props.tenancy.endDate}</td>
    <td className="text-center" >
      <Button onClick={ () => { createNewDirectDebit(); } } bsStyle="primary" className="btn-new-dd btn btn-primary" disabled={props.cancelling} >New Direct Debit</Button>
    </td>
  </tr>;
}

function TenancyWithDDOk (props) {
  return <tr className="tenancy-with-dd-ok">
    <td><span className="hidden-xs">Current Tenancy -</span> {props.tenancy.tenancyId}</td>
    <td className="hidden-xs">{props.tenancy.propertyDescription}</td>
    <td className="hidden-xs">{props.tenancy.startDate + " - " + props.tenancy.endDate}</td>
    <td className="text-center text-success"><span><i className="fa fa-check-circle-o"></i> Ok</span></td>
  </tr>;
}

function TenancyWithDDProgress (props) {
  return <tr className="tenancy-with-dd-progress">
    <td><span className="hidden-xs">Current Tenancy -</span> {props.tenancy.tenancyId}</td>
    <td className="hidden-xs">{props.tenancy.propertyDescription}</td>
    <td className="hidden-xs">{props.tenancy.startDate + " - " + props.tenancy.endDate}</td>
    <td className="text-center text-muted"><span><i className="fa fa-clock-o hidden-xs"></i> In progress</span></td>
  </tr>;
}

function TenancyWithoutDD (props) {
  return <tr className="tenancy-without-dd">
    <td><span className="hidden-xs">Current Tenancy -</span> {props.tenancy.tenancyId}</td>
    <td className="hidden-xs">{props.tenancy.propertyDescription}</td>
    <td className="hidden-xs">{props.tenancy.startDate + " - " + props.tenancy.endDate}</td>
    <td className="text-center text-danger"><span><i className="fa fa-exclamation-circle"></i> No Direct Debit found</span></td>
  </tr>;
}

function TenancyWithDDFailed (props) {
  return <tr className="tenancy-with-dd-failed">
    <td><span className="hidden-xs">Current Tenancy -</span> {props.tenancy.tenancyId}</td>
    <td className="hidden-xs">{props.tenancy.propertyDescription}</td>
    <td className="hidden-xs">{props.tenancy.startDate + " - " + props.tenancy.endDate}</td>
    <td className="text-center text-muted"><span><i className="fa fa-check-circle-o"></i> Failed</span></td>
  </tr>;
}

function TenancyWithMoreThanOneDD (props) {
  return <tr className="tenancy-with-more-than-one-dd">
    <td><span className="hidden-xs">Current Tenancy -</span> {props.tenancy.tenancyId}</td>
    <td className="hidden-xs">{props.tenancy.propertyDescription}</td>
    <td className="hidden-xs">{props.tenancy.startDate + " - " + props.tenancy.endDate}</td>
    <td className="text-center text-danger"><span><i className="fa fa-exclamation-circle"></i> More than one Direct Debit for this tenancy</span></td>
  </tr>;
}
