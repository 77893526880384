import React from 'react';
import Button from 'react-bootstrap/lib/Button';
import Col from 'react-bootstrap/lib/Col';
import Row from 'react-bootstrap/lib/Row';
import Panel from 'react-bootstrap/lib/Panel';
import directDebitService from "../../../services/direct-debit-service.js";
let utils = require("../../../../lib/utils");
import classNames from 'classnames';
import Loading from "../loading.jsx";

export function ShowError (props) {
  if (props.waiting) return <Loading waiting={props.waiting} />;
  let functionCancel = function () {
    props.blockScreen();
    props.goToDashboard();
  };
  let functionError = null;
  switch (directDebitService.getErrorCodeForFunction(props.error)) {
    case "1": {
      functionError = function () {
        props.blockScreen();
        props.validateNewDirectDebit ("create");
      };
      break;
    }
    case "2": {
      functionError = function () {
        props.blockScreen();
        props.getSSOKeyAndDecline ();
      };
      break;
    }
    case "3": {
      functionError = function () {
        props.blockScreen();
        props.createNewDirectDebitForUser ();
      };
      break;
    }
    case "4": {
      functionError = function () {
        props.blockScreen();
        props.validatePersonalDetails ();
      };
      break;
    }
    case "5": {
      functionError = function () {
        props.blockScreen();
        props.listBank ();
      };
      break;
    }
    case "6":
    case "10":
    case "9": {
      functionError = function () {
        props.blockScreen();
        props.getSSOKeyAndGetDirectDebitDetails ();
      };
      break;
    }
    case "7": {
      functionError = function () {
        props.blockScreen();
        props.validatePaymentDetails();
      };
      break;
    }
    case "8": {
      functionError = function () {
        props.blockScreen();
        props.confirmDirectDebit();
      };
      break;
    }
    case "11": {
      functionError = function () {
        props.blockScreen();
        props.load();
      };
      break;
    }
    case "12": {
      functionError = function () {
        props.blockScreen();
        props.load();
      };
      break;
    }
    case "13": {
      functionError = function () {
        props.blockScreen();
        props.getDetailsAfterPersonalDetails();
      };
      break;
    }
  }

  let buttons = <Col xs={12} className="text-right">
    <Button className="button-failure-go-to-dashboard" bsStyle="primary" onClick={functionCancel}><i className="fa fa-arrow-left hidden-sm hidden-lg"></i> <span className="hidden-xs">Dashboard</span></Button>
    <Button className="button-failure-retry" bsStyle="success" onClick={functionError}><span className="hidden-xs">Retry</span> <i className="fa fa-retweet hidden-sm hidden-lg"></i></Button>
  </Col>;
  let apiErrorMessage = "";

  if (utils.isJson(props.error)) {
    if (props.error.response && !props.error.code.startsWith("configuration error")) {
      apiErrorMessage = props.error.response;
    } else {
      apiErrorMessage = "No error message available";
    }
  } else {
    apiErrorMessage = props.error.code ? props.error.code : "";
  }

  let errorTitle = <p><i className="fa fa-frown-o"></i> Oops! Error {props.error.code}</p>;
  let messageComplete = <Col xs={12}>
    <p className="error-content">Sorry! We coudn't load the page beacuse of the error: <strong className="api-error-message">{apiErrorMessage}</strong>. Please try again.</p>
    <p>If the error occurs again please contact us and provide the error code: <strong>{props.error.code}</strong>.</p>
  </Col>;
  let footer = <Row>{props.error && props.error.code && props.error.code.indexOf("DD") > -1 ? buttons : ""}</Row>;
  return <div className={classNames('container direct-debit error-page')}>
    <Row>
      <Col xs={12}>
        <Panel className="panel-min-height" header={errorTitle} footer={footer} bsStyle="danger">
          <Row>
            {props.error && !props.error.code ? props.error : messageComplete}
          </Row>
        </Panel>
      </Col>
    </Row>
  </div>;
}
