import React from 'react';
import Pagination from 'react-bootstrap/lib/Pagination';

export function PexPagination (props) {
  function getInitialState () {
    return {
      activePage: 1,
    };
  }

  function getPages () {
    if (Number((props.dataLength / props.pagination) % 1) >= Number("0.5") || Number((props.dataLength / props.pagination) % 1) === 0) {
      return Number((props.dataLength / props.pagination).toFixed(0));
    }
    return Number(((props.dataLength / props.pagination) + 1).toFixed(0));
  }

  function selectPaginationPage (activePage) {
    props.selectPaginationPage(activePage);
  }

  return <div className="search-pagination text-center">{props.dataLength > 0 ? <Pagination
    prev
    next
    first
    last
    ellipsis
    boundaryLinks
    items={getPages ()}
    maxButtons={5}
    activePage={props.activePage}
    onSelect={selectPaginationPage.bind(this)} /> : null}
  </div>;
}
