import React from 'react';
import Popover from 'react-bootstrap/lib/Popover';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import Button from 'react-bootstrap/lib/Button';
import ButtonGroup from 'react-bootstrap/lib/ButtonGroup';
import Row from 'react-bootstrap/lib/Row';
import FormControl from 'react-bootstrap/lib/FormControl';
import Col from 'react-bootstrap/lib/Col';
import { PexText } from "../form-components/pex-text.jsx";
import Checkbox from 'react-bootstrap/lib/Checkbox';
import utils from "../../../lib/utils";
import {PexDatePicker} from "../form-components/pex-date-picker.jsx";


export default class TenantAndGuarantorDetails extends React.Component {

  componentDidMount () {
    //TODO: if we have to call some api method for this step implement this method
    this.props.loadTenantAndGuarantorDetails();
  }

  render () {

    if (this.props.state && this.props.state.stepLoaded && !this.props.state.error) {

      return <div className={""}>
        <TenantsNumberSelector state={this.props.state}
          changeTenantsNumber={this.props.changeTenantsNumber}
        />
        <Tenants state={this.props.state} updateTenantAndGuarantorDetailsTenantForm={this.props.updateTenantAndGuarantorDetailsTenantForm}/>
        <Guarantor state={this.props.state}
          clickGuarantor={this.props.clickGuarantor}
          updateTenantAndGuarantorDetailsGuarantorForm={this.props.updateTenantAndGuarantorDetailsGuarantorForm}
        />
        <AdditionalTenantSelector state={this.props.state} changeAdditionalOccupantsNumber={this.props.changeAdditionalOccupantsNumber} />
        <AdditionalTenants state={this.props.state} updateTenantAndGuarantorDetailsAdditionalOccupantForm={this.props.updateTenantAndGuarantorDetailsAdditionalOccupantForm}/>
        <PrivacyPolicy state={this.props.state}/>
        <ConfirmBox state={this.props.state}
          updateTenantAndGuarantorDetailsGeneralCheckbox={this.props.updateTenantAndGuarantorDetailsGeneralCheckbox}/>
        <InfoBottomLabel state={this.props.state}/>
      </div>;
    }
    else return <div>Loading step...</div>;
  }
}

function TenantsNumberSelector (props) {

  let occupants = [];
  for (let i = 0;i < props.state.tenantAndGuarantorDetails.maxOcuppancy;i++) occupants.push(<option key={i + 1} value={i + 1}>{i + 1}</option>);

  function handleChange (event) {
    props.changeTenantsNumber(event.target.value);
  }
  let maxOccupancyPopover = <Popover id="tenantAndGuarantorDetails-max-occupancy-popover">
    <div className={"tenantAndGuarantorDetails-max-occupancy-popover-content"} dangerouslySetInnerHTML={{__html: utils.sanitize(props.state.labels["graingerBookingJourney.tenantAndGuarantorDetails.maxOccupancyPopover"])}}></div>
  </Popover>;

  return <div className={"tenants-number"}>
    <span>{props.state.labels["graingerBookingJourney.tenantAndGuarantorDetails.title1"]}</span>
    <FormControl className="tenants-number-selector" value={props.state.tenantAndGuarantorDetails.tenantsNumber} componentClass="select" onChange={handleChange.bind()}>
      {occupants}
    </FormControl>
    <span>{props.state.labels["graingerBookingJourney.tenantAndGuarantorDetails.title2"]}</span>
    <OverlayTrigger trigger="click" placement="right" overlay={maxOccupancyPopover} rootClose>
      <Button bsStyle="link" className={"tenantAndGuarantorDetails-max-occupancy-popover"}><i className='fa fa-question-circle'></i></Button>
    </OverlayTrigger>
  </div>;
}

function Tenants (props) {

  let tenants = [];
  let sizeLeftCol = 3;
  let sizeRightCol = 9;
  let textRight = "";

  if (props.state.tenantAndGuarantorDetails.tenantsNumber > 1) {
    sizeLeftCol = 2;
    sizeRightCol = 4;
    textRight = " text-right";
  }
  for (var i = 1; i <= props.state.tenantAndGuarantorDetails.tenantsNumber; i++) {
    function getFormControlProps () {
      return {
        values: props.state.tenantAndGuarantorDetails.tenants[i.toString()].values || {},
        errors: props.state.tenantAndGuarantorDetails.tenants[i.toString()].fieldsWithErrors || {},
        updateField: props.updateTenantAndGuarantorDetailsTenantForm,
        submitted: props.state.tenantAndGuarantorDetails.tenants[i.toString()].submitted,
        disabled: props.state.tenantAndGuarantorDetails.tenants[i.toString()].disabled,
      };
    }
    let left = <Col key={"label" + i} sm={sizeLeftCol} className={"tenant_" + i + " tenant-label" + textRight}><span>{props.state.labels["graingerBookingJourney.tenantAndGuarantorDetails.tenantLabel"] + " " + i}</span></Col>;
    let right = <Col key={"form" + i} sm={sizeRightCol} className={"tenant_" + i}>
      <Row>
        <PexText key="firstName" fieldKey="firstName" className="tenant-first-name col-sm-6" hideIcon={true}
          label={props.state.labels["graingerBookingJourney.tenantAndGuarantorDetails.firstNameLabel"]} index={i}
          {...getFormControlProps() } hideInputAndCol={true}/>
        <PexText key="lastName" fieldKey="lastName" className="tenant-last-name col-sm-6" hideIcon={true}
          label={props.state.labels["graingerBookingJourney.tenantAndGuarantorDetails.lastNameLabel"]} index={i}
          {...getFormControlProps() } hideInputAndCol={true}/>
        <PexText key="email" fieldKey="email" className="tenant-email col-sm-12" hideIcon={true}
          label={props.state.labels["graingerBookingJourney.tenantAndGuarantorDetails.emailLabel"]} index={i}
          {...getFormControlProps() } hideInputAndCol={true}/>
        <PexText key="phoneNumber" fieldKey="phoneNumber" className="tenant-phone col-sm-12" hideIcon={true}
          label={props.state.labels["graingerBookingJourney.tenantAndGuarantorDetails.phoneNumberLabel"]} index={i}
          {...getFormControlProps() } hideInputAndCol={true}/>
      </Row>
    </Col>;
    tenants.push(left);
    tenants.push(right);
  }
  return <Row className={"tenants"}>
    {tenants}
  </Row>;
}

function Guarantor (props) {

  let guarantorPopover = <Popover id="tenantAndGuarantorDetails-guarantor-popover">
    <div className={"tenantAndGuarantorDetails-guarantor-popover-content"} dangerouslySetInnerHTML={{__html: utils.sanitize(props.state.labels["graingerBookingJourney.tenantAndGuarantorDetails.guarantorPopover"])}}></div>
  </Popover>;

  if (!props.state.tenantAndGuarantorDetails.showGuarantorForm) {
    return <Row className="guarantor">
      <Col xs={11} >
        <Button className="add-guarantor-btn" bsStyle={"primary"} onClick={props.clickGuarantor}>{props.state.labels["graingerBookingJourney.tenantAndGuarantorDetails.addGuarantorButton"]}</Button>
      </Col>
      <Col xs={1} className={"guarantor-popover"}>
        <OverlayTrigger trigger="click" placement="right" overlay={guarantorPopover} rootClose>
          <Button bsStyle="link" className={"tenantAndGuarantorDetails-guarantor-popover"}><i className='fa fa-question-circle'></i></Button>
        </OverlayTrigger>
      </Col>
      <Col sm={12} className={"guarantor-link"} dangerouslySetInnerHTML={{__html: utils.sanitize(props.state.labels["graingerBookingJourney.tenantAndGuarantorDetails.guarantorLink"])}}></Col>
    </Row>;

  } else {


    function getFormControlProps () {
      return {
        values: props.state.tenantAndGuarantorDetails.guarantor.values || {},
        errors: props.state.tenantAndGuarantorDetails.guarantor.fieldsWithErrors || {},
        updateField: props.updateTenantAndGuarantorDetailsGuarantorForm,
        submitted: props.state.tenantAndGuarantorDetails.guarantor.submitted,
      };
    }

    function handleChangeConfirmGuarantor (event) {
      if (typeof event === "string") props.updateTenantAndGuarantorDetailsGuarantorForm(event);
      else props.updateTenantAndGuarantorDetailsGuarantorForm(event.target.name);
    }

    let guarantorConfirmErrorClass = "";
    if (!props.state.tenantAndGuarantorDetails.guarantorConfirm) guarantorConfirmErrorClass = " fieldError";

    return <Row className="guarantor">
      <Col sm={3} className="guarantor-label">
        <span>{props.state.labels["graingerBookingJourney.tenantAndGuarantorDetails.guarantorLabel"]}</span>
      </Col>
      <Col sm={9} >
        <Row>
          <Col xs={11} >
            <Button className="remove-guarantor-btn" onClick={props.clickGuarantor}>{props.state.labels["graingerBookingJourney.tenantAndGuarantorDetails.removeGuarantorButton"]}</Button>
          </Col>
          <Col xs={1} className={"guarantor-popover"}>
            <OverlayTrigger trigger="click" placement="right" overlay={guarantorPopover} rootClose>
              <Button bsStyle="link" className={"tenantAndGuarantorDetails-guarantor-popover"}><i className='fa fa-question-circle'></i></Button>
            </OverlayTrigger>
          </Col>
        </Row>
        <Row className="guarantor-form">
          <PexText key="firstName" fieldKey="firstName" className="guarantor-first-name col-sm-6" hideIcon={true}
            label={props.state.labels["graingerBookingJourney.tenantAndGuarantorDetails.firstNameLabel"]}
            {...getFormControlProps() } hideInputAndCol={true}/>
          <PexText key="lastName" fieldKey="lastName" className="guarantor-last-name col-sm-6" hideIcon={true}
            label={props.state.labels["graingerBookingJourney.tenantAndGuarantorDetails.lastNameLabel"]}
            {...getFormControlProps() } hideInputAndCol={true}/>
          <PexText key="email" fieldKey="email" className="guarantor-email col-sm-12" hideIcon={true}
            label={props.state.labels["graingerBookingJourney.tenantAndGuarantorDetails.emailLabel"]}
            {...getFormControlProps() } hideInputAndCol={true}/>
          <PexText key="phoneNumber" fieldKey="phoneNumber" className="guarantor-phone col-sm-12" hideIcon={true}
            label={props.state.labels["graingerBookingJourney.tenantAndGuarantorDetails.phoneNumberLabel"]}
            {...getFormControlProps() } hideInputAndCol={true}/>
        </Row>
        <Row>
          <Col sm={12}>
            <Checkbox className={"guarantor-confirm" + guarantorConfirmErrorClass} name={"guarantor-confirm"} onClick={()=>handleChangeConfirmGuarantor("guarantor-confirm")} checked={props.state.tenantAndGuarantorDetails.guarantorConfirm} inline>
              {utils.sanitize(props.state.labels["graingerBookingJourney.tenantAndGuarantorDetails.confirmGuarantor"])}
            </Checkbox>
          </Col>
        </Row>
      </Col>
    </Row>;
  }

}

function AdditionalTenantSelector (props) {

  function handleChange (event) {
    props.changeAdditionalOccupantsNumber(event.target.value);
  }

  let additionalOccupants = [];
  for (let i = 0;i < 5;i++) additionalOccupants.push(<option key={i} value={i}>{i}</option>);

  let additionalTenantPopover = <Popover id="additional-tenant-popover">
    <div className={"tenantAndGuarantorDetails-additional-tenant-popover"} dangerouslySetInnerHTML={{__html: utils.sanitize(props.state.labels["graingerBookingJourney.tenantAndGuarantorDetails.additionalTenantPopover"])}}></div>
  </Popover>;

  return <Row className={"additional-tenant"}>
    <Col xs={12} className={"additional-tenant-number"}>
      <span>{props.state.labels["graingerBookingJourney.tenantAndGuarantorDetails.additionalOccupantTitle1"]}</span>
      <FormControl className="additional-tenant-number-selector" value={props.state.tenantAndGuarantorDetails.additionalOccupantsNumber} componentClass="select" onChange={handleChange.bind()}>
        {additionalOccupants}
      </FormControl>
      <span>{props.state.labels["graingerBookingJourney.tenantAndGuarantorDetails.additionalOccupantTitle2"]}</span>
      <span className={"additional-tenant-popover"}>
        <OverlayTrigger trigger="click" placement="right" overlay={additionalTenantPopover} rootClose>
          <Button bsStyle="link" className={"tenantAndGuarantorDetails-additional-tenant-popover"}><i className='fa fa-question-circle'></i></Button>
        </OverlayTrigger>
      </span>
    </Col>
  </Row>;
}

function AdditionalTenants (props) {
  let additionalOccupants = [];
  let sizeLeftCol = 3;
  let sizeRightCol = 9;
  let textRight = "";

  if (props.state.tenantAndGuarantorDetails.additionalOccupantsNumber > 1) {
    sizeLeftCol = 2;
    sizeRightCol = 4;
    textRight = " text-right";
  }
  for (var i = 1; i <= props.state.tenantAndGuarantorDetails.additionalOccupantsNumber; i++) {
    function getFormControlProps () {
      return {
        values: props.state.tenantAndGuarantorDetails.additionalOccupants[i.toString()].values || {},
        errors: props.state.tenantAndGuarantorDetails.additionalOccupants[i.toString()].fieldsWithErrors || {},
        updateField: props.updateTenantAndGuarantorDetailsAdditionalOccupantForm,
        submitted: props.state.tenantAndGuarantorDetails.additionalOccupants[i.toString()].submitted,
      };
    }
    let left = <Col key={"label" + i} sm={sizeLeftCol} className={"additional-occupant_" + i + " additional-occupant-label" + textRight}><span>{props.state.labels["graingerBookingJourney.tenantAndGuarantorDetails.additionalOccupantLabel"] + " " + i}</span></Col>;
    let right = <Col key={"form" + i} sm={sizeRightCol} className={"additional-occupant_" + i}>
      <Row>
        <PexText key="email" fieldKey="email" className="additional-occupant-email col-sm-12" hideIcon={true}
          label={props.state.labels["graingerBookingJourney.tenantAndGuarantorDetails.emailLabel"]} index={i}
          {...getFormControlProps() } hideInputAndCol={true}/>
        <PexText key="firstName" fieldKey="firstName" className="additional-occupant-first-name col-sm-6" hideIcon={true}
          label={props.state.labels["graingerBookingJourney.tenantAndGuarantorDetails.firstNameLabel"]} index={i}
          {...getFormControlProps() } hideInputAndCol={true}/>
        <PexText key="lastName" fieldKey="lastName" className="additional-occupant-last-name col-sm-6" hideIcon={true}
          label={props.state.labels["graingerBookingJourney.tenantAndGuarantorDetails.lastNameLabel"]} index={i}
          {...getFormControlProps() } hideInputAndCol={true}/>
        <PexDatePicker key="dob" fieldKey="dob" className="additional-occupant-dob col-sm-12" hideIcon={true}
          label={props.state.labels["graingerBookingJourney.tenantAndGuarantorDetails.dobLabel"]} index={i}
          {...getFormControlProps() } hideInputAndCol={true}/>
        <PexText key="disclosableInterest" fieldKey="disclosableInterest" className="additional-occupant-relationship col-sm-12" hideIcon={true}
          label={props.state.labels["graingerBookingJourney.tenantAndGuarantorDetails.relationshipLabel"]} index={i}
          {...getFormControlProps() } hideInputAndCol={true}/>
      </Row>
    </Col>;
    additionalOccupants.push(left);
    additionalOccupants.push(right);
  }
  return <Row className={"additional-occupants"}>
    {additionalOccupants}
  </Row>;
}

function ConfirmBox (props) {

  return <div className={"confirm-box"}>
    <Col xs={12}>
      <Checkbox className="confirm-general-permission" name={"confirm-general-permission"} onClick={()=>props.updateTenantAndGuarantorDetailsGeneralCheckbox()} checked={props.state.tenantAndGuarantorDetails.generalConfirm} inline>
        {utils.sanitize(props.state.labels["graingerBookingJourney.tenantAndGuarantorDetails.confirmGeneralPermission"])}
      </Checkbox>
    </Col>
  </div>;
}

function InfoBottomLabel (props) {
  return <div className={"info bottom"}>
    <Col xs={12}><h4>{props.state.labels["graingerBookingJourney.tenantAndGuarantorDetails.infoBottomLabel"]}</h4></Col>
  </div>;
}

function PrivacyPolicy (props) {
  return <div className={"privacy-policy"}>
    <Col sm={12} className={"text-center"} dangerouslySetInnerHTML={{__html: utils.sanitize(props.state.labels["graingerBookingJourney.tenantAndGuarantorDetails.privacyPolicyLink"])}}></Col>
  </div>;
}
