import React from 'react';
import FormControl from 'react-bootstrap/lib/FormControl';
import { PexCommonField } from "./pex-common-field.jsx";
import PropTypes from 'prop-types';


export class PexTextArea extends React.Component {
  render () {
    return <PexCommonField {...this.props} ><TextAreaField /></PexCommonField>;
  }
}

PexTextArea.propTypes = {
  label: PropTypes.string.isRequired,
  fieldKey: PropTypes.string.isRequired,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  updateField: PropTypes.func.isRequired,
};

class TextAreaField extends React.Component {
  render () {
    return <FormControl componentClass="textarea" {...this.props} />;
  }

  placeholder () {
    return this.props.placeholder;
  }

}

TextAreaField.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.any,
};
