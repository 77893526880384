"use strict";
let moment = require('moment');

module.exports = function (intl) {
  return {
    formatDate: function (date) {
      return intl.formatDate(moment(date).format("YYYY-MM-DD"));
    },
    formatNumber: function (number) {
      return intl.formatNumber(number);
    },
  };
};
