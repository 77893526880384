import React from 'react';
import { PexCommonField } from "./pex-common-field.jsx";
import FormControl from 'react-bootstrap/lib/FormControl';

export class PexBudgetTextBox extends React.Component {

  render () {
    return <PexCommonField {...this.props}><BudgetTextBox {...this.props}/></PexCommonField>;
  }

}

function BudgetTextBox (props) {

  function hanldeOnChange (event, key) {
    props.onChange(event, key);
  }

  let currencyMessage = <div className={props.className + "-first-label"} dangerouslySetInnerHTML={{__html: props.utils.sanitize(props.attribute.currencyLabel ? props.attribute.currencyLabel : null)}}></div>;
  let textMessage = <div className={props.className + "-second-label"} dangerouslySetInnerHTML={{__html: props.utils.sanitize(props.attribute.text ? props.attribute.text : null)}}></div>;
  let budgetValue = <FormControl className={props.className + "-text-box"}
    value={props.attribute.fieldProps.value || ""}
    onChange={ (ev) =>{ hanldeOnChange(ev, props.attribute.key);}}/>;

  return <div className={props.className + "-container"}>
    {currencyMessage}
    {budgetValue}
    {textMessage}
  </div>;
}
