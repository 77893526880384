"use strict";

function initForm (formNames, labels, component) {
  let formValues = {};
  for (let formName of Object.keys(formNames)) {
    formValues[formName] = null;
  }

  let form = {
    "values": formValues,
    "formNames": formNames,
    "submitted": false,
    "fieldsWithErrors": checkForm(formValues, formNames, labels, component).fieldsWithErrors,
    "canSubmit": false,
    "APIerror": undefined,
  };

  return form;
}

function checkForm (values, formNames, labels, component) {
  let fieldsWithErrors = {};
  let has_errors = false;

  function checkMandatory (field) {
    if (!fieldsWithErrors[field.key] && (!values[field.key] || values[field.key] === "")) {
      has_errors = true;
      if (labels && labels[component + ".validation.isRequired"]) fieldsWithErrors[field.key] = labels[field.labelError] + " " + labels[component + ".validation.isRequired"];
    }
  }
  function checkEmailFormat (field) {
    var re = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    if (!fieldsWithErrors[field.key] && values[field.key] && !re.test(values[field.key])) {
      has_errors = true;
      if (labels && labels[component + ".validation.emailNotValid"]) fieldsWithErrors[field.key] = labels[field.labelError] + " " + labels[component + ".validation.emailNotValid"];
    }
  }
  function checkSameValue (field) {
    if (!fieldsWithErrors[field.key] && values[field.key] !== values[field.sameValue] ) {
      has_errors = true;
      if (labels && labels[component + ".validation.isNotTheSameValue"]) fieldsWithErrors[field.key] = labels[field.labelError] + " " + labels[component + ".validation.isNotTheSameValue"] + " " + labels[formNames[field.sameValue].labelError].toLowerCase();
    }
  }

  for (let field of Object.keys(formNames)) {
    if (formNames[field].mandatory) checkMandatory(formNames[field]);
    if (formNames[field].email) checkEmailFormat(formNames[field]);
    if (formNames[field].sameValue) checkSameValue(formNames[field]);
  }
  return {canSubmit: !has_errors, fieldsWithErrors: fieldsWithErrors};
}

module.exports = {
  initChangePasswordForm: function (labels) {
    let changePasswordForm = {
      "password": { "key": "password", "labelError": "otpLogin.changePassword.passwordLabel", "mandatory": true},
      "confirmPassword": { "key": "confirmPassword", "labelError": "otpLogin.changePassword.confirmPasswordLabel", "sameValue": "password", "mandatory": true},
    };
    return initForm(changePasswordForm, labels, "otpLogin.changePassword");
  },
  checkForm: function (values, formNames, labels, component) {
    return checkForm(values, formNames, labels, component);
  },
};
