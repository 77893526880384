"use strict";

function setPagination (labels, pagination) {
  let page = 1;
  let pageLimit = 0;
  let newLabels = [];

  for (let label of Object.keys(labels)) {
    labels[label].page = page;
    newLabels.push(labels[label]);
    pageLimit++;
    if (pageLimit === Number(pagination) ) {
      pageLimit = 0;
      page++;
    }
  }
  return newLabels;
}

function parseLabels (labels, newLabels, languages, filterGroup) {

  //Translations will have with this structure:
  /**
  labels: {
    "group.component.label": {
    "key":"group.component.label",
    "name":"group.component.label",
    "displayValue":"<b>group</b>.component.label",
      "languages": {
        "en-GB": "Some value",
        "es-ES": "Algun valor"
      }
    }
    {...}
  }
  **/
  //1 - filter by group
  if (Object.keys(newLabels.labels).length > 0 ) {
    for (let lang of languages) {
      Object.keys(newLabels.labels[lang]).sort().map(function (key) {
        if (key.startsWith(filterGroup) ) {
          if (labels[key] && labels[key].languages[lang]) {
            labels[key].languages[lang] = newLabels.labels[lang][key];
          } else {
            if (!labels[key]) labels[key] = {};
            if (!labels[key].languages) labels[key].languages = {};
            if (!labels[key].languages[lang]) labels[key].languages[lang] = {};
            labels[key].languages[lang] = newLabels.labels[lang][key];
          }
          labels[key].key = key;
          labels[key].name = key;
          labels[key].displayValue = key;
        }
      });
    }
  }
  return labels;
}

module.exports = {
  setPagination: function (labels, pagination) { return setPagination(labels, pagination); },
  parseLabels: function (labels, newLabels, languages, filterGroup) { return parseLabels (labels, newLabels, languages, filterGroup); },
};
