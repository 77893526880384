import React from 'react';
import Col from 'react-bootstrap/lib/Col';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import Radio from 'react-bootstrap/lib/Radio';
import InputGroup from 'react-bootstrap/lib/InputGroup';
import FormControl from 'react-bootstrap/lib/FormControl';
import Row from 'react-bootstrap/lib/Row';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import Button from 'react-bootstrap/lib/Button';
import Panel from 'react-bootstrap/lib/Panel';
import ButtonGroup from 'react-bootstrap/lib/ButtonGroup';
import Collapse from 'react-bootstrap/lib/Collapse';
import ListGroup from 'react-bootstrap/lib/ListGroup';
import ListGroupItem from 'react-bootstrap/lib/ListGroupItem';
import { PexText } from '../../form-components/pex-text.jsx';
import { PexDropDown } from '../../form-components/pex-drop-down.jsx';
import { PexFilterBsb } from "../../form-components/pex-filter-bsb.jsx";
let getCurrentMoment = require('../../../../lib/get-current-moment.js');

export default class UserChoosePayment extends React.Component {
  render () {
    let typeSelected = '';
    if (!this.props.changePaymentType) return <div className="payment-details-error">Message error: No changePaymentType function DD30001</div>;
    if (this.props.paymentTypeSelected) {
      typeSelected = this.props.paymentTypeSelected;
    } else {
      typeSelected = "bankAccount";
    }
    return (
      <div className="bank-debit-select">
        <RadioElementsPayment changePaymentType={this.props.changePaymentType} paymentTypeSelected={typeSelected}/>
        <BankPayment paymentTypeSelected={typeSelected}
          updatePaymentForm={this.props.updatePaymentForm}
          bankForm={this.props.bankForm}
          updateBankForm={this.props.updateBankForm}
          onKeyDownBsbHandler={this.props.onKeyDownBsbHandler}
          bankList={this.props.bankList}
          bankSelected={this.props.bankSelected}
          selectBank={this.props.selectBank}/>
        <CardPayment paymentTypeSelected={typeSelected}
          updatePaymentForm={this.props.updatePaymentForm}
          debitCardForm={this.props.debitCardForm}
          updateDebitCardForm={this.props.updateDebitCardForm}
        />
      </div>
    );
  }
}

function RadioElementsPayment (props) {
  function changePaymentType (newActiveForm) {
    props.changePaymentType(newActiveForm);
  };

  let options = [];
  if (props.paymentTypeSelected === 'debitCard') {
    options.push(<Button key ="1" bsSize="xs" className="radio-selection-bank-mode" onClick={ () => changePaymentType("bankAccount")}>
      <i className="fa fa-university fa-5x"></i>
      <p>Bank Account</p>
    </Button>);
    options.push(<Button key ="2" bsSize="xs" className="btn-off radio-selection-card-mode active" onClick={ () => changePaymentType("debitCard")}>
      <i className="fa fa-credit-card fa-5x"></i>
      <p>Debit Card</p>
    </Button>);
  } else {
    options.push(<Button key ="1" bsSize="xs" className="btn-on radio-selection-bank-mode active" onClick={ () => changePaymentType("bankAccount")} >
      <i className="fa fa-university fa-5x"></i>
      <p>Bank Account</p>
    </Button>);
    options.push(<Button key ="2" bsSize="xs" className="radio-selection-card-mode" onClick={ () => changePaymentType("debitCard")}>
      <i className="fa fa-credit-card fa-5x"></i>
      <p>Debit Card</p>
    </Button>);
  }

  return (
    <div className="big-switch" id="status">
      <ButtonGroup name="big-switch">{options}</ButtonGroup>
    </div>
  );
}

function BankPayment (props) {
  function updatePaymentForm (event) {
    props.updatePaymentForm(event.target.name, event.target.value);
  }

  function selectBank (bank) {
    props.selectBank(bank);
  }

  let formProps = {
    values: props.bankForm.values || {},
    errors: props.bankForm.fieldsWithErrors ? props.bankForm.fieldsWithErrors : {},
    updateField: props.updateBankForm,
    submitted: props.bankForm.submitted,
  };

  let bankList = null;
  if (props.bankForm.values.code !== "" && props.bankList && props.bankList.bankInfoList && props.bankList.bankInfoList.length > 1 && Object.keys(props.bankSelected).length === 0) {
    let options = [];
    let list = JSON.parse(JSON.stringify(props.bankList.bankInfoList));
    for (let bank of list) {
      if (bank.code.startsWith(props.bankForm.values.code)) {
        options.push(<BankOption key={bank.code} bank={bank} selectBank={ ()=>{selectBank(bank);} } />);
      }
    }
    bankList = <ListGroup bsClass="bank-list">{options}</ListGroup>;
  }

  return (
    <Collapse in={props.paymentTypeSelected === "bankAccount"} >
      <div className="bank-account" id="bank-account">
        <div className="decription">
            Please enter your Direct Debit details:
        </div>
        <Row>
          <Col sm={4}>
            <PexFilterBsb key="code" fieldKey="code" id="code" name="code" className="code mandatory" placeholder="Enter your BSB number" label="BSB" {...formProps}
              onKeyDownBsbHandler={props.onKeyDownBsbHandler}/>
            {bankList}
          </Col>
          <Col sm={6}>
            <strong className="bank-name">{props.bankSelected && props.bankSelected.name ? props.bankSelected.name : ""}</strong>
          </Col>
        </Row>
        <Row>
          <Col sm={4}>
            <PexText key="accountName" fieldKey="accountName" id="accountName" name="accountName" className="accountName mandatory" placeholder="Full name on account" label="Name on account" {...formProps} />
          </Col>
          <Col sm={4}>
            <PexText key="accountNumber" fieldKey="accountNumber" id="accountNumber" name="accountNumber" className="accountNumber mandatory" placeholder="Type your account number" label="Account number" {...formProps} />
          </Col>
          <Col sm={4}>
          </Col>
        </Row>
        <BankSelectedValue name="code_" value={formProps.values["code_"]}/>
      </div>
    </Collapse>
  );

}
function BankSelectedValue (props) {
  return <input type="hidden" name={props.name} value={props.value} />;
}

function BankOption (props) {
  return <ListGroupItem bsClass="bank-option btn btn-default" onClick={props.selectBank} >{props.bank.code + " (" + props.bank.name + ")"}</ListGroupItem>;
}

function CardPayment (props) {
  let formProps = {
    values: props.debitCardForm.values || {},
    errors: props.debitCardForm.fieldsWithErrors ? props.debitCardForm.fieldsWithErrors : {},
    updateField: props.updateDebitCardForm,
    submitted: props.debitCardForm.submitted,
  };
  function updatePaymentForm (event) {
    props.updatePaymentForm(event.target.name, event.target.value);
  }
  let expiryMonthArr = [];
  let expiryYearArr = [];
  for (let month = 1;month <= 12;month++) expiryMonthArr.push(<option key={month} value={month < 10 ? "0" + month : month}>{month < 10 ? "0" + month : month}</option>);
  for (let year = Number(getCurrentMoment().format("YY"));year <= Number(getCurrentMoment().format("YY")) + 9;year++) expiryYearArr.push(<option key={year} value={year}>{year}</option>);


  // NOTE: to see the required message for expiryMonth and expiryYear the input-group has not to have font-size:0, but we do nothing because it is a design thing.
  return (
    <Collapse in={props.paymentTypeSelected === "debitCard"} >
      <div className="card-panel" id="card">
        <div className="decription">
              Please enter your card details:
        </div>
        <Row>
          <Col sm={4}>
            <PexText key="cardNumber" fieldKey="cardNumber" id="cardNumber" name="cardNumber" className="cardNumber mandatory" placeholder="Enter your card number" label="Card number" {...formProps} />
          </Col>
          <Col sm={4}>
            <PexText key="nameOnCard" fieldKey="nameOnCard" id="nameOnCard" name="nameOnCard" className="nameOnCard mandatory" placeholder="Full name on card" label="Name (as it appears on card)" {...formProps} />
          </Col>
        </Row>
        <Row>
          <Col sm={4}>
            <ControlLabel htmlFor="expirationDate">Expiration date</ControlLabel>
            <PexDropDown fieldKey="expiryMonth" id="expiryMonth" name="expiryMonth" className = "input-group-btn expiryMonth mandatory" input-group={true} placeholder= "Month" {...formProps} >{expiryMonthArr}</PexDropDown>
            <PexDropDown fieldKey="expiryYear" id="expiryYear" name="expiryYear" className = "input-group-btn expiryYear mandatory" input-group={true} placeholder= "Year" {...formProps} >{expiryYearArr}</PexDropDown>
          </Col>
          <Col sm={4}>
            <PexText key="securityCode" fieldKey="securityCode" id="securityCode" name="securityCode" className="securityCode mandatory" placeholder="Enter your credit card security code" label="Security code (3 on back, Amex: 4 on front)" {...formProps} />
          </Col>
        </Row>
      </div>
    </Collapse>
  );

}
