import React from 'react';
import Popover from 'react-bootstrap/lib/Popover';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import Button from 'react-bootstrap/lib/Button';
import ButtonGroup from 'react-bootstrap/lib/ButtonGroup';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import Image from 'react-bootstrap/lib/Image';
import utils from "../../../lib/utils";


export default class Addons extends React.Component {

  componentDidMount () {
    this.props.loadAddons();
  }

  render () {

    if (this.props.state && this.props.state.stepLoaded && !this.props.state.error) {
      let roomDescription = "";
      let fplan = <Image src={"http://via.placeholder.com/350x150"} thumbnail />;

      if (this.props.state.stay.room.features.filter(function (feature) { return feature.type.lookupValue === "SHORT_DESCRIPTION";}).length > 0) {
        roomDescription += this.props.state.stay.room.features.filter(function (feature) { return feature.type.lookupValue === "SHORT_DESCRIPTION";})[0].text;
      }
      if (this.props.state.stay.room.floor && this.props.state.stay.room.floor.displayValue) {
        roomDescription += ", " + this.props.state.stay.room.floor.displayValue;
      }
      if (this.props.state.stay.room.features.filter(function (feature) { return feature.type.lookupValue === "SIZE";}).length > 0) {
        roomDescription += ", " + this.props.state.stay.room.features.filter(function (feature) { return feature.type.lookupValue === "SIZE";})[0].text;
      }

      let addonsTitleLabel = utils.format_path(this.props.state.labels["graingerBookingJourney.addons.title"], {"MONTHS": this.props.state.tenancyMonths, "ROOM_NAME": this.props.state.stay.room.displayValue});;

      if (this.props.state.stay.room.features.filter(function (feature) { return feature.type.lookupValue === "FPLAN";}).length > 0) {
        fplan = <Image src={this.props.state.stay.room.features.filter(function (feature) { return feature.type.lookupValue === "FPLAN";})[0].image} thumbnail />;
      } else if (this.props.state.stay.room.features.filter(function (feature) { return feature.type.lookupValue === "IMAGE";}).length > 0) {
        fplan = <Image src={this.props.state.stay.room.features.filter(function (feature) { return feature.type.lookupValue === "IMAGE";})[0].image} thumbnail />;
      }


      let informationPopover = <Popover id="addons-parking-information">
        <div className={"addons-popover-content"} dangerouslySetInnerHTML={{__html: utils.sanitize(this.props.state.labels["graingerBookingJourney.getStartedA.popoverInformationLabel"])}}></div>
      </Popover>;

      let parkingButtonLabel = this.props.state.labels["graingerBookingJourney.addons.joinWaitList"];
      if (this.props.state.addons.offerParking) {
        parkingButtonLabel = utils.format_path(this.props.state.labels["graingerBookingJourney.addons.parkingButton"], {"PARKING_PRICE": this.props.state.addons.offerParking.price.number});
      }

      let totalMonthlyLabel = utils.format_path(this.props.state.labels["graingerBookingJourney.addons.totalMonthlyLabelWithoutParking"], {"TOTAL_PRICE": this.props.state.stay.room.price});
      if (this.props.state.addons.withParking && this.props.state.addons.offerParking) {
        totalMonthlyLabel = utils.format_path(this.props.state.labels["graingerBookingJourney.addons.totalMonthlyLabelWithParking"], {"TOTAL_PRICE": parseFloat(this.props.state.stay.room.price) + parseFloat(this.props.state.addons.offerParking.price.number)});
      }

      return <div className={""}>
        <div className={""}>
          <h3 className={"addons-title"} dangerouslySetInnerHTML={{__html: utils.sanitize(addonsTitleLabel)}}></h3>
        </div>
        <Row className={"addons-message"}>
          <Col md={6}>
            <div className={"room-building-description well well-sm"}>
              <div className={"room-name"}>{this.props.state.stay.room.title}</div>
              <div className={"room-price"}>{this.props.state.labels["graingerBookingJourney.addons.currency"] + this.props.state.stay.room.price + " " + this.props.state.labels["graingerBookingJourney.addons.period"]}</div>
              <div className={"room-description"} dangerouslySetInnerHTML={{__html: utils.sanitize(roomDescription)}}></div>
              <div className={"room-image"}>{fplan}</div>
              <div className={"room-details-url"}>
                <Button bsStyle={"link"} className={"room-see-details-btn"} dangerouslySetInnerHTML={{__html: utils.sanitize(this.props.state.labels["graingerBookingJourney.addons.seeDetailsLabel"])}}></Button>
              </div>
            </div>
          </Col>
          <Col md={6}>
            <Button className={"availability-period-btn"} block active>{this.props.state.tenancyMonthsLabel}</Button>
            <Button className={"gym-btn"} block active>{this.props.state.labels["graingerBookingJourney.addons.gymButton"]}</Button>
            <Button className={"wifi-speed-btn"} block active>{this.props.state.labels["graingerBookingJourney.addons.speedWifiButton"]}</Button>
            <ButtonGroup className={"parking"}>
              <Button className={"parking-btn"} onClick={()=>this.props.clickParking()} active={this.props.state.addons.withParking}>{parkingButtonLabel}</Button>
              <OverlayTrigger trigger="click" placement="right" overlay={informationPopover} rootClose>
                <Button bsStyle="link" className={"addons-popover-btn"}><i className='fa fa-question-circle'></i></Button>
              </OverlayTrigger>
            </ButtonGroup>
          </Col>
          <Col sm={12}>
            <Button bsStyle={"link"} className={"cancel-booking-btn"} onClick={this.props.cancelOnHoldBooking}>
              {this.props.state.labels["graingerBookingJourney.addons.changeApartmentLink"]}
            </Button>
          </Col>
          <Col sm={12}>
            <h4 className={"summary"}>
              {this.props.state.labels["graingerBookingJourney.addons.totalMonthlyLabel"]}
              <span className={"summary-quote"}>{totalMonthlyLabel}</span>
            </h4>
          </Col>
        </Row>
      </div>;
    }
    else return <div>Loading step...</div>;
  }
}
