import React from 'react';
import ajax from '../lib/ajax.js';
import formService from './services/form-service.js';
import { PexDropDown } from "./components/form-components/pex-drop-down.jsx";
import Form from 'react-bootstrap/lib/Form';
import Panel from 'react-bootstrap/lib/Panel';
import ButtonGroup from 'react-bootstrap/lib/ButtonGroup';
import Button from 'react-bootstrap/lib/Button';
import Nav from 'react-bootstrap/lib/Nav';
import Navbar from 'react-bootstrap/lib/Navbar';
import NavItem from 'react-bootstrap/lib/NavItem';
import Col from 'react-bootstrap/lib/Col';
import Row from 'react-bootstrap/lib/Row';
let api = require('../lib/api.js')(ajax);

import { createStore } from 'redux';

export default class WidgetDemo extends React.Component {
  constructor (props) {
    super(props);
    let myState = {};
    if (!this.props.api) myState = {data: {error: "configuration error - no api specified"}};
    myState = Object.assign({}, myState, formService.initWidgetDemoForm(this.props.requiredFields));
    myState.loaded = false;
    this.store = createStore(function (state = myState, action) {
      switch (action.type) {
        case "MERGE_STATE": return Object.assign({}, myState, action.state);
        case "GET_OPTIONS": return Object.assign(state, { "nextAction": "getOptions" });
        case "GET_OPTIONS_OK": return Object.assign(state, { "formOptions": JSON.parse(JSON.stringify(action.getOptionsResponse)), "loaded": true, "nextAction": "DONE" });
        case "API_ERROR": return Object.assign({}, state, {data: {error: action.error}, "waiting": false, "loaded": true, "nextAction": "DONE"});
        case "WAITING": return Object.assign(state, {waiting: true});
        case "SHOW_WIDGETS": return Object.assign(state, {"showWidgets": true});
        case "UPDATE_FIELD": {
          let values = state.values;
          values[action.field] = action.value;
          if (action.field === "area") values.building = "";
          let new_state = Object.assign({}, state, formService.checkForm(values, state.formNames), values);
          return Object.assign({}, state, new_state);
        }
        case "SUBMITTED_WITH_ERRORS": return Object.assign(state, {submitted: true});
        default: return state;
      }
    }, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
    this.state = this.store.getState();
  }

  componentWillUnmount () {
    this.unsubscribe();
  }

  render () {
    if (this.state && this.state.loaded) {
      return <div className={(this.state.waiting) ? "divFrozen" : ""}>
        <WidgetDemoNav />
        <FormPex state={this.state} waiting={::this.waiting} showWidgets={::this.showWidgets}
          submittedWithErrors={::this.submittedWithErrors} generalProps={this.getFormControlProps()} />
        <Widgets state={this.state} api={this.props.api} sso={this.props.sso}/>
      </div>;
    } else {
      return <div>Loading...</div>;
    }
  }

  componentDidMount () {
    if (this.store) {
      this.unsubscribe = this.store.subscribe(function () {
        let state = {};
        for (let key of Object.keys(this.state)) state[key] = null;
        this.setState(Object.assign({}, state, this.store.getState()));
      }.bind(this));
      this.store.dispatch({type: "GET_OPTIONS"});
    }
  }

  componentDidUpdate (prevProps, prevState) {
    switch (this.state.nextAction) {
      case "getOptions":
        this[this.state.nextAction]();
    }
  }

  waiting () {
    this.store.dispatch({type: "WAITING"});
  }

  getFormControlProps () {
    return {
      values: this.state.values || {},
      errors: this.state.fieldsWithErrors ? this.state.fieldsWithErrors : {},
      updateField: this.updateFieldFromEvent.bind(this),
      submitted: this.state.submitted,
      waiting: this.state.waiting,
    };
  }

  updateFieldFromEvent (field, event) {
    this.store.dispatch({type: "UPDATE_FIELD", "field": field, "value": event.target.value});
  }

  submittedWithErrors () {
    this.store.dispatch({type: "SUBMITTED_WITH_ERRORS"});
  }

  showWidgets () {
    this.store.dispatch({type: "SHOW_WIDGETS"});
  }

  getOptions () {
    let self = this;
    api.marketingCollections(this.props.api, {}, function (data) {
      self.store.dispatch({type: "GET_OPTIONS_OK", getOptionsResponse: data});
    }, function (error) {
      self.store.dispatch({type: "API_ERROR", "error": error});
    });
  }
}

function FormPex (props) {

  const areas = [];
  let buildings = [];
  let roomsObject = {};

  if (props.state.formOptions && props.state.formOptions !== undefined && props.state.formOptions.locations.length >= 1) {
    for (let areaOption of props.state.formOptions.locations) {
      areas.push(areaOption);
      if (areaOption.buildings) {
        for (let buildingOption of areaOption.buildings) {
          let building = buildingOption;
          building.location = areaOption;
          buildings.push(building);
          if (buildingOption.roomTypes) {
            for (let roomTypeOption of buildingOption.roomTypes) {
              if (!roomsObject[roomTypeOption.lookupValue]) roomsObject[roomTypeOption.lookupValue] = roomTypeOption;
            }
          }
        }
      }
    }
    let roomTypes = Object.values(roomsObject);
    if (props.state.values.building) {
      for (let building of buildings) {
        if (building.lookupValue === props.state.values.building) {
          props.state.values.area = building.location.lookupValue;
          break;
        }
      }
    }
    if (props.state.values.area) {
      for (let building_idx in buildings) {
        let newBuilding = buildings[building_idx];
        if (newBuilding.location.lookupValue !== props.state.values.area) {
          delete buildings[building_idx];
        }
      }
    }
    // if (props.state.values.roomType) {
    //   for (let singleBuilding of buildings) {
    //     var aBuilding = {};
    //     aBuilding[singleBuilding.lookupValue] = singleBuilding.lookupValue;
    //     for (let sbRoomType of singleBuilding.roomTypes) {
    //       aBuilding.roomTypes[sbRoomType.lookupValue] = sbRoomType;
    //     }
    //     if (aBuilding.roomTypes[props.state.values.roomType]) {
    //       delete buildings[singleBuilding_idx];
    //     }
    //   }
    // }

    var areaOptions = areas.map(function (option) {
      return <option key={option.lookupValue} value={option.lookupValue}>{option.displayValue}</option>;
    });

    var buildingOptions = buildings.map(function (option) {
      return <option key={option.lookupValue} value={option.lookupValue}>{option.displayValue}</option>;
    });

    if (roomTypes.length >= 1) {
      var roomTypeOptions = roomTypes.map(function (option) {
        return <option key={option.lookupValue} value={option.lookupValue}>{option.displayValue}</option>;
      });
    }
  }

  return <Navbar fixedTop>
    <Nav>
      { areaOptions ? <PexDropDown fieldKey="area" className="widget-demo-area" iconClassName="fa fa-university"
        label="Select an area" {...props.generalProps }> {areaOptions} </PexDropDown> : "The system may not have any areas" }

      { buildingOptions ? <PexDropDown fieldKey="building" className="widget-demo-building" iconClassName="fa fa-building"
        label="Select a building" {...props.generalProps }> {buildingOptions} </PexDropDown> : "The system may not have any buildings" }


      { roomTypeOptions ? <PexDropDown fieldKey="roomType" className="widget-demo-roomType" iconClassName="fa fa-bed"
        label="Select a room type" {...props.generalProps }> {roomTypeOptions} </PexDropDown> : "The system may not have any room types" }
    </Nav>
  </Navbar>;
}

function Widgets (props) {

  let widgetArea, widgetBuilding, widgetRoomType, contactAttributes, contactAttributesUrl, buildingResultsAttributesUrl;

  if (props.state.values) {
    if (props.state.values.area) widgetArea = props.state.values.area;
    if (props.state.values.building) widgetBuilding = props.state.values.building;
    if (props.state.values.roomType) widgetRoomType = props.state.values.roomType;
  }

  /* building the strings required to show examples of the implementation for the contact form here. The thought at this point is to create these statements
  for each variation of plug-in, however I am sure I could probably make some kind of service to create these for each widget as they are quite similar */
  // CONTACT FORM
  if (widgetArea) contactAttributesUrl = "?location=" + widgetArea;
  if (widgetBuilding) contactAttributesUrl = "?building=" + widgetBuilding;
  if (widgetArea && widgetBuilding) contactAttributesUrl = "?location=" + widgetArea + "&building=" + widgetBuilding;
  widgetArea ? contactAttributes = " location='" + widgetArea + "'" : contactAttributes = "";
  if (widgetBuilding) contactAttributes += " building='" + widgetBuilding + "'";
  let standardContactImplementation = "<div id='contact-form' class='pex-contact-form' api='" + props.api + "'>Initialising...</div>";
  let contactImplementation = "<div id='contact-form'" + contactAttributes + " class='pex-contact-form' api='" + props.api + "'>Initialising...</div>";
  let contactImplementationUrl = "<a href='/contact.html" + contactAttributesUrl + "'>Contact Us at " + widgetBuilding + "</a>";

  // BUILDING RESULTS
  let standardBuildingResultsImplementation = "<div id='building-form' class='pex-results-in-building' api='" + props.api + "'>Initialising...</div>";
  let buildingResultsImplementation = "<div id='building-form' class='pex-results-in-building' building='" + widgetBuilding + "' api='" + props.api + "'>Initialising...</div>";
  let buildingResultsImplementationUrl = "<a href='/building.html?building=" + widgetBuilding + "'>View the results in " + widgetBuilding + "</a>";

  let standardArrangeImplementation = "<div id='arrange-form' class='pex-contact-form' api='" + props.api + "'>Initialising...</div>";
  let arrangeImplementationUrl = "<a href='/arrange.html'>View the arrange form</a>";

  let standardSearchResultsImplementation = "<div id='demo-search-applet' class='pex-search-applet' sso='" + props.sso + "' api='" + props.api + "' arrange='arrange-room-viewing-form.html?room=<ROOM>' enquire='enquiry-room.html?room=<ROOM>' uiversitiesFilteredByLocation='false' timeOutBook='700'> Initialising... </div>";

  return <div className="container">
    <Row>
      <Col md={12} xs={12} className="col-centred">
        <div className="center">

          <Panel header = {<div className="widget-demo-header"><h3>Getting Started</h3></div>} >
            <p>The PEX Widgets DOM is rendered onto any web page by including a particular HTML tag. This then allows you to modify the look and feel via CSS styles like any other DOM element on your page.
              This also means that any class selector that the widgets use will inherit any matching styles on the target page, for example, if you have a CSS class of <code>.button</code>, any element with
              the button style will inehrit the styles defined. <br /><br />

              In order to use the PEX Widgets library, you must first ensure that the <a href="/widgets/pex-widgets-no-polyfill.js">pex-widgets-no-polyfill.js</a>, <a href="/widgets/babel-polyfill.js">babel-polyfill.js</a> and
            <a href="lib/query_string.js"> query_string.js</a> files are included <strong>at the end</strong> of any page on which a PEX widget will exist, in the form of
              a resource link, in this order: </p>

            <pre>
              &lt;script src='{window.location.host + "/widgets/babel-polyfill.js"}'&gt;&lt;/script&gt; <br />
              &lt;script src='{window.location.host + "/widgets/pex-widgets-no-polyfill.js"}'&gt;&lt;/script&gt; <br />
              &lt;script src='{window.location.host + "/lib/query_string.js"}'&gt;&lt;/script&gt;
            </pre>

            <strong>Other Notes</strong>
            <p>The ".html" examples throughout this demo can be replaced with whatever page the widget is embedded on, for example:</p>
            <ul>
              <li>/contact.html becomes /contact-us</li>
              <li>/search-applet.html becomes /search</li>
            </ul>

            <strong>Styling</strong>

            <p>If you want to use the default PEX Widget styling, you will also need to include these Bootstrap CSS files, however, you are free to style the widgets with your own CSS:</p>

            <pre>
              &lt;link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css"&gt; <br />
              &lt;link href="https://maxcdn.bootstrapcdn.com/bootswatch/3.3.7/cosmo/bootstrap.min.css" rel="stylesheet" integrity="sha384-h21C2fcDk/eFsW9sC9h0dhokq5pDinLNklTKoxIZRUn3+hvmgQSffLLQ4G4l2eEr" crossorigin="anonymous"&gt; <br />
              &lt;link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.6.3/css/font-awesome.min.css" rel="stylesheet" integrity="sha384-T8Gy5hrqNKT+hzMclPo118YTQO6cYprQmhrYwIiQ/3axmI1hQomh7Ud2hPOy8SP1" crossorigin="anonymous"&gt;
            </pre>

            <p>If you choose to style the widgets with your own CSS please bear in mind that there are some DOM elements that are detrimental to the functionality
              of the widgets.<br /><br />
              There are some reserved DOM elements that cannot be hidden via styles, if they are, you will resume responsibility if the widgets
              stop working in the desired way:</p>

            <ul>
              <li><strong>DO NOT</strong> hide the "Enquire", "Viewing", or "Book" buttons.</li>
              <ul>
                <li>If you want to remove the "Enquire" or "Viewing" buttons, you can do so by removing that attribute from the widget implementation. For example: <br />
                  <pre>&lt;div id="demo-search-applet" arrange="arrange-room-viewing-form.html"&gt;&lt;/div&gt;</pre>
                  becomes: <br />
                  <pre>&lt;div id="demo-search-applet"&gt;&lt;/div&gt;</pre>
                </li>
              </ul>
              <li><strong>DO NOT</strong> add or remove DOM elements with JavaScript. If you need a DOM change, please contact PEX Support.</li>
            </ul>

          </Panel>

          <Panel header = {<div className="widget-demo-header"><h3>Contact Form</h3></div>} >
            <p>The Contact Form plug-in is embedded in the following way:</p>
            <code>{standardContactImplementation}</code>
            <p>The Contact Form plug-in accepts the following parameters:</p>
            <ul>
              <li>api (required)</li>
              <li>location</li>
              <li>building</li>
              <li>requiredFields</li>
              <li>thanksmessage</li>
            </ul>
            <p>The Contact Form plug-in can preselect valid form properties that are passed into it. As an example, select an Area and/or Building from the dropdowns above
              and check the result. The below code shows how you would implement the contact form on the page:</p>
            <code>{(widgetBuilding || widgetArea) ? contactImplementation : "For an example, please select a Building and/or Area"}</code>
            <p>If you have multiple areas and buildings, you will want to build a URL dynamically with a query string that instructs the widget what to do:</p>
            <a className={(widgetBuilding || widgetArea) ? "show" : "hide"} href={"contact.html" + contactAttributesUrl}><strong>Example link (click)</strong><code>{contactImplementationUrl}</code></a>
          </Panel>

          <Panel header = {<div className="widget-demo-header"><h3>Arrange a Viewing Form</h3></div>} >
            <p>The Arrange a Viewing Form plug-in is embedded in the following way:</p>
            <code>{standardArrangeImplementation}</code>
            <p>The Arrange a Viewing Form plug-in accepts the following parameters:</p>
            <ul>
              <li>api (required)</li>
              <li>requiredFields</li>
              <li>thanksmessage</li>
            </ul>
            <p>This is a link to an example of the Arrange Form plug-in:</p>
            <a href="arrange.html"><strong>Example link (click)</strong><code>{arrangeImplementationUrl}</code></a>
          </Panel>

          <Panel header = {<div className="widget-demo-header"><h3>Room Type Availability in a Building</h3></div>} >
            <p>The Room Type Availability in a Building plug-in is embedded in the following way:</p>
            <code>{(widgetBuilding) ? standardBuildingResultsImplementation : "For an example, please select a Building"}</code>
            <p>The Results in a Building plug-in accepts the following parameters:</p>
            <ul>
              <li>building (required)</li>
            </ul>
            <p>The Results in a Building plug-in will show each room type available in a building</p>
            <code>{(widgetBuilding) ? buildingResultsImplementation : "For an example, please select a Building"}</code>
            <p>If you have multiple buildings, you will want to build a URL dynamically with a query string that instructs the widget what to do:</p>
            <a className={(widgetBuilding) ? "show" : "hide"} href={"building.html?building=" + widgetBuilding}><strong>Example link (click)</strong><code>{buildingResultsImplementationUrl}</code></a>
          </Panel>

          <Panel header = {<div className="widget-demo-header"><h3>Search & Register Applet</h3></div>} >
            <p>The Search & Register applet is embedded in the following way:</p>
            <code>{standardSearchResultsImplementation}</code>
            <p>The Results in a Building plug-in accepts the following parameters:</p>
            <ul>
              <li>api (required)</li>
              <li>sso (required)</li>
              <li>contact (room) form (optional)</li>
              <li>arrange a viewing (room) form (optional)</li>
            </ul>

            <p><strong>Please note</strong> that the Contact and Arrange a Viewing plug-ins that the Search & Register applet links to are different to the two aforementioned forms.
              Please see the Contact Form (Room) and Arrange a Viewing (Room) plug-in implementation instructions for more info. </p> <br />
            <a href="search-applet-with-prs-journey.html"><strong>Example link with PRS booking journey (click)</strong></a> <br />
            <a href="search-applet-with-student-booking-journey.html"><strong>Example link with Student booking journey (click)</strong></a>
          </Panel>

        </div>
      </Col>
    </Row>
  </div>;
}

function WidgetDemoNav () {
  return <Nav className="widget-nav" bsStyle="pills" stacked>
    <NavItem disabled>
      Widget Demo Links:
    </NavItem>
    <NavItem eventKey={1} href="https://docs.google.com/document/d/13IsqvAXQl6e7rmg-uwtu1yWJJAAmt25NtGCFsi_0Hpo/edit?usp=sharing" title="Agency Expectations">
      Agency Expectations
    </NavItem>
    <NavItem eventKey={2} href="https://docs.google.com/document/d/1FoVA92Br5EX5Wo81d_hL3dUMGgHzA-nAgDBMLV0ukZo/edit?usp=sharing" title="Styling the PEX Web Applications">
      Styling the Web Applications
    </NavItem>
    <NavItem eventKey={3} href="https://docs.google.com/document/d/1IkwOVYPPx93RQCgjUdv2UBE1GfW0NnJ62_6ZC5UqD9c/edit?usp=sharing" title="Student Leasing Journey">
      Student Leasing Journey Documentation
    </NavItem>
    <NavItem eventKey={3} href="https://docs.google.com/document/d/18Pjo8FfVDW_4l9FbE6PKSuAHTWp5SG6_EdDjaLQ1Nk0/edit?usp=sharing" title="PRS Leasing Journey Documentation">
      PRS Leasing Journey Documentation
    </NavItem>
    <NavItem eventKey={3} href="https://docs.google.com/document/d/1SpCLzaZnRS4WJxL0aJyNUclyaXavlwA-VBdHsCW52LY/edit?usp=sharing" title="Resident Portal Documentation">
      Resident Portal Documentation
    </NavItem>
    <NavItem eventKey={3} href="https://docs.google.com/document/d/1Ngcl8O4gYVT8buxAjPohqY0N8skblVhJtiHpm_wBEAg/edit?usp=sharing" title="PmX / Widgets Documentation">
      PmX / Widgets Documentation
    </NavItem>
  </Nav>;
}
