import React from 'react';
import Col from 'react-bootstrap/lib/Col';
import Row from 'react-bootstrap/lib/Row';
import Button from 'react-bootstrap/lib/Button';
import Form from 'react-bootstrap/lib/Form';
import InputGroup from 'react-bootstrap/lib/InputGroup';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import FormControl from 'react-bootstrap/lib/FormControl';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import Panel from 'react-bootstrap/lib/Panel';
import { PexPassword } from "../form-components/pex-password.jsx";
import translateService from "../../services/translate-service";
import TagManager from 'react-gtm-module';
import PexCookiePolicy from '../../../legal/pex-cookie-policy.jsx';


export default class PexNewPassword extends React.Component {

  render () {

    let self = this;

    function translate (name) {
      return translateService.getLabelsTranslated(self.props.labels, "searchApplet.pexNewPassword.", name, self.props.defaultLabels);
    }

    if (!this.props.lostPasswordConfirmationUsername || !this.props.lostPasswordConfirmationVerificationId) return <div className="pex-new-password-url-error">{translate("invalidURL")}</div>;
    let formProps = {
      values: this.props.data.values,
      errors: this.props.data.fieldsWithErrors,
      submitted: this.props.data.submitted,
      updateField: this.props.updateNewPasswordForm,
    };
    let errorMessage = this.props.newPasswordError ? <p className="pex-new-password-error">{this.props.newPasswordError}</p> : null;
    let title = <h3 className="pex-new-password-form-title"><strong>{translate("title")}</strong></h3>;
    return <Row>
      <Col sm={6} className="pex-new-password col-centered">
        <Form horizontal onSubmit={this.props.startLostPasswordConfirmation}>
          <Panel header = {title} >
            <h4 className="pex-new-password-instructions">{translate("instructionsLabel")}</h4>
            <PexPassword fieldKey="password" className = "pex-new-password-form-password" iconClassName="fa fa fa-lock" label={translate("passwordLabel")} placeholder={translate("passwordPlaceholderl")} {...formProps } />
            <PexPassword fieldKey="confirmPassword" className = "pex-new-password-form-confirm-password" iconClassName="fa fa fa-lock" label={translate("confirmPasswordLabel")} placeholder={translate("confirmPasswordPlaceholder")} {...formProps } />
            <Button bsStyle="success" className="pex-new-password-save-button" type="submit">{translate("saveButton")}</Button>
            {errorMessage}
          </Panel>
        </Form>
      </Col>
      <PexCookiePolicy colMd={9} />
    </Row>;
  }

}
