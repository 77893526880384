import React from 'react';
import FormControl from 'react-bootstrap/lib/FormControl';
import { PexCommonField } from "./pex-common-field.jsx";
import DatePicker from 'react-datepicker';
import moment from 'moment';

export class PexDatePicker extends React.Component {
  render () {
    return <PexCommonField {...this.props}>
      <DateField excludeDates={this.props.excludeDates ? this.props.excludeDates : []}
        maxDate={this.props.maxDate ? this.props.maxDate : null}
        getCurrentMoment={this.props.getCurrentMoment ? this.props.getCurrentMoment : null}
      />
    </PexCommonField>;
  }
}

class DateField extends React.Component {
  handleChange (date) {
    this.props.onChange({target: {value: date.unix()}});
  }

  render () {
    return <DatePicker dateFormat="DD/MM/YY" minDate={this.props.getCurrentMoment} className="form-control" selected={this.props.value ? moment.unix(this.props.value) : null} placeholderText={this.props.placeholder} onChange={::this.handleChange}
      excludeDates={this.props.excludeDates ? this.props.excludeDates : []} maxDate={this.props.maxDate}/>;
  }
}
