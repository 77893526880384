import React from 'react';
import Modal from 'react-bootstrap/lib/Modal';


export class WrapModal extends React.Component {

  render () {
    return <Modal
      show={this.props.showModal}
      container={this.props.modalContainer}
      onHide={this.props.closeModal}>
      {React.cloneElement(this.props.children)}
      <div className={"modal-footer text-center"}>
        {this.props.buttonsFooter}
      </div>
    </Modal>;
  }

}
