import React from 'react';
import FormControl from 'react-bootstrap/lib/FormControl';

export default class LangSelector extends React.Component {


  constructor (props) {
    super(props);
    this.state = {};
    this.state.selectLbl = "Select language";
    if (window.location.search.split("=")[1] === "en-GB") this.state.selectLbl = "Select language";
    else if (window.location.search.split("=")[1] === "es-ES") this.state.selectLbl = "Seleccione idioma";
  }

  handleChange (ev) {
    //change in the url the lang selected keeping the hash
    history.pushState(null, "", "?lang=" + ev.target.value + window.location.hash);
    //reload the url to be in the same page with other language
    window.location.reload();
  }


  render () {

    return <FormControl value={window.location.search.split("=")[1] || "-"} componentClass="select" id="lang-selector" name="lang-selector" onChange={::this.handleChange} >
      <option value="-">{this.state.selectLbl}</option>
      <option value="es-ES">es-ES (Spain)</option>
      <option value="en-GB">en-GB (England)</option>
      <option value="nl-NL">nl_NL (Netherlands)</option>
      <option value="de-DE">de_DE (Germany)</option>
      <option value="pl-PL">pl_PL (Poland)</option>
      <option value="hu-HU">hu_HU (Hungary)</option>
      <option value="zh-CN">zh_CN (China)</option>
    </FormControl>;
  }

}
