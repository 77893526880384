"use strict";

let _ = require('underscore');
let utils = require("../../../lib/utils");
_ = utils.extendUnderscore(_);

module.exports = {
  setLongLabels: function (labels) {
    let longLabels = {};
    longLabels["graingerBookingJourney.getStartedA.info3Label"] = "<p><strong>Please have the following ready:</strong></p>" +
      "<p><strong>A debit or credit card for your £500 advance payment to reserve your new home.</strong></p>" +
      "<p>After submitting your details, the apartment will be reserved whilst our referencing partner, Van Mildert carres our your referencing checks</p>" +
      "<p><strong>Contact information for any additional tenants or guarantors and their consent to share their details with us.</strong></p>" +
      "<p><strong>Contact information of your emplyer and current (or previous) landlord if applicable.</strong></p>" +
      "<p><strong>Up to 3 years of previous addresses</strong></p><p><strong>Your national insurance number</strong></p>" +
      "<p><strong>UK Bank account details</strong></p>";
    longLabels["graingerBookingJourney.referencingPre.requirements"] = "<p>Please have the following ready:</p>" +
      "<p>Contact information of your employer and a previous landlord (if applicable)</p>" +
      "<p>Your income details</p>" +
      "<p>3 years of previous addresses</p>" +
      "<p>Your national insurance number</p>" +
      "<p>Bank details to confirm UK residency</p>";
    labels = Object.assign({}, labels, longLabels);
    return labels;
  },
  getUpdatePersonDetailsPayload: function (getStartedBForm) {
    let userPreferences = [];
    if (getStartedBForm.noFullTime) {
      if (getStartedBForm.noFullTime === "no-full-time-guarantor") userPreferences.push({"label": "GUARANTOR_THAT_EXCEEDS"});
      else userPreferences.push({"label": "PAY_IN_ADVANCE"});
    }
    if (getStartedBForm.credit) {
      if (getStartedBForm.credit === "credit-no") userPreferences.push({"label": "NO"});
      else userPreferences.push({"label": "YES"});
    }
    return userPreferences;
  },
  getPriceFormatted: function (lang, style, currency, priceToMultipy, numberToMultiply) {
    let priceFormatted = new Intl.NumberFormat(lang, { style: style, currency: currency}).format(parseFloat(priceToMultipy) * numberToMultiply);
    priceFormatted = priceFormatted.substring(1, priceFormatted.length);
    if (priceFormatted.endsWith(".00")) priceFormatted = priceFormatted.substring(0, priceFormatted.length - 3);
    return priceFormatted;
  },
  validateTenantAndGuarantorDetailsForms: function (tenantAndGuarantorDetails) {
    // console.log("-------------------")
    // console.log(tenantAndGuarantorDetails)
    // console.log(tenantAndGuarantorDetails.tenants["1"])
    //TENANTS
    for (let tenant of Object.keys(tenantAndGuarantorDetails.tenants)) if (!tenantAndGuarantorDetails.tenants[tenant].canSubmit) return false;
    //GUARANTOR
    // console.log(tenantAndGuarantorDetails.guarantor)
    if (tenantAndGuarantorDetails.showGuarantorForm && (!tenantAndGuarantorDetails.guarantor.canSubmit || !tenantAndGuarantorDetails.guarantorConfirm)) return false;
    //ADDITIONAL TENANTS
    if (tenantAndGuarantorDetails.additionalOccupants && Object.keys(tenantAndGuarantorDetails.additionalOccupants).length > 0) {
      for (let additionalOccupant of Object.keys(tenantAndGuarantorDetails.additionalOccupants)) if (!tenantAndGuarantorDetails.additionalOccupants[additionalOccupant].canSubmit) return false;
    }
    //CONFIRM CHECK BOX
    if (!tenantAndGuarantorDetails.generalConfirm) return false;
    return true;
  },
  getPayloadToAddPersonToTenancy: function (tenantAndGuarantorDetails) {
    for (let tenant of Object.keys(tenantAndGuarantorDetails.tenants)) {
      if (tenant === "1") continue;
      if (!tenantAndGuarantorDetails.tenants[tenant].sentToApi) {
        let payload = {
          "person": tenantAndGuarantorDetails.tenants[tenant].values,
          "contactGroup": "RESIDENT",
        };
        return {
          "form": "tenant",
          "index": tenant,
          "payload": payload,
        };
      }
    }
    if (tenantAndGuarantorDetails.additionalOccupants && Object.keys(tenantAndGuarantorDetails.additionalOccupants).length > 0) {
      for (let additionalOccupant of Object.keys(tenantAndGuarantorDetails.additionalOccupants)) {
        if (!tenantAndGuarantorDetails.additionalOccupants[additionalOccupant].sentToApi) {
          let payload = {
            "person": tenantAndGuarantorDetails.additionalOccupants[additionalOccupant].values,
            "contactGroup": "TENANT",
          };
          payload.person.dateOfBirth = tenantAndGuarantorDetails.additionalOccupants[additionalOccupant].values.dob;
          delete payload.person.dob;
          return {
            "form": "additionalOcupant",
            "index": additionalOccupant,
            "payload": payload,
          };
        }
      }
    }
    return "completed";
  }, updateTenantAdded: function (tenantAndGuarantorDetails, tenantAdded) {
    let stepCompleted = true;

    if (tenantAdded.form === "tenant") {
      tenantAndGuarantorDetails.tenants[tenantAdded.index].sentToApi = true;
    } else {
      tenantAndGuarantorDetails.additionalOccupants[tenantAdded.index].sentToApi = true;
    }
    for (let tenant of Object.keys(tenantAndGuarantorDetails.tenants)) if (tenant !== "1" && !tenantAndGuarantorDetails.tenants[tenant].sentToApi) stepCompleted = false;

    if (tenantAndGuarantorDetails.additionalOccupants && Object.keys(tenantAndGuarantorDetails.additionalOccupants).length > 0) {
      for (let additionalOccupant of Object.keys(tenantAndGuarantorDetails.additionalOccupants)) {
        if (!tenantAndGuarantorDetails.additionalOccupants[additionalOccupant].sentToApi) {
          stepCompleted = false;
        }
      }
    }

    return { "tenantAndGuarantorDetails": tenantAndGuarantorDetails, "stepCompleted": stepCompleted};
  }, formatUserValues: function (user) {
    let tenant = {};
    tenant.firstName = user.firstName;
    tenant.lastName = user.lastName;
    tenant.email = user.username;
    tenant.phoneNumber = user.mobileNumber;
    return tenant;
  },
};
