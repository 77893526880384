import React from 'react';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import Panel from 'react-bootstrap/lib/Panel';
import ListGroup from 'react-bootstrap/lib/ListGroup';
import ListGroupItem from 'react-bootstrap/lib/ListGroupItem';
import Checkbox from 'react-bootstrap/lib/Checkbox';
import Button from 'react-bootstrap/lib/Button';
import Badge from 'react-bootstrap/lib/Badge';
import utils from "../../../lib/utils";
import moment from "moment/moment";

export default class PayAdvanced extends React.Component {

  componentDidMount () {
    this.props.loadPayAdvanced();
  }

  render () {
    function paymentOk () {
      window.location.replace(window.location.href + "?component=success");
    }

    function paymentKo () {
      window.location.replace(window.location.href + "?component=failure");
    }


    if (this.props.state && this.props.state.stepLoaded && !this.props.state.error) {
      let paymentsTitle = <h3 className={"payments-panel-title"} dangerouslySetInnerHTML={{__html: utils.sanitize(this.props.state.labels["graingerBookingJourney.payAdvanced.paymentsTitle"])}}></h3>;
      let advancePaymentTitle = <h3 className={"advance-payment-panel-title"} dangerouslySetInnerHTML={{__html: utils.sanitize(this.props.state.labels["graingerBookingJourney.payAdvanced.advancePaymentTitle"])}}></h3>;
      let paymentSchedule = this.props.state.payAdvanced.getPaymentsResponse.paymentSchedules.TENANCY[0].paymentSchedule;
      let paymentsToShow = [];
      let dueArr = paymentSchedule.filter( function (payment) {
        return payment.due === true;
      });
      let i = 0;
      for (let payment of dueArr) {
        i++;
        if (payment.from && payment.to) {
          let nameAndDate = payment.name + " " + moment(payment.from).format("DD/MM/YYYY") + " - " + moment(payment.to).format("DD/MM/YYYY");
          paymentsToShow.push(<ListGroupItem key={i} className={"due-payment-" + i}>{nameAndDate}<Badge>{this.props.state.labels["graingerBookingJourney.payAdvanced.currency"] + payment.amount}</Badge></ListGroupItem>);
        } else paymentsToShow.push(<ListGroupItem key={i} className={"due-payment-" + i}>{payment.name}<Badge>{this.props.state.labels["graingerBookingJourney.payAdvanced.currency"] + payment.amount}</Badge></ListGroupItem>);
      }
      paymentsToShow.push(<ListGroupItem key={"total-due"} className={"total-due"}><strong>{this.props.state.labels["graingerBookingJourney.payAdvanced.totalDUE"]}</strong>
        <Badge>{this.props.state.labels["graingerBookingJourney.payAdvanced.currency"] + this.props.state.payAdvanced.getPaymentsResponse.totalDue}</Badge>
      </ListGroupItem>);

      let paymentIframe = null;
      if (this.props.state.payAdvanced.conditionsConfirm && this.props.state.payAdvanced.showIframe) paymentIframe = <embed src={this.props.state.payAdvanced.paymentUrl} style={{width: 100 + "%", height: 100 + "%"}}/>;

      return <div className={""}>
        <Row>
          <Col sm={12}>
            <h4 className={"payAdvanced-title"} dangerouslySetInnerHTML={{__html: utils.sanitize(this.props.state.labels["graingerBookingJourney.payAdvanced.title"])}}></h4>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <span className={"payAdvanced-info"} dangerouslySetInnerHTML={{__html: utils.sanitize(this.props.state.labels["graingerBookingJourney.payAdvanced.info"])}}></span>
          </Col>
        </Row>
        <Row className={"payments"}>
          <Col sm={12}>
            <Panel header={paymentsTitle}>
              <legend className={"panel-legend"} dangerouslySetInnerHTML={{__html: utils.sanitize(this.props.state.labels["graingerBookingJourney.payAdvanced.legend"])}}></legend>
              <ListGroup componentClass="ul" className={"total-funds"}>
                {paymentsToShow}
              </ListGroup>
              <div className={"text-center total-funds-footer"} dangerouslySetInnerHTML={{__html: utils.sanitize(this.props.state.labels["graingerBookingJourney.payAdvanced.totalFundsFooter"])}}></div>
            </Panel>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Panel header={advancePaymentTitle}>
              <h3 className={"advance-payment-panel-info"} dangerouslySetInnerHTML={{__html: utils.sanitize(this.props.state.labels["graingerBookingJourney.payAdvanced.advancePaymentInfo"])}}></h3>
              <Checkbox className={"terms-checkbox"} name={"payAdvanced-terms-conditions"} onClick={this.props.updateStep4ConditionsConfirm} checked={this.props.state.payAdvanced.conditionsConfirm} inline>
                {this.props.state.labels["graingerBookingJourney.payAdvanced.conditionsInfo"]}
                <span dangerouslySetInnerHTML={{__html: utils.sanitize(this.props.state.labels["graingerBookingJourney.payAdvanced.conditionsLink"])}}></span>
              </Checkbox>
              <Button className={"card-btn"} bsStyle={"primary"} onClick={this.props.showIframePayAdvanced} disabled={!this.props.state.payAdvanced.conditionsConfirm}>{this.props.state.labels["graingerBookingJourney.payAdvanced.cardButton"]}</Button>
              <input id="change-view-ok" style={{display: "none"}} onClick={()=>{paymentOk();}}/>
              <input id="change-view-error" style={{display: "none"}} onClick={()=>{paymentKo();}}/>
              <div className={"sagepay-payments"} dangerouslySetInnerHTML={{__html: utils.sanitize(this.props.state.labels["graingerBookingJourney.payAdvanced.sagepayLabel"])}}></div>
              {paymentIframe}
            </Panel>
          </Col>
        </Row>
        <Row>
          <Col sm={12} className={"text-center tenancy-agreement"} dangerouslySetInnerHTML={{__html: utils.sanitize(this.props.state.labels["graingerBookingJourney.payAdvanced.tenancyAgreement"])}}></Col>
        </Row>

      </div>;
    }
    else return <div>Loading step...</div>;
  }
}


