import React from 'react';


export class PexValidationMessage extends React.Component {
  constructor (props) {
    super(props);
    this.state = {};
  }
  render () {
    if (this.props.submitted && this.props.errors[this.props.fieldKey]) {
      return <span >{this.props.errors[this.props.fieldKey]}</span>;
    } else {
      return null;
    }
  }
}
