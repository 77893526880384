"use strict";

const getLabelsTranslated = function getLabelsTranslated (labels, name) {
  return labels["genericWebformWidget." + name];
};

module.exports = {
  getLabelsTranslated: function (labels, name) {
    return getLabelsTranslated(labels, name);
  },
  setLabels: function (labels, defaultLabels) {
    for (let key of Object.keys(labels)) defaultLabels[key] = labels[key];
    return defaultLabels;
  },
};
