import React from 'react';
import utils from "../../../lib/utils";
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import Popover from 'react-bootstrap/lib/Popover';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import Button from 'react-bootstrap/lib/Button';

export default class ReferencingPre extends React.Component {

  componentDidMount () {
    this.props.loadReferencingPre();
  }

  render () {

    if (this.props.state && this.props.state.stepLoaded && !this.props.state.error) {

      return <div className={""}>
        <Row>
          <Col sm={12}>
            <h3 className={"referencingPre-title"} dangerouslySetInnerHTML={{__html: utils.sanitize(this.props.state.labels["graingerBookingJourney.referencingPre.title"])}}></h3>
            <div className={"referencingPre-subtitle"} dangerouslySetInnerHTML={{__html: utils.sanitize(this.props.state.labels["graingerBookingJourney.referencingPre.subtitle"])}}></div>
            <div className={"referencingPre-requirements well well-sm"} dangerouslySetInnerHTML={{__html: utils.sanitize(this.props.state.labels["graingerBookingJourney.referencingPre.requirements"])}}></div>
          </Col>
        </Row>
      </div>;
    }
    else return <div>Loading step...</div>;
  }
}


