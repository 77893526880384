import React from 'react';

export class PexThanks extends React.Component {
  render () {
    return (
      <div className="panel panel-default">
        <div className="panel-heading">
          <div className="panel-title">Message sent</div>
        </div>
        <div className="panel-body">
          <span dangerouslySetInnerHTML={{__html: this.props.message()}}></span>
        </div>
      </div>);
  }

}
