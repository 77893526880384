import React from 'react';
import { PexCommonField } from "./pex-common-field.jsx";
import Checkbox from 'react-bootstrap/lib/Checkbox';

export class PexCheckBox extends React.Component {

  render () {
    return <PexCommonField {...this.props}><CheckBoxField {...this.props}/></PexCommonField>;
  }

}

function CheckBoxField (props) {

  function hanldeOnChange (event, key) {
    props.onChange(event, key);
  }

  return <div className={props.attribute.key + "-container"}>
    <Checkbox className={props.attribute.key + "-checkbox"}
      value={props.attribute.fieldProps.value || ""}
      checked={props.attribute.fieldProps.value === "true"}
      onClick={ (ev) =>{ hanldeOnChange(ev, props.attribute.key);}}>{props.attribute.text ? props.attribute.text : null}</Checkbox>
  </div>;
}
