"use strict";

let defaultLabels = JSON.parse(JSON.stringify(require("../../../lang/search-applet/en-GB.json"))).labels["en-GB"];

function initForm (formNames, requiredFields, labels, component) {
  //NOTE: requiredFields
  //- index 0: fieldName
  //- index 1: labelError TODO: make it optional too
  //- index 2: defaultValue(optional)
  let formValues = {};

  function setUpExtraRequiredFields () {
    for (let row of requiredFields.split(";").filter(function (el) {return el.length !== 0;})) {
      let requiredField = row.split(":");
      formNames[requiredField[0]] = {"key": requiredField[0], "labelError": requiredField[1], "extra": true, "mandatory": true};
    }
  }

  function setDefaultValue () {
    for (let reqfield of requiredFields.split(";").filter(function (el) {return el.length !== 0;})) {
      if (reqfield.split(":").length === 3) {
        formValues[reqfield.split(":")[0]] = formValues[reqfield.split(":")[0]] = reqfield.split(":")[2];
      }
    }
  }

  if (requiredFields) setUpExtraRequiredFields ();
  for (let formName of Object.keys(formNames)) formValues[formName] = "";
  if (requiredFields) setDefaultValue ();

  //TODO: change loginError by error and chnge as well in login component, same for register
  let form = {
    "values": formValues,
    "formNames": formNames,
    "submitted": false,
    "fieldsWithErrors": checkForm(formValues, formNames, labels, component).fieldsWithErrors,
    "canSubmit": false,
    "APIerror": undefined,
  };

  return form;
}

function checkForm (values, formNames, labels, component) {
  let error_list = [];
  let fieldsWithErrors = {};
  let has_errors = false;

  // The groupKey only is to save the error in the same groupKey to show one message error for a group of fields. NEVER use the groupKey in values array.
  function getKeyForError (field) {
    if (field.groupKey) return field.groupKey;
    return field.key;
  }

  function checkEmailFormat (field) {
    var re = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    if (!fieldsWithErrors[getKeyForError(field)] && values[field.key] && !re.test(values[field.key])) {
      has_errors = true;
      if (field.extra) fieldsWithErrors[getKeyForError(field)] = field.labelError + " " + defaultLabels[component + ".validation.emailNotValid"];
      else if (labels && labels[component + ".validation.emailNotValid"]) fieldsWithErrors[getKeyForError(field)] = labels[field.labelError] + " " + labels[component + ".validation.emailNotValid"];
      else fieldsWithErrors[getKeyForError(field)] = defaultLabels[field.labelError] + " " + defaultLabels[component + ".validation.emailNotValid"];
    }
  }
  function checkSameValue (field) {
    if (!fieldsWithErrors[getKeyForError(field)] && values[field.key] !== values[field.sameValue] ) {
      has_errors = true;
      if (field.extra) fieldsWithErrors[getKeyForError(field)] = field.labelError + " " + defaultLabels[component + ".validation.isNotTheSameValue"] + " " + formNames[field.sameValue].labelError.toLowerCase();
      else if (labels && labels[component + ".validation.isNotTheSameValue"]) fieldsWithErrors[getKeyForError(field)] = labels[field.labelError] + " " + labels[component + ".validation.isNotTheSameValue"] + " " + labels[formNames[field.sameValue].labelError].toLowerCase();
      else fieldsWithErrors[getKeyForError(field)] = defaultLabels[field.labelError] + " " + defaultLabels[component + ".validation.isNotTheSameValue"] + " " + defaultLabels[formNames[field.sameValue].labelError].toLowerCase();
    }
  }
  function checkNumberValue (field) {
    if (!fieldsWithErrors[getKeyForError(field)] && values[field.key] && isNaN(values[field.key]) ) {
      has_errors = true;
      if (field.extra) fieldsWithErrors[getKeyForError(field)] = field.labelError + " " + defaultLabels[component + ".validation.numberNotValid"];
      else if (labels && labels[component + ".validation.numberNotValid"]) fieldsWithErrors[getKeyForError(field)] = labels[field.labelError] + " " + labels[component + ".validation.numberNotValid"];
      else fieldsWithErrors[getKeyForError(field)] = defaultLabels[field.labelError] + " " + defaultLabels[component + ".validation.numberNotValid"];
    }
  }
  function checkMandatory (field) {
    if (!fieldsWithErrors[getKeyForError(field)] && (!values[field.key] || values[field.key] === "")) { //PRIORITY ONE. REQUIRED
      has_errors = true;
      if (field.extra) fieldsWithErrors[getKeyForError(field)] = field.labelError + " " + defaultLabels[component + ".validation.isRequired"];
      else if (labels && labels[component + ".validation.isRequired"]) fieldsWithErrors[getKeyForError(field)] = labels[field.labelError] + " " + labels[component + ".validation.isRequired"];
      else fieldsWithErrors[getKeyForError(field)] = defaultLabels[field.labelError] + " " + defaultLabels[component + ".validation.isRequired"];
    }
  }

  for (let field of Object.keys(formNames)) {
    if (formNames[field].mandatory) checkMandatory(formNames[field]);
    if (formNames[field].email) checkEmailFormat(formNames[field]);
    if (formNames[field].sameValue) checkSameValue(formNames[field]);
    if (formNames[field].number) checkNumberValue(formNames[field]);
  }
  return {canSubmit: !has_errors, fieldsWithErrors: fieldsWithErrors};
}

function checkUserEntity (userData) {
  //get the register form
  let registerForm = initRegisterFormForUpdate();

  for (let key of Object.keys(registerForm.values)) {
    if (userData[key]) registerForm.values = userData[key];
  }
  //check if every rqeuired value is there
  return registerForm;
}

function setNoSpamBoolean (attribute, value) {
  if (value === "false") value = "true";
  else value = "false";
  return value;
}

module.exports = {
  initLoginForm: function (labels) {
    let loginForm = {
      "username": { "key": "username", "labelError": "searchApplet.pexLogin.usernameLabel", "email": true, "mandatory": true},
      "password": { "key": "password", "labelError": "searchApplet.pexLogin.passwordLabel", "mandatory": true},
    };
    return initForm(loginForm, false, labels, "searchApplet");
  },
  initRegisterForm: function (labels) {
    let registerForm = {
      "username": { "key": "username", "labelError": "searchApplet.pexRegister.usernameLabel", "email": true, "mandatory": true},
      "usernameconfirm": { "key": "usernameconfirm", "labelError": "searchApplet.pexRegister.usernameConfirmation", "sameValue": "username", "mandatory": true},
      "password": { "key": "password", "labelError": "searchApplet.pexRegister.passwordLabel", "mandatory": true},
      "passwordconfirm": { "key": "passwordconfirm", "labelError": "searchApplet.pexRegister.passwordConfirmation", "sameValue": "password", "mandatory": true},
      "firstName": { "key": "firstName", "labelError": "searchApplet.pexRegister.firstNameLabel", "mandatory": true},
      "lastName": { "key": "lastName", "labelError": "searchApplet.pexRegister.lastNameLabel", "mandatory": true},
      "gender": { "key": "gender", "labelError": "searchApplet.pexRegister.genderLabel", "mandatory": true},
      "mobileCountry": { "key": "mobileCountry", "labelError": "searchApplet.pexRegister.countryPrefixLabel", "mandatory": true, "groupKey": "countryPhone" },
      "mobileNumber": { "key": "mobileNumber", "labelError": "searchApplet.pexRegister.phoneLabel", "number": true, "mandatory": true, "groupKey": "countryPhone"},
      "noSpam": { "key": "noSpam", "labelError": "searchApplet.pexRegister.noSpamLabel", "mandatory": false },
    };
    return initForm(registerForm, false, labels, "searchApplet");
  },
  initArrangeForm: function (requiredFields, labels) {
    let arrangeForm = {
      "building": { "key": "building", "labelError": "arrangeForm.propertyLabelError", "mandatory": true},
      "roomType": { "key": "roomType", "labelError": "arrangeForm.roomTypeLabelError", "mandatory": true},
      "date": { "key": "date", "labelError": "arrangeForm.dateLabel", "mandatory": true},
      "time": { "key": "time", "labelError": "arrangeForm.hourSlotLabel", "mandatory": true},
      "firstName": { "key": "firstName", "labelError": "arrangeForm.firstNameLabel", "mandatory": true},
      "lastName": { "key": "lastName", "labelError": "arrangeForm.lastNameLabel", "mandatory": true},
      "mobileCountry": { "key": "mobileCountry", "labelError": "arrangeForm.countryPrefixLabel", "mandatory": true, "groupKey": "countryPhone" },
      "mobileNumber": { "key": "mobileNumber", "labelError": "arrangeForm.phoneLabel", "number": true, "mandatory": true, "groupKey": "countryPhone"},
      "emailAddress": { "key": "emailAddress", "labelError": "arrangeForm.emailLabel", "email": true, "mandatory": true},
      "bookingType": { "key": "bookingType", "labelError": "arrangeForm.bookingTypeLabel", "mandatory": false},
      "noSpam": { "key": "noSpam", "labelError": "searchApplet.pexRegister.noSpamLabel", "mandatory": false },
    };
    return initForm(arrangeForm, requiredFields, labels, "arrangeForm");
  },
  initArrangeRoomViewingForm: function (requiredFields, labels) {
    let arrangeRoomViewingForm = {
      "date": { "key": "date", "labelError": "arrangeRoomViewingForm.dateLabel", "mandatory": true},
      "time": { "key": "time", "labelError": "arrangeRoomViewingForm.hourSlotLabel", "mandatory": true},
      "firstName": { "key": "firstName", "labelError": "arrangeRoomViewingForm.firstNameLabel", "mandatory": true},
      "lastName": { "key": "lastName", "labelError": "arrangeRoomViewingForm.lastNameLabel", "mandatory": true},
      "mobileCountry": { "key": "mobileCountry", "labelError": "arrangeRoomViewingForm.countryPrefixLabel", "mandatory": true, "groupKey": "countryPhone" },
      "mobileNumber": { "key": "mobileNumber", "labelError": "arrangeRoomViewingForm.phoneLabel", "number": true, "mandatory": true, "groupKey": "countryPhone"},
      "emailAddress": { "key": "emailAddress", "labelError": "arrangeRoomViewingForm.emailLabel", "email": true, "mandatory": true},
      "bookingType": { "key": "bookingType", "labelError": "arrangeRoomViewingForm.bookingTypeLabel", "mandatory": false},
    };
    return initForm(arrangeRoomViewingForm, requiredFields, labels, "arrangeRoomViewingForm");
  },
  initContactForm: function (requiredFields, labels) {
    let contactForm = {
      "location": { "key": "location", "labelError": "enquiryForm.cityLabel", "mandatory": true},
      "building": { "key": "building", "labelError": "enquiryForm.propertyLabelError", "mandatory": true},
      "firstName": { "key": "firstName", "labelError": "enquiryForm.firstNameLabel", "mandatory": true},
      "lastName": { "key": "lastName", "labelError": "enquiryForm.lastNameLabel", "mandatory": true},
      "mobileCountry": { "key": "mobileCountry", "labelError": "enquiryForm.countryPrefixLabel", "mandatory": true, "groupKey": "countryPhone" },
      "mobileNumber": { "key": "mobileNumber", "labelError": "enquiryForm.phoneLabel", "number": true, "mandatory": true, "groupKey": "countryPhone"},
      "emailAddress": { "key": "emailAddress", "labelError": "enquiryForm.emailLabel", "email": true, "mandatory": true},
      "bookingType": { "key": "bookingType", "labelError": "enquiryForm.bookingTypeLabel", "mandatory": false},
      "noSpam": { "key": "noSpam", "labelError": "searchApplet.pexRegister.noSpamLabel", "mandatory": false },
    };
    return initForm(contactForm, requiredFields, labels, "enquiryForm");
  },
  initEnquiryRoomForm: function (requiredFields, labels) {
    let enquiryRoomForm = {
      "firstName": { "key": "firstName", "labelError": "enquiryRoomForm.firstNameLabel", "mandatory": true},
      "lastName": { "key": "lastName", "labelError": "enquiryRoomForm.lastNameLabel", "mandatory": true},
      "mobileCountry": { "key": "mobileCountry", "labelError": "enquiryRoomForm.countryPrefixLabel", "mandatory": true, "groupKey": "countryPhone" },
      "mobileNumber": { "key": "mobileNumber", "labelError": "enquiryRoomForm.phoneLabel", "number": true, "mandatory": true, "groupKey": "countryPhone"},
      "emailAddress": { "key": "emailAddress", "labelError": "enquiryRoomForm.emailLabel", "email": true, "mandatory": true},
      "bookingType": { "key": "bookingType", "labelError": "enquiryRoomForm.bookingTypeLabel", "mandatory": false},
    };
    return initForm(enquiryRoomForm, requiredFields, labels, "enquiryRoomForm");
  },
  initInterestForm: function (labels) {
    let interestForm = {
      "area": { "key": "area", "labelError": "interestForm.areaLabel", "mandatory": true},
      "firstName": { "key": "firstName", "labelError": "interestForm.firstNameLabel", "mandatory": true},
      "lastName": { "key": "lastName", "labelError": "interestForm.lastNameLabel", "mandatory": true},
      "emailAddress": { "key": "emailAddress", "labelError": "interestForm.emailLabel", "email": true, "mandatory": true},
      "mobileCountry": { "key": "mobileCountry", "labelError": "interestForm.countryPrefixLabel", "mandatory": true, "groupKey": "countryPhone" },
      "mobileNumber": { "key": "mobileNumber", "labelError": "interestForm.phoneLabel", "number": true, "mandatory": true, "groupKey": "countryPhone"},
    };
    return initForm(interestForm, false, labels, "interestForm");
  },
  initResetPasswordForm: function (labels) {
    let resetPasswordForm = {
      "email": { "key": "email", "labelError": "searchApplet.pexResetPassword.emailLabel", "email": true, "mandatory": true},
    };
    return initForm(resetPasswordForm, false, labels, "searchApplet");
  },
  initNewPasswordForm: function (labels) {
    let newPasswordForm = {
      "password": { "key": "password", "labelError": "searchApplet.pexNewPassword.passwordLabel", "mandatory": true},
      "confirmPassword": { "key": "confirmPassword", "labelError": "searchApplet.pexNewPassword.confirmPasswordLabel", "sameValue": "password", "mandatory": true},
    };
    return initForm(newPasswordForm, false, labels, "searchApplet");
  },
  checkForm: function (values, formNames, labels, component) {
    return checkForm(values, formNames, labels, component);
  },
  initRegisterFormForUpdate: function (labels) {
    let registerForm = {
      "username": { "key": "username", "labelError": "searchApplet.pexRegister.usernameLabel", "email": true, "mandatory": true},
      "firstName": { "key": "firstName", "labelError": "searchApplet.pexRegister.firstNameLabel", "mandatory": true},
      "lastName": { "key": "lastName", "labelError": "searchApplet.pexRegister.lastNameLabel", "mandatory": true},
      "gender": { "key": "gender", "labelError": "searchApplet.pexRegister.genderLabel", "mandatory": true},
      "mobileCountry": { "key": "mobileCountry", "labelError": "searchApplet.pexRegister.countryPrefixLabel", "mandatory": true, "groupKey": "countryPhone" },
      "mobileNumber": { "key": "mobileNumber", "labelError": "searchApplet.pexRegister.phoneLabel", "number": true, "mandatory": true, "groupKey": "countryPhone"},
      "noSpam": { "key": "noSpam", "labelError": "searchApplet.pexRegister.noSpamLabel", "mandatory": false },
    };
    return initForm(registerForm, false, labels, "searchApplet");
  },
  updateRegisterForm: function (userData) {
    //get the register form
    let registerForm = this.initRegisterFormForUpdate();
    for (let key of Object.keys(registerForm.values)) {
      if (userData[key] || key === "noSpam") {
        if (key === "gender") registerForm.values[key] = userData[key].lookupValue;
        else if (key === "mobileNumber" && userData["mobileCountry"]) registerForm.values[key] = userData[key].substring(userData[key], userData["mobileCountry"].length + 1);
        else if (key === "noSpam") registerForm.values[key] = (userData[key] + "" === "false") + "";
        else registerForm.values[key] = userData[key];
      }
    }
    return registerForm;
  },
  setNoSpamBoolean: function (attribute, value) {
    return setNoSpamBoolean(attribute, value);
  },
  getDropdownsContactForm (state) {
    let dropdowns = {};

    dropdowns.locations = state.allBuildingsAndCities.filter(function (location) { return location.buildings; }).map(function (location) { return location; });
    dropdowns.buildings = [];

    for (let location of dropdowns.locations) { for (let building of location.buildings) { dropdowns.buildings.push(building); }}

    if (state.values.building) {
      for (let location of dropdowns.locations) {
        for (let building of location.buildings) {
          if (building.lookupValue === state.values.building) {
            state.values.location = location.lookupValue;
            break;
          }
        }
      }
    }

    if (state.values.location) {
      for (let location of dropdowns.locations) {
        if (location.lookupValue !== state.values.location) {
          for (let building of location.buildings) {
            if (!state.hideCityDropdown) {
              let index = dropdowns.buildings.indexOf(building);
              dropdowns.buildings.splice(index, 1);
            }
          }
        }
      }
    }
    return dropdowns;
  },
};
