import React from 'react';
import ajax from '../../lib/ajax.js';

export default class ResultsInCity extends React.Component {
  constructor (props) {
    super(props);
    this.state = {};
  }

  render () {
    if (!this.props.location) {
      return <div>no location specified</div>;
    }

    if (!this.props.api) {
      return <div>no api specified</div>;
    }

    if (this.state.data) {
      return <Results location={this.props.location} data={this.state.data} viewing={this.props.viewing} book={this.props.book} enquire={this.props.enquire} />;
    } else {
      return <div>just a sec</div>;
    }
  }

  componentDidMount () {
    let self = this;
    if (this.props.location && this.props.api) {
      ajax(this.props.api, '/rs/webService/searchAvailability', {
        "criteria": {"location": [this.props.location]},
      }, function (data) {
        self.setState({data: data});
      }, function () {
      });
    }
  }
}

class Results extends React.Component {
  render () {
    //THIS ASSUMES THAT MULITPLE BUILDING RESULTS FOR ONE LOCATION ARE THE SAME FORMAT AS MULTI LOCATION RESULTS

    let results = [];
    for (let combination of this.props.data.locations) {
      let result = {};
      result.building = combination.building.displayValue.toUpperCase();
      result.lowestPrice = 10000;
      let frequency = "";
      let imagePath = "";
      for (let roomType of combination.roomTypes) {
        for (let room of roomType.rooms) {
          if (room.price < result.lowestPrice && room.bookable === true) {
            result.lowestPrice = room.price;
            //Set frequency with value of room with lowest price, to maintain the correlation
            frequency = room.frequency;
            if (roomType !== undefined && roomType.thumbList !== undefined && roomType.thumbList[0] !== undefined) {
              imagePath = roomType.thumbList[0];
            } else {
              imagePath = "";
            }
          }
        }
      }

      if (frequency !== "" && frequency !== undefined) {
        //we can remove the 'hly' if as rule every display value has it, but we don't know so
        //for each value known we add an if sentence.
        if (frequency.lookupValue === "WEEKLY") {
          result.rentFrequency = "per week*";
        } else if (frequency.lookupValue === "MONTHLY") {
          result.rentFrequency = "per month*";
        } else {
          // If we unknow the lookup value we show the display value directly
          result.rentFrequency = frequency.displayValue;
        }
      } else {
        //if room has not frequency we show nothing
        result.rentFrequency = "";
      }

      result.locationString = combination.building.displayValue + ", " + combination.location.displayValue;
      result.image = imagePath;
      results.push(result);
    }
    let self = this;
    return <div className="panel panel-default">
      <div className="panel-heading text-center"><h3><strong>Properties</strong></h3></div>
      {results.map(function (data) {
        return <ResultBox building={data.building} viewing={self.props.viewing} book={self.props.book} enquire={self.props.enquire} location={self.props.location} lowestPrice={data.lowestPrice} locationString={data.locationString} image={data.image} key={data.building} rentFrequency={data.rentFrequency} />;
      })}
    </div>;

  }
}

class ResultBox extends React.Component {
  render () {
    return <div>
      <div className="panel-body">
        <div className="spb-img">
          <img src={this.props.image} alt="Properties" className="img-responsive"/>
        </div>

        <div className="spb-room-title text-center col-xs-12"><h3>{this.props.building}</h3></div>

        <div className="spb-location col-xs-12 well">
          <div className="spb-roomprice col-md-6 col-xs-12">
            <div className="text-center">
              <span>Room prices starting from </span>
              <span className="price-frequency">
                <span className="spb-price-large"><i className="fa fa-gbp"></i> <b>{this.props.lowestPrice}</b></span>
                <span className="spb-rent-frequency"> <i>{this.props.rentFrequency}</i></span>
              </span>
            </div>
          </div>

          <div className="location text-center col-md-6 col-xs-12">
            {this.props.locationString}
          </div>
        </div>
      </div>

      <div className="panel-footer text-center">
        <div className="btn-group">
          <a href={this.props.book.replace("<LOCATION>", this.props.location)} className="btn btn-success btn-sm  search-results-book-now-link"><i className="fa fa-bed hidden-xs"></i> <span className="">Book</span></a>

          <a href={this.props.viewing.replace("<LOCATION>", this.props.location)} className="btn btn-default btn-sm search-results-arrange-viewing-link"><i className="fa fa-eye hidden-xs"></i> <span className="">Viewing</span></a>

          <a href={this.props.enquire.replace("<LOCATION>", this.props.location)} className="btn btn-default btn-sm search-results-enquire-link"><i className="fa fa-comments-o hidden-xs"></i> <span className="">Enquiry</span></a>
        </div>
      </div>
    </div>;
  }
}
