//TODO: create widget with last functionality of Alberto
import React from 'react';
import ajax from './../lib/ajax.js';
import ajaxSingleSign from './ajaxSingleSign.js';
import Panel from 'react-bootstrap/lib/Panel';
import DropdownButton from 'react-bootstrap/lib/DropdownButton';
import Dropdown from 'react-bootstrap/lib/Dropdown';
import FormControl from 'react-bootstrap/lib/FormControl';
import Col from 'react-bootstrap/lib/Col';
import MenuItem from 'react-bootstrap/lib/MenuItem';

export default class EssentialWidget extends React.Component {

  constructor (props) {
    super(props);
    this.state = { "location": "-", "locationDisplayValue": "-", "building": "-", "buildingDisplayValue": "-", "lastUpdated": "-", "locationOpen": false, "buildingOpen": false};
  }

  render () {
    let _self = this;
    if (this.state.error) return <div>{this.state.error}</div>;
    else if (this.state.data) {
      let configForm = {
        "bookingSearchSite": this.props.bookingSearchSite,
        "bookingSite": this.props.bookingSite,
        "updateLocation": ::this.updateLocation,
        "location": this.state.location,
        "locationDisplayValue": this.state.locationDisplayValue,
        "updateBuilding": ::this.updateBuilding,
        "building": this.state.building,
        "buildingDisplayValue": this.state.buildingDisplayValue,
        "lastUpdated": this.state.lastUpdated,
        "locationOpen": this.state.locationOpen,
        "buildingOpen": this.state.buildingOpen,
        "openCloseDropDowns": ::this.openCloseDropDowns,
      };
      return <FormPex {...this.state.data} {...configForm} />;
    }
    else return <div>just a sec</div>;
  }

  componentDidMount () {
    var self = this;

    if (!this.props.api) {
      this.setState({"error": "configuration error - No api attribute"});
      return;
    }
    if (!this.props.singleSign) {
      this.setState({"error": "configuration error - No singleSign atribute"});
      return;
    }
    if (!this.props.bookingSite) {
      this.setState({"error": "configuration error - No bookingSite atribute"});
      return;
    }
    if (!this.props.bookingSearchSite) {
      this.setState({"error": "configuration error - No bookingSearchSite atribute"});
      return;
    }

    //1.Get key
    ajaxSingleSign.getSSOkey(this.props.singleSign, '/getSSOKey', function (ssoKey) {
      let newState = { data: { "ssoKey": ssoKey} };
      //2.Validate object
      ajaxSingleSign.singleSign(self.props.singleSign, '/validateobject', { "uuid": ssoKey}, function (validateObjectResponse) {
        newState.data.loggedIn = validateObjectResponse.loggedIn;
        //3.if logged call init
        if (validateObjectResponse.loggedIn) {
          ajax(self.props.api, '/rs/webService/init', {}, function (initResponse) {
            newState.data.collections = initResponse.select;
            self.setState(newState);
          }, function () {
            self.setState({"error": "execution error - Error connecting to api"});
          });
        } else {
          self.setState(newState);
        }
      },
      function () {
        self.setState({"error": "execution error - Error connecting to SingleSign"});
      });
    }, function () {
      self.setState({"error": "execution error - Error connecting to SingleSign"});
    });
  }

  updateLocation (value) {
    let newState = { "lastUpdated": "location"};
    if (value === "-") {
      newState.building = "-";
      newState.buildingDisplayValue = "-";
      newState.location = "-";
      newState.locationDisplayValue = "-";
    } else {
      newState.location = value.split("___")[0];
      newState.locationDisplayValue = value.split("___")[1];
      newState.building = "-";
      newState.buildingDisplayValue = "-";
    }
    newState.locationOpen = false;
    newState.buildingOpen = false;

    this.setState(newState);
  }

  updateBuilding (value) {
    let newState = { "lastUpdated": "building"};
    if (this.state.location === "-" && value !== "-") {
      let buildingLookupValue = value.split("___")[0];
      for (let building of this.state.data.collections.building) {
        if (building.lookupValue === buildingLookupValue) {
          newState.location = building.location.lookupValue;
          newState.locationDisplayValue = building.location.displayValue;
          newState.building = value.split("___")[0];
          newState.buildingDisplayValue = value.split("___")[1];
          break;
        }
      }
    } else {
      if (value === "-") {
        newState.building = "-";
        newState.buildingDisplayValue = "-";
      } else {
        newState.building = value.split("___")[0];
        newState.buildingDisplayValue = value.split("___")[1];
      }
    }
    newState.locationOpen = false;
    newState.buildingOpen = false;

    this.setState(newState);
  }

  openCloseDropDowns (isOpen, label) {
    let newState = {};
    if (label === "Location") {
      newState.locationOpen = isOpen;
      newState.buildingOpen = false;
    } else {
      newState.locationOpen = false;
      newState.buildingOpen = isOpen;
    }
    this.setState(newState);
  }

}

//correspond withs the div for SSOController
function FormPex (props) {
  let form = [];

  if (!props.loggedIn) {
    form.push(<div key="1" id="regbutton"><a href={props.bookingSite} className="homestart">Find an Apartment</a></div>);
  } else {
    let optionsLocation = [];
    let optionsBuilding = [];

    //init method doesn't return every location, but building collection inside building object yes (Perhaps it's an API bug)
    //so I loop buildings to get building options and I create an array of locations with lookupValue as key to avoid duplicated
    for (let building of props.collections.building) {
      optionsLocation[building.location.lookupValue] = JSON.parse(JSON.stringify(building.location));
      optionsBuilding[building.lookupValue] = JSON.parse(JSON.stringify(building));
    }

    optionsLocation = parseAndSort(optionsLocation, props.building);
    optionsBuilding = parseAndSort(optionsBuilding, props.location);

    //depending the last option selected I update the other dropdown
    if ((props.lastUpdated === "location" && props.location !== "-") || (props.lastUpdated === "building" )) {
      optionsBuilding = optionsBuilding.filter(function (building) {
        return building.location.lookupValue === props.location;
      });
    }

    form.push(<Dropdown id="regbutton" key="1">
      <ContainerToggle bsRole="toggle">Find an apartment</ContainerToggle>
      <ContainerMenu bsRole="menu" optionsLocation={optionsLocation}
        locationDisplayValue={props.locationDisplayValue}
        updateLocation ={props.updateLocation}
        optionsBuilding={optionsBuilding}
        buildingDisplayValue={props.buildingDisplayValue}
        updateBuilding={props.updateBuilding}
        searchLink ={props.bookingSearchSite + "/" + (props.location === "-" ? "undefined" : props.location) + "/" + (props.building === "-" ? "undefined" : props.building)}
        locationOpen= {props.locationOpen}
        buildingOpen= {props.buildingOpen}
        openCloseDropDowns={props.openCloseDropDowns}>
      </ContainerMenu>
    </Dropdown>);
  }
  return <span>{form}</span>;

  function parseAndSort (collection, filter) {
    collection = Object.values(collection).map(function (element) { return element; });
    collection.sort(function (a, b) { return a.displayValue > b.displayValue;});
    return collection;
  }

}

class ContainerToggle extends React.Component {
  constructor (props, context) {
    super(props, context);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick (e) {
    e.preventDefault();
    this.props.onClick(e);
  }

  render () {
    return (
      <div id="regbutton" onClick={this.handleClick}>
        <a href="" className="widget-trigger homestart" >{this.props.children}</a>
      </div>
    );
  }
}

class ContainerMenu extends React.Component {

  goToBooking () {
    window.location.href = this.props.searchLink;
  }

  render () {
    return <div id="searchWidget" className="dropdown-menu" >
      <DropDownPex id="dropdown_location" value={this.props.locationDisplayValue} update={this.props.updateLocation} options={ this.props.optionsLocation } label="Location" button_id="location_dropdown"
        isOpen= {this.props.locationOpen} openCloseDropDowns={this.props.openCloseDropDowns}/>
      <div className="thirty-spacer-widget"></div>
      <DropDownPex id="dropdown_building" value={this.props.buildingDisplayValue} update={this.props.updateBuilding} options={ this.props.optionsBuilding } label="Development" button_id="location_dropdown"
        isOpen= {this.props.buildingOpen} openCloseDropDowns={this.props.openCloseDropDowns} />
      <div className="thirty-spacer-widget"></div>
      <div className="widget-search"><input type="button" onClick={::this.goToBooking} value="Search" /></div>
    </div>;
  }
}


class DropDownPex extends React.Component {

  constructor (props, context) {
    super(props, context);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.onToggle = this.onToggle.bind(this);
  }

  onToggle (isOpen) {
    this.props.openCloseDropDowns(isOpen, this.props.label);
  }

  handleUpdate (ev) {
    this.props.update(ev.target.name);
  }

  render () {
    let classes = "pex-drop-container";

    return <Dropdown id={this.props.id} className={classes} onToggle={this.onToggle} open={this.props.isOpen} >
      <DropdownPexToggle bsRole="toggle" value={this.props.value === "-" ? "All" : this.props.value} button_id={this.props.button_id} label={this.props.label}/>
      <DropdownPexMenu bsRole="menu" options={this.props.options} update={this.handleUpdate} />
    </Dropdown>;
  }
}

class DropdownPexToggle extends React.Component {
  constructor (props, context) {
    super(props, context);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick (e) {
    this.props.onClick(e);
  }

  render () {
    return (
      <span>
        <div className="pex-drop-title-holder">{this.props.label}</div>
        <button id={this.props.button_id} type="button" className="dropdown-toggle" onClick={this.handleClick} >{this.props.value} <span className="caret"></span></button>
        {this.props.children}
      </span>
    );
  }
}

class DropdownPexMenu extends React.Component {

  render () {
    let self = this;
    let options = [];
    options.push(<MenuItem key="-" onClick={self.props.update} name="-">All</MenuItem>);
    this.props.options.map(function (element) {
      options.push(<MenuItem key={element.lookupValue} onClick={self.props.update} name={element.lookupValue + "___" + element.displayValue} >{element.displayValue}</MenuItem>);
      return;
    });
    return <ul className="dropdown-menu">{options}</ul>;
  }
}
