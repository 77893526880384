import React from 'react';
import ButtonToolbar from 'react-bootstrap/lib/ButtonToolbar';
import ToggleButtonGroup from 'react-bootstrap/lib/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/lib/ToggleButton';
import { PexCommonField } from "./pex-common-field.jsx";
import PropTypes from 'prop-types';

export class BedRoomSelection extends React.Component {
  render () {

    return <PexCommonField {...this.props} ><BedRoomField {...this.props}/></PexCommonField>;
  }
}

BedRoomSelection.propTypes = {
  label: PropTypes.string.isRequired,
  fieldKey: PropTypes.string.isRequired,
  errors: PropTypes.object.isRequired,
  updateField: PropTypes.func.isRequired,
};

class BedRoomField extends React.Component {
  handleChange (value) {
    /**
     * Logic: to select a bedroom range user cannot jump values. For example for bedrooms 1, 2 and 3 user cannot select 1 and 3 or 3 and 1, he has to click 1, 2 and 3.
     * In the case the user clicks 1 and after 3 only 3 will be active.
     */

    //the toogle buttons returns all values, so we don't know what button pressed the user
    //we need to calc the diff between values when we rendered and new values when user change some value
    //thanks https://stackoverflow.com/questions/1187518/how-to-get-the-difference-between-two-arrays-in-javascript
    function arr_diff (a1, a2) {
      var a = [], diff = [];
      for (var i = 0; i < a1.length; i++) a[a1[i]] = true;
      for (var i = 0; i < a2.length; i++) if (a[a2[i]]) delete a[a2[i]]; else a[a2[i]] = true;
      for (var k in a) diff.push(k);
      return diff;
    }

    let newValue = value.sort();
    let lastValue = value[value.length - 1];
    if (lastValue !== undefined && value.length > 1) {
      lastValue = arr_diff(value, this.props.value)[0];
      for (let pos in newValue) {
        //to avoid jumps the pos of the position -1 has to be the same that this position - 1
        if (pos > 0 && parseInt(newValue[pos - 1]) !== parseInt(newValue[pos]) - 1) {
          newValue = [lastValue.toString()];
          break;
        }
      }
      //TODO: DON'T REMOVE THIS UNTIL WE SEE HOW RECIVE THE VALUES FROM THE API BECAUSE THIS WAY NEEDS TO BE IMPROVED BUT WORKS WITH VALUES NO NUMERIC
      // let allOptions = this.props.options.map(function (el) { return el.lookupValue;});
      // let indexLastValue = allOptions.findIndex(function (el) { return el === lastValue;});
      // let leftValue = allOptions[indexLastValue - 1];
      // let rightValue = allOptions[indexLastValue + 1];
      // if (!leftValue && !rightValue) {
      //   throw "Exception it cannot happen";
      // } else if (!leftValue && rightValue) {
      //   let rightValueSelected = value.findIndex(function (el) { return el === rightValue;});
      //   if (rightValueSelected === -1) newValue = [lastValue.toString()];
      // } else if (leftValue && !rightValue) {
      //   let leftValueSelected = value.findIndex(function (el) { return el === leftValue;});
      //   if (leftValueSelected === -1) newValue = [lastValue.toString()];
      // } else if (leftValue && rightValue) {
      // let leftValueSelected = value.findIndex(function (el) { return el === leftValue;});
      // let rightValueSelected = value.findIndex(function (el) { return el === rightValue;});
      // if (leftValueSelected !== -1 && rightValueSelected !== -1) newValue = [lastValue.toString()];
      // if (leftValueSelected === -1 && rightValueSelected === -1) newValue = [lastValue.toString()];
      // console.log("Is the left VAlue selected?:" + leftValueSelected)
      // console.log("Is the right VAlue selected?:" + rightValueSelected)
      // console.log("New value:" + newValue)
      // }
    }

    this.props.onChange(newValue);
  }

  render () {
    let options = [];
    for (let option of this.props.options.sort(function (a, b) { return a.lookupValue - b.lookupValue;})) {
      options.push(<ToggleButton key={option.lookupValue} className={this.props.className + "-" + option.lookupValue} value={option.lookupValue}>
        {option.displayValue}
      </ToggleButton>);
    }

    return <ButtonToolbar className={this.props.className}>
      <ToggleButtonGroup type="checkbox" onChange={(value)=>this.handleChange(value)} value={this.props.value}>
        {options}
      </ToggleButtonGroup>
      <div className={"info-text"}>{this.props.attribute.text}</div>
    </ButtonToolbar>;
  }
}
