import React from 'react';
import InputGroup from 'react-bootstrap/lib/InputGroup';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import classNames from 'classnames';
import Dropdown from 'react-bootstrap/lib/Dropdown';
import onClickOutside from 'react-onclickoutside';

class PexRadioSelectDropdownWithSearch extends React.Component {
  constructor (props) {
    super(props);
  }
  handleClickOutside () {
    this.props.onClickOutside(this.props.collectionName);
  }


  render () {
    let classesParent = this.props.className;
    return <FormGroup className={classNames(classesParent) + " radio-dropdown-container-with-search dropdown-radio-select dropdown-radio-with-search-" + this.props.collectionName} >
      <ControlLabel htmlFor={this.props.label}>{this.props.label}</ControlLabel>
      <div>
        <RadioSelectField onClickOpen={this.props.onClickOpen}
          onSelectOption={this.props.onSelectOption}
          onSearchTextHandler={this.props.onSearchTextHandler}
          open={this.props.open}
          data={this.props.data}
          labels={this.props.labels}
          defaultLabels={this.props.defaultLabels}
          collectionName={this.props.collectionName}
          placeholder={this.props.placeholder}
          elementSelected = {this.props.elementSelected}
          textFilterValue = {this.props.textFilterValue}
          value={this.props.value}
          iconClasses={this.props.iconClasses}/>
      </div>
    </FormGroup>;
  }
}
//This export is required to use the clickOutside event.
export default onClickOutside(PexRadioSelectDropdownWithSearch);

function RadioSelectField (props) {
  function onClickOpen () {
    props.onClickOpen(props.collectionName);
  }
  //This event is required to manage the open attribute, if we remove the declaration it won't work
  function onToggle () {
    return;
  }
  let open = false;
  if (props.open && props.open.length > 0 && props.open === props.collectionName ) open = true;
  return <Dropdown id={props.collectionName} className={"dropdown-single-select-with-search dropdown-single-" + props.collectionName} open={open} onToggle={onToggle}>
    <Dropdown.Toggle id={props.collectionName}
      className ={"radio-select-toggle-btn dropdown-toggle-" + props.collectionName}
      onClick={ () => {onClickOpen();}}>
      <DropDownText data={props.data}
        labels={props.labels}
        defaultLabels={props.defaultLabels}
        placeholder={props.placeholder}
        elementSelected={props.elementSelected}
        value={props.value}
        iconClasses={props.iconClasses}/>
    </Dropdown.Toggle>
    <Dropdown.Menu className={"pex-menu-items-" + props.collectionName}>
      <DropDownOptions collectionName={props.collectionName}
        data={props.data}
        onSelectOption={props.onSelectOption}
        onSearchTextHandler={props.onSearchTextHandler}
        textFilterValue={props.textFilterValue}
        labels={props.labels}
        iconClasses={props.iconClasses}/>
    </Dropdown.Menu>
  </Dropdown>;
}

function DropDownText (props) {
  let icon = "";
  if (props.elementSelected && props.elementSelected.lookupValue) icon = props.iconClasses + "-" + props.elementSelected.lookupValue;
  return <span className={"dropdown-text"}><i className={icon}></i>{props.value}</span>;
}

function DropDownOptions (props) {

  function onSelectOption (value) {
    props.onSelectOption(value);
  }

  function onSearchTextHandler (event) {
    props.onSearchTextHandler(event);
  }

  let finalElements = [];
  //TODO: javi add the place holder option
  finalElements.push(<input onChange={(ev) => onSearchTextHandler(ev)}
    type="text"
    key="search-filter"
    value={props.textFilterValue || ""}
    className={props.collectionName + "-search-filter text-box-search-filter"}
  />);
  for (let obj of props.data) {
    if (props.textFilterValue !== undefined && obj.displayValue.indexOf(props.textFilterValue.toUpperCase()) !== -1) {
      finalElements.push(<li
        id={obj.lookupValue}
        key={obj.lookupValue}
        className={"list-group-item pex-radio pex-radio-element-" + props.collectionName + "-" + obj.lookupValue}
        onClick={() => onSelectOption(obj)}><i className={props.iconClasses + "-" + obj.lookupValue}></i> {obj.displayValue}
      </li>);
    }
  }

  return <span>{finalElements}</span>;
}
