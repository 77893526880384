import React from 'react';
import Col from 'react-bootstrap/lib/Col';

export default class DeclineAcceptInfo extends React.Component {
  render () {
    return (
      <Col sm={12} className="decline-accept-content">
        <p className="decline-accept-tenant-name">{"Will you accept to set up a EDDR for " + this.props.tenantName + "? Below you can check the rent amount and tenancy details."}</p>
        <div className="decline-accept-rent">
          <div className="decline-accept-rent-label">Amount</div>
          <div><h4>{this.props.rent}</h4></div>
        </div>
        <div className="decline-accept-frequency">
          <div className="decline-accept-frequency-label">Frequency</div>
          <div><h4>{this.props.frequency}</h4></div>
        </div>
        <div className="decline-accept-firstDD">
          <div className="decline-accept-firstDD-label">Date of the first DD</div>
          <div><h4>{this.props.firstDD}</h4></div>
        </div>
        <div className="decline-accept-lastDD">
          <div className="decline-accept-lastDD-label">Date of the last DD</div>
          <div><h4>{this.props.lastDD}</h4></div>
        </div>
      </Col>
    );
  }
}
