import React from 'react';
import { PexThanks } from "../components/form-components/pex-thanks.jsx";
import { PexText } from "../components/form-components/pex-text.jsx";
import { PexTextArea } from "../components/form-components/pex-text-area.jsx";
import { PexDropDown } from "../components/form-components/pex-drop-down.jsx";
import {PexCountryPhone} from '../components/form-components/pex-country-phone.jsx';
import formService from '../services/form-service.js';
import searchService from '../services/search-service.js';
import translateService from '../services/translate-service.js';

import ajax from '../../lib/ajax.js';
let api = require('../../lib/api.js')(ajax);
import utils from '../../lib/utils.js';
import Form from 'react-bootstrap/lib/Form';
import Panel from 'react-bootstrap/lib/Panel';
import ButtonGroup from 'react-bootstrap/lib/ButtonGroup';
import Button from 'react-bootstrap/lib/Button';
import TagManager from 'react-gtm-module';
const defaultLabels = JSON.parse(JSON.stringify(require("../../../lang/search-applet/en-GB.json"))).labels["en-GB"];

let format_path = utils.format_path;

import { createStore } from 'redux';

export default class InterestForm extends React.Component {
  constructor (props) {
    super(props);
    let new_state = {};
    new_state = formService.initInterestForm ();
    new_state.isOpen = "";
    new_state.textFilterValue = "";
    if (props.gtmId) {
      let tagManagerArgs = { "gtmId": props.gtmId};
      TagManager.initialize(tagManagerArgs);
    }
    let self = this;
    this.store = createStore(function (state = new_state, action) {
      switch (action.type) {
        case "LOAD": return Object.assign(state, {"nextAction": "getLabels", "lang": action.lang});
        case "TYPE_INTO_FORM":
          let values = JSON.parse(JSON.stringify(state.values));
          values[action.field] = action.value;
          let _state = formService.checkForm(values, state.formNames, state.labels, "interestForm");
          _state.values = values;
          return Object.assign({}, state, _state);
        case "THANKS": return Object.assign({}, state, {state: "sent", "waiting": false});
        case "API_ERROR": return Object.assign({}, state, {data: {error: action.response}, "waiting": false, "nextAction": "DONE"});
        case "CONFIGURATION_ERROR": return Object.assign({}, state, {"error": "configuration error"});
        case "DATA": return Object.assign({}, state, {data: action.data, "nextAction": "DONE"});
        case "SUBMITTED": return Object.assign({}, state, {submitted: true});
        case "WAITING": return Object.assign(state, {waiting: true});
        case "SUBMIT_WITH_ERROR": {
          let newState = {};
          let error = searchService.getError(action.message, action.messageType, null, self.state.labels, null, "interestForm.customAPIMessage.");
          newState.submitError = error;
          newState.waiting = false;
          return Object.assign({}, state, newState);
        }
        case "GET_LABELS_OK": {
          let interestForm = formService.initInterestForm(action.response.labels[state.lang]);
          return Object.assign({}, state, {"labels": action.response.labels[state.lang], "nextAction": "getFormCollections"}, interestForm);
        }
        case "GET_FORM_COLLECTION_RESPONSE": {
          let countries = action.getFormCollectionsResponse.countries.map(
            function (country) {
              let obj = {};
              obj.lookupValue = country.lookupValue;
              obj.diallingCode = country.diallingCode;
              obj.displayValue = country.displayValue + " (" + country.diallingCode + ")";
              return obj;
            }
          ).filter(function (el) {return el.diallingCode && Number.isInteger(Number(el.diallingCode.substring(0, 1)));});
          return Object.assign(state, { "getFormCollectionsResponse": action.getFormCollectionsResponse, "nextAction": "init", "countries": countries});
        }
        case "SELECT_COUNTRY": {
          let newState = state;
          newState.countrySelected = action.countrySelected;
          newState.values["mobileCountry"] = action.countrySelected.diallingCode;
          newState = Object.assign(newState, formService.checkForm(newState.values, newState.formNames, newState.labels, "interestForm"));
          newState.isOpen = "";
          return Object.assign(state, {"textFilterValue": ""}, newState);
        }
        case "OPEN_CLOSE_DROPDOWN": {
          let isOpen = action.collectionName;
          if (state.isOpen && state.isOpen.length > 0 && state.isOpen === action.collectionName) isOpen = "";
          return Object.assign(state, {"isOpen": isOpen, "textFilterValue": ""});
        }
        case "CLICK_OUTSIDE": {
          return Object.assign(state, {"isOpen": ""});
        }
        case "SEARCH_FILTER_HANDLER": {
          return Object.assign(state, {"textFilterValue": action.value});
        }
        default:
          return state;
      }
    }, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

    this.state = this.store.getState();
  }

  componentWillUnmount () {
    this.unsubscribe();
  }

  render () {
    if (this.state.error) return <div>{this.state.error}</div>;
    if (this.state && this.state.state === "sent") return <PexThanks message={::this.getThanksMessage} />;
    else if (this.state.data && this.state.data.error) {
      let error_message = null;
      if (this.state.data.error.messages) {
        error_message = [this.state.data.error.messages[0].description, this.state.data.error.messages[0].message].filter(function (str) { return str; }).join(" - ");
        if (this.state.data.error.messages.length !== 1) error_message = "ERROR: Default error";
      } else {
        error_message = this.state.data.error;
      }
      return <div dangerouslySetInnerHTML={{__html: utils.sanitize(error_message)}}></div>;
    }
    else if (this.state.data) {
      return <div className={(this.state.waiting) ? "divFrozen" : "container-fluid"}>
        <FormPex state={this.state}
          submit={::this.submit}
          getLocationObject={::this.getLocationObject}
          waiting= {::this.waiting}
          generalProps={this.getFormControlProps()}
          areas={this.props.areas}
          submittedWithErrors={::this.submittedWithErrors}
          openCloseDropdown={this.openCloseDropdown.bind(this)}
          clickOutside={this.clickOutside.bind(this)}
          onSearchTextHandler={this.onSearchTextHandler.bind(this)}
          selectCountry={this.selectCountry.bind(this)}
          defaultLabels={defaultLabels}/>
      </div>;
    } else return <div>just a sec</div>;
  }

  componentDidMount () {
    this.unsubscribe = this.store.subscribe(function () {
      ///TODO: find a way to wrap up this code since we are using it in multiple components
      let state = {};
      for (let key of Object.keys(this.state)) state[key] = null; //we have to do this because replaceState no longer exists
      this.setState(Object.assign({}, state, this.store.getState()));

      //instead of setting the state to the redux store state, if we want to pass
      //access the redux store directly, we can replace setState with this.forceUpdate
    }.bind(this));

    if (!this.props.areas) {
      this.store.dispatch({type: "CONFIGURATION_ERROR"});
      return;
    }
    let lang = "en-GB";
    if (this.props.lang) lang = this.props.lang;
    this.store.dispatch({type: "LOAD", "lang": lang});

  }

  componentDidUpdate (prevProps, prevState) {
    switch (this.state.nextAction) {
      case "init":
      case "getLabels":
      case "getFormCollections":
        this[this.state.nextAction]();
    }
  }

  //  API calls

  submit (payload) {
    let self = this;
    api.createGeneralEnquiry(this.props.api, payload, this.thanks.bind(this),
      function (message, messageType) {
        self.store.dispatch({type: "SUBMIT_WITH_ERROR", "message": message, "messageType": messageType});
      });
  }

  getLabels () {
    let self = this;
    api.getLabels(this.props.api, {"languageIds": [this.state.lang], "group": "interestForm"}, function (response) {
      self.store.dispatch({ type: "GET_LABELS_OK", "response": response});
    }, function () {
      self.store.dispatch({"type": "API_ERROR", response: "API error 9787746I - getLabels error"});
    });
  }
  getFormCollections () {
    let self = this;
    api.getFormCollections(self.props.api, {}, function (data) {
      self.store.dispatch({type: "GET_FORM_COLLECTION_RESPONSE", "getFormCollectionsResponse": data});
    }, function (error) {
      self.store.dispatch({type: "API_ERROR", "error": error});
    });
  }

  //  Other methods

  init () {
    api.init(this.props.api, {}, function (data) {
      this.store.dispatch({type: "DATA", data: data});
    }.bind(this), function () {
      this.store.dispatch({type: "API_ERROR", response: response});
    });
  }

  thanks (response) {
    this.store.dispatch({type: "THANKS"});
  }

  getThanksMessage () {
    if (this.props.thanksmessage) {
      return format_path(this.props.thanksmessage, {
        LOCATION_NAME: this.getLocationObject(this.state.values.area).displayValue,
      });
    }
    return "THANKS!!!";
  }

  waiting () {
    this.store.dispatch({type: "WAITING"});
  }

  updateFieldFromEvent (field, event) {
    this.store.dispatch({type: "TYPE_INTO_FORM", field: field, value: event.target.value});
  }

  getFormControlProps () {
    return {
      values: this.state.values || {},
      errors: this.state.fieldsWithErrors ? this.state.fieldsWithErrors : {},
      updateField: this.updateFieldFromEvent.bind(this),
      submitted: this.state.submitted,
      waiting: this.state.waiting,
    };
  }

  getLocationObject (area) {
    for (let location of this.state.data.select.location) {
      if (location.lookupValue === area) {
        return {
          "lookupValue": location.lookupValue,
          "displayValue": location.displayValue,
        };
      }
    }
    return null;
  }

  submittedWithErrors () {
    this.store.dispatch({type: "SUBMITTED"});
  }

  selectCountry (country) {
    this.store.dispatch({type: "SELECT_COUNTRY", "countrySelected": JSON.parse(JSON.stringify(country))});
  }
  openCloseDropdown (collectionName) {
    this.store.dispatch({ type: "OPEN_CLOSE_DROPDOWN", "collectionName": collectionName});
  }
  clickOutside (collectionName) {
    this.store.dispatch({ type: "CLICK_OUTSIDE", "collectionName": collectionName});
  }
  onSearchTextHandler (event) {
    this.store.dispatch({ type: "SEARCH_FILTER_HANDLER", "value": event.target.value});
  }

}

function FormPex (props) {

  function translate (name) {
    return translateService.getLabelsTranslated(props.state.labels, "interestForm.", name, props.defaultLabels);
  }

  function handleSubmit (event) {
    event.preventDefault();
    if (!props.state.canSubmit) {
      props.submittedWithErrors();
      return;
    }
    props.waiting();
    let payload = {
      "activity": {
        type: "WEBENQ",
        location: props.state.values.area,
        "text": props.state.values.area + " (attending " + props.state.values.uniCollegeSchoolAttending + ")" + " - " + props.state.values.comments,
      },
      "user": {
        "username": props.state.values.emailAddress,
        "firstName": props.state.values.firstName,
        "lastName": props.state.values.lastName,
        "mobileCountry": props.state.values.mobileCountry,
        "mobileNumber": "+" + props.state.values.mobileCountry + props.state.values.mobileNumber,
      },
    };
    props.submit(payload);
  }

  let areasList = props.areas.split(",").map(function (area) {
    return area.trim();
  }).map(function (area) {
    return props.getLocationObject(area);
  }.bind(this)).filter(function (area) {
    return area; //since the previous map will add nulls in
  });

  let area_options = areasList.map(function (option) {
    return <option key={option.lookupValue} value={option.lookupValue}>{option.displayValue}</option>;
  });

  const panel1header = (<div><h3 className="interest-form-title text-center"><strong>{translate("sendMessageLabel")}</strong></h3></div>);
  return <Form horizontal onSubmit={handleSubmit.bind(this)}>
    <Panel header = {panel1header} >
      <PexDropDown fieldKey="area" className = "interest-form-area"
        iconClassName="fa fa-list" label={translate("areaLabel")} {...props.generalProps} >{area_options}</PexDropDown>
      <PexText fieldKey="firstName" className = "interest-form-first-name" iconClassName="fa fa-user" label={translate("firstNameLabel")} {...props.generalProps } />
      <PexText fieldKey="lastName" className = "interest-form-last-name" iconClassName="fa fa-user" label={translate("lastNameLabel")} {...props.generalProps } />
      <PexCountryPhone fieldKey="countryPhone"
        countryKey="mobileCountry"
        phoneKey="mobileNumber"
        className = "interest-form-phone-number"
        iconClassName="fa fa-phone"
        translate={translate.bind(this)}
        {...props.generalProps }
        label={translate("phoneLabel")}
        countriesData = {props.state.countries}
        countrySelected = {props.state.countrySelected}
        openCloseDropdown = {props.openCloseDropdown}
        clickOutside = {props.clickOutside}
        onSearchTextHandler = {props.onSearchTextHandler}
        isOpen = {props.state.isOpen}
        textValue = {translate("selectCountryLabel")}
        countryCollectionName = "country-prefix"
        textFilterValue = {props.state.textFilterValue}
        selectCountry = {props.selectCountry}/>
      <PexText fieldKey="emailAddress" className = "interest-form-email-address"iconClassName="fa fa-envelope-o" label={translate("emailLabel")} {...props.generalProps } />
      <PexText fieldKey="uniCollegeSchoolAttending" className = "interest-form-uni-college-school-attending" iconClassName="fa fa-university" label={translate("universityLabel")} {...props.generalProps } placeholder={translate("universityPlaceholder")} />
      <PexTextArea fieldKey="comments" className = "interest-form-comments" iconClassName="fa fa-pencil" label={translate("commentsLabel")} {...props.generalProps } placeholder={translate("commentsPlaceholder")} />
    </Panel>
    <ButtonGroup vertical block>
      <Button type="submit" className="btn-submit-interest-form">{translate("sendButton") + " "}<span className="fa fa-send" /></Button>
      <SubmitError submitError={props.state.submitError}/>
    </ButtonGroup>
  </Form>;
}

function SubmitError (props) {
  let error = props.submitError;
  if (error) return <div className="interest-form-submit-error">{error}</div>;
  return null;
}
