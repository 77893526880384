import React from 'react';
import utils from "../../../lib/utils";
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import Popover from 'react-bootstrap/lib/Popover';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import Button from 'react-bootstrap/lib/Button';

export default class ReferencingHold extends React.Component {

  componentDidMount () {
    this.props.loadReferencingHold();
  }

  render () {

    let tenants = [];
    //TODO: for each tenant add a column sm 6. Now I do only with current user but we have to do with all
    //TODO: I set application, landlord, credit and employeer how example to use. Change depending referencing response
    tenants.push(<Tenant key={0} index={0}
      user={this.props.state.user}
      application={true}
      landlord={true}
      credit={true}
      employer={true}

    />);

    if (this.props.state && this.props.state.stepLoaded && !this.props.state.error) {

      return <div className={"referencing-hold"}>
        <h4 dangerouslySetInnerHTML={{__html: utils.sanitize(this.props.state.labels["graingerBookingJourney.referencingHold.title"])}}></h4>
        <div dangerouslySetInnerHTML={{__html: utils.sanitize(this.props.state.labels["graingerBookingJourney.referencingHold.subtitle"])}} className={"info"}></div>
        <Row className={"referencing"}>
          {tenants}
        </Row>
      </div>;
    }
    else return <div>Loading step...</div>;
  }
}

function Tenant (props) {
  //TODO: what now is always checked=true has to change depending of referencing response
  let checks = [];
  if (props.application) checks.push(<ReferencingChecked key ={0} name={"Application"} checked={true} />);
  if (props.landlord) checks.push(<ReferencingChecked key ={1} name={"Landlord"} checked={false} />);
  if (props.credit) checks.push(<ReferencingChecked key ={2} name={"Credit check"} checked={false} />);
  if (props.employer) checks.push(<ReferencingChecked key ={3} name={"Employer"} checked={false} />);

  return <Col sm={6} className={"tenant text-center"}>
    <Col xs={12}>
      <h3>{"Tenant " + (props.index + 1) + ":" + props.user.firstName + " " + props.user.lastName}</h3>
      {checks}
    </Col>

  </Col>;
}

function ReferencingChecked (props) {

  let icon = "fa fa-refresh fa-5x";
  if (props.checked) icon = "fa fa-check-circle-o fa-5x";

  return <Col sm={3}>
    <i className={icon}></i>
    <div>{props.name}</div>
  </Col>;
}
