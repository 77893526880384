import React from 'react';
import Panel from 'react-bootstrap/lib/Panel';
import Col from 'react-bootstrap/lib/Col';
import Row from 'react-bootstrap/lib/Row';
import Button from 'react-bootstrap/lib/Button';
import UserChoosePayment from './steps-components/user-choose-payment.jsx';
import {InfoBar} from './info-bar.jsx';
import Form from 'react-bootstrap/lib/Form';
import classNames from 'classnames';
import Loading from "./loading.jsx";

export function PaymentDetails (props) {

  function goToPersonalDetails () {
    props.goToPersonalDetails(props.currentDirectDebit);
  }

  let myFooter = <Row>
    <Col xs={6}>
      <Button bsStyle="primary" className="go-to-dashboard" onClick={props.goToDashboard}>Dashboard</Button>
    </Col>
    <Col xs={6} className="text-right">
      <Button className="go-to-previous-step" onClick={ ()=>{goToPersonalDetails();}} ><i className="fa fa-arrow-left hidden-sm hidden-lg"></i><span className="hidden-xs">Previous step</span></Button>
      <Button type="submit" bsStyle="success" className="go-to-next-step"><span className="hidden-xs">Next step</span> <i className="fa fa-arrow-right hidden-sm hidden-lg"></i></Button>
    </Col>
  </Row>;
  if (props.waiting) return <Loading waiting={props.waiting} />;
  return (
    <div className={classNames('container direct-debit dd-new payment-details')}>
      <InfoBar infoBarData={props.infoBarData}/>
      <Row>
        <Col xs={12}>
          <Form onSubmit={props.validatePaymentDetails}>
            <Panel className="panel-min-height" header="Direct Debit Request" footer={myFooter}>
              <Col sm={12}>
                <UserChoosePayment changePaymentType={props.changePaymentType}
                  paymentTypeSelected={props.paymentTypeSelected}
                  bankForm={props.bankForm}
                  updateBankForm={props.updateBankForm}
                  selectBank={props.selectBank}
                  bankList={props.bankList}
                  bankSelected={props.bankSelected}
                  updateDebitCardForm={props.updateDebitCardForm}
                  debitCardForm={props.debitCardForm}
                  onKeyDownBsbHandler={props.onKeyDownBsbHandler}
                />
              </Col>
            </Panel>
          </Form>
        </Col>
      </Row>
    </div>
  );

}
