import React from 'react';
import ReactDOM from 'react-dom';
import Button from 'react-bootstrap/lib/Button';
import Col from 'react-bootstrap/lib/Col';
import utils from '../../../lib/utils.js';
import { ListElementPS } from "./pex-search-category.jsx";
import SearchDateSelector from "./search-date-selector.jsx";
import PexDropdownMultiSelect from "./pex-dropdown-multi-select.jsx";
import PexRadioSelectDropdown from "../form-components/pex-radio-select-dropdown.jsx";
import moment from 'moment';
import translateService from '../../services/translate-service.js';
import classNames from 'classnames';
import searchService from "../../services/search-service";

export default class PexSearchForm extends React.Component {

  focusSearchButton () {
    ReactDOM.findDOMNode(this.refs.searchButton).focus();
  }

  dataInOldFormat () {
    //TODO: remove this asap, we have to manage in other way, it makes the parent areas very hard
    let self = this;
    ///we have to synthesize the previous format with count by merging the initial data array with the current array
    function currentCount (categoryLookupValue, tagLookupValue) {
      if (!self.props.state.data.categories) return 0;
      for (let category of self.props.state.data.categories) {
        if (category.lookupValue === categoryLookupValue) {
          for (let tag of category.tags) {
            if (tag.lookupValue === tagLookupValue) {
              return tag.count;
            }
          }
        }
      }
      return 0;
    }

    let data = JSON.parse(JSON.stringify(this.props.state.initialData));
    data.categories = data.categories.map(function (category) {
      return {
        lookupValue: category.lookupValue,
        displayValue: category.displayValue,
        tags: category.tags.map(function (tag) {
          let new_tag = JSON.parse(JSON.stringify(tag));
          new_tag.count = currentCount(category.lookupValue, tag.lookupValue);
          return new_tag;
        }),
      };
    }).sort(function (a, b) { return a.displayValue.localeCompare(b.displayValue); });

    return data;
  }
  //TODO: Think about unify these methods about to click some option
  categoryClick (elementLookupValue, arrayName, selected) {
    //TODO: I think this condition has to be only  if ((!this.props.elementsDisabled["dropdowns"]. Fix on master
    if ((!this.props.elementsDisabled["dropdowns"] && arrayName !== "bookingTypes") || (arrayName === "bookingTypes" && !this.props.elementsDisabled["bookingTypes"])) {
      this.props.categoryClick (elementLookupValue, arrayName, selected);
    }
  }

  collectionOptionClick (elementLookupValue, arrayName, selected) {
    if ((!this.props.elementsDisabled["dropdowns"] && arrayName !== "bookingTypes") || (arrayName === "bookingTypes" && !this.props.elementsDisabled["bookingTypes"])) {
      this.props.collectionOptionClick(elementLookupValue, arrayName, selected);
    }
  }

  updateBudget (value) {
    if (!this.props.elementsDisabled["budget"]) {
      this.props.updateBudget(value);
    }
  }

  updateMinBudget (value) {
    if (!this.props.elementsDisabled["minBudget"]) {
      this.props.updateMinBudget(value);
    }
  }

  updateGender (value) {
    if (!this.props.elementsDisabled["gender"]) {
      this.props.updateGender(value);
    }
  }

  updateSearchDate (value) {
    if (!this.props.elementsDisabled["startDate"]) {
      var date = moment(value, "YYYY-MM-DD");
      if (date.isValid() || value === '') {
        this.props.updateSearchDate(value);
      }
    }
  }

  translate (name) {
    return translateService.getLabelsTranslated(this.props.state.labels, "searchApplet.searchForm.", name, this.props.defaultLabels);
  }

  translateSearchFormLabels (lookupValue, displayValue) {
    if (this.props.state.labels && this.props.state.labels["searchApplet.searchForm.category" + lookupValue]) return this.props.state.labels["searchApplet.searchForm.category" + lookupValue];
    return displayValue;
  }

  //GetBudget and GetBudgetData returns the values for the dropdowns selecting numbers multiples of 100 from the low value to the high value
  getMinBudgetData (value, budgetValue) {
    let data = {};
    data["-"] = this.translate("minBudgetPlaceholder");
    let lowBudgetFromProps = value;
    let priceRange = this.props.state.data.priceRange ? JSON.parse(JSON.stringify(this.props.state.data.priceRange)) : null;
    if (!priceRange || Object.keys(priceRange).length === 0) return { "data": data, "value": null};
    priceRange.low = Math.floor(priceRange.low);
    while (priceRange.low % 100 !== 0) priceRange.low--;
    if (value && value !== "-") {
      if (1.0 * value < 1.0 * priceRange.low && value - 100 < priceRange.low ) priceRange.low = value - 100;
      if (lowBudgetFromProps === priceRange.high) lowBudgetFromProps = priceRange.high - 100;
    }
    if (budgetValue && budgetValue !== "-") {
      for (let price = 100; price < 1.0 * priceRange.high; price += 100) {
        if (1.0 * price >= priceRange.low && 1.0 * price < 1.0 * budgetValue) data[price] = utils.format_rate(price, priceRange.unit);
      }
    } else {
      for (let price = 100; price < 1.0 * priceRange.high; price += 100) if (1.0 * price >= 1.0 * priceRange.low) data[price] = utils.format_rate(price, priceRange.unit);
    }

    return { "data": data, "value": lowBudgetFromProps};
  }
  getBudgetData (value, minBudgetValue) {
    let data = {};
    data["-"] = this.translate("budgetPlaceholder");

    let priceRange = this.props.state.data.priceRange ? JSON.parse(JSON.stringify(this.props.state.data.priceRange)) : null;
    if (!priceRange || Object.keys(priceRange).length === 0) return { "data": data, "value": null};
    if (value && value !== "-") {
      if (1.0 * value < 1.0 * priceRange.low && value - 100 < priceRange.low ) priceRange.low = value - 100;
      if (value > priceRange.high) priceRange.high = value;
    }
    if (minBudgetValue && minBudgetValue !== "-") {
      for (let price = 100; price < 1.0 * priceRange.high + 100; price += 100) if (1.0 * price > 1.0 * minBudgetValue) data[price] = utils.format_rate(price, priceRange.unit);
    } else {
      for (let price = 100; price < 1.0 * priceRange.high + 100; price += 100) if (1.0 * price > 1.0 * priceRange.low) data[price] = utils.format_rate(price, priceRange.unit);
    }
    return { "data": data, "value": value};
  }

  getGenderData (value) {
    let data = {};
    data["-"] = this.translate("genderPlaceholder");
    if (this.props.state.initialData.genders) {
      for (let gender of this.props.state.initialData.genders) {
        if (gender.lookupValue) data[gender.lookupValue] = gender.displayValue;
      }
    }
    return { "data": data, "value": value};
  }

  categoryDataFormatted (criteria, tags, collectionName) {
    let self = this;

    function isSelected (categories, nameArray, lookupValue) {
      if (collectionName === "PARENT-AREA") {
        let selected = false;
        for (let area of Object.keys(self.props.state.initialData.parentsOfAreas[lookupValue].areas)) {
          if (criteria.categories && criteria.categories["AREA"] && criteria.categories["AREA"].indexOf(area) !== -1) {
            selected = true;
          }
        }
        return selected;
      }
      return categories && categories[nameArray] && categories[nameArray].indexOf(lookupValue) !== -1;
    }
    function isDisabled (count, selected) {
      //I agreed with tom don't disable the parent areas dropdown for now
      if (collectionName === "PARENT-AREA") return false;
      if (selected) return false;
      if (count === 0) return true;
      else return false;
    }
    let data = {};
    for (let tag of tags) {
      data[tag.lookupValue] = {};
      data[tag.lookupValue].lookupValue = tag.lookupValue;
      data[tag.lookupValue].displayValue = tag.displayValue;
      data[tag.lookupValue].selected = isSelected (criteria.categories, collectionName, tag.lookupValue);
      data[tag.lookupValue].disabled = isDisabled (tag.count, data[tag.lookupValue].selected );
    }
    return data;
  }

  collectionDataFormatted (criteria, collectionName) {
    let data = JSON.parse(JSON.stringify(this.props.state.initialData[collectionName]));
    function isSelected (collection, nameArray, lookupValue) {
      return collection[nameArray] && collection[nameArray].indexOf(lookupValue) !== -1;
    }
    let optionsData = {};
    for (let option of data) {
      optionsData[option.lookupValue] = {};
      optionsData[option.lookupValue].lookupValue = option.lookupValue;
      optionsData[option.lookupValue].displayValue = option.displayValue;
      optionsData[option.lookupValue].selected = isSelected (criteria, collectionName, option.lookupValue);
    }
    return optionsData;
  }

  render () {
    let minBudgetData = this.getMinBudgetData(this.props.state.criteria.minBudget, this.props.state.criteria.budget);
    let budgetData = this.getBudgetData(this.props.state.criteria.budget, this.props.state.criteria.minBudget);
    let genderData = this.getGenderData(this.props.state.criteria.gender, this.props.state.criteria.gender);
    //parse start ranges
    let includeDates = searchService.getIncludeDates(this.props.state.data.startRanges);

    let self = this;
    let bookTypeDropdown = [];
    if (this.props.state.initialData["bookingTypes"]) {
      if (self.props.multiselectSearchForm === "true") {
        bookTypeDropdown.push(<PexDropdownMultiSelect
          collectionName = "bookingTypes"
          fieldKey = "bookingTypes"
          key = "bookingTypes"
          label = {this.translateSearchFormLabels("bookingTypes", this.translate("bookingTypesLabel"))}
          labels = {this.props.state.labels}
          defaultLabels = {this.props.defaultLabels}
          data = {this.collectionDataFormatted(this.props.state.criteria, "bookingTypes")}
          iconClassName = "fa fa-icon"
          className = ""
          open = {this.props.state.isOpen}
          errors = ""
          onSelectOption = {::self.collectionOptionClick}
          onClickOpen = {this.props.openCategoryDropdown}
          onClickOutside={this.props.clickOutside}
          elementDisabled = {self.props.elementsDisabled["bookingTypes"]}
        />);
      } else {
        let props = {};
        props.key = "bookingTypes";
        props.label = this.translateSearchFormLabels("bookingTypes", this.translate("bookingTypesLabel"));
        props.criteria = this.props.state.criteria;
        props.nameArray = "bookingTypes";
        props.data = JSON.parse(JSON.stringify(this.props.state.initialData["bookingTypes"]));
        props.categoryClick = ::self.collectionOptionClick;
        props.labels = this.props.state.labels;
        props.elementDisabled = this.props.elementsDisabled["bookingTypes"];
        bookTypeDropdown.push(<ListElementPS {...props} />);
      }
    }

    //HERE WE HAVE TO SEE HOW WHEN WE HAVE IN THE STATE "parentAreas"
    //-hide the area dropdown
    //-show the new one with the categories that we have parsed in parentAreas
    //Important methods are categoryClick, categoryDataFormatted
    let dropDowns = this.dataInOldFormat().categories.map(function (dropDown) {
      let props = {};
      if (self.props.multiselectSearchForm === "true") {
        let elementProps = {};
        elementProps.collectionName = dropDown.lookupValue;
        elementProps.fieldKey = dropDown.lookupValue;
        elementProps.key = dropDown.lookupValue;
        elementProps.label = self.translateSearchFormLabels(dropDown.lookupValue, dropDown.displayValue);
        elementProps.labels = self.props.state.labels;
        elementProps.defaultLabels = self.props.defaultLabels;
        elementProps.data = self.categoryDataFormatted(self.props.state.criteria, dropDown.tags, elementProps.collectionName);
        elementProps.iconClassName = "fa fa-icon";
        elementProps.className = "";
        elementProps.open = self.props.state.isOpen;
        elementProps.errors = {};
        elementProps.elementDisabled = self.props.elementsDisabled["dropdowns"];
        return <PexDropdownMultiSelect {...elementProps}
          onSelectOption = {::self.categoryClick}
          onClickOpen = {self.props.openCategoryDropdown}
          onClickOutside={self.props.clickOutside}/>;
      } else {
        props.key = dropDown.lookupValue;
        props.label = self.translateSearchFormLabels(dropDown.lookupValue, dropDown.displayValue);
        props.criteria = self.props.state.criteria; //so how do we use map and filter but avoid self and arrow?
        props.nameArray = dropDown.lookupValue;
        props.data = dropDown.tags;
        props.categoryClick = ::self.categoryClick;
        props.labels = self.props.state.labels;
        props.elementDisabled = self.props.elementsDisabled["dropdowns"];
        props.parentsOfAreas = self.props.state.initialData.parentsOfAreas ? self.props.state.initialData.parentsOfAreas : null;
        return <ListElementPS {...props} />;
      }}
    ).concat(bookTypeDropdown
    ).concat([<PexRadioSelectDropdown
      key="minBudget"
      onClickOpen={this.props.openCategoryDropdown}
      onSelectOption={::this.updateMinBudget}
      onClickOutside={this.props.clickOutside}
      open= {this.props.state.isOpen}
      data={minBudgetData.data}
      labels={this.props.labels}
      defaultLabels={this.props.defaultLabels}
      collectionName="min-budget"
      label={this.translate("minBudgetLabel")}
      iconClassName="fa fa-icon"
      value = {minBudgetData.value}
      elementDisabled = {self.props.elementsDisabled["minBudget"]}
      placeholder={minBudgetData.data["-"]}/>,
    <PexRadioSelectDropdown
      key="budget"
      onClickOpen={this.props.openCategoryDropdown}
      onSelectOption={::this.updateBudget}
      onClickOutside={this.props.clickOutside}
      open={this.props.state.isOpen}
      data={budgetData.data}
      labels={this.props.labels}
      defaultLabels={this.props.defaultLabels}
      collectionName="budget"
      iconClassName="fa fa-icon"
      label={this.translate("budgetLabel")}
      value= {budgetData.value}
      elementDisabled = {self.props.elementsDisabled["budget"]}
      placeholder={budgetData.data["-"]}/>,
    <PexRadioSelectDropdown
      key="gender"
      onClickOpen={this.props.openCategoryDropdown}
      onSelectOption={::this.updateGender}
      onClickOutside={this.props.clickOutside}
      open={this.props.state.isOpen}
      data={genderData.data}
      labels={this.props.labels}
      defaultLabels={this.props.defaultLabels}
      collectionName="gender"
      iconClassName="fa fa-icon"
      label={this.translate("genderLabel")}
      value= {genderData.value}
      placeholder={genderData.data["-"]}
      elementDisabled = {self.props.elementsDisabled["gender"]}
    />]
    ).concat([<SearchDateSelector
      searchService={this.props.searchService}
      onClickOutside={this.props.cancelChangeSearchDate}
      open={this.props.state.isOpen}
      onClickOpen={this.props.openCategoryDropdown}
      key= "startDate"
      fieldKey="date"
      className = "search-date"
      iconClassName="fa fa-calendar"
      labels={this.props.state.labels}
      label={this.translate("dateLabel")}
      defaultLabels={this.props.defaultLabels}
      translate={::this.translate}
      currentMoment= {this.props.getCurrentMoment}
      updateSearchDate = {this.updateSearchDate.bind(this)}
      value = {this.props.state.criteria.startDate}
      includeDates = {includeDates}
      elementDisabled = {self.props.elementsDisabled["startDate"]}
      setSelectedDate={this.props.setSelectedDate}
      selectedDate={this.props.selectedDate}
      cancelChangeSearchDate={this.props.cancelChangeSearchDate}
    />]);

    let searchFormClasses = classNames("search-filter-fixed",
      {"enabled-dropdowns": this.props.multiselectSearchForm === "true" });

    return <Col md={2} className={searchFormClasses}>
      <div className="fixed">
        <div className="user-menu-btns">
          <Button ref="searchButton" bsStyle="success" disabled={this.props.elementsDisabled["searchButton"]} onClick={this.props.startSearch} className="pex-search-button">{this.translate("searchButton")} <i className="fa fa-search pull-right"></i></Button>
          <LoginLogOutButton userLogged={this.props.state.userLogged} startLogout={this.props.startLogout} translate={this.translate.bind(this)} goToLoginPage={this.props.goToLoginPage}/>
        </div>
        <div className="search-button text-right top">
          <Button ref="resetSearchButton" disabled={this.props.elementsDisabled["resetButton"]} bsStyle="primary" onClick={this.props.reset} className="pex-reset-search-button"><span className="reset-search-label">{this.translate("resetSearchButton")}</span> <i className="fa fa-refresh"></i></Button>
          <Button bsStyle="default" disabled={this.props.elementsDisabled["myShortlistButton"]} onClick={this.props.linkToMyShortlist} className="pex-my-shortlist-button my-shortlist-link"><span className="refresh-btn-label">{this.translate("myShortlistButton")}</span> <i className="fa fa-star"></i></Button>
        </div>
        {this.props.error}
        {this.props.searchError}
        {dropDowns}
        <div className="search-button text-right bottom">
          <div className="search-btns">
            <Button ref="bottomSearchButton" disabled={this.props.elementsDisabled["searchButton"]} bsStyle="success" onClick={this.props.startSearch} className="bottom-pex-search-button btn-block">{this.translate("searchButton")} <i className="fa fa-search pull-right"></i></Button>
          </div>
        </div>
      </div>
    </Col>;
  }

}

function LoginLogOutButton (props) {
  if (props.userLogged) return <Button className="search-logout-button" onClick={props.startLogout} bsStyle="danger">{props.translate("logOutButton")}</Button>;
  return <Button className="search-login-button" onClick={props.goToLoginPage} bsStyle="primary">{props.translate("logInButton")}</Button>;
}
