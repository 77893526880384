import React from 'react';
import Col from 'react-bootstrap/lib/Col';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import ButtonGroup from 'react-bootstrap/lib/ButtonGroup';
import Button from 'react-bootstrap/lib/Button';
import InputGroup from 'react-bootstrap/lib/InputGroup';
import FormControl from 'react-bootstrap/lib/FormControl';
import { PexText } from '../../form-components/pex-text.jsx';
let utils = require("../../../../lib/utils");

export default class DirectDebitUserSelection extends React.Component {

  render () {
    if (!this.props.yesNoAction) return <Col sm={6}><div className="dd-user-error">Message error: No yesNoAction prop found. Error: DD10001</div></Col>;
    if (!this.props.emailChangeHandler) return <Col sm={6}><div className="dd-user-error">Message error: No emailChangeHandler prop found. Error: DD10002</div></Col>;
    let messageInfo = <div className="user-message-info"><b>If No! please enter the email address of the person registering the Direct Debit on your behalf</b></div>;
    if (this.props.webContents && this.props.webContents["dd_request.info_message"]) {
      messageInfo = <div className="user-message-info" dangerouslySetInnerHTML={{__html: utils.sanitize(this.props.webContents["dd_request.info_message"])}}></div>;
    }
    return (
      <Col sm={6} className="dd-user-select">
        <p>Will the Direct Debit be set up from an account under the tenant's name?</p>
        {messageInfo}
        <RadioElements yesNoAction={this.props.yesNoAction} optionSelected={this.props.optionSelected}/>
        {this.props.optionSelected === "no" ? <EmailContent emailChangeHandler={this.props.emailChangeHandler} form={this.props.form}/> : null}
      </Col>
    );
  }
}

function RadioElements (props) {
  let options = [];
  if (props.optionSelected === 'no') {
    options.push(<Button key ="1" className="radio-selection-click-yes" value="yes" onClick={props.yesNoAction} >YES</Button>);
    options.push(<Button key ="2" className="radio-selection-click-no active" bsStyle="primary" value="no" onClick={props.yesNoAction}>NO</Button>);
  } else {
    options.push(<Button key ="1" className="radio-selection-click-yes active" bsStyle="success" value="yes" onClick={props.yesNoAction} >YES</Button>);
    options.push(<Button key ="2" className="radio-selection-click-no" value="no" onClick={props.yesNoAction}>NO</Button>);
  }

  return (
    <div className="payer-switch">
      <ButtonGroup name="payer-switch">{options}</ButtonGroup>
    </div>
  );
}

function EmailContent (props) {

  let formProps = {
    values: props.form.values || {},
    errors: props.form.fieldsWithErrors ? props.form.fieldsWithErrors : {},
    updateField: props.emailChangeHandler,
    submitted: props.form.submitted,
  };
  return (
    <div className="email-content">
      <PexText key="email"fieldKey="email" id="email" name="email" className="user-email-selection input-group mandatory" label="" {...formProps} iconClassName="fa fa-envelope-o" placeholder="Email"/>
      <PexText key="confirmEmail" fieldKey="confirmEmail" id="confirmEmail" name="confirmEmail" className="user-email-selection-confirm input-group mandatory" label="" {...formProps} iconClassName="fa fa-envelope" placeholder="Confirm email"/>
    </div>
  );

}
