"use strict";

const getLabelsTranslated = function getLabelsTranslated (labels, component, name, defaultLabels) {
  if (labels && labels[component + name]) return labels[component + name];
  return defaultLabels[component + name];
};

module.exports = {
  getLabelsTranslated: function (labels, component, name, defaultLabels) {
    return getLabelsTranslated(labels, component, name, defaultLabels);
  },
};
