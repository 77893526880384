import React from 'react';
import ajax from '../lib/ajax.js';
import Button from 'react-bootstrap/lib/Button';
import Row from 'react-bootstrap/lib/Row';
import PexLogin from "./components/search-components/pex-login.jsx";
import PexRegister from "./components/search-components/pex-register.jsx";
import PexBookNowInfo from "./components/search-components/pex-book-now-info.jsx";
import PexSearchForm from "./components/search-components/pex-search-form.jsx";
import SearchResults from "./components/search-components/search-results.jsx";
import MyShortlist from "./components/search-components/my-shortlist.jsx";
import PexRoomDetails from "./components/search-components/pex-room-details.jsx";
import PexResetPassword from "./components/search-components/pex-reset-password.jsx";
import PexNewPassword from "./components/search-components/pex-new-password.jsx";
import PexCookiePolicy from "../legal/pex-cookie-policy.jsx";
import {ShowError} from "./components/search-components/show-error.jsx";
import {ValidateAccount} from "./components/search-components/validate-account.jsx";
import {BookingSummary} from "./components/search-components/booking-summary.jsx";
let getCurrentMoment = require('../lib/get-current-moment.js'); //TODO: how do we do an es6 import for this?
import moment from 'moment';
let api = require('../lib/api.js')(ajax);
import searchService from './services/search-service.js';
import roomService from './services/room-service.js';
import formService from './services/form-service.js';

import {injectIntl} from 'react-intl';
import TagManager from 'react-gtm-module';
let utils = require("../lib/utils");
import { createStore } from 'redux';
const defaultLabels = JSON.parse(JSON.stringify(require("../../lang/search-applet/en-GB.json"))).labels["en-GB"];


class SearchApplet extends React.Component {
  constructor (props) {
    super(props);
    let self = this;
    this.store = createStore(function (state = {}, action ) {
      // console.log(action.type);
      switch (action.type) {
        case "MERGE_STATE":
          return Object.assign({}, state, action.state);
        case "LOAD": {
          let newState = initState ();
          searchService.setIntl(self.props.intl);
          let searchAppletEvents = searchService.createSearchAppletEvents();
          newState.userLoggedInSearchAppletEvent = searchAppletEvents.userLoggedInSearchAppletEvent;
          newState.userLoggedOutSearchAppletEvent = searchAppletEvents.userLoggedOutSearchAppletEvent;
          roomService.setIntl(self.props.intl);
          newState.nextAction = "getLabels";
          newState.waiting = true;
          newState.lang = action.lang;
          newState.lang = "en-GB";
          newState.links = { book: self.props.book, enquire: self.props.enquire, arrange: self.props.arrange };
          newState.elementsDisabled = searchService.getElementsDisabled(newState.criteria, false, self.props.mandatoryStartDate === "true");
          if (self.props.lang) newState.lang = self.props.lang;
          return Object.assign({}, state, newState);
        }
        case "GET_LABELS_OK": {
          //SAVE THE FILE WITH DEFAULT VALUES IN THE STATE IN THE VARIABLE defaultLabels
          let newState = {};
          newState = {
            "pexLogin": formService.initLoginForm (action.response.labels[state.lang]),
            "pexRegister": formService.initRegisterForm (action.response.labels[state.lang]),
            "pexResetPassword": formService.initResetPasswordForm (action.response.labels[state.lang]),
            "pexNewPassword": formService.initNewPasswordForm (action.response.labels[state.lang]),
          };
          newState.pexRegister.values["noSpam"] = formService.setNoSpamBoolean(newState.pexRegister.values["noSpam"], "true");
          newState.pexRegister.isOpen = {};
          newState.pexRegister.textFilterValue = "";
          if (props.searchByParentAreas) newState.nextAction = "getParentAreas";
          else newState.nextAction = "getWebContent";
          return Object.assign({}, state, {"labels": action.response.labels[state.lang]}, newState);
        }
        case "GET_PARENT_AREAS_OK": {
          let newState = {};
          newState.parentAreas = {};
          action.response.bobs.map(function (el) {
            //only get the areas that are enabled onwebsite
            if (el.attributes.filter(function (attribute) { return attribute.name === "enabledOnWebsite" && attribute.value;}).length > 0) newState.parentAreas [el.lookupValue] = el;
          });
          return Object.assign(newState, state, { "nextAction": "getWebContent"});
        }
        case "GET_WEB_CONTENT_OK": {
          let webContents = searchService.parseWebContents(action.response);
          return Object.assign({}, state, { "nextAction": "loadListShortlist", "webContents": webContents});
        }
        case "LOAD_MY_SHORTLIST": {
          return Object.assign(state, {nextAction: "showListShortlist", "waiting": false, currentComponent: "myShortlist", "loaded": true, "data": action.data} );
        }
        case "CONFIGURATION_ERROR": {
          return Object.assign({}, state, {error: "configuration error - " + action.message});
        }
        case "SEARCH_ROOM_BEFORE_SHOW_COMPONENT": {
          let newState = {};
          newState.nextAction = "searchRoomByParam";
          if (self.getCurrentPath().component === "bookingSummary") newState.flexibleDateSelected = null;
          newState.data = JSON.parse(JSON.stringify(action.data));
          newState.initialData = JSON.parse(JSON.stringify(action.data));
          newState.criteria = searchService.processSearchFormSelectedValues (newState.data, state.criteria, self.getCurrentPath().searchCriteria, getCurrentMoment(), state.user, state.userLogged, state.initialData, props.searchByParentAreas, state.parentAreas).criteria;
          return Object.assign({}, state, newState);
        }
        case "REDIRECT_TO_SEARCH_APPLET": {
          return Object.assign({}, state, {"waiting": false, nextAction: "redirectToSearchApplet"}, action.data ? {"data": JSON.parse(JSON.stringify(action.data)), "initialData": JSON.parse(JSON.stringify(action.data))} : {});
        }
        case "SAVE_INITIAL_DATA": {
          let newState = {};
          let data = JSON.parse(JSON.stringify(action.data));
          newState.initialData = JSON.parse(JSON.stringify(action.data));
          newState.data = data;
          if (state.userLogged) newState.nextAction = "getUserInLoad";
          else newState.nextAction = "showSearchApplet";
          newState.elementsDisabled = searchService.getElementsDisabled(state.criteria, newState.initialData.settings, self.props.mandatoryStartDate === "true");
          newState.formSettings = action.formSettings;
          return Object.assign({}, state, newState);
        }
        case "SHOW_SEARCH_APPLET": {
          let newState = {};
          newState.currentComponent = "searchApplet";

          //set search criteria with url searchcriteria if we have it
          let processedData = searchService.processSearchFormSelectedValues (state.data, state.criteria, self.getCurrentPath().searchCriteria, getCurrentMoment(), state.user, state.userLogged, state.initialData, props.searchByParentAreas, state.parentAreas);
          if (processedData.someCriteria || processedData.searchcriteria === "ALL") {
            newState.criteria = processedData.criteria;
            if (processedData.searchcriteria === "ALL") {
              newState.loaded = true;
              newState.waiting = true;
              newState.nextAction = "searchAfterLoad";
              newState.sortSelected = self.getCurrentPath().sortType;
            } else {
              newState.waiting = true;
              newState.nextAction = "callOptionsWithPreselect";
            }
          } else if (self.getCurrentPath().hash) {
            newState.loaded = true;
            newState.waiting = true;
            newState.nextAction = "searchRoomByParam";
            newState.sortSelected = self.getCurrentPath().sortType;
          } else {
            newState.waiting = false;
            newState.nextAction = "DONE";
            newState.loaded = true;
          }
          newState.elementsDisabled = searchService.getElementsDisabled(newState.criteria, state.initialData.settings, self.props.mandatoryStartDate === "true");

          return Object.assign({}, state, newState);
        }
        case "ADD_TO_SHORTLIST": {
          return Object.assign({}, state, {"nextAction": "callAddShortlist", "waiting": true, "pexLogin": Object.assign(state.pexLogin, {"APIerror": null}),
            "addShortlistData": {"payload": {"offer": action.offer.lookupValue}, "offer": action.offer}});
        }
        case "ADD_TO_SHORTLIST_FROM_ACTION": {
          return Object.assign({}, state, {"nextAction": "callAddShortlist", "waiting": true, "pexLogin": Object.assign(state.pexLogin, {"APIerror": null})});
        }
        case "CALL_LISTSHORTLIST": {
          return Object.assign({}, state, {"nextAction": "callListShortlist", "error": null, "waiting": true});
        }
        case "UPDATE_SEARCH_DATA": {
          return Object.assign({}, state, { "nextAction": "DONE", "waiting": true});
        }
        case "UPDATE_SHORTLIST_IN_SEARCH_DATA": {
          let newState = {};
          //TODO: extract to its own method
          let shortlist_keys = searchService.getKeysOfOffersInShortlist(action.shortlist);
          if (self.getCurrentPath().component === "roomDetails" && state.roomDetailsData) {
            for (let offer of state.roomDetailsData.offers) {
              if (shortlist_keys && shortlist_keys[offer.lookupValue]) offer.inShortlist = true;
              else offer.inShortlist = false;
            }
          }
          if (state.searchData) {
            for (let unit of state.searchData) {
              for (let offer of unit.offers) {
                if (shortlist_keys && shortlist_keys[offer.lookupValue]) offer.inShortlist = true; else offer.inShortlist = false;
              }
            }
          }
          return Object.assign({}, state, {"nextAction": action.nextAction, "shortlist": action.shortlist, "waiting": false, "userLogged": true, "activePageShortlist": "1"}, newState);
        }
        case "REMOVE_SHORTLIST": {
          return Object.assign({}, state, {"nextAction": "callRemoveShortlist", "waiting": true, "pexLogin": Object.assign(state.pexLogin, {"APIerror": null}), "removeShortlistData": {"payload": {"offer": action.offer.lookupValue}, "offer": action.offer}});
        }
        case "REMOVE_SHORTLIST_FROM_ACTION": {
          return Object.assign({}, state, {"nextAction": "callRemoveShortlist", "waiting": true, "pexLogin": Object.assign(state.pexLogin, {"APIerror": null})});
        }
        case "REMOVE_SHORTLIST_FROM_MYSHORTLIST": {
          return Object.assign({}, state, {"waiting": true, "nextAction": "callRemoveShortlistFromMyshortlist", "removeShortlistDataFromMyShortlist": {"payload": {"offer": action.offer.lookupValue}, "offer": action.offer}});
        }
        case "LOAD_USER_NOT_LOGGED": {
          return Object.assign({}, state, {"nextAction": "initOptions", "shortlist": null, "userLogged": false});
        }
        case "USER_NOT_LOGGED_MY_SHORTLIST": {
          return Object.assign({}, state, {"nextAction": "DONE", "shortlist": null, "waiting": false, "userLogged": false});
        }
        case "SHOW_SHORTLIST": {
          return Object.assign({}, state, {"nextAction": "loadListShortlist", "waiting": true, "currentComponent": "myShortlist"});
        }
        case "SHOW_SHORTLIST_FROM_REGISTER": {
          return Object.assign({}, state, {"nextAction": "loadListShortlistFromRegister", "currentComponent": "myShortlist", "waiting": true});
        }
        case "SORT_RESULT": {
          return Object.assign({}, state, {sortSelected: state.sortSelected, nextAction: "DONE", waiting: false,
            searchData: searchService.sortBy(state.searchData, state.sortSelected, state.pagination, state.searchSettings, state.formSettings,
              state.links, state.labels, defaultLabels)});
        }
        case "SEARCH_SIMILAR": {
          return Object.assign({}, state, {"currentComponent": null, "waiting": true, "nextAction": "searchRoomByParam" });
        }
        case "LOGIN_ERROR": {
          return Object.assign({}, state, {currentComponent: "login", waiting: false, nextAction: "DONE", pexLogin: Object.assign(state.pexLogin, {"APIerror": action.error})});
        }
        case "BOOK_NOW_AFTER_LOGIN":
        {
          let newState = { "pexLogin": state.pexLogin };
          newState.nextAction = "bookNow";
          newState.waiting = "true";
          if (!state.bookNowData) {
            newState.bookNowData = {
              "offerLookupValue": state.roomDetailsData.offer.lookupValue,
              "startDate": state.roomDetailsData.offer.start,
              "link": state.roomDetailsData.room.bookNowLink,
            };
          }
          newState.pexLogin.APIerror = undefined;
          return Object.assign({}, state, newState);
        }
        case "HANDLE_BOOK_NOW": {
          let newState = {};
          newState.nextAction = "bookNow";
          newState.waiting = true;
          newState.bookNowData = {"offerLookupValue": action.offerLookupValue, "startDate": action.startDate, "room": action.room, "link": action.room.bookNowLink, "offer": action.offer};
          newState.areaForGetRoomCollections = {"area": action.area};
          return Object.assign({}, state, newState);
        }
        case "GO_TO_LOGIN_PAGE": {
          let newState = {};
          if (action.data) newState.data = action.data;
          if (!state.initialData && action.data) newState.initialData = action.data;
          newState.nextAction = "DONE";
          newState.waiting = false;
          newState.loaded = true;
          newState.currentComponent = "login";
          return Object.assign({}, state, newState);
        }
        case "BOOK_NOW_MESSAGE": {
          return Object.assign({}, state, {currentComponent: "bookNowInfo", nextAction: "DONE", waiting: false, bookNowData: action.bookNowData});
        }
        case "SEARCH_AFTER_LOAD": {
          return Object.assign({}, state, {data: action.data, nextAction: "searchAfterLoad", sortSelected: action.sortType, "loaded": true});
        }
        case "SEARCH_ROOM_BY_PARAM_DONE":
        {
          let newState = {
            "currentComponent": self.getCurrentPath().component,
          };
            // dont think I need this
          if (self.getCurrentPath().component === "register") {
            newState.nextAction = "callGetFormCollections";
          } else if (self.getCurrentPath().component === "bookingSummary") {
            if (action.roomSearch) {
              newState.bookNowData = {"offerLookupValue": self.getCurrentPath().offer, "room": action.roomSearch.roomDetailsData.room, "offer": action.roomSearch.roomDetailsData.offer};
            } else {
              newState.bookNowData = {"error": true};
            }
            newState.waiting = false;
            newState.nextAction = "DONE";
            newState.loaded = true;
          }
          else {
            newState.waiting = false;
            newState.nextAction = "DONE";
            newState.loaded = true;
          }
          if (action.roomSearch) {
            newState.searchData = action.roomSearch.searchData;
            if (action.roomSearch.roomDetailsData) {
              newState.roomDetailsData = action.roomSearch.roomDetailsData;
              if (action.roomSearch.roomDetailsData.room.area) newState.areaForGetRoomCollections = { "area": action.roomSearch.roomDetailsData.room.area };
            }
          } else {
            newState.searchData = [];
          }
          return Object.assign({}, state, newState);
        }
        case "START_LOGIN": {
          return Object.assign({}, state, {"waiting": true, "nextAction": "startLogin"});
        }
        case "START_VALIDATE_ACCOUNT": {
          return Object.assign({}, state, {"waiting": true, "nextAction": "validateAccount"});
        }
        case "START_REGISTER_USER": {
          return Object.assign({}, state, {"waiting": true, "nextAction": "registerUser"});
        }
        case "START_UPDATE_PERSON_DETAILS": {
          return Object.assign({}, state, {"waiting": true, "nextAction": "updatePersonDetails"});
        }
        case "SET_LOGIN_FORM_SUBMITTED_WITH_ERROR": {
          let new_state = { "pexLogin": state.pexLogin };
          new_state.pexLogin.submitted = true;
          return Object.assign({}, state, new_state);
        }
        case "SET_REGISTER_FORM_SUBMITTED_WITH_ERROR": {
          let new_state = { "pexRegister": state.pexRegister };
          new_state.pexRegister.submitted = true;
          return Object.assign({}, state, new_state);
        }
        case "GO_TO_REGISTER": {
          return Object.assign({}, state, { waiting: true, "nextAction": "callGetFormCollections"});
        }
        case "START_LOST_PASSWORD": {
          return Object.assign({}, state, {"waiting": true, "nextAction": "lostPassword"});
        }
        case "START_LOST_PASSWORD_CONFIRMATION": {
          return Object.assign({}, state, {"waiting": true, "nextAction": "lostPasswordConfirmation"});
        }
        case "CALL_GET_FORM_COLLECTION_OK": {
          let newState = {};
          newState.loaded = true;
          let countries = action.getFormCollectionsResponse.countries.map(function (country) {
            let obj = {};
            obj.lookupValue = country.lookupValue;
            obj.diallingCode = country.diallingCode;
            obj.displayValue = country.displayValue + " (" + country.diallingCode + ")";
            return obj;
          }).filter(function (el) {return el.diallingCode && Number.isInteger(Number(el.diallingCode.substring(0, 1)));});
          if (state.pexRegister && state.pexRegister.values && state.pexRegister.values.gender === "" && state.criteria.gender && (state.criteria.gender === "F" || state.criteria.gender === "M")) {
            state.pexRegister.values.gender = state.criteria.gender;
          }
          return Object.assign({}, state, newState, {"waiting": false, "getFormCollectionsResponse": action.getFormCollectionsResponse, "currentComponent": "register", "nextAction": "DONE", "countries": countries});
        }
        case "UPDATE_LOGIN_FORM": {
          let newState = { "pexLogin": state.pexLogin};
          newState.pexLogin.values[action.field] = action.value;
          newState.pexLogin = Object.assign(newState.pexLogin, formService.checkForm(newState.pexLogin.values, newState.pexLogin.formNames, state.labels, "searchApplet"));
          return Object.assign(state, newState);
        }
        case "UPDATE_REGISTER_FORM": {
          let newState = { "pexRegister": state.pexRegister};
          newState.pexRegister.values[action.field] = action.value;
          newState.pexRegister = Object.assign(newState.pexRegister, formService.checkForm(newState.pexRegister.values, newState.pexRegister.formNames, state.labels, "searchApplet"));
          return Object.assign(state, newState);
        }
        case "UPDATE_BOOLEAN": {
          let newState = { "pexRegister": state.pexRegister};
          newState.pexRegister.values[action.field] = formService.setNoSpamBoolean(newState.pexRegister.values[action.field], action.value);
          newState.pexRegister = Object.assign(newState.pexRegister, formService.checkForm(newState.pexRegister.values, newState.pexRegister.formNames, state.labels, "searchApplet"));
          return Object.assign(state, newState);
        }
        case "API_ERROR": {
          return Object.assign({}, state, {"nextAction": "DONE", "error": action.error, "waiting": action.waiting});
        }
        case "ERROR_LOADING_ROOM_DETAILS": {
          return Object.assign({}, state, {"nextAction": "DONE", "currentComponenet": "roomDetails", "error": action.error, "waiting": false});
        }
        case "SHOW_FULL_SCREEN_ERROR": {
          return Object.assign({}, state, {"nextAction": "DONE", "error": action.error, currentComponent: "showError", "waiting": false});
        }
        case "API_SEARCH_ERROR": {
          return Object.assign({}, state, {"nextAction": "DONE", "searchError": action.searchError, "waiting": action.waiting });
        }
        case "UPDATE_LOST_PASSWORD_FORM": {
          let newState = { "pexResetPassword": state.pexResetPassword};
          newState.pexResetPassword.values[action.field] = action.value;
          newState.pexResetPassword = Object.assign(newState.pexResetPassword, formService.checkForm(newState.pexResetPassword.values, newState.pexResetPassword.formNames, state.labels, "searchApplet"));
          return Object.assign(state, newState);
        }
        case "UPDATE_LOST_PASSWORD_CONFIRMATION_FORM": {
          let newState = { "pexNewPassword": state.pexNewPassword};
          newState.pexNewPassword.values[action.field] = action.value;
          newState.pexNewPassword = Object.assign(newState.pexNewPassword, formService.checkForm(newState.pexNewPassword.values, newState.pexNewPassword.formNames, state.labels, "searchApplet"));
          return Object.assign(state, newState);
        }
        //TODO: unifiy these search error dispatchers
        case "BUTTON_SEARCH_ERROR": {
          return Object.assign({}, state, {"nextAction": "DONE", "waiting": false, "searchError": "api error 43259235"});
        }
        case "LOAD_REGISTER_FORM": {
          return Object.assign({}, state, {"currentComponent": "register", "nextAction": "loadRegisterForm"});
        }
        case "REGISTER_FORM_LOADED": {
          return Object.assign({}, state, {"currentComponent": "register", "nextAction": "DONE"});
        }
        case "START_SEARCH": {
          return Object.assign({}, state, {"waiting": true, searchError: undefined, "nextAction": "search"});
        }
        case "SEARCH_OK": {
          return Object.assign({}, state, {"waiting": false, "searchData": action.searchData, "nextAction": "DONE", "activePage": "1", "searchSettings": action.searchSettings});
        }
        case "CHANGE_CURRENT_COMPONENT": {
          return Object.assign({}, state, {"currentComponent": action.currentComponent, "nextAction": "DONE", "waiting": false, "userNotLogged": action.userNotLogged, "loaded": true});
        }
        case "CHANGE_ACTIVE_PAGE": {
          let newState = {};
          if (state.currentComponent === "myShortlist") newState.activePageShortlist = action.activePage;
          else newState.activePage = action.activePage;
          return Object.assign({}, state, newState);
        }
        case "GO_TO_ROOM_DETAILS": {
          return Object.assign({}, state, {"currentComponent": "roomDetails", "roomDetailsData": action.roomDetailsData});
        }
        case "START_GET_OPTIONS": {
          return Object.assign({}, state, {"waiting": true, error: undefined, "nextAction": "getOptions"});
        }
        case "UPDATE_BUDGET": {
          let newState = {};
          newState.criteria = state.criteria;
          newState.criteria.budget = action.budget;
          newState.nextAction = "startGetOptions";
          newState.isOpen = "";
          newState.elementsDisabled = searchService.getElementsDisabled(newState.criteria, state.initialData.settings, self.props.mandatoryStartDate === "true");
          return Object.assign({}, state, newState);
        }
        case "UPDATE_MIN_BUDGET": {
          let newState = {};
          newState.criteria = state.criteria;
          newState.criteria.minBudget = action.minBudget;
          newState.nextAction = "startGetOptions";
          newState.isOpen = "";
          newState.elementsDisabled = searchService.getElementsDisabled(newState.criteria, state.initialData.settings, self.props.mandatoryStartDate === "true");
          return Object.assign({}, state, newState);
        }
        case "UPDATE_GENDER": {
          let newState = {};
          newState.criteria = state.criteria;
          newState.criteria.gender = action.gender;
          newState.nextAction = "startGetOptions";
          newState.isOpen = "";
          if (state.data.settings && state.data.settings["booking.strictGenders"] && action.gender === "-") {
            newState.nextAction = "DONE";
          }
          newState.elementsDisabled = searchService.getElementsDisabled(newState.criteria, state.initialData.settings, self.props.mandatoryStartDate === "true");
          return Object.assign({}, state, newState);
        }
        case "CATEGORY_CLICK": {
          let newState = {};
          if (props.searchByParentAreas && action.arrayName === "PARENT-AREA") {
            //THIS METHOD HAS TO CHANGE WHEN THE arrayName be parentAreas, to return to the criteria every tags of areas of this parent
            newState.criteria = searchService.updateParentArea(JSON.parse(JSON.stringify(state.criteria)), action.elementLookupValue, action.selected, state.initialData);
          } else {
            //THIS METHOD HAS TO CHANGE WHEN THE arrayName be parentAreas, to return to the criteria every tags of areas of this parent
            newState.criteria = searchService.updateCategory(JSON.parse(JSON.stringify(state.criteria)), action.elementLookupValue, action.arrayName, action.selected);
          }
          newState.nextAction = "startGetOptions";
          newState.elementsDisabled = searchService.getElementsDisabled(newState.criteria, state.initialData.settings, self.props.mandatoryStartDate === "true");
          return Object.assign({}, state, newState);
        }
        case "COLLECTION_OPTION_CLICK": {
          let newState = {};
          newState.criteria = searchService.updateCollection(JSON.parse(JSON.stringify(state.criteria)), action.elementLookupValue, action.arrayName, action.selected);
          newState.nextAction = "startGetOptions";
          newState.elementsDisabled = searchService.getElementsDisabled(newState.criteria, state.initialData.settings, self.props.mandatoryStartDate === "true");
          return Object.assign({}, state, newState);
        }
        case "RESET_SEARCH": {
          let newState = {};
          newState.criteria = { "categories": {}, "minBudget": "-", "budget": "-", "bookingTypes": "", "startDate": "" };
          if (state.userLogged) {
            newState.criteria.gender = state.criteria.gender;
          } else {
            newState.criteria.gender = "-";
          }
          newState.hash = null;
          newState.selectedDate = null;
          newState.searchData = undefined;
          newState.data = state.initialData;
          newState.elementsDisabled = searchService.getElementsDisabled(newState.criteria, state.initialData.settings, self.props.mandatoryStartDate === "true");
          return Object.assign({}, state, newState);
        }
        case "UPDATE_SEARCH_DATE": {
          let newState = {};
          newState.criteria = state.criteria;
          newState.criteria.startDate = moment(action.searchDate).format("YYYY-MM-DD");
          newState.nextAction = "startGetOptions";
          newState.waiting = true;
          newState.isOpen = "";
          newState.elementsDisabled = searchService.getElementsDisabled(newState.criteria, state.initialData.settings, self.props.mandatoryStartDate === "true");
          return Object.assign({}, state, newState);
        }
        case "GET_OPTIONS_ERROR": {
          return Object.assign({}, state, {waiting: false, error: "api error 2373828F", "nextAction": "DONE"});
        }
        case "GET_OPTIONS_OK": {
          let newState = {
            "data": action.data,
            "error": undefined,
            "searchError": undefined,
            "nextAction": "search",
          };
          if (state.elementsDisabled.stopSearch) {
            newState.nextAction = "DONE";
            newState.waiting = false;
          }
          newState.formSettings = action.formSettings;
          return Object.assign({}, state, newState);
        }
        case "UPDATE_SORT_SELECTED": {
          return Object.assign({}, state, {"waiting": true, "sortSelected": action.sortSelected, "nextAction": "sortSearch"});
        }
        case "REGISTER_USER_OK": {
          let newState = { "pexRegister": state.pexRegister };
          if (newState.pexRegister.APIerror) newState.pexRegister.APIerror = undefined;
          newState.nextAction = "loginAfterRegister" ;
          return Object.assign({}, state, newState);
        }
        case "REGISTER_USER_KO": {
          let newState = { "pexRegister": state.pexRegister };
          //IMPORTANT: now we receive the messageType as well, we have to make a refactor(PMX-165) to apply a common logic for API error messages
          let error = searchService.getError(action.message, action.messageType, state.webContents, state.labels, "register_page.", "searchApplet.customAPIMessage.");
          newState.pexRegister.APIerror = error;
          newState.waiting = false;
          newState.nextAction = "DONE";
          return Object.assign({}, state, newState);
        }
        case "DATA_TO_VALIDATE_ACCOUNT": {
          let newState = {};
          newState.waiting = false;
          newState.nextAction = "DONE";
          newState.currentComponent = "validateAccount";
          newState.validateAccountAction = self.getCurrentPath().action;
          newState.userLoggedFrom = action.userLoggedFrom;
          return Object.assign({}, state, newState);
        }
        case "UPDATE_PERSON_DETAILS_OK": {
          let newState = { "pexRegister": state.pexRegister };
          if (newState.pexRegister.APIerror) newState.pexRegister.APIerror = undefined;
          newState.nextAction = "actionAfterLoginOrRegister" ;
          return Object.assign({}, state, newState);
        }
        case "UPDATE_PERSON_DETAILS_KO": {
          let newState = { "pexRegister": state.pexRegister };
          //IMPORTANT: now we receive the messageType as well, we have to make a refactor(PMX-165) to apply a common logic for API error messages
          let error = searchService.getError(action.message, action.messageType, state.webContents, state.labels, "register_page.", "searchApplet.customAPIMessage.");
          newState.pexRegister.APIerror = error;
          newState.waiting = false;
          newState.nextAction = "DONE";
          return Object.assign({}, state, newState);
        }
        case "SHOW_LOST_PASSWORD": {
          return Object.assign({}, state, {"currentComponent": "resetPassword", "showResetPasswordMessage": false, "nextAction": "DONE", "waiting": false, "loaded": true}, action.data ? {"data": JSON.parse(JSON.stringify(action.data)), "initialData": JSON.parse(JSON.stringify(action.data))} : {});
        }
        case "LOST_PASSWORD_OK": {
          return Object.assign({}, state, {"showResetPasswordMessage": true, "waiting": false, "nextAction": "DONE"});
        }
        case "LOST_PASSWORD_KO": {
          let error = searchService.getError(action.message, action.messageType, state.webContents, state.labels, "resetPassword_page.", "searchApplet.customAPIMessage.");
          return Object.assign({}, state, {"resetPasswordError": error, "waiting": false, "nextAction": "DONE"});
        }
        case "LOST_PASSWORD_SUBMITTED_WITH_ERRORS": {
          return Object.assign({}, state, {"pexResetPassword": Object.assign(state.pexResetPassword, Object.assign(state.pexResetPassword, {"submitted": true}))});
        }
        case "LOST_PASSWORD_CONFIRMATION_OK": {
          return Object.assign({}, state, {"nextAction": "goToLoginPage", "waiting": true});
        }
        case "LOST_PASSWORD_CONFIRMATION_KO": {
          return Object.assign({}, state, {"newPasswordError": action.error, "waiting": false, "nextAction": "DONE"});
        }
        case "LOST_PASSWORD_CONFIRMATION_SUBMITTED_WITH_ERRORS": {
          return Object.assign({}, state, {"pexNewPassword": Object.assign(state.pexNewPassword, Object.assign(state.pexNewPassword, {"submitted": true}))});
        }
        case "SHOW_LOST_PASSWORD_CONFIRMATION": {
          return Object.assign({}, state, {"currentComponent": "newPassword", "newPasswordError": null, "nextAction": "DONE", "waiting": false, "loaded": true}, action.data ? {"data": JSON.parse(JSON.stringify(action.data)), "initialData": JSON.parse(JSON.stringify(action.data))} : {});
        }
        case "NORMAL_LOGIN": {
          return Object.assign({}, state, {"currentComponent": "searchApplet", "nextAction": "DONE", "waiting": false});
        }
        case "START_LOGOUT": {
          return Object.assign({}, state, {"nextAction": "logout", "waiting": true});
        }
        case "OPEN_CLOSE_CATEGORY_DROPDOWN": {
          let newState = {};
          if (state.isOpen && state.isOpen.length > 0 && state.isOpen === action.collectionName) newState.isOpen = "";
          else newState.isOpen = action.collectionName;
          // if (action.collectionName === "date" && newState.isOpen === "") newState.selectedDate = state.criteria.startDate;
          return Object.assign(state, newState);
        }
        case "CLICK_OUTSIDE": {
          return Object.assign(state, {"isOpen": ""});
        }
        case "USER_LOGGED_OK": {
          let newState = {};
          if (action.data) {
            newState.data = action.data;
            newState.initialData = JSON.parse(JSON.stringify(action.data));
          }
          return Object.assign({}, state, {"userLogged": true, "nextAction": "getUser"}, newState);
        }
        case "GET_USER_OK": {
          let pexRegister = formService.updateRegisterForm(action.getUserResponse.user);
          let newState = {"pexRegister": pexRegister};
          newState.pexRegister = Object.assign(newState.pexRegister, formService.checkForm(newState.pexRegister.values, newState.pexRegister.formNames, state.labels, "searchApplet"));
          newState.isOpen = "";
          newState.pexRegister.textFilterValue = state.pexRegister.textFilterValue;
          newState.pexRegister.submitted = true;
          newState.nextAction = "getShortListAfterLogin";
          newState.user = action.getUserResponse.user;
          newState.criteria = state.criteria;
          if (state.data.settings && state.data.settings["booking.strictGenders"] && newState.user.gender && newState.user.gender.lookupValue) newState.criteria.gender = newState.user.gender.lookupValue;
          return Object.assign({}, state, newState);
        }
        case "GET_USER_IN_LOAD_OK": {
          let newState = {};
          newState.user = action.getUserResponse.user;
          newState.nextAction = "showSearchApplet";
          return Object.assign({}, state, newState);
        }
        case "GET_SHORTLIST_AFTER_LOGIN_OK": {
          let nextAction = "getFormCollectionsToUpdateUser";
          if (state.searchData) {
            let shortlist_keys = searchService.getKeysOfOffersInShortlist(action.shortlist);
            for (let unit of state.searchData) {
              for (let offer of unit.offers) {
                if (shortlist_keys && shortlist_keys[offer.lookupValue]) offer.inShortlist = true; else offer.inShortlist = false;
              }
            }
          }
          if (state.pexRegister.canSubmit) nextAction = "actionAfterLoginOrRegister";
          return Object.assign({}, state, { "shortlist": action.shortlist, "nextAction": nextAction});
        }
        case "GET_FORM_COLLECTION_TO_UPDATE_PERSON_DETAILS_OK": {
          let countries = action.getFormCollectionsResponse.countries.map(function (country) {
            let obj = {};
            obj.lookupValue = country.lookupValue;
            obj.diallingCode = country.diallingCode;
            obj.displayValue = country.displayValue + " (" + country.diallingCode + ")";
            return obj;
          }).filter(function (el) {return el.diallingCode && Number.isInteger(Number(el.diallingCode.substring(0, 1)));});
          return Object.assign({}, state, {"nextAction": "DONE", "currentComponent": "updatePersonDetails", "waiting": false, "getFormCollectionsResponse": action.getFormCollectionsResponse, "loaded": true, "countries": countries});
        }
        case "SELECT_COUNTRY_REGISTER": {
          let newState = {"pexRegister": state.pexRegister};
          newState.pexRegister.countrySelected = action.countrySelected;
          //If the country has not dialling code we set it with the string 'undefined' to show this string in the dropdown to identify this value is not in the database
          newState.pexRegister.values["mobileCountry"] = action.countrySelected.diallingCode;
          newState.pexRegister = Object.assign(newState.pexRegister, formService.checkForm(newState.pexRegister.values, newState.pexRegister.formNames, state.labels, "searchApplet"));
          newState.isOpen = "";
          newState.pexRegister.textFilterValue = "";
          return Object.assign(state, newState);
        }
        case "OPEN_CLOSE_DROPDOWN_REGISTER": {
          let newState = { "pexRegister": state.pexRegister };
          newState.isOpen = action.collectionName;
          newState.pexRegister.textFilterValue = "";
          if (state.isOpen && state.isOpen.length > 0 && state.isOpen === action.collectionName) newState.isOpen = "";
          else newState.isOpen = action.collectionName;
          return Object.assign(state, newState);
        }
        case "SEARCH_FILTER_HANDLER_REGISTER": {
          let newState = { "pexRegister": state.pexRegister };
          newState.pexRegister.textFilterValue = action.value;
          return Object.assign(state, newState);
        }
        case "SELECTED_DATE_IN_SEARCH_DATEPICKER": {
          let newState = {};
          newState.selectedDate = action.selectedDate;
          return Object.assign(state, newState);
        }
        case "CANCEL_CHANGE_SEARCH_DATE": {
          let newState = {};
          newState.selectedDate = null;
          newState.isOpen = "";
          return Object.assign(state, newState);
        }
        case "SHOW_BOOKING_SUMMARY": {
          let newState = { "waiting": false, "loaded": true, "flexibleDateSelected": null, "nextAction": "DONE", "currentComponent": "bookingSummary", "areaForGetRoomCollections": {"area": action.room.area} };
          if (action.data) newState.data = action.data;
          newState.bookNowData = {"offer": action.offer, "room": action.room, "offerLookupValue": action.offer.lookupValue};
          return Object.assign(state, newState);
        }
        case "UPDATE_BOOK_DATE": {
          let newState = {};
          newState.flexibleDateSelected = action.date;
          newState.bookNowData = {"offerLookupValue": state.bookNowData.offerLookupValue, "room": state.bookNowData.room, "offer": state.bookNowData.offer};
          return Object.assign(state, newState);
        }
        default:
          return state;
      }
    }, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
    this.state = {};

    if (props.gtmId) {
      let tagManagerArgs = { "gtmId": props.gtmId};
      TagManager.initialize(tagManagerArgs);
    }
  }

  componentWillUnmount () {
    //I think I could have just put
    //this.componentWillUnmount = this.store.subscribe ...
    //above.  that would have been pretty cool
    this.unsubscribe();
  }

  render () {
    if (!this.state.loaded) {
      if (this.state.error) { //TODO: rename error optionsError since search has its own
        return <div>{this.state.error}</div>;
      }
      return <div>just a sec</div>;
    }

    if (this.state.currentComponent && this.state.currentComponent === "showError") {
      return <ShowError error={this.state.error} />;
    } else if (this.state.currentComponent && this.state.currentComponent === "login") {
      return <div className={(this.state.waiting) ? "divFrozen container-fluid" : "container-fluid"}>
        <PexLogin
          updateLoginForm={::this.updateLoginForm}
          data={this.state.pexLogin}
          getCurrentPath={::this.getCurrentPath}
          login={::this.login}
          register={::this.register}
          labels={this.state.labels}
          defaultLabels= {defaultLabels}
          goToResetPassword={::this.loadResetPassword}
        />
      </div>;
    } else if (this.state.currentComponent && (this.state.currentComponent === "register" || this.state.currentComponent === "updatePersonDetails" )) {
      let registerOpts = {
        "updateRegisterForm": ::this.updateRegisterForm,
        "updateBoolean": ::this.updateBoolean,
        "startRegisterUser": ::this.startRegisterUser,
        "startUpdatePersonDetails": ::this.startUpdatePersonDetails,
        "backToLogin": ::this.backToLogin,
        "state": this.state,
        "data": this.state.pexRegister,
        "universitiesFilteredByLocation": this.props.universitiesFilteredByLocation,
        "startLogout": ::this.startLogout,
        "openCloseDropdown": ::this.openCloseDropdownRegister,
        "clickOutside": ::this.clickOutside,
        "onSearchTextHandler": ::this.onSearchTextHandlerRegister,
        "selectCountry": ::this.selectCountryRegister,
        "defaultLabels": defaultLabels,
      };
      return <div className={(this.state.waiting) ? "divFrozen container-fluid" : "container-fluid"}>
        <PexRegister {...registerOpts} />
      </div>;
    } else if (this.state.currentComponent && this.state.currentComponent === "bookNowInfo") {
      return <div className={(this.state.waiting) ? "divFrozen container-fluid" : "container-fluid"}>
        <PexBookNowInfo data={this.state.bookNowData}
          timeOutBook={this.props.timeOutBook}
          webContents={this.state.webContents}
          defaultLabels={defaultLabels}
          labels={this.state.labels}
          redirectToSearchApplet={this.redirectToSearchApplet.bind(this)}
        />
      </div>;
    } else if (this.state.currentComponent && this.state.currentComponent === "roomDetails") {
      return <div className={(this.state.waiting) ? "divFrozen container-fluid" : "container-fluid"}>
        <PexRoomDetails searchService = {searchService}
          defaultLabels={defaultLabels}
          labels={this.state.labels}
          handleBookNow={this.handleBookNow.bind(this)}
          addShortlist={this.addShortlist.bind(this)}
          removeShortlist={this.removeShortlist.bind(this)}
          redirectToSearchApplet={this.redirectToSearchApplet.bind(this)}
          unit={this.state.roomDetailsData}
          from={this.getCurrentPath().from}
          userLogged={this.state.userLogged}
          goToOfferTable={this.goToOfferTable.bind(this)}
          showbookingSummary={this.showbookingSummary.bind(this)}
        />
      </div>;
    } else if (this.state.currentComponent && this.state.currentComponent === "myShortlist") {
      let myshortlistData = searchService.prepareSearchResult (JSON.parse(JSON.stringify(this.state.shortlist)), this.props.pagination, this.state);

      let myShortlistProps = {
        "shortlistData": myshortlistData,
        "linkTemplates": this.state.links,
        "activePageShortlist": this.state.activePageShortlist,
        "pagination": this.props.pagination,
        "shortlist": this.state.shortlist,
        "addShortlist": ::this.addShortlist,
        "removeShortlist": ::this.removeShortlistFromMyShortlist,
        "userLogged": this.state.userLogged,
        "error": this.state.error,
        "searchSimilar": ::this.searchSimilar,
        "searchService": searchService,
        "selectPaginationPage": ::this.selectPaginationPage,
        "handleBookNow": ::this.handleBookNow,
        "goToRoomDetails": ::this.goToRoomDetails,
        "labels": this.state.labels,
        "defaultLabels": defaultLabels,
        "showbookingSummary": ::this.showbookingSummary,
      };
      return <div className={(this.state.waiting) ? "divFrozen container-fluid" : "container-fluid"}>
        <MyShortlist {...myShortlistProps} />
      </div>;
    } else if (this.state.currentComponent && this.state.currentComponent === "resetPassword") {
      return <div className={(this.state.waiting) ? "divFrozen container-fluid" : "container-fluid"}>
        <PexResetPassword updateResetPasswordForm={::this.updateResetPasswordForm}
          data={this.state.pexResetPassword}
          startLostPassword={::this.startLostPassword}
          showResetPasswordMessage={this.state.showResetPasswordMessage}
          resetPasswordError={this.state.resetPasswordError}
          labels={this.state.labels}
          defaultLabels= {defaultLabels}
        />
      </div>;
    } else if (this.state.currentComponent && this.state.currentComponent === "newPassword") {
      return <div className={(this.state.waiting) ? "divFrozen container-fluid" : "container-fluid"}>
        <PexNewPassword updateNewPasswordForm={::this.updateNewPasswordForm}
          data={this.state.pexNewPassword}
          startLostPasswordConfirmation={::this.startLostPasswordConfirmation}
          newPasswordError={this.state.newPasswordError}
          lostPasswordConfirmationUsername={this.getCurrentPath().username}
          lostPasswordConfirmationVerificationId={this.getCurrentPath().verificationId}
          labels={this.state.labels}
          defaultLabels= {defaultLabels}/>
      </div>;
    } else if (this.state.currentComponent && this.state.currentComponent === "validateAccount") {
      return <div className={(this.state.waiting) ? "divFrozen container-fluid" : "container-fluid"}>
        <ValidateAccount startValidateAccount={::this.startValidateAccount}
          defaultLabels= {defaultLabels}
          labels={this.state.labels}
          validateAccountAction={this.state.validateAccountAction}/>
      </div>;
    } else if (this.state.currentComponent && this.state.currentComponent === "bookingSummary") {
      return <div className={(this.state.waiting) ? "divFrozen container-fluid" : "container-fluid"}>
        <BookingSummary state={this.state}
          searchService={searchService}
          defaultLabels= {defaultLabels}
          handleBookDate={this.handleBookDate.bind(this)}
          handleBookNow={this.handleBookNow.bind(this)}
          redirectToSearchApplet={this.redirectToSearchApplet.bind(this)}/>
      </div>;
    }
    else {
      let searchFormProps = {
        "state": this.state,
        "pagination": this.props.pagination,
        "startSearch": ::this.startSearch,
        "startGetOptions": ::this.startGetOptions,
        "updateBudget": ::this.updateBudget,
        "updateMinBudget": ::this.updateMinBudget,
        "updateGender": ::this.updateGender,
        "categoryClick": ::this.categoryClick,
        "reset": ::this.reset,
        "updateSearchDate": ::this.updateSearchDate,
        "getCurrentMoment": getCurrentMoment(),
        "goToLoginPage": ::this.goToLoginPage,
        "startLogout": ::this.startLogout,
        "multiselectSearchForm": this.props.multiselectSearchForm,
        "openCategoryDropdown": ::this.openCategoryDropdown,
        "clickOutside": ::this.clickOutside,
        "collectionOptionClick": ::this.collectionOptionClick,
        "defaultLabels": defaultLabels,
        "searchService": searchService,
        "setSelectedDate": ::this.setSelectedDate,
        "selectedDate": this.state.selectedDate,
        "cancelChangeSearchDate": ::this.cancelChangeSearchDate,
        "elementsDisabled": this.state.elementsDisabled,
      };
      searchFormProps.error = this.state.error ? <APIError retryMethod={::this.retry} /> : null;
      searchFormProps.searchError = this.state.searchError ? <APIError /> : null;

      //TODO: probably we have not to set the prop linkTemplates and to use directly when the element is clicked because now we set the method.
      let searchResultsProps = {
        "searchData": this.state.searchData,
        "linkTemplates": this.state.links,
        "pagination": this.props.pagination,
        "strictGender": this.state && this.state.initialData && this.state.initialData.settings && this.state.initialData.settings["booking.strictGenders"] && this.state.initialData.settings["booking.strictGenders"] === true,
        "shortlist": this.state.shortlist,
        "addShortlist": ::this.addShortlist,
        "removeShortlist": ::this.removeShortlist,
        "activePage": this.state.activePage,
        "selectSearch": ::this.selectSearch,
        "sortSelected": this.state.sortSelected,
        "criteria": this.state.criteria,
        "webContents": this.state.webContents,
        "searchService": searchService,
        "selectPaginationPage": ::this.selectPaginationPage,
        "handleBookNow": ::this.handleBookNow,
        "goToRoomDetails": ::this.goToRoomDetails,
        "labels": this.state.labels,
        "defaultLabels": defaultLabels,
        "showbookingSummary": ::this.showbookingSummary,
      };
      //<div>state: <pre>{JSON.stringify(this.state, null, 2)}</pre></div>
      //TODO: try to use just one "waiting" variable. If we see that sometimes we need 2 because there are operations that finishs the process sooner and other later.
      //we will manage it with conditions
      return <div className={(this.state.waiting || this.state.waiting || this.state.waiting || this.state.waiting) ? "divFrozen container-fluid" : "container-fluid"}>
        <Row className="">
          <PexSearchForm ref="searchForm" {...searchFormProps} linkToMyShortlist={this.linkToMyShortlist.bind(this)} />
          <SearchResults {...searchResultsProps} />
          <PexCookiePolicy colMd={12} />
        </Row>
      </div>;
    }

  }

  componentDidMount () {
    this.unsubscribe = this.store.subscribe(function () {
      ///TODO: find a way to wrap up this code since we are using it in multiple components
      let state = {};
      for (let key of Object.keys(this.state)) state[key] = null; //we have to do this because replaceState no longer exists
      this.setState(Object.assign({}, state, this.store.getState()));
      //instead of setting the state to the redux store state, if we want to pass
      //access the redux store directly, we can replace setState with this.forceUpdate
    }.bind(this));

    if (!this.props.api) {
      this.store.dispatch({type: "CONFIGURATION_ERROR", message: "no api specified"});
      return;
    }
    if (!this.props.book) {
      this.store.dispatch({type: "CONFIGURATION_ERROR", message: "no book url specified"});

      return;
    }
    if (!this.props.enquire) {
      this.store.dispatch({type: "CONFIGURATION_ERROR", message: "no enquire url specifiedd"});
      return;
    }
    if (!this.props.sso) {
      this.store.dispatch({type: "CONFIGURATION_ERROR", message: "no sso specified"});
      return;
    }

    if (this.props.pagination) {
      if (!Number.isInteger(Number(this.props.pagination))) {
        this.store.dispatch({type: "CONFIGURATION_ERROR", message: "pagination attribute is not integer"});
        return;
      }
    }

    if (!this.props.arrange) {
      this.store.dispatch({type: "CONFIGURATION_ERROR", message: "no arrange url specified"});
      return;
    }

    if (this.props.preselect) {
      this.store.dispatch({type: "CONFIGURATION_ERROR", message: "preselect attribute no longer exist; use url"});
    }
    //NOTE: De default time is 5 seconds, but we can decrease the time for e2e tests with this attribute between 1 to 6000 milliseconds, before the auto redirection to the book link
    if (this.props.timeOutBook && !Number.isInteger(Number(this.props.timeOutBook)) || ( Number.isInteger(Number(this.props.timeOutBook)) && (Number(this.props.timeOutBook) < 0 || Number(this.props.timeOutBook) > 6000 )) ) {
      this.store.dispatch({type: "CONFIGURATION_ERROR", message: "timeOutBook attribute has to be a number between 0 to 6000"});
    }

    if (this.props.mandatoryStartDate) {
      if (this.props.mandatoryStartDate !== "true" && this.props.mandatoryStartDate !== "true") {
        this.store.dispatch({type: "CONFIGURATION_ERROR", message: "Wrong value for mandatoryStartDate"});
      }
    }

    if (this.props.searchByParentAreas) {
      if (this.props.searchByParentAreas !== "true") {
        this.store.dispatch({type: "CONFIGURATION_ERROR", message: "to activate searchByParentAreas the value has to be 'true'"});
        return;
      } else if (!this.props.apiUser || !this.props.apiPassword) {
        this.store.dispatch({type: "CONFIGURATION_ERROR", message: "searchByParentAreas needs apiUser and apiPassword"});
        return;
      }
    }

    for (let param of this.getCurrentPath().params) {
      if (param.includes("searchcriteria=") && param !== "searchcriteria=ALL") {
        for (let row of param.replace("searchcriteria=", "").split(";").filter(function (el) {return el.length !== 0;})) {
          if ((row.match(new RegExp(":")) || []).length > 1 || (row.match(new RegExp(":")) || []).length === 0) {
            this.store.dispatch({type: "CONFIGURATION_ERROR", message: "preselect attribute has a badly format"});
            return;
          }
        }
      }
    }

    if (window.onpopstate) throw "this is not designed to work on a page that is already using onpopstate";

    window.onpopstate = function () {
      this.store.dispatch({type: "LOAD", currentPath: this.getCurrentPath()});
    }.bind(this);

    this.store.dispatch({type: "LOAD", currentPath: this.getCurrentPath()});
  }

  //this event is called when the state is updated after the first render
  //we cannot call the API inside the ajax suscess because if not we have not API calls secuencially
  //it simulates promises
  componentDidUpdate (prevProps, prevState) {
    // console.log(this.state.nextAction);
    switch (this.state.nextAction) {
      case "bookNow":
        this.callBookonline();
        break;
      case "initOptions":
      case "callOptionsWithPreselect":
      case "loginAfterRegister":
      case "searchRoomByParam":
      case "redirectToSearchApplet":
      case "callAddShortlist":
      case "callListShortlist":
      case "callRemoveShortlist":
      case "loadListShortlist":
      case "loadListShortlistFromRegister":
      case "linkToMyShortlist":
      case "linkToLoginBeforeShorlist":
      case "callRemoveShortlistFromMyshortlist":
      case "sortSearch":
      case "startLogin":
      case "callGetFormCollections":
      case "getWebContent":
      case "loadRegisterForm":
      case "search":
      case "getOptions":
      case "startGetOptions":
      case "startRegisterUser":
      case "registerUser":
      case "getLabels":
      case "lostPassword":
      case "lostPasswordConfirmation":
      case "goToLoginPage":
      case "searchAfterLoad":
      case "logout":
      case "actionAfterLoginOrRegister":
      case "getUser":
      case "getFormCollectionsToUpdateUser":
      case "startUpdatePersonDetails":
      case "updatePersonDetails":
      case "getUserInLoad":
      case "showSearchApplet":
      case "getShortListAfterLogin":
      case "showListShortlist":
      case "setSelectedDate":
      case "validateAccount":
      case "getParentAreas":
        this[this.state.nextAction]();
    }
  }

  getCurrentPath () {
    let path = location.hash.split("/");
    let params = [];
    let paramsStr = "";
    let searchCriteria = "";
    let action = null;
    let sortType = null;
    let hash = null;
    let from = null;
    let offer = null;
    let username = null;
    let verificationId = null;
    let room = null;
    if (path.length > 1) for (let i = 1; i < path.length;i++) if (path[i] !== "" && path[i].indexOf("=") !== -1) params.push(path[i]);
    if (params.length > 1) for (let param of params) paramsStr += "/" + param;
    else if (params.length === 1 && params[0].indexOf("=") !== -1) paramsStr = "/" + params[0];
    for (let param of params) {
      if (param.split("=")[0] === "action") action = param.split("=")[1];
      else if (param.split("=")[0] === "searchcriteria") searchCriteria = param;
      else if (param.split("=")[0] === "sortType") sortType = param.split("=")[1];
      else if (param.split("=")[0] === "hash") hash = param.split("=")[1];
      else if (param.split("=")[0] === "from") from = param.split("=")[1];
      else if (param.split("=")[0] === "offer") offer = param.split("=")[1];
      else if (param.split("=")[0] === "username") username = param.split("=")[1];
      else if (param.split("=")[0] === "verificationId") verificationId = param.split("=")[1];
      else if (param.split("=")[0] === "room") room = param.split("=")[1];
    }
    return {
      "component": path[0] === "" || path[1] === "" || path[1].startsWith("searchcriteria") ? "searchApplet" : path[1],
      "params": params,
      "paramsStr": paramsStr,
      "searchCriteria": searchCriteria,
      "action": action,
      "sortType": sortType,
      "hash": hash,
      "from": from,
      "offer": offer,
      "username": username,
      "verificationId": verificationId,
      "room": room,
    };
  }

  //API CALLS
  lostPassword () {
    let self = this;
    api.lostPassword(this.props.api, { "username": this.state.pexResetPassword.values.email}, function (response) {
      self.store.dispatch({ "type": "LOST_PASSWORD_OK"});
    }, function (message, messageType) {
      self.store.dispatch({"type": "LOST_PASSWORD_KO", "message": message, "messageType": messageType});
    });
  }

  lostPasswordConfirmation () {
    let self = this;
    let payload = {
      "username": self.getCurrentPath().username,
      "password": this.state.pexNewPassword.values.password,
      "verificationId": self.getCurrentPath().verificationId,
    };
    api.lostPasswordConfirmation(this.props.api, payload, function (response) {
      if (!payload.username || !payload.verificationId) self.store.dispatch({"type": "LOST_PASSWORD_CONFIRMATION_KO", "error": response});
      self.store.dispatch({ "type": "LOST_PASSWORD_CONFIRMATION_OK"});
    }, function (error) {
      self.store.dispatch({"type": "LOST_PASSWORD_CONFIRMATION_KO", "error": error});
    });
  }

  startValidateAccount (event) {
    event.preventDefault();
    this.store.dispatch({ type: "START_VALIDATE_ACCOUNT"});
  }

  validateAccount () {
    if (this.state.userLoggedFrom === "login") this.startLogin ();
    else if (this.state.userLoggedFrom === "register") this.loginAfterRegister ();
  }

  startLogin () {
    this.loginUser (this.state.pexLogin.values.username, this.state.pexLogin.values.password, "login");
  }

  loginAfterRegister () {
    this.loginUser (this.state.pexRegister.values.username, this.state.pexRegister.values.password, "register");
  }

  loginUser (username, password, component) {
    let self = this;
    api.singleSignOnLoginobject(this.props.sso, {"username": username, "password": password}, function (loginResponse) {
      if (loginResponse.loggedIn) {
        document.dispatchEvent(self.state.userLoggedInSearchAppletEvent);
        self.store.dispatch({ type: "USER_LOGGED_OK"});
      }
      else {
        if (loginResponse.message.indexOf("Your user account is not active") !== -1) {
          history.pushState(null, "", "#/validateAccount" + self.getCurrentPath().paramsStr);
          self.store.dispatch({type: "DATA_TO_VALIDATE_ACCOUNT", "userLoggedFrom": component});
        } else {
          if (component === "login" ) {
            //an error in the login screen keeps the user in the login screen. Example: username and password incorrect
            self.store.dispatch({type: "LOGIN_ERROR", "error": loginResponse.message});
          } else if (component === "register") {
            history.pushState(null, "", "#/error");
            self.store.dispatch({ type: "SHOW_FULL_SCREEN_ERROR", "error": "Internal issue 42523423"});
          }
        }
      }
    }, function (error) {
      if (component === "login" ) {
        //an error in the login screen keeps the user in the login screen. Example: username and password incorrect
        self.store.dispatch({type: "LOGIN_ERROR", "error": error});
      } else {
        history.pushState(null, "", "#/error");
        //We assume that when API can't connect with the server we receive a string
        if (utils.isJson(error)) self.store.dispatch({ type: "SHOW_FULL_SCREEN_ERROR", "error": "Internal issue 98236422"});
        else self.store.dispatch({ type: "SHOW_FULL_SCREEN_ERROR", "error": error});
      }
    });
  }

  getUser () {
    let self = this;
    api.getSSOKeyAndGetUser(this.props.sso, this.props.api, {}, function (getUserResponse) {
      self.store.dispatch({ type: "GET_USER_OK", "getUserResponse": getUserResponse});
    }, function (getUserResponse) {
      //We send to api error page because we call the login method  and after we get the user, so if this call fails it is an api error
      self.store.dispatch({"type": "API_ERROR", "error": "api error 345352343685", "waiting": false});
    }, function (getUserResponse) {
      self.store.dispatch({"type": "API_ERROR", "error": "api error 636352343685", "waiting": false});
    });
  }

  getUserInLoad () {
    let self = this;
    api.getSSOKeyAndGetUser(this.props.sso, this.props.api, {}, function (getUserResponse) {
      self.store.dispatch({ type: "GET_USER_IN_LOAD_OK", "getUserResponse": getUserResponse});
    }, function (getUserResponse) {
      //We send to api error page because we call the login method  and after we get the user, so if this call fails it is an api error
      self.store.dispatch({"type": "API_ERROR", "error": "api error 3453523432432", "waiting": false});
    }, function (getUserResponse) {
      self.store.dispatch({"type": "API_ERROR", "error": "api error 6363523436666", "waiting": false});
    });
  }

  getOptions () {
    let payload = searchService.parseCriteriaToPayload(this.state.criteria, this.state.initialData);
    let self = this;
    api.progressiveSearchForm(this.props.api, payload, function (data) {
      self.store.dispatch({type: "GET_OPTIONS_OK", "data": data, "formSettings": data.settings});
    }, function () {
      self.store.dispatch({type: "GET_OPTIONS_ERROR"});
    });
  }

  initOptions () {
    let self = this;
    api.progressiveSearchForm(this.props.api, {}, function (data) {
      //THINK ABOUT TO OVERWRITE HERE THE DATA TO CREATE THE parentAreas, so we can have some structure like a normal category, but with an extra field
      //for each area inside, so we can manage it like the nromal area, and only modify the criteria

      let initialData = JSON.parse(JSON.stringify(data));
      if (self.props.searchByParentAreas) if (data.categories) initialData = searchService.createParentArea(self.state.parentAreas, initialData);

      let component = self.getCurrentPath().component;
      if (component === "roomDetails" || component === "login" || component === "register" || component === "bookingSummary") {
        self.store.dispatch({ type: "SEARCH_ROOM_BEFORE_SHOW_COMPONENT", "data": initialData});
      } else if (component === "searchApplet") {
        self.store.dispatch({ type: "SAVE_INITIAL_DATA", "data": initialData, "formSettings": data.settings});
      } else if (component === "bookNowInfo") {
        self.store.dispatch({ type: "REDIRECT_TO_SEARCH_APPLET", "data": initialData});
      } else if (component === "myShortlist") {
        self.store.dispatch({ type: "LOAD_MY_SHORTLIST", "data": initialData});
      } else if (component === "resetPassword") {
        self.store.dispatch({ type: "SHOW_LOST_PASSWORD", "data": initialData});
      } else if (component === "newPassword") {
        self.store.dispatch({ type: "SHOW_LOST_PASSWORD_CONFIRMATION", "data": initialData});
      } else if (component === "validateAccount") {
        self.redirectToLoginPageFromValidate(initialData);
      } else if (component === "updatePersonDetails") {
        if (self.state.userLogged) self.store.dispatch({ type: "USER_LOGGED_OK", "data": initialData});
        else self.goToLoginPage();
      }

    }, function (err) {
      self.store.dispatch({"type": "API_ERROR", "error": "api error 2373828C", "waiting": false});
    });

  }

  executeSearch (pushToUrl) {
    let criteria = JSON.parse(JSON.stringify(this.state.criteria));
    let payload = searchService.parseCriteriaToPayload(criteria, this.state.initialData);
    let self = this;
    let sortType = this.state.sortSelected ? "/sortType=" + this.state.sortSelected : "";
    if (pushToUrl) {
      if (self.getCurrentPath().hash) {
        history.pushState(null, "", searchService.createSearchCriteria("#/searchApplet/hash=" + self.getCurrentPath().hash + "/", criteria, this.state.initialData) + sortType);
      } else {
        history.pushState(null, "", searchService.createSearchCriteria("#/", criteria, this.state.initialData) + sortType);
      }
    }
    api.progressiveSearchSearch(this.props.api, payload, function (data) {
      if (self.getCurrentPath().hash) {
        const hash = self.getCurrentPath().hash;
        let searchData = searchService.prepareSearchResult (data.offers, self.props.pagination, self.state, data.settings, self.state.initialData.settings);
        let offerSearched = searchData.filter((item) => { return item.hash === hash;});
        let roomSearch = null;
        if (offerSearched.length) roomSearch = { "searchData": offerSearched };
        if (roomSearch && roomSearch.searchData) self.store.dispatch({"type": "SEARCH_OK", "searchData": roomSearch.searchData});
        else self.store.dispatch({"type": "SEARCH_OK", "searchData": [], "searchSettings": data.settings});
      } else {
        let searchData = searchService.prepareSearchResult (data.offers, self.props.pagination, self.state, data.settings, self.state.initialData.settings);
        self.store.dispatch({"type": "SEARCH_OK", "searchData": searchData});
      }
    }, function () {
      self.store.dispatch({"type": "BUTTON_SEARCH_ERROR"});
    });
  }

  getWebContent () {
    let self = this;
    api.getWebContent(this.props.api, { "group": "SEARCH_APPLET"}, function (data) {
      self.store.dispatch({ type: "GET_WEB_CONTENT_OK", "response": data});
    }, function () {
      self.store.dispatch({"type": "API_ERROR", "error": "api error 245232524", "waiting": false});
    });

  }

  getLabels () {
    let self = this;
    let lang = "en-GB";
    if (this.state.lang.length > 1) lang = this.state.lang;
    api.getLabels(this.props.api, {"languageIds": [lang], "group": "searchApplet"}, function (data) {
      self.store.dispatch({ type: "GET_LABELS_OK", "response": data});
    }, function () {
      self.store.dispatch({"type": "API_ERROR", "error": "api error 245232525", "waiting": false});
    });
  }

  getParentAreas () {
    let self = this;
    let payload = {
      "apiUser": this.props.apiUser,
      "apiPassword": this.props.apiPassword,
      "bobType": "Area",
      "attributesToInclude": ["code", "name", "parent", "enabledOnWebsite"],
    };
    api.getAll(this.props.api, payload, function (data) {
      self.store.dispatch({ type: "GET_PARENT_AREAS_OK", "response": data});
    }, function () {
      self.store.dispatch({"type": "API_ERROR", "error": "api error 245232526", "waiting": false});
    });
  }

  searchRoomByParam () {
    let self = this;
    let roomSearch = null;

    if (self.getCurrentPath().from === "shortlist") {
      if (this.state.userLogged && this.getCurrentPath().component === "roomDetails") {
        const hash = this.getCurrentPath().hash;
        let searchData = searchService.prepareSearchResult (JSON.parse(JSON.stringify(self.state.shortlist)), self.props.pagination, self.state, null, self.state.initialData.settings);
        let offerSearched = searchData.filter((item) => { return item.hash === hash;});
        if (offerSearched.length) roomSearch = { "searchData": searchData, "roomDetailsData": {"room": offerSearched[0].roomData, "offers": offerSearched[0].offers} };
      }
      this.store.dispatch({ type: "SEARCH_ROOM_BY_PARAM_DONE", "roomSearch": roomSearch});
    } else {
      let payload = searchService.parseCriteriaToPayload(JSON.parse(JSON.stringify(this.state.criteria)), this.state.initialData, self.getCurrentPath());
      api.progressiveSearchSearch(this.props.api, payload, function (data) {
        if (self.getCurrentPath().component === "roomDetails") {
          if (payload.unitMda) {
            let searchData = searchService.prepareSearchResult(data.offers, self.props.pagination, self.state, data.settings, self.state.initialData.settings);
            if (searchData.length) roomSearch = { "searchData": searchData, "roomDetailsData": {"room": searchData[0].roomData, "offers": searchData[0].offers} };
          } else {
            const hash = self.getCurrentPath().hash;
            let searchData = searchService.prepareSearchResult(data.offers, self.props.pagination, self.state, data.settings, self.state.initialData.settings);
            let offerSearched = searchData.filter((item) => { return item.hash === hash;});
            if (offerSearched.length) roomSearch = { "searchData": searchData, "roomDetailsData": {"room": offerSearched[0].roomData, "offers": offerSearched[0].offers} };
          }
        } else if (self.getCurrentPath().component === "searchApplet" && self.getCurrentPath().hash) {
          const hash = self.getCurrentPath().hash;
          let searchData = searchService.prepareSearchResult(data.offers, self.props.pagination, self.state, data.settings, self.state.initialData.settings);
          let offerSearched = searchData.filter((item) => { return item.hash === hash;});
          if (offerSearched.length) roomSearch = { "searchData": offerSearched };
        } else {
          let offerLookupValue = self.getCurrentPath().offer;
          let offerSearched = data.offers.filter((offer) => { return offer.lookupValue === offerLookupValue; });
          let searchData = searchService.prepareSearchResult(offerSearched, self.props.pagination, self.state, data.settings, self.state.initialData.settings);
          if (searchData.length) roomSearch = { "searchData": searchData, "roomDetailsData": {"room": searchData[0].roomData, "offer": offerSearched[0]} };
        }
        self.store.dispatch({type: "SEARCH_ROOM_BY_PARAM_DONE", "roomSearch": roomSearch});
      }, function (error) {
        if (self.getCurrentPath().component === "roomDetails") self.store.dispatch({ "type": "ERROR_LOADING_ROOM_DETAILS", "error": error });
        else self.store.dispatch({"type": "API_SEARCH_ERROR", "searchError": "api error 43323423424", "waiting": false});
      });
    }
  }

  //Add to the shortlist a room. This method can be called from search applet or as last action of login/register
  callAddShortlist () {
    let self = this;
    api.getSSOKeyAndAddShortlist(this.props.sso, this.props.api, this.state.addShortlistData.payload, function (addShortlistResponse) {
      self.store.dispatch({ type: "CALL_LISTSHORTLIST"});
    }, function (addShortlistResponse) {
      history.pushState(null, "", searchService.createSearchCriteria("#/login/", self.state.criteria, self.state.initialData) + "/action=addtoshortlist/offer=" + self.state.addShortlistData.offer.lookupValue);
      self.store.dispatch({ type: "GO_TO_LOGIN_PAGE"});
    }, function (addShortlistResponse) {
      self.store.dispatch({"type": "API_ERROR", "error": "api error 63620273685", "waiting": false});
    });
  }

  //TODO: try to unify these methods in one
  //Method called just in the load in order to know if user is logged in. In poisitive case we get his shortlist
  loadListShortlist () {
    let self = this;
    api.getSSOKeyAndListShortlist(this.props.sso, this.props.api, {}, function (listShortlistResponse) {
      self.store.dispatch({ type: "UPDATE_SHORTLIST_IN_SEARCH_DATA", "shortlist": listShortlistResponse.shortlist ? JSON.parse(JSON.stringify(listShortlistResponse.shortlist)) : null, "nextAction": "initOptions"});
    }, function (listShortlistResponse) {
      self.store.dispatch({ type: "LOAD_USER_NOT_LOGGED"});
    }, function (listShortlistResponse) {
      self.store.dispatch({"type": "API_ERROR", "error": "api error 539171323619", "waiting": false});
    });
  }

  loadListShortlistFromRegister () {
    let self = this;
    api.getSSOKeyAndListShortlist(this.props.sso, this.props.api, {}, function (listShortlistResponse) {
      self.store.dispatch({ type: "UPDATE_SHORTLIST_IN_SEARCH_DATA", "shortlist": listShortlistResponse.shortlist ? JSON.parse(JSON.stringify(listShortlistResponse.shortlist)) : null, "nextAction": "DONE"});
    }, function (listShortlistResponse) {
      self.store.dispatch({ type: "LOAD_USER_NOT_LOGGED"});
    }, function (listShortlistResponse) {
      self.store.dispatch({"type": "API_ERROR", "error": "api error 539171323619", "waiting": false});
    });
  }

  showListShortlist () {
    let self = this;
    api.getSSOKeyAndListShortlist(this.props.sso, this.props.api, {}, function (listShortlistResponse) {
      self.store.dispatch({ type: "UPDATE_SHORTLIST_IN_SEARCH_DATA", "shortlist": listShortlistResponse.shortlist ? JSON.parse(JSON.stringify(listShortlistResponse.shortlist)) : null, "nextAction": "DONE"});
    }, function (listShortlistResponse) {
      self.store.dispatch({ type: "USER_NOT_LOGGED_MY_SHORTLIST"});
    }, function (listShortlistResponse) {
      self.store.dispatch({"type": "API_ERROR", "error": "api error 24134134156", "waiting": false});
    });
  }

  callListShortlist () {
    let self = this;
    api.getSSOKeyAndListShortlist(this.props.sso, this.props.api, {}, function (listShortlistResponse) {
      let nextAction = "redirectToSearchApplet";
      if (self.getCurrentPath().component === "roomDetails") nextAction = "DONE";
      self.store.dispatch({ type: "UPDATE_SHORTLIST_IN_SEARCH_DATA", "shortlist": listShortlistResponse.shortlist ? JSON.parse(JSON.stringify(listShortlistResponse.shortlist)) : null, "nextAction": nextAction});
    }, function (listShortlistResponse) {
      self.store.dispatch({"type": "API_ERROR", "error": "api error 2545235232", "waiting": false});
    }, function (listShortlistResponse) {
      self.store.dispatch({"type": "API_ERROR", "error": "api error 3564334156", "waiting": false});
    });
  }

  getShortListAfterLogin () {
    let self = this;
    api.getSSOKeyAndListShortlist(this.props.sso, this.props.api, {}, function (listShortlistResponse) {
      self.store.dispatch({ type: "GET_SHORTLIST_AFTER_LOGIN_OK", "shortlist": listShortlistResponse.shortlist ? JSON.parse(JSON.stringify(listShortlistResponse.shortlist)) : null});
    }, function (listShortlistResponse) {
      self.store.dispatch({"type": "API_ERROR", "error": "api error 2545235232", "waiting": false});
    }, function (listShortlistResponse) {
      self.store.dispatch({"type": "API_ERROR", "error": "api error 3564334156", "waiting": false});
    });
  }
  //end TODO

  callRemoveShortlist () {
    let self = this;
    api.getSSOKeyAndRemoveShortlist(this.props.sso, this.props.api, this.state.removeShortlistData.payload, function (listShortlistResponse) {
      self.store.dispatch({ type: "CALL_LISTSHORTLIST"});
    }, function (listShortlistResponse) {
      history.pushState(null, "", searchService.createSearchCriteria("#/login/", self.state.criteria, self.state.initialData) + "/action=removeshortlist/offer=" + self.state.removeShortlistData.offer.lookupValue);
      self.store.dispatch({ type: "GO_TO_LOGIN_PAGE"});
    }, function (listShortlistResponse) {
      self.store.dispatch({"type": "API_ERROR", "error": "api error 8731011458", "waiting": false});
    });
  }

  callRemoveShortlistFromMyshortlist () {
    let self = this;
    api.getSSOKeyAndRemoveShortlist(this.props.sso, this.props.api, this.state.removeShortlistDataFromMyShortlist.payload, function (listShortlistResponse) {
      self.store.dispatch({ type: "UPDATE_SHORTLIST_IN_SEARCH_DATA", "shortlist": listShortlistResponse.shortlist ? JSON.parse(JSON.stringify(listShortlistResponse.shortlist)) : null, "nextAction": "loadListShortlist"});
    }, function (listShortlistResponse) {
      self.store.dispatch({ type: "USER_NOT_LOGGED_MY_SHORTLIST"});
    }, function (listShortlistResponse) {
      self.store.dispatch({"type": "API_ERROR", "error": "api error 8731011458", "waiting": false});
    });
  }

  callBookonline () {
    let self = this;
    api.getSSOKeyAndBook(this.props.sso, this.props.api, {"offer": this.state.bookNowData.offerLookupValue, "startDate": this.state.bookNowData.startDate}, function (bookNowResponse) {
      let timeInMillis = new Date().getTime();
      history.pushState(null, "", "#/bookNowInfo/" + timeInMillis);
      let bookNowData = JSON.parse(JSON.stringify(self.state.bookNowData));
      bookNowData.bookNowResponse = bookNowResponse;
      bookNowData.timeInMillis = timeInMillis;
      self.store.dispatch({ type: "BOOK_NOW_MESSAGE", "bookNowData": bookNowData });
    }, function (bookResponse) {
      history.pushState(null, "", "#/login" + roomService.createBookCriteria({"offer": self.state.bookNowData.offerLookupValue, "startDate": self.state.bookNowData.startDate}, "/action=book"));
      self.store.dispatch({ type: "GO_TO_LOGIN_PAGE"});
    }, function (message, messageType) {
      history.pushState(null, "", "#/bookNowInfo");
      let bookNowData = {};
      bookNowData.messageType = messageType;
      bookNowData.message = message;
      self.store.dispatch({ type: "BOOK_NOW_MESSAGE", "bookNowData": bookNowData});
    });
  }

  callOptionsWithPreselect () {
    let self = this;
    api.progressiveSearchForm(this.props.api, searchService.parseCriteriaToPayload(this.state.criteria, this.state.initialData), function (data) {
      self.store.dispatch({ type: "SEARCH_AFTER_LOAD", "data": JSON.parse(JSON.stringify(data)), "sortType": self.getCurrentPath().sortType});
    }, function (err) {
      self.store.dispatch({"type": "API_ERROR", "error": "api error 2373828D", "waiting": false});
    });
  }

  callGetFormCollections () {
    let self = this;
    let payload = {};
    if (this.state.areaForGetRoomCollections && this.state.areaForGetRoomCollections.area) payload = { location: this.state.areaForGetRoomCollections.area };
    api.getFormCollections(this.props.api, payload, function (response) {
      history.pushState(null, "", "#/register" + self.getCurrentPath().paramsStr);
      self.store.dispatch({ type: "CALL_GET_FORM_COLLECTION_OK", "getFormCollectionsResponse": JSON.parse(JSON.stringify(response))});
    }, function (err) {
      self.store.dispatch({"type": "API_ERROR", "error": "api error 2342342", "waiting": false});
    });
  }

  getFormCollectionsToUpdateUser () {
    let self = this;
    let payload = {};
    if (this.state.areaForGetRoomCollections && this.state.areaForGetRoomCollections.area) payload = { location: this.state.areaForGetRoomCollections.area };
    api.getFormCollections(this.props.api, payload, function (response) {
      history.pushState(null, "", "#/updatePersonDetails" + self.getCurrentPath().paramsStr);
      self.store.dispatch({ type: "GET_FORM_COLLECTION_TO_UPDATE_PERSON_DETAILS_OK", "getFormCollectionsResponse": JSON.parse(JSON.stringify(response))});
    }, function (err) {
      self.store.dispatch({"type": "API_ERROR", "error": "api error 2342343", "waiting": false});
    });
  }

  registerUser () {
    let self_ = this;
    let payload = {
      "user": {
        "username": this.state.pexRegister.values.username,
        "firstName": this.state.pexRegister.values.firstName,
        "lastName": this.state.pexRegister.values.lastName,
        "gender": this.state.pexRegister.values.gender,
        "mobileCountry": this.state.pexRegister.values.mobileCountry,
        "mobileNumber": "+" + this.state.pexRegister.values.mobileCountry + this.state.pexRegister.values.mobileNumber,
      },
      "password": this.state.pexRegister.values.password,
    };
    if (this.state.pexRegister.values.noSpam === "false") {
      payload.user.noSpam = true;
    } else {
      payload.user.noSpam = false;
    }
    if (this.state.pexRegister.values.uniCollegeSchoolAttending) payload.user.university = this.state.pexRegister.values.uniCollegeSchoolAttending;
    api.webServiceUserRegistration(this.props.api, payload, function (registerResponse) {
      self_.store.dispatch({ type: "REGISTER_USER_OK"});
    }, function (message, messageType) {
      if (messageType === "277") {
        history.pushState(null, "", "#/validateAccount" + self_.getCurrentPath().paramsStr);
        self_.store.dispatch({ type: "DATA_TO_VALIDATE_ACCOUNT", "userLoggedFrom": "register"});
      } else self_.store.dispatch({ type: "REGISTER_USER_KO", "message": message, "messageType": messageType});
    }, false);
  }

  updatePersonDetails () {
    let self_ = this;
    let payload = {
      "user": {
        "username": this.state.pexRegister.values.username,
        "firstName": this.state.pexRegister.values.firstName,
        "lastName": this.state.pexRegister.values.lastName,
        "gender": this.state.pexRegister.values.gender,
        "mobileCountry": this.state.pexRegister.values.mobileCountry,
        "mobileNumber": "+" + this.state.pexRegister.values.mobileCountry + this.state.pexRegister.values.mobileNumber,
      },
    };
    if (this.state.pexRegister.values.password) payload.password = this.state.pexRegister.values.password;
    if (this.state.pexRegister.values.noSpam === "false") {
      payload.user.noSpam = true;
    } else {
      payload.user.noSpam = false;
    }
    if (this.state.pexRegister.values.uniCollegeSchoolAttending) payload.user.university = this.state.pexRegister.values.uniCollegeSchoolAttending;
    api.getSSOKeyAndUpdatePersonDetails(this.props.sso, this.props.api, payload, function (updatePersonDetailsResponse) {
      self_.store.dispatch({ type: "UPDATE_PERSON_DETAILS_OK"});
    }, function (updatePersonDetailsResponse) {
      self_.goToLoginPage();
    }, function (message, messageType) {
      self_.store.dispatch({"type": "UPDATE_PERSON_DETAILS_KO", "message": message, "messageType": messageType});
    });
  }

  logout () {
    let self = this;
    api.getSSOKeyAndLogout(this.props.sso, function (response) {
      document.dispatchEvent(self.state.userLoggedOutSearchAppletEvent);
      history.pushState(null, "", "#/");
      self.store.dispatch({ type: "LOAD"});
    }, function () {
      self.store.dispatch({ type: "API_ERROR", "error": "api error 23445556 cannot logout", "waiting": false});
    });
  }

  // END API CALLS-----------------------------------------------------------

  //BOOK ROOM

  handleBookNow (offerLookupValue, start, room, area, offer) {
    this.store.dispatch({type: "HANDLE_BOOK_NOW", "offerLookupValue": offerLookupValue, "startDate": start, "room": room, "area": area, "offer": offer});
  }

  //SHORT LIST
  addShortlist (offer) {
    this.store.dispatch({type: "ADD_TO_SHORTLIST", "offer": offer});
  }

  removeShortlist (offer) {
    this.store.dispatch({type: "REMOVE_SHORTLIST", "offer": offer});
  }

  removeShortlistFromMyShortlist (offer) {
    this.store.dispatch({type: "REMOVE_SHORTLIST_FROM_MYSHORTLIST", "offer": offer});
  }

  searchSimilar (hash) {
    history.pushState(null, "", "#/searchApplet/hash=" + hash);
    this.store.dispatch({type: "SEARCH_SIMILAR", "hash": hash});
  }

  //LOGIN, REGISTER AND RESET PASSWORD

  updateLoginForm (field, event) {
    this.store.dispatch({ type: "UPDATE_LOGIN_FORM", "field": field, "value": event.target.value});
  }

  updateRegisterForm (field, event) {
    this.store.dispatch({ type: "UPDATE_REGISTER_FORM", "field": field, "value": event.target.value});
  }

  updateBoolean (field, event) {
    this.store.dispatch({ type: "UPDATE_BOOLEAN", "field": field, "value": event.target.value});
  }

  updateResetPasswordForm (field, event) {
    this.store.dispatch({ type: "UPDATE_LOST_PASSWORD_FORM", "field": field, "value": event.target.value});
  }

  updateNewPasswordForm (field, event) {
    this.store.dispatch({ type: "UPDATE_LOST_PASSWORD_CONFIRMATION_FORM", "field": field, "value": event.target.value});
  }

  startRegisterUser (event) {
    event.preventDefault();
    if (!this.state.pexRegister.canSubmit) this.store.dispatch({ type: "SET_REGISTER_FORM_SUBMITTED_WITH_ERROR"});
    else this.store.dispatch({ type: "START_REGISTER_USER"});
  }

  startUpdatePersonDetails (event) {
    event.preventDefault();
    if (!this.state.pexRegister.canSubmit) this.store.dispatch({ type: "SET_REGISTER_FORM_SUBMITTED_WITH_ERROR"});
    else this.store.dispatch({ type: "START_UPDATE_PERSON_DETAILS"});
  }

  backToLogin () {
    history.pushState(null, "", "#/login" + this.getCurrentPath().paramsStr);
    this.store.dispatch({ type: "CHANGE_CURRENT_COMPONENT", "currentComponent": "login"});
  }

  login (event) {
    event.preventDefault();
    if (!this.state.pexLogin.canSubmit) this.store.dispatch({ type: "SET_LOGIN_FORM_SUBMITTED_WITH_ERROR"});
    else this.store.dispatch({ type: "START_LOGIN"});
  }

  startLogout () {
    this.store.dispatch({type: "START_LOGOUT"});
  }

  startLostPassword (event) {
    event.preventDefault();
    if (!this.state.pexResetPassword.canSubmit) {
      this.store.dispatch({ type: "LOST_PASSWORD_SUBMITTED_WITH_ERRORS"});
      return;
    }
    this.store.dispatch({ type: "START_LOST_PASSWORD"});
  }
  register () {
    this.store.dispatch({type: "GO_TO_REGISTER"});
  }

  loadRegisterForm () {
    history.pushState(null, "", "#/register" + this.getCurrentPath().paramsStr);
    this.store.dispatch({ type: "REGISTER_FORM_LOADED"});
  }

  loadResetPassword () {
    history.pushState(null, "", "#/resetPassword");
    this.store.dispatch({ type: "SHOW_LOST_PASSWORD"});
  }

  startLostPasswordConfirmation (event) {
    event.preventDefault();
    if (!this.state.pexNewPassword.canSubmit) {
      this.store.dispatch({ type: "LOST_PASSWORD_CONFIRMATION_SUBMITTED_WITH_ERRORS"});
      return;
    }
    this.store.dispatch({ type: "START_LOST_PASSWORD_CONFIRMATION"});
  }
  selectCountryRegister (country) {
    this.store.dispatch({type: "SELECT_COUNTRY_REGISTER", "countrySelected": JSON.parse(JSON.stringify(country))});
  }
  openCloseDropdownRegister (collectionName) {
    this.store.dispatch({ type: "OPEN_CLOSE_DROPDOWN_REGISTER", "collectionName": collectionName});
  }
  onSearchTextHandlerRegister (event) {
    this.store.dispatch({ type: "SEARCH_FILTER_HANDLER_REGISTER", "value": event.target.value});
  }

  //SEARCH

  selectSearch (sortSelected) {
    history.pushState(null, "", "#/" + this.getCurrentPath().searchCriteria + "/sortType=" + sortSelected);
    this.store.dispatch({ type: "UPDATE_SORT_SELECTED", "sortSelected": sortSelected});
  }

  sortSearch () {
    this.store.dispatch({ type: "SORT_RESULT"});
  }

  selectPaginationPage (activePage) {
    this.store.dispatch({ type: "CHANGE_ACTIVE_PAGE", "activePage": activePage});
  }

  startSearch () {
    this.store.dispatch({ type: "START_SEARCH"});
  }

  search () {
    this.executeSearch (true);
  }

  searchAfterLoad () {
    //avoid push to the url the current searchcriteria because currently we have it there
    this.executeSearch (false);
  }

  retry () {
    this.getOptions ();
  }

  startGetOptions () {
    this.store.dispatch({type: "START_GET_OPTIONS"});
  }

  categoryClick (elementLookupValue, arrayName, selected) {
    this.store.dispatch({type: "CATEGORY_CLICK", "elementLookupValue": elementLookupValue, "arrayName": arrayName, "selected": selected});
  }

  collectionOptionClick (elementLookupValue, arrayName, selected) {
    this.store.dispatch({type: "COLLECTION_OPTION_CLICK", "elementLookupValue": elementLookupValue, "arrayName": arrayName, "selected": selected});
  }

  updateBudget (budget) {
    this.store.dispatch({type: "UPDATE_BUDGET", "budget": budget});
  }

  updateMinBudget (minBudget) {
    this.store.dispatch({type: "UPDATE_MIN_BUDGET", "minBudget": minBudget});
  }

  updateGender (gender) {
    this.store.dispatch({type: "UPDATE_GENDER", "gender": gender});
  }

  reset () {
    history.pushState(null, "", "#/");
    this.store.dispatch({type: "RESET_SEARCH"});
  }

  updateSearchDate (searchDate) {
    this.store.dispatch({type: "UPDATE_SEARCH_DATE", "searchDate": searchDate});
  }

  //NAVIGATION

  goToRoomDetails (hash, offers, myRoom, shortlist) {
    let hashUrl = "#/roomDetails/hash=" + hash;
    if (shortlist) hashUrl += "/from=shortlist";
    if (this.state.data.settings && this.state.data.settings["booking.strictGenders"] === true) {
      let criteria = JSON.parse(JSON.stringify(this.state.criteria));
      hashUrl += "/" + searchService.createSearchCriteria("", criteria, this.state.initialData);
    }
    history.pushState(null, "", hashUrl);
    this.store.dispatch({ type: "GO_TO_ROOM_DETAILS", "roomDetailsData": {"room": myRoom, "offers": offers}});
  }

  redirectToSearchApplet () {
    history.pushState(null, "", "#/" + this.getCurrentPath().searchCriteria);
    this.store.dispatch({ type: "CHANGE_CURRENT_COMPONENT", "currentComponent": "searchApplet"});
  }

  linkToMyShortlist () {
    if (this.state.userLogged) {
      history.pushState(null, "", "#/myShortlist");
      this.store.dispatch({type: "SHOW_SHORTLIST"});
    } else {
      history.pushState(null, "", "#/login/action=showshortlist/");
      this.store.dispatch({ type: "GO_TO_LOGIN_PAGE"});
    }

  }

  goToLoginPage () {
    history.pushState(null, "", "#/login" + this.getCurrentPath().paramsStr);
    this.store.dispatch({ type: "GO_TO_LOGIN_PAGE"});
  }
  redirectToLoginPageFromValidate (data) {
    history.pushState(null, "", "#/login");
    this.store.dispatch({ type: "GO_TO_LOGIN_PAGE", "data": data});
  }
  openCategoryDropdown (collectionName) {

    if (collectionName === "gender") {
      if (this.state.data.settings && this.state.data.settings["booking.strictGenders"] && this.state.criteria.gender !== "-") {
        //Do nothing, looks easy the  condition for now. If we have a value selected and strict gender activated we don't open the dropdown to select gender
      } else {
        this.store.dispatch({ type: "OPEN_CLOSE_CATEGORY_DROPDOWN", "collectionName": collectionName});
      }
    } else {
      this.store.dispatch({ type: "OPEN_CLOSE_CATEGORY_DROPDOWN", "collectionName": collectionName});
    }

  }
  clickOutside (collectionName) {
    if (this.state.isOpen && this.state.isOpen === collectionName) {
      this.store.dispatch({ type: "CLICK_OUTSIDE", "collectionName": collectionName});
    }
  }

  actionAfterLoginOrRegister () {
    let action = this.getCurrentPath().action;
    let from = this.getCurrentPath().component;
    if (action === "addtoshortlist") {
      this.store.dispatch({ type: "ADD_TO_SHORTLIST_FROM_ACTION"});
    } else if (action === "removeshortlist") {
      this.store.dispatch({ type: "REMOVE_SHORTLIST_FROM_ACTION"});
    } else if (action === "showshortlist") {
      history.pushState(null, "", "#/myShortlist");
      if (from === "login") this.store.dispatch({ type: "SHOW_SHORTLIST"});
      else this.store.dispatch({ type: "SHOW_SHORTLIST_FROM_REGISTER"});
    } else if (action === "book") {
      this.store.dispatch({ type: "BOOK_NOW_AFTER_LOGIN"});
    } else {
      history.pushState(null, "", "#/" + this.getCurrentPath().searchCriteria);
      this.store.dispatch({ type: "SHOW_SEARCH_APPLET"});
    }
  }

  showSearchApplet () {
    this.store.dispatch({ type: "SHOW_SEARCH_APPLET"});
  }

  setSelectedDate (selectedDate) {
    this.store.dispatch({ type: "SELECTED_DATE_IN_SEARCH_DATEPICKER", "selectedDate": selectedDate});
  }
  cancelChangeSearchDate () {
    if (this.state.isOpen && this.state.isOpen === "date") {
      this.store.dispatch({type: "CANCEL_CHANGE_SEARCH_DATE"});
    }
  }
  goToOfferTable () {
    document.getElementById('room-list').scrollIntoView(true);
  }

  handleBookDate (momentDate) {
    this.store.dispatch({ type: "UPDATE_BOOK_DATE", "date": momentDate.format("YYYY-MM-DD")});
  }

  showbookingSummary (offer, room) {
    let parameters = "";
    if (this.state.criteria.startDate !== "-" && this.state.criteria.startDate !== "" && offer.startUntil) parameters = "/searchcriteria=SEARCH_DATE:" + this.state.criteria.startDate + ";";
    history.pushState(null, "", "#/bookingSummary/offer=" + offer.lookupValue + parameters);
    this.store.dispatch({ type: "SHOW_BOOKING_SUMMARY", "offer": offer, "room": room});
  }

}

export default injectIntl(SearchApplet, {withRef: true});

function APIError (props) {
  return <div className="pex-api-error">
    api error {props.retryMethod ? <Button className="pex-retry-button" onClick={props.retryMethod}>Retry</Button> : null}
  </div>;
}

function initState () {
  //TODO: bookingTypes doesn't follow the logic to init values.
  return {
    "criteria": {
      "categories": {},
      "budget": "-",
      "minBudget": "-",
      "gender": "-",
      "bookingTypes": "",
      "startDate": "",
    },
    "currentUserInfo": null,
    "searchData": undefined,
    "shortlist": null,
  };
}
