import React from 'react';
import Col from 'react-bootstrap/lib/Col';
import Row from 'react-bootstrap/lib/Row';
import Button from 'react-bootstrap/lib/Button';
import Form from 'react-bootstrap/lib/Form';
import InputGroup from 'react-bootstrap/lib/InputGroup';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import FormControl from 'react-bootstrap/lib/FormControl';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import Panel from 'react-bootstrap/lib/Panel';
import { PexText } from "../form-components/pex-text.jsx";
import { PexPassword } from "../form-components/pex-password.jsx";
import formService from '../../services/form-service.js';
import classNames from 'classnames';
import translateService from '../../services/translate-service.js';
import TagManager from 'react-gtm-module';
import PexCookiePolicy from "../../../legal/pex-cookie-policy.jsx";

export default class PexLogin extends React.Component {

  render () {
    let self = this;

    function translate (name) {
      return translateService.getLabelsTranslated(self.props.labels, "searchApplet.pexLogin.", name, self.props.defaultLabels);
    }

    let formProps = {
      "typeColLabel": "md",
      "sizeColLabel": 3,
      "typeColComponent": "md",
      "sizeColComponent": 12,
      values: this.props.data.values,
      errors: this.props.data.fieldsWithErrors,
      submitted: this.props.data.submitted,
      updateField: this.props.updateLoginForm,
    };


    let personIsRegistering = classNames({
      'show-register': true,
      'hide': this.props.getCurrentPath().action === "showshortlist" && !this.props.getCurrentPath().offer,
    });
    let registerButton = this.props.getCurrentPath().action === "removeshortlist" ? null : <Button bsStyle="primary" onClick={this.props.register.bind(this)} className={personIsRegistering + " pex-register-button btn-block"}>{translate("registerButton")}</Button>;

    let title = <h3 className="pex-login-form-title"><strong>{translate("sigInLabel", "Please sign in ")}<span className={personIsRegistering}>{" " + translate("registerLabel")}</span></strong></h3>;
    return <Row>
      <Col sm={6} className="login col-centered">
        <Form horizontal onSubmit={this.props.login}>
          <Panel header = {title} >
            <PexText fieldKey="username" className = "pex-login-username" iconClassName="fa fa-user" label={translate("usernameLabel")} {...formProps } />
            <PexPassword fieldKey="password" className = "pex-login-password" iconClassName="fa fa-lock" label={translate("passwordLabel")} {...formProps } />
            <FormGroup className="" controlId="" >
              <Col componentClass={ControlLabel} md={3}></Col>
              <Button bsStyle="link" className="pex-forgot-password-link pull-right" onClick={this.props.goToResetPassword}>{translate("forgotPasswordLink")}</Button>
              <Button bsStyle="success" type="submit" className="pex-login-button btn-block">{translate("loginButton") + " "}<i className="fa fa-long-arrow-right"></i></Button>
              <p className={personIsRegistering + " text-center"}>{translate("orLabel")}</p>
              {registerButton}
              {this.props.data.APIerror ? <p className="pex-login-api-error"> {this.props.data.APIerror }</p> : null}
            </FormGroup>
          </Panel>
        </Form>
      </Col>
      <PexCookiePolicy colMd={9} />
    </Row>;
  }
}
