import React from 'react';
import FormControl from 'react-bootstrap/lib/FormControl';
import { PexCommonField } from "./pex-common-field.jsx";
import PropTypes from 'prop-types';


export class PexPassword extends React.Component {
  render () {
    return <PexCommonField {...this.props}><PasswordField/></PexCommonField>;
  }
}

PexPassword.propTypes = {
  label: PropTypes.string.isRequired,
  fieldKey: PropTypes.string.isRequired,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  updateField: PropTypes.func.isRequired,
};

class PasswordField extends React.Component {
  render () {
    return <FormControl type="password" {...this.props}/>;
  }

  placeholder () {
    return this.props.placeholder;
  }

}

//couldn't figure out how to put this inside the es6 class
//https://facebook.github.io/react/docs/reusable-components.html said to put it
//in the constructor
//but then there is a message that it was to be a class attribute not instance attribute
//and then found this advice on https://github.com/facebook/react-native/issues/1772
PasswordField.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.any,
};
