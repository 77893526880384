import React from 'react';
import Button from 'react-bootstrap/lib/Button';
import utils from '../../../lib/utils.js';
import translateService from "../../services/translate-service";
import searchService from "../../services/search-service";
import {ShowError} from './show-error.jsx';
import TagManager from 'react-gtm-module';

export default class PexBookNowInfo extends React.Component {

  constructor (props) {
    super(props);
    this.state = { "redirectToBookLink": null };
  }

  render () {
    let self = this;
    function gotolink () {
      clearTimeout(this.state.redirectToBookLink);
      window.location.href = this.props.data.link;
    }
    function translate (name) {
      return translateService.getLabelsTranslated(self.props.labels, "searchApplet.bookNowInfo.", name, self.props.defaultLabels);
    }

    let message = translate("processingBookLabel");
    if (this.props.webContents && this.props.webContents["book_info.message"]) message = <span dangerouslySetInnerHTML={{__html: utils.sanitize(this.props.webContents["book_info.message"])}}></span>;
    let buttonLabel = translate("clickHereLabel");
    if (this.props.webContents && this.props.webContents["book_info.label_button_link"]) buttonLabel = <span dangerouslySetInnerHTML={{__html: utils.sanitize(this.props.webContents["book_info.label_button_link"])}}></span>;

    if (!this.props.data) return <div>just a sec</div>;
    if (this.props.data.bookNowResponse && this.props.data.bookNowResponse.status && this.props.data.bookNowResponse.status === "OK") {
      if (!this.props.data.link || this.props.data.link === undefined) return <p>{"Error, the booking link is: " + this.props.data.link}</p>;
      return <div className="booking-success">
        <div className="booking-success-header page-header">
          <h1>{"Booking " + this.props.data.bookNowResponse.booking.lookupValue}</h1>
        </div>
        <div className="row booking-success">
          <div className="col-sm-12 col-md-4 col-md-offset-4">
            <div className="panel panel-success">
              <div className="panel-body">
                <div className="booking-success-message">
                  {message}
                  <Button bsStyle="success" className="link-to-sup" onClick={() => { gotolink();}} href={this.props.data.link}>{buttonLabel}</Button>
                </div>
              </div>
              <div className="panel-footer text-right">
              </div>
            </div>
          </div>
        </div>
      </div>;
    }
    let errorToShow = searchService.getError(self.props.data.message, self.props.data.messageType, self.props.webContents, self.props.labels, "bookNowInfo_page.", "searchApplet.customAPIMessage.");
    let footerError = <Button className="go-to-search-btn" bsStyle="success" onClick={this.props.redirectToSearchApplet}>
      <i className="fa fa-icon"></i> <span className="hidden-xs">{translate("goToSearchButtonLabel")}</span>
    </Button>;
    return <ShowError error={errorToShow} header={translate("somethingWrongLabel")} classname={"pex-book-now-info-title"} footer={footerError}/>;
  }

  componentDidMount () {
    let self = this;
    if (this.props.data.bookNowResponse && this.props.data.bookNowResponse.status && this.props.data.bookNowResponse.status === "OK") {
      if (this.props.data.link || this.props.data.link !== undefined) {
        this.state.redirectToBookLink = setTimeout(function () {
          if (window.location.hash.indexOf("bookNowInfo") !== -1 && self.props.data.timeInMillis && window.location.hash.indexOf(self.props.data.timeInMillis) !== -1) {
            window.location.href = self.props.data.link;
          }
        }, this.props.timeOutBook ? this.props.timeOutBook : 5000);
      }
    }
  }

  componentWillUnmount () {
    if (this.state.redirectToBookLink) clearTimeout(this.state.redirectToBookLink);
  }

}
