import React from 'react';
import { PexCommonField } from "./pex-common-field.jsx";
import Checkbox from 'react-bootstrap/lib/Checkbox';

export class PexCheckbox extends React.Component {

  render () {
    return <PexCommonField {...this.props}><CheckboxField {...this.props}/></PexCommonField>;
  }

}

function CheckboxField (props) {

  function handleOnChange (ev, key) {
    let value = ev.target.value === "true";
    props.onChange(!value, key);
  }

  return <Checkbox className={props.fieldKey + "-checkbox"}
    value={props.values[props.fieldKey]}
    checked={props.values[props.fieldKey]}
    onChange={ (ev) =>{ handleOnChange(ev, props.fieldKey);}}>{props.text ? props.text : null} {props.link ? props.link : null}</Checkbox>;
}
