"use strict";

let utils = require("../../lib/utils");
import React from 'react';
import Carousel from 'react-bootstrap/lib/Carousel';
let intl;
let wrapIntl;
let defaultLabels = JSON.parse(JSON.stringify(require("../../../lang/search-applet/en-GB.json"))).labels["en-GB"];

function setIntl (pIntl) {
  intl = pIntl;
  wrapIntl = require("../../lib/wrapIntl")(intl);
}

function getLinksTemplates (myRoom, room, linkTemplates) {
  myRoom.arrangeLink = utils.format_path(linkTemplates.arrange, {ROOM: room.lookupValue});
  myRoom.enquireLink = utils.format_path(linkTemplates.enquire, {ROOM: room.lookupValue});
  myRoom.bookNowLink = utils.format_path(linkTemplates.book, {ROOM: room.lookupValue});
  return myRoom;
}

function getDetails (myRoom, room) {
  myRoom.images = [];
  myRoom.tabs = {
    "epc": null,
    "description": null,
    "fplan": null,
  };
  myRoom.epc = null;
  myRoom.descriptionTab = null;
  myRoom.fplan = null;
  myRoom.description = "";
  myRoom.shortDescription = "";
  myRoom.feat = "";
  myRoom.summary = "";
  myRoom.displayAddress = "";
  myRoom.area = "";
  for (let category in room.categories) {
    if (room.categories[category].lookupValue === "AREA") {
      myRoom.area = room.categories[category].tags[0].lookupValue;
    }
  }
  for (let detail in room.details) {
    if (room.details[detail].type === "IMAGE") myRoom.images.push({ "key": detail + "img", "src": room.details[detail].value});

    if (room.details[detail].type === "EPC_DOCS") {
      if (!myRoom.tabs.epc) myRoom.tabs.epc = "";
      myRoom.tabs.epc = room.details[detail].value;
    }
    if (room.details[detail].type === "FLOORPLAN") {
      myRoom.images.push({ "key": detail + "fplan", "src": room.details[detail].value });
    }
    if (room.details[detail].type === "SHORT_DESCRIPTION") {
      if (!myRoom.tabs.description) myRoom.tabs.description = {};
      myRoom.tabs.description.short += room.details[detail].value;
      myRoom.shortDescription += room.details[detail].value;
    }
    if (room.details[detail].type === "DESCRIPTION") {
      if (!myRoom.tabs.description) myRoom.tabs.description = {};
      myRoom.tabs.description.long += room.details[detail].value;
      myRoom.description += room.details[detail].value;
    }
    if (room.details[detail].type === "FPLAN") {
      if (!myRoom.tabs.fplan) myRoom.tabs.fplan = "";
      myRoom.tabs.fplan = room.details[detail].value;
    }
    if (room.details[detail].type === "FEAT") {
      myRoom.feat = room.details[detail].value;
    }
    if (room.details[detail].type === "SUMMARY") {
      myRoom.summary = room.details[detail].value;
    }
    if (room.details[detail].type === "DISPLAY_ADDRESS") {
      myRoom.displayAddress = room.details[detail].value;
    }
  }
  return myRoom;
}

function getCategories (myRoom, room) {
  myRoom.features = [];
  myRoom.floor = "";
  myRoom.place = "";
  myRoom.building = "";
  myRoom.propertyType = "";
  myRoom.portfolio = "";
  if (room.categories) {
    for (let category in room.categories) {

      if (room.categories[category].lookupValue === "FLOOR") {
        if (room.categories[category].tags) {
          for (let appliance of room.categories[category].tags) {
            myRoom.floor += appliance.displayValue + ", ";
          }
          if (myRoom.floor !== "")myRoom.floor = myRoom.floor.substring(0, myRoom.floor.length - 2);
        }

      } else if (room.categories[category].lookupValue === "PROPERTYTYPE") {
        for (let propertyType of room.categories[category].tags) {
          //this is a single choice so this will only every happen once
          //but if things ever change, we will see funny looking titles to spot the problem immediately
          //instead of just taking the first one
          myRoom.propertyType += propertyType.displayValue;
          myRoom.roomType = { "lookupValue": propertyType.lookupValue, "displayValue": propertyType.displayValue};
        }
      } else if (room.categories[category].lookupValue === "AREA") {
        if (room.categories[category].tags) {
          for (let appliance of room.categories[category].tags) {
            //this is a single choice so this will only every happen once
            //but if things ever change, we will see funny looking titles to spot the problem immediately
            //instead of just taking the first one
            myRoom.place += appliance.displayValue;
          }
        }
      } else if (room.categories[category].lookupValue === "BUILDING") {
        if (room.categories[category].tags) {
          for (let appliance of room.categories[category].tags) {
            //this is a single choice so this will only every happen once
            //but if things ever change, we will see funny looking titles to spot the problem immediately
            //instead of just taking the first one
            myRoom.building += appliance.displayValue;
          }
        }
      } else if (room.categories[category].lookupValue === "PORTFOLIO") {
        if (room.categories[category].tags) {
          for (let appliance of room.categories[category].tags) {
            myRoom.portfolio += appliance.displayValue;
          }
        }
      } else {
        if (room.categories[category].tags) {
          for (let appliance of room.categories[category].tags) {
            myRoom.features.push(appliance.displayValue);
          }
        }
      }
    }
    myRoom.features = myRoom.features.join(", ");
  }
  return myRoom;
}

//TODO: re-think if we need this and if only it is valid for testing we can improve
function getPositionRank (myRoom, room, nearestOffer, offers, hash) {
  if (typeof(room.position) !== "undefined" && room.position >= 0) {
    if (nearestOffer) {
      myRoom.positionRank = "-" + hash + "-price-" + nearestOffer.price.number + "-number-of-offers-" + offers.length + "-";
    } else {
      myRoom.positionRank = "-" + hash + "-price-" + offers[0].price.number + "-number-of-offers-" + offers.length + "-";
    }
  } else {
    myRoom.positionRank = "-" + hash + "-number-of-offers-" + offers.length + "-";
  }
  return myRoom;
}

function getPrice (myRoom, room, offers, labels) {
  let someOfferWithPrice = false;
  let fromLabel = "";
  if (defaultLabels && defaultLabels["searchApplet.pexRoom.fromLabel"]) fromLabel = defaultLabels["searchApplet.pexRoom.fromLabel"];
  if (labels && labels["searchApplet.pexRoom.fromLabel"]) fromLabel = labels["searchApplet.pexRoom.fromLabel"];
  for (let offer of offers) if (offer.price.number > 0) someOfferWithPrice = true;
  // myRoom.priceStr = utils.format_rate(wrapIntl.formatNumber(room.minPrice.number), room.minPrice.unit);
  myRoom.priceStr = utils.format_rate(room.minPrice.number, room.minPrice.unit);
  if (offers.length > 1 && someOfferWithPrice) myRoom.priceStr = fromLabel + " " + myRoom.priceStr;
  if (!someOfferWithPrice) myRoom.priceStr = "";
  return myRoom;
}

function getCarousel (images) {
  let roomImages = [];
  for (let image of images) roomImages.push(<Carousel.Item key={image.key}><img alt="room image" src={image.src}/></Carousel.Item>);
  if (roomImages.length === 0) {
    roomImages.push(<Carousel.Item key="A0">
      <img alt="room-image" src="http://placehold.it/1920x1080/ff7518/cccccc"/>
      <Carousel.Caption>
        <h3>ROOM WITHOUT IMAGES</h3>
        <p>This room has no images</p>
      </Carousel.Caption>
    </Carousel.Item>);
  }
  return roomImages;
}

module.exports = {
  processRoomInfo: function (myRoom, room, nearestOffer, offers, linkTemplates, hash, labels) {
    myRoom = getDetails (myRoom, room);
    myRoom = getLinksTemplates(myRoom, room, linkTemplates);
    myRoom = getCategories(myRoom, room);
    myRoom = getPositionRank(myRoom, room, nearestOffer, offers, hash);
    myRoom = getPrice(myRoom, room, offers, labels);
    myRoom.offers = JSON.parse(JSON.stringify(offers));
    return myRoom;
  },
  createBookCriteria: function (payload, action) {
    if (action) return action + "/offer=" + payload.offer + "/" + "startDate=" + payload.startDate;
    return "/offer=" + payload.offer + "/" + "startDate=" + payload.startDate;
  },
  getCarousel: function (images) {
    return getCarousel (images);
  },
  setIntl: function (pIntl) {
    return setIntl(pIntl);
  },
  getDetails: function (myRoom, room) {
    return getDetails(myRoom, room);
  },
  getCategories: function (myRoom, room) {
    return getCategories(myRoom, room);
  },
};
