import React from 'react';
import { PexText } from '../../form-components/pex-text.jsx';
import { PexDropDown } from "../../form-components/pex-drop-down.jsx";
import { PexDatePicker } from "../../form-components/pex-date-picker.jsx";
import Row from 'react-bootstrap/lib/Row';
import Form from 'react-bootstrap/lib/Form';
import Panel from 'react-bootstrap/lib/Panel';
import moment from 'moment';
let getCurrentMoment = require('../../../../lib/get-current-moment.js');
import classNames from 'classnames';

export default class WebForm extends React.Component {

  constructor (props) {
    super(props);
  }

  render () {
    if (this.props.stepLoaded && this.props.webForm) {
      return <Row>
        {this.getWebComponents()}
      </Row>;
    }
    else return <Row className="loading-step">loading...</Row>;
  }

  getWebComponents () {
    function getClassNames (attribute, classSpecial) {
      return classNames(attribute.path, attribute.additionalClassName, {"mandatory": attribute.mandatory}, classSpecial);
    }
    let webComponents = [];
    let formProps = {
      values: this.props.webForm.values || {},
      errors: this.props.webForm.fieldsWithErrors ? this.props.webForm.fieldsWithErrors : {},
      updateField: this.props.updateField,
      submitted: this.props.webForm.submitted,
    };
    for (let attribute of this.props.webForm.entityInfo.attributes) {
      if (attribute.fieldType === "DROPDOWN") {
        webComponents.push(<PexDropDown key={attribute.path} id={attribute.path} name={attribute.path} fieldKey={attribute.path} className={getClassNames (attribute)} placeholder={"Please select a " + attribute.name} iconClassName={attribute.iconClassName} label={attribute.label} {...formProps} > {attribute.dropdownOptions} </PexDropDown>);
      } else if (attribute.fieldType === "DATE") {
        let dateprops = {};
        if (attribute.path === "dateOfBirth") dateprops.maxDate = getCurrentMoment();
        webComponents.push(<PexDatePicker key={attribute.path} fieldKey={attribute.path} id={attribute.path} name={attribute.path} className={getClassNames (attribute, "datepicker")} iconClassName={attribute.iconClassName} label={attribute.label} {...formProps} {...dateprops} />);
      } else {
        webComponents.push(<PexText key={attribute.path} id={attribute.path} name={attribute.path} fieldKey={attribute.path} className={getClassNames (attribute)} label={attribute.label} {...formProps} iconClassName={attribute.iconClassName}/>);
      }
    }
    return webComponents;
  }

}
