import React from 'react';
import Button from 'react-bootstrap/lib/Button';

export default class ButtonDownloadPdf extends React.Component {

  downloadPdf () {
    var link = document.createElement("a");
    link.download = this.props.nameForFileDownloaded;
    link.href = this.props.agreementUrl;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  render () {
    return (
      <div className={this.props.className}>
        <Button bsStyle="info" onClick={(el, state) => this.downloadPdf()} ><i className="fa fa-arrow-circle-o-down"></i> {this.props.label}</Button>
      </div>
    );

  }

}
