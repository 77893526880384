import React from 'react';
import ajax from '../../../lib/ajax.js';
let api = require('../../../lib/api.js')(ajax);
import { createStore } from 'redux';
import {ShowError} from "./show-error.jsx";
import {LoginForm} from "./login-form.jsx";
import {WrapModal} from "../form-components/wrap-modal.jsx";
import Col from 'react-bootstrap/lib/Col';
import Row from 'react-bootstrap/lib/Row';
import Panel from 'react-bootstrap/lib/Panel';
import Image from 'react-bootstrap/lib/Image';
import ButtonGroup from 'react-bootstrap/lib/ButtonGroup';
import Button from 'react-bootstrap/lib/Button';
import Popover from 'react-bootstrap/lib/Popover';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import Alert from 'react-bootstrap/lib/Alert';
import DatePicker from 'react-datepicker';
let getCurrentMoment = require('../../../lib/get-current-moment.js');
import utils from "../../../lib/utils";
import _ from 'underscore';
import moment from "moment/moment";
import classNames from 'classnames';
import formService from "../services/form-service";

const defaultLabels = JSON.parse(JSON.stringify(require("../../../../lang/clients/grainger-booking-journey-applet/en-GB.json"))).labels["en-GB"];

function initState () {
  return {
    "nextAction": "getLabels",
    "waiting": true,
    "loaded": false,
    "error": null,
    "showModal": false,
  };
}

export default class Step0 extends React.Component {

  constructor (props) {
    super(props);
    let self = this;
    this.store = createStore(function (state = {}, action) {
      // console.log(action.type);
      let newState = {};
      switch (action.type) {
        case "LOAD": return Object.assign(state, initState(), { "lang": action.lang});
        case "GET_LABELS_OK": {
          let labels = defaultLabels;
          if (action.response.labels[state.lang])
            for (let key of Object.keys(action.response.labels[state.lang])) labels[key] = action.response.labels[state.lang][key];
          return Object.assign({}, state, {"labels": labels, "nextAction": "getSSOKeyAndValidateObject"});
        }
        case "LIST_SHORTLIST_OK": {
          if (action.shortlist) newState.shortlist = action.shortlist;
          newState.loaded = true;
          newState.waiting = false;
          newState.nextAction = "DONE";
          return Object.assign({}, state, newState);
        }
        case "START_SEARCH": {
          newState.searchData = action.searchData;
          newState.waiting = true;
          newState.unitMda = action.unitMda;
          newState.avalabilityPeriodSelected = null;
          newState.moveInDate = null;
          newState.offerSelected = null;
          newState.showOther = false;
          newState.nextAction = "search";
          return Object.assign({}, state, newState);
        }
        case "SEARCH_OK": {
          newState.searchData = action.searchData;
          newState.waiting = false;
          newState.nextAction = "DONE";
          return Object.assign({}, state, newState);
        }
        case "SELECT_AP": {
          newState.avalabilityPeriodSelected = action.avalabilityPeriodSelected;
          newState.offerSelected = action.offerSelected;
          newState.moveInDate = null;
          newState.showOther = action.showOther;
          return Object.assign({}, state, newState);
        }
        case "SET_MOVE_IN_DATE": return Object.assign({}, state, {"moveInDate": action.moveInDate});
        case "ERROR": return Object.assign(state, {"nextAction": "DONE", "laoded": true, "error": action.error, "waiting": false});
        case "MOUSE_IN_ROOM": {
          newState.roomFocused = action.roomFocused;
          return Object.assign(state, newState);
        }
        case "MOUSE_OUT_ROOM": {
          newState.roomFocused = null;
          return Object.assign(state, newState);
        }
        case "OPEN_MODAL": {
          newState.loginForm = Object.assign({}, formService.initStep0cForm(state.labels));
          newState.showModal = true;
          newState.waiting = false;
          newState.nextAction = "DONE";
          return Object.assign(state, newState);
        }
        case "CLOSE_MODAL": {
          newState.showModal = false;
          return Object.assign(state, newState);
        }
        case "VALIDATE_OBJECT_OK": {
          newState.nextAction = "listShortlist";
          return Object.assign(state, newState);
        }
        case "VALIDATE_OBJECT_FROM_MODAL": {
          newState.nextAction = "bookNow";
          return Object.assign(state, newState);
        }
        case "START_VALIDATE_OBJECT_CLICKING_LOGIN": {
          newState.waiting = true;
          newState.nextAction = "getSSOKeyAndValidateObjectFromModal";
          return Object.assign(state, newState);
        }
        case "UPDATE_FIELD": {
          newState = { "loginForm": state.loginForm};
          newState.loginForm.values[action.field] = action.value;
          newState.loginForm = Object.assign(newState.loginForm, formService.checkForm(newState.loginForm.values, newState.loginForm.formNames, state.labels, "graingerBookingJourney.login"));
          return Object.assign({}, state, newState);
        }
        case "LOGIN_FORM_SUBMITTED_WITH_ERRORS": {
          newState = { "loginForm": state.loginForm };
          newState.loginForm.submitted = true;
          return Object.assign(state, newState);
        }
        case "START_LOGIN": {
          newState.waiting = true;
          newState.nextAction = "startLogin";
          return Object.assign(state, newState);
        }
        case "USER_LOGGED_OK": {
          newState.nextAction = "bookNow";
          return Object.assign(state, newState);
        }
        case "LOGIN_ERROR": {
          newState.loginForm = state.loginForm;
          newState.loginForm.APIerror = action.error;
          newState.waiting = false;
          newState.nextAction = "DONE";
          return Object.assign(state, newState);
        }
        case "BOOK_OK": {
          newState.nextAction = "goToStep1";
          return Object.assign(state, newState);
        }
        default:
          return state;
      }
    }, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
    this.state = this.store.getState();
  }

  componentDidUpdate () {
    // console.log(this.state.nextAction);
    switch (this.state.nextAction) {
      case "getLabels":
      case "listShortlist":
      case "startSearch":
      case "search":
      case "getSSOKeyAndValidateObject":
      case "getSSOKeyAndValidateObjectFromModal":
      case "goToStep1":
      case "startLogin":
      case "bookNow":
        this[this.state.nextAction]();
    }
  }

  componentDidMount () {
    if (this.store) {
      this.unsubscribe = this.store.subscribe(function () {
        let state = {};
        for (let key of Object.keys(this.state)) state[key] = null;
        this.setState(Object.assign({}, state, this.store.getState()));
      }.bind(this));

      if (!this.props.api) return this.store.dispatch({type: "ERROR", "error": "Configuration error - No api specified"});
      if (!this.props.sso) return this.store.dispatch({type: "ERROR", "error": "Configuration error - No sso specified"});
      if (!this.props.step1URL) return this.store.dispatch({type: "ERROR", "error": "Configuration error - No step1URL specified"});

      let lang = "en-GB";
      if (this.props.lang) lang = this.props.lang;

      window.onpopstate = function () { this.store.dispatch({type: "LOAD", "lang": lang}); }.bind(this);
      this.store.dispatch({type: "LOAD", "lang": lang});
    }
    return true;
  }

  componentWillUnmount () {
    this.unsubscribe();
  }

  render () {
    if (this.state && this.state.loaded && !this.state.error) {
      return <Step state={this.state}
        startSearch={this.startSearch.bind(this)}
        selectAvailabilityPeriod={this.selectAvailabilityPeriod.bind(this)}
        onChangeMoveInDate={this.onChangeMoveInDate.bind(this)}
        handleRoomMouseEnter={this.handleRoomMouseEnter.bind(this)}
        handleRoomMouseLeave={this.handleRoomMouseLeave.bind(this)}
        modalContainer={this}
        closeModal={this.closeModal.bind(this)}
        submitLoginForm={this.submitLoginForm.bind(this)}
        updateFieldFromEvent = {this.updateFieldFromEvent.bind(this)}
        startValidateObjectClickingLogin={this.startValidateObjectClickingLogin.bind(this)}
      />;
    }
    else if (this.state.error) return <ShowError error={this.state.error} />;
    else return <div>Loading...</div>;
  }

  // API CALLS
  getLabels () {
    let self = this;
    api.getLabels(this.props.api, {"languageIds": [this.state.lang], "group": "graingerBookingJourney"}, function (response) {
      self.store.dispatch({type: "GET_LABELS_OK", "response": response});
    }, function (message, messageType) {
      let apiErrorCode = "";
      if (messageType) apiErrorCode = messageType + " - ";
      self.store.dispatch({"type": "ERROR", "error": "GRAINGER-BOOKING-JOURNEY-ERROR-0000001 API error - " + apiErrorCode + " getLabels - " + message});
    });
  }
  listShortlist () {
    let self = this;
    api.getSSOKeyAndListShortlist(this.props.sso, this.props.api, {}, function (response) {
      self.store.dispatch({ type: "LIST_SHORTLIST_OK", "shortlist": response.shortlist});
    }, function () {
      self.store.dispatch({"type": "ERROR", "error": "GRAINGER-BOOKING-JOURNEY-ERROR-0000003 error - listShortlist - Session invalid"});
    }, function (message, messageType) {
      let apiErrorCode = "";
      if (messageType) apiErrorCode = messageType + " - ";
      self.store.dispatch({"type": "ERROR", "error": "GRAINGER-BOOKING-JOURNEY-ERROR-0000002 API error - " + apiErrorCode + " listShortlist - " + message});
    });
  }
  search () {
    let self = this;
    api.progressiveSearchSearch(this.props.api, {"unitMda": this.state.unitMda}, function (data) {
      if (!data.offers || data.offers.length === 0) self.store.dispatch({"type": "ERROR", "error": "GRAINGER-BOOKING-JOURNEY-ERROR-00000045 API error - Room selected has not offers asociated"});
      else self.store.dispatch({"type": "SEARCH_OK", "searchData": data});
    }, function (message, messageType) {
      let apiErrorCode = "";
      if (messageType) apiErrorCode = messageType + " - ";
      self.store.dispatch({"type": "ERROR", "error": "GRAINGER-BOOKING-JOURNEY-ERROR-0000004 API error - " + apiErrorCode + " progressiveSearch/search - " + message});
    });
  }

  getSSOKeyAndValidateObject () {
    let self = this;
    api.getSSOKeyAndValidateObject(this.props.sso, this.props.sso, {}, function (response) {
      if (!response.loggedIn) {
        return self.store.dispatch({"type": "ERROR", "error": self.state.labels["graingerBookingJourney.step0a.userNologgedIn"]});
      } else {
        return self.store.dispatch({"type": "VALIDATE_OBJECT_OK", "response": response});
      }
    }, function (message, messageType) {
      let apiErrorCode = "";
      if (messageType) apiErrorCode = messageType + " - ";
      self.store.dispatch({"type": "ERROR", "error": "GRAINGER-BOOKING-JOURNEY-ERROR-0000005 API error - " + apiErrorCode + " validateObject - " + message});
    });
  }
  getSSOKeyAndValidateObjectFromModal () {
    let self = this;
    api.getSSOKeyAndValidateObject(this.props.sso, this.props.sso, {}, function (response) {
      if (!response.loggedIn) {
        return self.store.dispatch({"type": "OPEN_MODAL"});
      } else return self.store.dispatch({"type": "VALIDATE_OBJECT_FROM_MODAL", "response": response});
    }, function (message, messageType) {
      let apiErrorCode = "";
      if (messageType) apiErrorCode = messageType + " - ";
      self.store.dispatch({"type": "ERROR", "error": "GRAINGER-BOOKING-JOURNEY-ERROR-0000006 API error - " + apiErrorCode + " validateObject - " + message});
    });
  }
  startLogin () {
    let self = this;
    api.singleSignOnLoginobject(this.props.sso, {"username": this.state.loginForm.values.username, "password": this.state.loginForm.values.password}, function (loginResponse) {
      if (loginResponse.loggedIn) {
        self.store.dispatch({ type: "USER_LOGGED_OK"});
      }
      else {
        self.store.dispatch({type: "LOGIN_ERROR", "error": loginResponse.message});
      }
    }, function (message, messageType) {
      let apiErrorCode = "";
      if (messageType) apiErrorCode = messageType + " - ";
      self.store.dispatch({"type": "ERROR", "error": "GRAINGER-BOOKING-JOURNEY-ERROR-0000007 API error - " + apiErrorCode + " singleSignOnLoginobject - " + message});
    });
  }
  bookNow () {
    let self = this;
    api.getSSOKeyAndBook(this.props.sso, this.props.api, {"offer": this.state.offerSelected.lookupValue, "startDate": this.state.moveInDate}, function (bookNowResponse) {
      return self.store.dispatch({"type": "BOOK_OK"});
    }, function (bookNowResponse) {
      return self.store.dispatch({"type": "OPEN_MODAL"});
    },
    function (message, messageType) {
      let apiErrorCode = "";
      if (messageType) apiErrorCode = messageType + " - ";
      self.store.dispatch({"type": "ERROR", "error": "GRAINGER-BOOKING-JOURNEY-ERROR-0000008 API error - " + apiErrorCode + "getSSOKeyAndBook  - " + message});
    });
  }

  //METHODS FOR COMPONENTS
  startSearch (unitMda) {
    this.store.dispatch({"type": "START_SEARCH", "unitMda": unitMda});
  }

  selectAvailabilityPeriod (avalabilityPeriodSelected, offerSelected) {
    if (avalabilityPeriodSelected !== "other") {
      if (offerSelected.start) {
        if (offerSelected.startUntil && getCurrentMoment().isAfter(offerSelected.start) && getCurrentMoment().isAfter(offerSelected.startUntil)) {
          this.store.dispatch({"type": "ERROR", "error": "GRAINGER-BOOKING-JOURNEY-ERROR-0000021 error - wrong start dates availability"});
        } else this.store.dispatch({"type": "SELECT_AP", "avalabilityPeriodSelected": avalabilityPeriodSelected, "offerSelected": offerSelected, "showOther": false});
      } else this.store.dispatch({"type": "ERROR", "error": "GRAINGER-BOOKING-JOURNEY-ERROR-0000020 error - no offer start"});
    } else this.store.dispatch({"type": "SELECT_AP", "avalabilityPeriodSelected": avalabilityPeriodSelected, "offerSelected": offerSelected, "showOther": !this.state.showOther});
  }

  onChangeMoveInDate (event) {
    this.store.dispatch({"type": "SET_MOVE_IN_DATE", "moveInDate": moment.unix(event.target.value).format("YYYY-MM-DD")});
  }

  handleRoomMouseEnter (roomLookupValue) {
    this.store.dispatch({"type": "MOUSE_IN_ROOM", "roomFocused": roomLookupValue});
  }
  handleRoomMouseLeave (roomLookupValue) {
    this.store.dispatch({"type": "MOUSE_OUT_ROOM", "roomFocused": roomLookupValue});
  }
  closeModal () {
    this.store.dispatch({"type": "CLOSE_MODAL"});
  }
  startValidateObjectClickingLogin () {
    this.store.dispatch({"type": "START_VALIDATE_OBJECT_CLICKING_LOGIN"});
  }
  submitLoginForm () {
    if (!this.state.loginForm.canSubmit) this.store.dispatch({ type: "LOGIN_FORM_SUBMITTED_WITH_ERRORS"});
    else {
      this.store.dispatch({"type": "START_LOGIN"});
    }


  }
  goToStep1 () {
    window.location.replace(this.props.step1URL);
  }
  // METHODS
  updateFieldFromEvent (field, event) {
    let value = "";
    if (field === "privacyPolicy") value = event;
    else value = event.target.value;
    this.store.dispatch({type: "UPDATE_FIELD", "field": field, "value": value});
  }

}

function Step (props) {
  let offersBookables = null;
  if (props.state.shortlist) offersBookables = props.state.shortlist.filter(function (offer) { return offer.price.number > -1 && offer.bookable; });

  if ((offersBookables && !offersBookables.length) || !offersBookables) {
    return <Panel className={"step0a-container"}>
      <Row className={"no-shortlist"} dangerouslySetInnerHTML={{__html: utils.sanitize(props.state.labels["graingerBookingJourney.step0a.noShortlistAvilable"])}}></Row>
    </Panel>;
  }

  let offersGroupedByRoom = _.groupBy(offersBookables, function (offer) { return offer.room.lookupValue;});
  let area = offersBookables[0].room.categories.filter (function (category) { return category.lookupValue === "AREA";})[0];
  let apartamentsViewedLabel = props.state.labels["graingerBookingJourney.step0a.labelTitle2"];
  let selectApartmentLabel = props.state.labels["graingerBookingJourney.step0a.labelTitle3"];
  if (Object.keys(offersGroupedByRoom).length === 1) {
    apartamentsViewedLabel = utils.format_path(props.state.labels["graingerBookingJourney.step0a.labelTitle2Singular"], {"AREA": area.tags[0].displayValue});
    selectApartmentLabel = props.state.labels["graingerBookingJourney.step0a.labelTitle3Singular"];
  }
  else apartamentsViewedLabel = utils.format_path(apartamentsViewedLabel, {"ROOM_COUNT": Object.keys(offersGroupedByRoom).length, "AREA": area.tags[0].displayValue});

  let rooms = [];
  for (let room of Object.keys(offersGroupedByRoom)) {
    rooms.push(<Room key={room}
      offers={offersGroupedByRoom[room]}
      state={props.state}
      startSearch={props.startSearch}
      handleRoomMouseEnter={props.handleRoomMouseEnter}
      handleRoomMouseLeave={props.handleRoomMouseLeave}
    />);
  }


  let availabilityPeriods = null;
  if (props.state.searchData) availabilityPeriods = <AvailabilityPeriods state={props.state}
    selectAvailabilityPeriod={props.selectAvailabilityPeriod}
    onChangeMoveInDate={props.onChangeMoveInDate}
  />;
  let otherPopover = <Popover id="other-info">
    <div className={"other-popover-info"} dangerouslySetInnerHTML={{__html: utils.sanitize(props.state.labels["graingerBookingJourney.step0a.apMoveInContactInfo"])}}></div>
  </Popover>;
  let moveIn = <MoveIn state={props.state} onChangeMoveInDate={props.onChangeMoveInDate}/>;
  if (!props.state.avalabilityPeriodSelected) moveIn = null;
  else if (props.state.avalabilityPeriodSelected === "other") {
    moveIn = <div className={classNames("other-length row collapse", props.state.showOther ? "in" : "")} id={"other"}>
      <Col sm={12}>
        <Alert bsStyle="info">
          <OverlayTrigger trigger="click" placement="right" overlay={otherPopover} trigger="click" rootClose>
            <Button className={"ap-other-contact"} bsStyle="link">{props.state.labels["graingerBookingJourney.step0a.apMoveInContact"]}</Button>
          </OverlayTrigger>
          {props.state.labels["graingerBookingJourney.step0a.otherLabelInfo"]}
        </Alert>
      </Col>
    </div>;
  }

  let divFrozen = props.state.waiting ? "divFrozen" : "";

  let buttonsFooter = [];

  buttonsFooter.push(<Button key="0" bsStyle="link" className={"login-back"} onClick={()=>{props.closeModal();}}>{props.state.labels["graingerBookingJourney.login.backButtonLabel"]}</Button>);
  buttonsFooter.push(<Button key="1" bsStyle="default" onClick={()=>{props.submitLoginForm();}} className={"login-start"}>{props.state.labels["graingerBookingJourney.login.startButtonLabel"]}</Button>);

  return <div className={"container step0a-container " + divFrozen}>
    <WrapModal showModal={props.state.showModal} modalContainer={props.modalContainer} closeModal={props.closeModal} buttonsFooter={buttonsFooter}>
      <LoginForm closeModal={props.closeModal}
        submitLoginForm={props.submitLoginForm}
        updateFieldFromEvent = {props.updateFieldFromEvent}
        state={props.state}
      />
    </WrapModal>
    <div className={"widget-content"}>
      <Row className={"message"}>
        <Col xs={12}>
          <div className={"title"}>
            <h2 className={"step0a-title-label-1"} dangerouslySetInnerHTML={{__html: utils.sanitize(props.state.labels["graingerBookingJourney.step0a.labelTitle1"])}}></h2>
          </div>
          <div className={"title"}>
            <h4 className={"step0a-title-label-2"} dangerouslySetInnerHTML={{__html: utils.sanitize(apartamentsViewedLabel)}}></h4>
          </div>
          <div className={"title"}>
            <h4 className={"step0a-title-label-3"} dangerouslySetInnerHTML={{__html: utils.sanitize(selectApartmentLabel)}}></h4>
          </div>
        </Col>
      </Row>
      <Row className={"select-panels"}>
        <Col>
          {rooms}
        </Col>
      </Row>
      <Row className={"apply"}>
        <Col>
          <Button className={"step0a-more-rooms-label"} bsStyle={"link"} dangerouslySetInnerHTML={{__html: utils.sanitize(props.state.labels["graingerBookingJourney.step0a.moreRoomsLabel"])}}></Button>
        </Col>
      </Row>
      {availabilityPeriods}
      {moveIn}
      <Row className={"btn-bottom text-center"}>
        <Col xs={12}>
          <hr />
          <Button className={"log-in-button"} bsStyle={"success"} disabled={!props.state.moveInDate} onClick={props.startValidateObjectClickingLogin}>{props.state.labels["graingerBookingJourney.step0a.logInButtonLabel"]}</Button>
        </Col>
      </Row>
    </div>
  </div>;
}


function Room (props) {

  function handleRoomMouseEnter (lookupValue) {
    props.handleRoomMouseEnter(lookupValue);
  }
  function handleRoomMouseLeave (lookupValue) {
    props.handleRoomMouseLeave(lookupValue);
  }

  let offerMinPrice = _.min(props.offers, function (offer) { return offer.price.number;});
  let address = "";
  let flpan = <Image src={"http://via.placeholder.com/350x150"} thumbnail />;
  let size = "";
  let shortDescription = "";
  if (offerMinPrice.room.details.filter(function (detail) { return detail.type === "DISPLAY_ADDRESS";}).length > 0) {
    address = offerMinPrice.room.details.filter(function (detail) { return detail.type === "DISPLAY_ADDRESS";})[0].value;
  }
  let floor = offerMinPrice.room.categories.filter (function (category) { return category.lookupValue === "FLOOR";});
  if (floor.length > 0) {
    if (address) address += ", " + floor[0].tags[0].displayValue;
    else address = floor[0].tags[0].displayValue;
  }

  if (offerMinPrice.room.details.filter(function (detail) { return detail.type === "FPLAN";}).length > 0) {
    flpan = <Image src={offerMinPrice.room.details.filter(function (detail) { return detail.type === "FPLAN";})[0].value} thumbnail />;
  } else if (offerMinPrice.room.details.filter(function (detail) { return detail.type === "IMAGE";}).length > 0) {
    flpan = <Image src={offerMinPrice.room.details.filter(function (detail) { return detail.type === "IMAGE";})[0].value} thumbnail />;
  }
  let roomType = offerMinPrice.room.categories.filter (function (category) { return category.lookupValue === "PROPERTYTYPE";});
  if (roomType.length > 0) roomType = roomType[0].tags[0].displayValue;
  else roomType = "";

  let roomSelectedClassName = "";
  if (offerMinPrice.room.lookupValue === props.state.unitMda) roomSelectedClassName = "panel-success";

  if (offerMinPrice.room.details.filter(function (detail) { return detail.type === "SIZE";}).length > 0) {
    size = offerMinPrice.room.details.filter(function (detail) { return detail.type === "SIZE";})[0].value;
  }
  if (offerMinPrice.room.details.filter(function (detail) { return detail.type === "SHORT_DESCRIPTION";}).length > 0) {
    shortDescription = offerMinPrice.room.details.filter(function (detail) { return detail.type === "SHORT_DESCRIPTION";})[0].value;
  }
  let roomPanelHeader = <h3 className={"panel-title room-display-value"}>{props.state.labels["graingerBookingJourney.step0a.roomDisplayValue"] + " " + offerMinPrice.room.displayValue}</h3>;
  let roomPanelFooter = <div className={"text-center"}><Button bsStyle={"link"} className={"room-see-details-btn"} dangerouslySetInnerHTML={{__html: utils.sanitize(props.state.labels["graingerBookingJourney.step0a.seeDetailsLabel"])}}></Button></div>;
  let panelStyle = "default";
  if (props.state.roomFocused && props.state.roomFocused === offerMinPrice.room.lookupValue) panelStyle = "success";
  return <Col md={4} className={classNames("room", "room-" + offerMinPrice.room.lookupValue + "-container")} onClick={() => {props.startSearch(offerMinPrice.room.lookupValue);} }>
    <Panel bsStyle={panelStyle}
      onMouseEnter={() => {handleRoomMouseEnter(offerMinPrice.room.lookupValue);}}
      onMouseLeave={() => {handleRoomMouseLeave(offerMinPrice.room.lookupValue);}}
      className={classNames(roomSelectedClassName, "room-" + offerMinPrice.room.lookupValue)}
      header={roomPanelHeader} footer={roomPanelFooter}>
      <div className={"room-min-price"}>{utils.format_rate(offerMinPrice.price.number, offerMinPrice.price.unit)}</div>
      <div className={"room-type"}>{roomType}</div>
      <div className={"room-address"} dangerouslySetInnerHTML={{__html: utils.sanitize(address)}}></div>
      <div className={"room-size"} dangerouslySetInnerHTML={{__html: utils.sanitize(size)}}></div>
      <Row className={"room-fplan"}>
        <Col xs={12}>
          {flpan}
        </Col>
      </Row>
      <div className={"room-short-description"} dangerouslySetInnerHTML={{__html: utils.sanitize(shortDescription)}}></div>
    </Panel>
  </Col>;
}

function AvailabilityPeriods (props) {

  let availabilityPeriods = [];
  let offersGroupedByAvailability = _.sortBy(props.state.searchData.offers, function (offer) { return offer.availability.lookupValue; } );

  offersGroupedByAvailability = _.groupBy(offersGroupedByAvailability, function (offer) { return offer.availability.lookupValue;});
  for (let key of Object.keys(offersGroupedByAvailability)) {
    availabilityPeriods.push(<ButtonGroup key={key}><Button key={key}
      active={props.state.avalabilityPeriodSelected && props.state.avalabilityPeriodSelected !== "other" && props.state.avalabilityPeriodSelected.lookupValue === key}
      className={"ap-btn"}
      onClick={() => {props.selectAvailabilityPeriod(offersGroupedByAvailability[key][0].availability, offersGroupedByAvailability[key][0]);} }>
      {offersGroupedByAvailability[key][0].availability.displayValue}</Button></ButtonGroup>);
  }
  availabilityPeriods.push(<ButtonGroup key={"other"}><Button key={"other"} className={"ap-btn"} onClick={() => {props.selectAvailabilityPeriod("other");} }>
    {props.state.labels["graingerBookingJourney.step0a.apOther"]}</Button></ButtonGroup>);

  return <Row className={"ap-container tenancy-length"}>
    <Col xs={12}>
      <h1 className={"ap-title"} dangerouslySetInnerHTML={{__html: utils.sanitize(props.state.labels["graingerBookingJourney.step0a.apTitle"])}}></h1>
      <h4 className={"ap-standard-tenancy"} dangerouslySetInnerHTML={{__html: utils.sanitize(props.state.labels["graingerBookingJourney.step0a.apStandardTenancy"])}}></h4>
      <h4 className={"ap-rent-increase"} dangerouslySetInnerHTML={{__html: utils.sanitize(props.state.labels["graingerBookingJourney.step0a.apRentIncrease"])}}></h4>
      <ButtonGroup className={"ap-btn-group"} justified>{availabilityPeriods}</ButtonGroup>
    </Col>
  </Row>;
}

function MoveIn (props) {
  function handleChangeDate (date) {
    props.onChangeMoveInDate({target: {value: date.unix()}});
  }
  let contactUsPopover = <Popover id="contact-us-info">
    <div className={"ap-move-in-contact-popover-info"} dangerouslySetInnerHTML={{__html: utils.sanitize(props.state.labels["graingerBookingJourney.step0a.apMoveInContactInfo"])}}></div>
  </Popover>;

  let minDate = moment(props.state.offerSelected.start);
  if (getCurrentMoment().isAfter(props.state.offerSelected.start)) minDate = getCurrentMoment();
  let maxDate = moment(minDate).add(3, "months");
  if (props.state.offerSelected.startUntil) maxDate = moment(props.state.offerSelected.startUntil);

  return <Row className={"date-select"}>
    <Col xs={12}>
      <h1 className={"ap-move-in-title"} dangerouslySetInnerHTML={{__html: utils.sanitize(props.state.labels["graingerBookingJourney.step0a.apMoveInTitle"])}}></h1>
      <h4 className={"ap-move-in-subtitle"}>{props.state.labels["graingerBookingJourney.step0a.apMoveInSubtitle"]}
        <OverlayTrigger trigger="click" placement="right" overlay={contactUsPopover} trigger="click" rootClose>
          <Button className={"ap-move-in-contact"} bsStyle="link">{props.state.labels["graingerBookingJourney.step0a.apMoveInContact"]}</Button>
        </OverlayTrigger>
      </h4>
    </Col>

    <Col xs={12} className="move-in-datepicker-container text-center">
      <DatePicker className={ "move-in-datepicker"}
        selected={props.state.moveInDate ? moment(props.state.moveInDate, "YYYY-MM-DD") : null}
        onChange={(date) => {handleChangeDate(date);}}
        minDate={minDate}
        maxDate={maxDate}
        monthsShown={2}
        inline={true}/>
    </Col>
  </Row>;
}
