import React from 'react';
import InputGroup from 'react-bootstrap/lib/InputGroup';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import classNames from 'classnames';
import Dropdown from 'react-bootstrap/lib/Dropdown';
import onClickOutside from 'react-onclickoutside';

class PexRadioSelectDropdown extends React.Component {
  constructor (props) {
    super(props);
  }
  handleClickOutside () {
    this.props.onClickOutside(this.props.collectionName);
  }


  render () {
    let classesParent = this.props.className;
    return <FormGroup className={classNames(classesParent) + " radio-dropdown-container dropdown-radio-select dropdown-radio-" + this.props.collectionName} >
      <ControlLabel htmlFor={this.props.label}>{this.props.label}</ControlLabel>
      <InputGroup>
        <InputGroup.Addon><i className={this.props.iconClassName}/></InputGroup.Addon>
        <RadioSelectField onClickOpen={this.props.onClickOpen}
          onSelectOption={this.props.onSelectOption}
          open={this.props.open}
          data={this.props.data}
          labels={this.props.labels}
          defaultLabels={this.props.defaultLabels}
          collectionName={this.props.collectionName}
          placeholder={this.props.placeholder}
          value={this.props.value}
          elementDisabled = {this.props.elementDisabled}/>
      </InputGroup>
    </FormGroup>;
  }
}
//This export is required to use the clickOutside event.
export default onClickOutside(PexRadioSelectDropdown);

function RadioSelectField (props) {
  function onClickOpen () {
    props.onClickOpen(props.collectionName);
  }
  //This event is required to manage the open attribute, if we remove the declaration it won't work
  function onToggle () {
    return;
  }

  let open = false;
  if (props.open && props.open.length > 0 && props.open === props.collectionName ) open = true;
  return <Dropdown id={props.collectionName} disabled={props.elementDisabled} className={"dropdown-single-select dropdown-single-" + props.collectionName} open={open} onToggle={onToggle}>
    <Dropdown.Toggle id={props.collectionName}
      className ={"radio-select-toggle-btn dropdown-toggle-" + props.collectionName}
      onClick={ () => {onClickOpen();}}>
      <DropDownText data={props.data}
        labels={props.labels}
        defaultLabels={props.defaultLabels}
        placeholder={props.placeholder}
        value={props.value}/>
    </Dropdown.Toggle>
    <Dropdown.Menu className={"pex-menu-items-" + props.collectionName}>
      <DropDownOptions collectionName={props.collectionName}
        data={props.data}
        onSelectOption={props.onSelectOption}
        labels={props.labels}
        value={props.value}/>
    </Dropdown.Menu>
  </Dropdown>;
}

function DropDownText (props) {
  return <span className="dropdown-text">{props.data[props.value]}</span>;
}

function DropDownOptions (props) {

  function translateSearchFormTagLabels (key, value) {
    if (props.labels && props.labels["searchApplet.searchForm.radioSelectTag" + key]) return props.labels["searchApplet.searchForm.radioSelectTag" + key];
    return value;
  }
  function onSelectOption (value) {
    props.onSelectOption(value);
  }

  let finalElements = [];
  //The place holder is the first value that we show
  let placeHolderSelected = "radio-element-no-selected";
  if ("-" === props.value ) placeHolderSelected = "radio-element-selected";
  finalElements.push(<li id="placeHolder" key="placeHolder" className={"list-group-item pex-radio pex-radio-element-" + props.collectionName + "-placeholder"} onClick={() => onSelectOption("-")} >
    <i className={placeHolderSelected} /> {translateSearchFormTagLabels("-", props.data["-"])}
  </li>);
  //rest of options
  if (props.data && Object.keys(props.data).length > 0) {
    Object.keys(props.data).map(function (key) {
      if (key !== "-") {
        let selectedOrNoClass = "radio-element-no-selected";
        if (key === props.value ) selectedOrNoClass = "radio-element-selected radio-selected-" + props.collectionName + "-" + key;
        finalElements.push(<li id={key} key={key} className={"list-group-item pex-radio pex-radio-element-" + props.collectionName + "-" + key} onClick={() => onSelectOption(key)} >
          <i className={selectedOrNoClass} /> {translateSearchFormTagLabels(key, props.data[key])}
        </li>);
      }

    });
  }

  return <span>{finalElements}</span>;
}
